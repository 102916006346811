import React, { useState, useEffect } from 'react';
import './Statuses.scss';
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../../Page/Layer/SubMenu/SubMenu';
import CreateStatus from './Statuses/Modal/CreateStatus';
import DeleteStatus from './Statuses/Modal/DeleteStatus';
import EditStatus from './Statuses/Modal/EditStatus';
import { STATUSES } from '../../../../../utils/constants/api';
import Button, {Size, Type} from '../../../../Component/Button/Button';
import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';


type Statuses = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

export type StatusFromApi = {
    id: string,
    name: string,
}

function Statuses(props: Statuses) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createStatusModal, setCreateStatusModal] = useState<boolean>(false);
    const [editStatusModal, setEditStatusModal] = useState<boolean>(false);
    const [deleteStatusModal, setDeleteStatusModal] = useState<boolean>(false);

    const [statuses, setStatuses] = useState<StatusFromApi[]>([]);
    const [statusToChange, setStatusToChange] = useState<StatusFromApi | null>(null);
    const [statusToDelete, deleteStatus] = useState<StatusFromApi | null>(null);

    useEffect(() => {
        getStatuses();
    }, [])

    function getStatuses() {
        fetch(STATUSES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((statuses: StatusFromApi[]) => setStatuses(statuses));
    }

    function editStatusModalVisibility(status: StatusFromApi): void {
        setStatusToChange(status);
        setEditStatusModal(true);
    }

    function confirmDeleteStatusModalVisibility(status: StatusFromApi): void {
        deleteStatus(status)
        setDeleteStatusModal(true);
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Employee Statuses</h3>
                                </div>

                                <div className='add-button-block'>
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add status"
                                        size={ Size.Medium }
                                        type={ Type.Accent }
                                        onClick={ () => setCreateStatusModal(true) }
                                    />
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <table className="statuses-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Status
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {statuses.map(status => {
                                        return (
                                            <tr key={ status.id }>
                                                <td title={ status.name }>{ status.name }</td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editStatusModalVisibility(status) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteStatusModalVisibility(status) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateStatus
                    opened={ createStatusModal }
                    onOk={ () => { getStatuses(); setCreateStatusModal(false); } }
                    onClose={ () => setCreateStatusModal(false) }
                />
                {
                    statusToDelete ?
                        <DeleteStatus
                            opened={ deleteStatusModal }
                            onOk={ () => { getStatuses(); setDeleteStatusModal(false); deleteStatus(null) } }
                            onClose={ () => { setDeleteStatusModal(false); deleteStatus(null) } }
                            status={ statusToDelete }
                        /> : null
                }
                {
                    statusToChange
                        ?
                        <EditStatus
                            opened={ editStatusModal }
                            onOk={ () => { getStatuses(); setEditStatusModal(false); setStatusToChange(null) } }
                            onClose={ () => { setEditStatusModal(false); setStatusToChange(null) } }
                            status={ statusToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Statuses;