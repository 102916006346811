import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select';

import Button, {Size, Type} from "../../../../../Component/Button/Button";
import Checkbox from "../../../../../Component/Input/Checkbox/Checkbox";

import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import {CountryFromApi} from "../../../../../../shared/Location/data/CountryFromApi";
import {CompanyFromApi} from "../../../../../../shared/Company/data/CompanyFromApi";
import {FilterValues} from "../../../../../../shared/Main/Calendar/data/FilterValues";

import blue from '../../../../../../assets/images/input/main-calendar/blue_8.svg';
import orange from '../../../../../../assets/images/input/main-calendar/orange_8.svg';
import green from '../../../../../../assets/images/input/main-calendar/green_8.svg';

import {COMPANIES, COUNTRIES} from "../../../../../../utils/constants/api";

import './Filter.scss';

type Filter = {
    filterValues: FilterValues,
    updateFilterValues: (values: FilterValues) => void,
    onOk: (newFilterValues: FilterValues) => any,
    onCancel: () => any,
}

function Filter(props: Filter) {

    const [showStateHolidays, setShowStateHolidays] = useState<boolean>(props.filterValues.showStateHolidays);
    const [showProfessionalHolidays, setShowProfessionalHolidays] = useState<boolean>(props.filterValues.showProfessionalHolidays);
    const [showCorporateEvents, setShowCorporateEvents] = useState<boolean>(props.filterValues.showCorporateEvents);

    const [country, setCountry] = useState<SelectValue|null>(null);
    const [countryOptions, setCountryOptions] = useState<SelectValue[]>([]);

    const [company, setCompany] = useState<SelectValue|null>(null);
    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);

    const filterRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        fetch(COUNTRIES + '?usedInLocations=true', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
            })
            .then(response => response.json())
            .then(data => {
                const countries = data.map((country: CountryFromApi) => ({ label: country.name, value: country.code }));
                setCountryOptions(countries);
            })
            .catch(error => console.error('An error occurred fetching countries:', error));

        fetch(COMPANIES, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                const companies = data.map((company: CompanyFromApi) => ({ label: company.name, value: company.id }));
                setCompanyOptions(companies);
            })
            .catch(error => console.error('An error occurred fetching companies:', error));
    }, []);

    useEffect(() => {
        if (countryOptions.length > 0 && props.filterValues.countryCodeFromFilter) {
            const selectedCountry = countryOptions.find(c => c.value === props.filterValues.countryCodeFromFilter);
            setCountry(selectedCountry || null);
        }

        if (companyOptions.length > 0 && props.filterValues.companyIdFromFilter) {
            const selectedCompany = companyOptions.find(c => c.value === props.filterValues.companyIdFromFilter);
            setCompany(selectedCompany || null);
        }
    }, [countryOptions, companyOptions]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as Node;
            if (filterRef.current !== null) {
                if (!filterRef.current?.contains(target)) {
                    props.onCancel();
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function handleCountryChange(newCountry: any) {
        setCountry(newCountry);
    }

    function handleCompanyChange(newCompany: any) {
        setCompany(newCompany);
    }

    function onOkHandler() {
        if (country && company) {
            const updatedFilterValues: FilterValues = {
                showStateHolidays,
                showProfessionalHolidays,
                showCorporateEvents,
                countryCodeFromFilter: country.value,
                companyIdFromFilter: company.value,
            };

            props.updateFilterValues(updatedFilterValues);

            props.onOk(updatedFilterValues);
        } else {
            console.error('Country and company must be selected!');
        }
    }

    function updateShowStateHolidays(newValue: boolean) {
        if (!newValue && !showProfessionalHolidays && !showCorporateEvents) {
            return;
        }
        setShowStateHolidays(newValue);
    }

    function updateShowProfessionalHolidays(newValue: boolean) {
        if (!newValue && !showStateHolidays && !showCorporateEvents) {
            return;
        }
        setShowProfessionalHolidays(newValue);
    }

    function updateShowCorporateEvents(newValue: boolean) {
        if (!newValue && !showStateHolidays && !showProfessionalHolidays) {
            return;
        }
        setShowCorporateEvents(newValue);
    }

    return (
        <div className="filter-container" ref={filterRef}>
            <div className="filter-group">
                <div className={'header'}>Type</div>
                <div className="body">
                    <div className='filter-checkbox only-input-container'>
                        <Checkbox
                            disabled={false}
                            label={null}
                            required={null}
                            checked={showStateHolidays}
                            checkBoxLabel='State holidays'
                            onChange={updateShowStateHolidays}
                            switch={false}
                        />
                        <img src={orange} style={{marginLeft : '5px', marginTop: '2px', height: '10px'}}/>
                    </div>
                    <div className='filter-checkbox only-input-container'>
                        <Checkbox
                            disabled={false}
                            label={null}
                            required={null}
                            checked={showProfessionalHolidays}
                            checkBoxLabel='Professional holidays'
                            onChange={updateShowProfessionalHolidays}
                            switch={false}
                        />
                        <img src={green} style={{marginLeft : '5px', marginTop : '2px', height : '10px'}}/>
                    </div>
                    <div className='filter-checkbox only-input-container'>
                        <Checkbox
                            disabled={false}
                            label={null}
                            required={null}
                            checked={showCorporateEvents}
                            checkBoxLabel='Corporate events'
                            onChange={updateShowCorporateEvents}
                            switch={false}
                        />
                        <img src={blue} style={{marginLeft : '5px', marginTop : '-2px', height : '10px'}}/>
                    </div>
                </div>
            </div>

            <div className="filter-group">
                <div className={'header'}>Location</div>
                <div className="body">
                    <Select
                        classNamePrefix='react-select'
                        isSearchable={ true }
                        isMulti={ false }
                        value={ country }
                        options={ countryOptions }
                        onChange={ newCountry => handleCountryChange(newCountry)}
                        placeholder={'Type a location'}
                    />
                </div>
            </div>

            <div className="filter-group">
                <div className={'header'}>Company</div>
                <div className="body">
                    <Select
                        classNamePrefix='react-select'
                        isSearchable={ true }
                        isMulti={ false }
                        value={ company }
                        options={ companyOptions }
                        onChange={ newCompany => handleCompanyChange(newCompany)}
                        placeholder={'Type a company'}
                    />
                </div>
            </div>

            <div className={'filter-footer'}>
                <Button
                    text={ 'Cancel' }
                    size={ Size.Medium }
                    type={ Type.Ghost }
                    onClick={ props.onCancel }
                />
                <Button
                    text={ 'Apply' }
                    size={ Size.Medium }
                    type={ Type.Accent }
                    onClick={ onOkHandler }
                />
            </div>
        </div>
    );
}

export default Filter;
