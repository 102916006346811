import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Popover } from '@headlessui/react'

import CreateCorporateEvent from './Modal/CreateCorporateEvent';
import EditCorporateEvent from './Modal/EditCorporateEvent';
import DeleteCorporateEvent from "./Modal/DeleteCorporateEvent";
import Button, {Size, Type} from '../../../../Component/Button/Button';

import { CorporateEventFromApi } from '../../../../../shared/Holiday/CorporateEvent/data/CorporateEventFromApi';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import { LocationFromApi } from '../../Locations/Locations';
import {AuthEmployee} from "../../../../../shared/Security/data/AuthEmployee";

import { COMPANIES, LOCATIONS, CORPORATE_EVENTS } from '../../../../../utils/constants/api';
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_OPERATIONAL_MANAGER
} from "../../../../../shared/Security/constants/AccessLevels";

import './CorporateEvents.scss';
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";

type CorporateEvents = {
    year: string,
    authEmployee: AuthEmployee,
}

function CorporateEvents(props: CorporateEvents) {
    const [createCorporateEventModal, setCreateCorporateEventModal] = useState<boolean>(false);
    const [editCorporateEventModal, setEditCorporateEventModal] = useState<boolean>(false);
    const [deleteCorporateEventModal, setDeleteCorporateEventModal] = useState<boolean>(false);

    const [corporateEvents, setCorporateEvents] = useState<CorporateEventFromApi[]>([]);
    const [corporateEventToChange, setCorporateEventToChange] = useState<CorporateEventFromApi | null>(null);
    const [corporateEventToDelete, deleteCorporateEvent] = useState<CorporateEventFromApi | null>(null);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);
    const [locations, setLocations] = useState<LocationFromApi[]>([]);

    function getCompanies(): void {
        fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((companies: CompanyFromApi[]) => setCompanies(companies));
    }

    function getLocations(): void {
        fetch(LOCATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((locations: LocationFromApi[]) => setLocations(locations));
    }

    useEffect(() => {
        getCompanies();
        getLocations();
        if (props.year) {
            getCorporateEvents(props.year);
        }
    }, [props.year]);

    function getCorporateEvents(year: string) {
        return fetch(CORPORATE_EVENTS + '?year=' + year, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((corporateEvents: CorporateEventFromApi[]) => setCorporateEvents(corporateEvents));
    }

    function editCorporateEventModalVisibility(corporateEvent: CorporateEventFromApi): void {
        setCorporateEventToChange(corporateEvent);
        setEditCorporateEventModal(true);
    }

    function confirmDeleteCorporateEventModalVisibility(corporateEvent: CorporateEventFromApi): void {
        deleteCorporateEvent(corporateEvent)
        setDeleteCorporateEventModal(true);
    }

    function getLocationNames(locationIds: string[]): string {
        let locationNames: string[] = [];
        locationIds.forEach(locationId => {
            const location = locations.find(location => location.id === locationId);
            if (location) {
                locationNames.push(location.name + ' ' + getGMTOffsetByTimezoneName(location.value.timezone));
            }
        });
        return locationNames.join('; ');
    }

    function showCorporateEvent({corporateEvent}: { corporateEvent: any }): void {
        fetch(CORPORATE_EVENTS + '/' + corporateEvent.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: corporateEvent.value.name,
                companyIds: corporateEvent.value.companyIds,
                locationIds: corporateEvent.value.locationIds,
                date: corporateEvent.value.date,
                dayOff: corporateEvent.value.dayOff,
                paidTimeOff: corporateEvent.value.paidTimeOff,
                text: corporateEvent.value.text,
                visible: !corporateEvent.value.visible,
                archiveAfterFinish: corporateEvent.value.archiveAfterFinish
            })
        })
            .then(response => {
                if (response.ok) {
                    getCorporateEvents(props.year);
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    // Access levels
    const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div className='corporate-events'>
            {hasRole ?
                <div className='add-button-block'>
                    { props.year ?
                        <Button
                            text="<i className='material-icons add-item'>add</i> Add Corporate Event"
                            size={ Size.Medium }
                            type={ Type.Accent }
                            onClick={ () => setCreateCorporateEventModal(true) }
                        /> : null }
                </div> : null
            }
            <table className="corporate-events-table">
                <thead>
                <tr>
                    <th>
                        Name
                        <a href='#'>
                            <i className='material-icons'>arrow_downward</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Locations
                    </th>
                    <th className={'center'}>
                        Company
                        <a className={'zero-padding'} href='#'>
                            <i className='material-icons'>arrow_drop_down</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Date
                        <a href='#'>
                            <i className='material-icons'>filter_list</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Text
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {corporateEvents.map(corporateEvent => {
                    return (
                        <tr key={ corporateEvent.id }>
                            <td>{ corporateEvent.value.name }</td>
                            <td className={'center'}>{getLocationNames(corporateEvent.value.locationIds)}</td>
                            <td className={'center'}><div className={'count-margin-fix'}>{ corporateEvent.value.companyIds.length }</div></td>
                            <td className={'center'}>{ corporateEvent.value.date ? moment(corporateEvent.value.date).format("DD.MM.YYYY") : '' }</td>
                            <td className={'center'}>{ corporateEvent.value.text ?
                                <i className='material-icons check-success'>done</i> :
                                <i className='material-icons'>remove</i> }</td>
                            <td className={'center'}>
                                {hasRole ?
                                    <Popover className="relative">
                                        <Popover.Button className={'vert-menu-btn'}><i className='material-icons'>more_vert</i></Popover.Button>

                                        <Popover.Panel className="absolute z-10">
                                            <i className='material-icons'
                                               onClick={ () => editCorporateEventModalVisibility(corporateEvent) }>
                                                edit
                                            </i>
                                            <i className='material-icons'
                                               onClick={ () => confirmDeleteCorporateEventModalVisibility(corporateEvent) }>
                                                delete
                                            </i>
                                            <i className='material-icons'
                                               onClick={ () => showCorporateEvent({corporateEvent: corporateEvent}) }>
                                                {corporateEvent.value.visible ? "visibility" : "visibility_off"}
                                            </i>
                                        </Popover.Panel>
                                    </Popover> : null
                                }
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <CreateCorporateEvent
                opened={ createCorporateEventModal }
                onOk={ () => { getCorporateEvents(props.year); setCreateCorporateEventModal(false); } }
                onClose={ () => setCreateCorporateEventModal(false) }
                companies={ companies }
                locations={ locations }
            />
            {
                corporateEventToDelete ?
                    <DeleteCorporateEvent
                        opened={ deleteCorporateEventModal }
                        onOk={ () => { getCorporateEvents(props.year); setDeleteCorporateEventModal(false); deleteCorporateEvent(null) } }
                        onClose={ () => { setDeleteCorporateEventModal(false); deleteCorporateEvent(null) } }
                        corporateEvent={ corporateEventToDelete }
                    /> : null
            }
            {
                corporateEventToChange
                    ?
                    <EditCorporateEvent
                        opened={ editCorporateEventModal }
                        onOk={ () => { getCorporateEvents(props.year); setEditCorporateEventModal(false); setCorporateEventToChange(null) } }
                        onClose={ () => { setEditCorporateEventModal(false); setCorporateEventToChange(null) } }
                        companies={ companies }
                        locations={ locations }
                        corporateEvent={ corporateEventToChange }
                    />
                    : null
            }
        </div>
    );
}

export default CorporateEvents;