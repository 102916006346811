import React from 'react';
import BaseModal  from './BaseModal';
import {CORPORATE_EVENTS} from '../../../../../../utils/constants/api';
import { CorporateEventFromApi } from '../../../../../../shared/Holiday/CorporateEvent/data/CorporateEventFromApi';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../../utils/constants/errors";
import moment from "moment/moment";
import {CompanyFromApi} from "../../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../../Locations/Locations";
import {getGMTOffsetByTimezoneName} from "../../../../../Common/Timezones/Services";

type EditCorporateEvent = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    corporateEvent: CorporateEventFromApi
    companies: CompanyFromApi[],
    locations: LocationFromApi[]
};

function EditCategory(props: EditCorporateEvent) {
    function edit(
        name: string,
        companies: string[],
        locations: string[],
        date: Date | null,
        dayOff: boolean,
        paidTimeOff: boolean,
        text: string,
        visible: boolean,
        archiveAfterFinish: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(CORPORATE_EVENTS + '/' + props.corporateEvent.id , {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                companyIds: companies,
                locationIds: locations,
                date: date ? date.getFullYear() + '-' + (date.getMonth()+1) + "-" + date.getDate() : null,
                dayOff: dayOff,
                paidTimeOff: paidTimeOff,
                text: text,
                visible: visible,
                archiveAfterFinish: archiveAfterFinish
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'title.categoryId',
                        message: 'Category is required'
                    }]);
                }
            });
    }

    return (
        <BaseModal
            opened={props.opened}
            modalTitle='Edit Corporate Event'
            name={props.corporateEvent.value.name}
            companyValues={ props.companies }
            companies={props.corporateEvent.value.companyIds.map(company => {
                let companyName = '';
                props.companies.map(companyValue => {
                    if (companyValue.id === company) {
                        companyName = companyValue.name;
                    }
                })
                return {
                    label: companyName,
                    value: company
                }
            }).filter(company => company.label !== '')}
            locationValues={ props.locations }
            locations={props.corporateEvent.value.locationIds.map(location => {
                let locationName = '';
                props.locations.map(locationValue => {
                    if (locationValue.id === location) {
                        locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                    }
                })
                return {
                    label: locationName,
                    value: location
                }
            }).filter(company => company.label !== '')}
            date={ props.corporateEvent.value.date ? new Date(moment(props.corporateEvent.value.date).format('YYYY-MM-DD HH:mm')) : null }
            dayOff={props.corporateEvent.value.dayOff ? 'true' : 'false'}
            paidTimeOff={props.corporateEvent.value.paidTimeOff ? 'true' : 'false'}
            text={props.corporateEvent.value.text}
            visible={props.corporateEvent.value.visible}
            archiveAfterFinish={props.corporateEvent.value.archiveAfterFinish}
            okText='Edit'
            onCancel={props.onClose}
            onOk={edit}
         />
    )
}

export default EditCategory;