import { Navigate, Outlet } from 'react-router-dom'
import { MAIN } from '../../../utils/constants/routes';
import { AuthEmployee } from '../../../shared/Security/data/AuthEmployee';

type PrivateRoute = {
    authEmployee: AuthEmployee | null,
    roles: string[]
}

function PrivateRoutes({ authEmployee, roles }: PrivateRoute) {
    if (!authEmployee) {
        // If not logged in, redirect to main page
        return <Navigate to={ MAIN } />;
    } else if (!roles.some(role => authEmployee.roles.includes(role))) {
        // If logged in but doesn't have the required role, redirect to a forbidden page
        return <Navigate to="/forbidden" />;
    }
    // If logged in and has the required role, render the component
    return <Outlet />;
}

export default PrivateRoutes;