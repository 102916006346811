export function getRegexForSocial (socialValue: string | undefined) {
    switch (socialValue) {
        case 'LinkedIn':
            return /^[a-zA-Z0-9а-яА-ЯёЁіІїЇєЄґҐ_-]*$/;
        case 'Other':
            return /^[^"'\\`]*$/;
        case 'TikTok':
        case 'Instagram':
        case 'Facebook':
        case 'Twitter':
        case 'Reddit':
        case 'YouTube':
        case 'Telegram':
        case 'Threads':
        case 'Calendly':
            return /^[a-zA-Z0-9-_.]*$/;
        case 'WhatsApp':
        case 'Signal':
        case 'Viber':
            return /^\+?[0-9\s-()]*$/;
        case 'Slack':
            return /^[a-zA-Z0-9._-]*(@[a-zA-Z0-9.-]*)?$/;
        case 'Threema':
            return /^(\+?|\+?[0-9\s-()]*)$|^[a-zA-Z0-9._-]*(@[a-zA-Z0-9.-]*)?$/;
        default:
            return /^[a-zA-Z0-9\-_]*$/;
    }
}