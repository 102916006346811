import React, { useState, useEffect } from 'react';
import './TimeOffTypes.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import CreateTimeOffType from './Modal/CreateTimeOffType';
import DeleteTimeOffType from './Modal/DeleteTimeOffType';
import EditTimeOffType from './Modal/EditTimeOffType';
import {TIME_OFF_TYPE_ICONS, TIME_OFF_TYPES} from '../../../../utils/constants/api';
import Button, {Size, Type} from '../../../Component/Button/Button';
import {TimeOffTypeFromApi} from '../../../../shared/TimeOffType/data/TimeOffTypeFromApi';
import {IconFromApi} from "../../../../shared/TimeOffType/data/IconFromApi";
import {TimeOffTypeIconsCollection} from "../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';

type TimeOffTypes = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function TimeOffTypes(props: TimeOffTypes) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createTimeOffTypeModal, setCreateTimeOffTypeModal] = useState<boolean>(false);
    const [editTimeOffTypeModal, setEditTimeOffTypeModal] = useState<boolean>(false);
    const [deleteTimeOffTypeModal, setDeleteTimeOffTypeModal] = useState<boolean>(false);

    const [iconsFromApi, setIconsFromApi] = useState<IconFromApi[]>([]);
    const [timeOffTypes, setTimeOffTypes] = useState<TimeOffTypeFromApi[]>([]);
    const [timeOffTypeToChange, setTimeOffTypeToChange] = useState<TimeOffTypeFromApi | null>(null);
    const [timeOffTypeToDelete, deleteTimeOffType] = useState<TimeOffTypeFromApi | null>(null);

    useEffect(getTimeOffTypes, []);
    useEffect(getIconsFromApi, []);

    function getTimeOffTypes() {
        fetch(TIME_OFF_TYPES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((timeOffTypes: TimeOffTypeFromApi[]) => setTimeOffTypes(timeOffTypes));
    }

    function getIconsFromApi(): void {
        fetch(TIME_OFF_TYPE_ICONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((icons: IconFromApi[]) => setIconsFromApi(icons))
    }

    function getIconNameById(id: number): string {
        let iconName = iconsFromApi.find(icon => icon.id === id)?.correspondingIconName;
        return iconName ? iconName : '';
    }

    function editTimeOffTypeModalVisibility(timeOffType: TimeOffTypeFromApi): void {
        setTimeOffTypeToChange(timeOffType);
        setEditTimeOffTypeModal(true);
    }

    function confirmDeleteTimeOffTypeModalVisibility(timeOffType: TimeOffTypeFromApi): void {
        deleteTimeOffType(timeOffType)
        setDeleteTimeOffTypeModal(true);
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Time Off Types</h3>
                                </div>

                                <div className='add-button-block'>
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add Time Off Type"
                                        size={ Size.Medium }
                                        type={ Type.Accent }
                                        onClick={ () => setCreateTimeOffTypeModal(true) }
                                    />
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <table className='time-off-types-table'>
                                    <thead>
                                    <tr>
                                        <th>
                                            Time Off Type
                                        </th>
                                        <th>
                                            Icon
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {timeOffTypes.map(timeOffType => {
                                        return (
                                            <tr key={ timeOffType.id }>
                                                <td title={ timeOffType.value.name }>{ timeOffType.value.name }</td>

                                                <td>
                                                    {<div dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[
                                                            getIconNameById(timeOffType.value.timeOffTypeIconId) as keyof typeof TimeOffTypeIconsCollection
                                                            ] }}/>}
                                                </td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editTimeOffTypeModalVisibility(timeOffType) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteTimeOffTypeModalVisibility(timeOffType) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateTimeOffType
                    opened={ createTimeOffTypeModal }
                    onOk={ () => { getTimeOffTypes(); setCreateTimeOffTypeModal(false); } }
                    onClose={ () => setCreateTimeOffTypeModal(false) }
                />
                {
                    timeOffTypeToDelete ?
                        <DeleteTimeOffType
                            opened={ deleteTimeOffTypeModal }
                            onOk={ () => { getTimeOffTypes(); setDeleteTimeOffTypeModal(false); deleteTimeOffType(null) } }
                            onClose={ () => { setDeleteTimeOffTypeModal(false); deleteTimeOffType(null) } }
                            timeOffType={ timeOffTypeToDelete }
                        /> : null
                }
                {
                    timeOffTypeToChange
                        ?
                        <EditTimeOffType
                            opened={ editTimeOffTypeModal }
                            onOk={ () => { getTimeOffTypes(); setEditTimeOffTypeModal(false); setTimeOffTypeToChange(null) } }
                            onClose={ () => { setEditTimeOffTypeModal(false); setTimeOffTypeToChange(null) } }
                            timeOffTypeToChange={ timeOffTypeToChange }
                            iconLabel = {<div dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[
                                    getIconNameById(timeOffTypeToChange.value.timeOffTypeIconId) as keyof typeof TimeOffTypeIconsCollection
                                    ] }}/>}
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default TimeOffTypes;