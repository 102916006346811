import { COMPANIES } from "../../utils/constants/api";
import { SelectValue } from "../Common/data/SelectValue";
import { CompanyFromApi } from "../Company/data/CompanyFromApi";

export const fetchCompanyOptions = async (): Promise<SelectValue[]> => {
    const response = await fetch(COMPANIES, {
        method: 'GET',
        headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
    });
    const companies: CompanyFromApi[] = await response.json();

    return companies.map(company => ({
        value: company.id,
        label: company.name
    }));
};