import React from 'react';
import {TIME_OFF_POLICIES} from '../../../../../utils/constants/api';
import { TimeOffPolicyFromApi } from '../../../../../shared/TimeOffPolicy/data/TimeOffPolicyFromApi';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../utils/constants/errors";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../Locations/Locations";
import TimeOffPolicyParentModal from "./TimeOffPolicyParentModal";
import {AccrualScheduleCast} from "../../../../../shared/TimeOffPolicy/data/AccrualSchedule";
import {castAccrualSchedule} from "./CreateTimeOffPolicy";
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";

type EditTimeOffPolicy = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    timeOffPolicy: TimeOffPolicyFromApi
    companies: CompanyFromApi[],
    locations: LocationFromApi[]
};

function EditTimeOffPolicy(props: EditTimeOffPolicy) {
    function edit(
        name: string,
        companies: string[],
        locations: string[],
        iconId: string,
        description: string,
        hoursPerWorkingDay: number,
        accrualSchedule: object,
        employeesReceiveAccruedTimeAt: string,
        firstAccrualShouldBe: string,
        recalculateTheRemainingDays: boolean,
        hasCarryOverAfterTheCarryOverDate: boolean,
        carryOverDays: number,
        carryOverDaysAreUnlimited: boolean,
        carryOverDateType: string,
        carryOverDay: number | null,
        carryOverMonth: number | null,
        hasWaitingPeriodBeforeAccruingTimeStarts: boolean,
        waitingPeriodDays: number,
        untilEmploymentDate: boolean,
        capOnTheBalanceAnEmployeeCanAccrue: boolean,
        maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
        automaticallyApproveRequests: boolean,
        approvalEmployeeType: string[]|null,
        approvalEmployeeIds: string[]|null,
        allowToAddReplacementPerson: boolean,
        allowToAddReplacementPersonIsMandatory: boolean,
        commentFieldIsMandatory: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(TIME_OFF_POLICIES + '/' + props.timeOffPolicy.id , {
            method: 'PUT',
            body: JSON.stringify({
                generalInfo:{
                    name: name,
                    companiesIds: companies,
                    locationsIds: locations,
                    iconId: iconId,
                    description: description,
                },
                accrualSchedule: {
                    hoursPerWorkingDay: hoursPerWorkingDay,
                    accrualSchedule: castAccrualSchedule(accrualSchedule as AccrualScheduleCast),
                },
                accrualOptions: {
                    employeesReceiveAccruedTimeAt: employeesReceiveAccruedTimeAt,
                    firstAccrualShouldBe: firstAccrualShouldBe,
                    recalculateTheRemainingDays: recalculateTheRemainingDays,
                    hasCarryOverAfterTheCarryOverDate: hasCarryOverAfterTheCarryOverDate,
                    carryOverDays: carryOverDays,
                    carryOverDaysAreUnlimited: carryOverDaysAreUnlimited,
                    carryOverDateType: carryOverDateType,
                    carryOverDay: carryOverDay,
                    carryOverMonth: carryOverMonth,
                    hasWaitingPeriodBeforeAccruingTimeStarts: hasWaitingPeriodBeforeAccruingTimeStarts,
                    waitingPeriodDays: waitingPeriodDays,
                    untilEmploymentDate: untilEmploymentDate,
                    capOnTheBalanceAnEmployeeCanAccrue: capOnTheBalanceAnEmployeeCanAccrue,
                    maxNumberOfDaysForTheCapEmployeeCanAccrue: maxNumberOfDaysForTheCapEmployeeCanAccrue,
                },
                approvalOptions: {
                    automaticallyApproveRequests: automaticallyApproveRequests,
                    approvalEmployeeType: approvalEmployeeType,
                    approvalEmployeeIds: approvalEmployeeIds,
                    allowToAddReplacementPerson: allowToAddReplacementPerson,
                    allowToAddReplacementPersonIsMandatory: allowToAddReplacementPerson ? allowToAddReplacementPersonIsMandatory : false,
                    commentFieldIsMandatory: commentFieldIsMandatory
                }
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'timeOffPolicy.name',
                        message: 'Not found'
                    }]);
                }
            });
    }

    return (
        <TimeOffPolicyParentModal
            source={ 'edit' }
            opened={ props.opened }
            name={ props.timeOffPolicy.value.generalInfo.name }
            companyValues={ props.companies }
            companies={props.timeOffPolicy.value.generalInfo.companiesIds.map(company => {
                let companyName = '';
                props.companies.map(companyValue => {
                    if (companyValue.id === company) {
                        companyName = companyValue.name;
                    }
                })
                return {
                    label: companyName,
                    value: company
                }
            }).filter(company => company.label !== '')}
            locationValues={ props.locations }
            locations={props.timeOffPolicy.value.generalInfo.locationsIds.map(location => {
                let locationName = '';
                props.locations.map(locationValue => {
                    if (locationValue.id === location) {
                        locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                    }
                })
                return {
                    label: locationName,
                    value: location
                }
            }).filter(company => company.label !== '')}
            iconId = { props.timeOffPolicy.value.generalInfo.iconId }
            description={ props.timeOffPolicy.value.generalInfo.description }
            hoursPerWorkingDay={ props.timeOffPolicy.value.accrualSchedule.hoursPerWorkingDay }
            accrualSchedule={ props.timeOffPolicy.value.accrualSchedule.accrualSchedule }
            employeesReceiveAccruedTimeAt={ props.timeOffPolicy.value.accrualOptions.employeesReceiveAccruedTimeAt }
            firstAccrualShouldBe={ props.timeOffPolicy.value.accrualOptions.firstAccrualShouldBe }
            recalculateTheRemainingDays={ props.timeOffPolicy.value.accrualOptions.recalculateTheRemainingDays ? 'true' : 'false' }
            hasCarryOverAfterTheCarryOverDate={ props.timeOffPolicy.value.accrualOptions.hasCarryOverAfterTheCarryOverDate ? 'true' : 'false' }
            carryOverDays={ props.timeOffPolicy.value.accrualOptions.carryOverDays }
            carryOverDaysAreUnlimited={ props.timeOffPolicy.value.accrualOptions.carryOverDaysAreUnlimited ? 'true' : 'false'}
            carryOverDateType={ props.timeOffPolicy.value.accrualOptions.carryOverDateType }
            carryOverDay={ props.timeOffPolicy.value.accrualOptions.carryOverDay }
            carryOverMonth={ props.timeOffPolicy.value.accrualOptions.carryOverMonth }
            hasWaitingPeriodBeforeAccruingTimeStarts={ props.timeOffPolicy.value.accrualOptions.hasWaitingPeriodBeforeAccruingTimeStarts ? 'true' : 'false' }
            waitingPeriodDays={ props.timeOffPolicy.value.accrualOptions.waitingPeriodDays }
            untilEmploymentDate={ props.timeOffPolicy.value.accrualOptions.untilEmploymentDate ? 'true' : 'false' }
            capOnTheBalanceAnEmployeeCanAccrue={ props.timeOffPolicy.value.accrualOptions.capOnTheBalanceAnEmployeeCanAccrue ? 'true' : 'false' }
            maxNumberOfDaysForTheCapEmployeeCanAccrue={ props.timeOffPolicy.value.accrualOptions.maxNumberOfDaysForTheCapEmployeeCanAccrue }
            automaticallyApproveRequests={ props.timeOffPolicy.value.approvalOptions.automaticallyApproveRequests ? 'true' : 'false' }
            approvalEmployeeType={ props.timeOffPolicy.value.approvalOptions.approvalEmployeeType }
            approvalEmployeeIds={ props.timeOffPolicy.value.approvalOptions.approvalEmployeeIds }
            allowToAddReplacementPerson={ props.timeOffPolicy.value.approvalOptions.allowToAddReplacementPerson ? 'true' : 'false' }
            allowToAddReplacementPersonIsMandatory={ props.timeOffPolicy.value.approvalOptions.allowToAddReplacementPersonIsMandatory }
            commentFieldIsMandatory={ props.timeOffPolicy.value.approvalOptions.commentFieldIsMandatory }
            onOk={ edit }
            onCancel={ props.onClose }
        />
    )
}

export default EditTimeOffPolicy;