import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import TextField, {Type} from '../../../../../../Component/Input/TextField/TextField';
import DatePicker from '../../../../../../Component/Input/DatePicker/DatePicker';

import {EMPLOYEES, GENDERS, SEXES} from "../../../../../../../utils/constants/api";
import {VALIDATION_ERROR} from '../../../../../../../utils/constants/errors';
import {EMPLOYEE_PROFILE} from '../../../../../../../utils/constants/routes';
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../../shared/Security/constants/AccessLevels";

import { EmployeeFromApi } from '../../../../../../../shared/Employee/data/EmployeeFromApi';
import {SexFromApi} from '../../../../../../../shared/Employee/Sex/data/SexFromApi';
import {GenderFromApi} from '../../../../../../../shared/Employee/Gender/data/GenderFromApi';
import {SelectValue} from '../../../../../../../shared/Common/data/SelectValue';
import {AuthEmployee} from "../../../../../../../shared/Security/data/AuthEmployee";

import {ValidationError} from '../../../../../../../shared/Common/Error/ValidationError';
import {fieldAutoResizer} from "../../../../../../../utils/helpers/EmployeeProfile/fieldAutoResizer";
import {
    dateFromStringWithDefaultTimezone
} from "../../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type Profile = {
    employee: EmployeeFromApi,
    authEmployee: AuthEmployee,
    isMyProfile: boolean | null,
    onDataUpdate: () => void,
}

function PersonalInfo(props: Profile) {
    const navigate = useNavigate();

    const [oldEmployee, setOldEmployee] = useState<EmployeeFromApi | null>(null);

    const [firstName, setFirstName] = useState<string>(props.employee.firstName ?? '');
    const [firstNameError, setFirstNameError] = useState<string | null>(null);

    const [lastName, setLastName] = useState<string>(props.employee.lastName ?? '');
    const [lastNameError, setLastNameError] = useState<string | null>(null);

    const [otherName, setOtherName] = useState<string>(props.employee.otherName ?? '');
    const [otherNameError, setOtherNameError] = useState<string | null>(null);

    const [firstNameEn, setFirstNameEn] = useState<string>(props.employee.firstNameEn);
    const [firstNameEnError, setFirstNameEnError] = useState<string | null>(null);

    const [lastNameEn, setLastNameEn] = useState<string>(props.employee.lastNameEn);
    const [lastNameEnError, setLastNameEnError] = useState<string | null>(null);

    const [otherNameEn, setOtherNameEn] = useState<string>(props.employee.otherNameEn ?? '');
    const [otherNameEnError, setOtherNameEnError] = useState<string | null>(null);

    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(props.employee.dateOfBirth ? dateFromStringWithDefaultTimezone(props.employee.dateOfBirth.substring(0, 10)) : null);
    const [dateOfBirthError, setDateOfBirthError] = useState<string | null>(null);

    const [nickname, setNickname] = useState<string>(props.employee.nickname);
    const [nicknameError, setNicknameError] = useState<string | null>(null);

    const [genderOptions, setGenderOptions] = useState<SelectValue[]>([]);
    const [sexOptions, setSexOptions] = useState<SelectValue[]>([]);

    function resetAll(): void {
        setFirstNameError(null);
        setLastNameError(null);
        setOtherNameError(null);
        setFirstNameEnError(null);
        setLastNameEnError(null);
        setOtherNameEnError(null);
        setDateOfBirthError(null);
        setNicknameError(null);
    }

    const [sex, setSex] = useState<SelectValue | null>(
        props.employee.sexId && props.employee.sexName
            ? { label: props.employee.sexName, value: props.employee.sexId }
            : null
    );
    const [gender, setGender] = useState<SelectValue | null>(
        props.employee.genderId && props.employee.genderName
            ? { label: props.employee.genderName, value: props.employee.genderId }
            : null
    );

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

    function setValue(value: any, field: any) {
        if (!value) {
            value = null;
        }
        if (oldEmployee && ((field === 'nickname' && oldEmployee.nickname === value)
            || (field === 'first-name-en' && oldEmployee.firstNameEn === value)
            || (field === 'last-name-en' && oldEmployee.lastNameEn === value)
            || (field === 'email' && oldEmployee.email === value))) {
            return
        }
        resetAll();
        if (field === 'date-of-birth') {
            if (typeof value === 'string') {
                value = new Date(value);
            }

            if (!(value instanceof Date) || isNaN(value.getTime())) {
                console.error('Received invalid date value:', value);
                setDateOfBirthError('Please enter a valid date');
                return;
            } else {
                setDateOfBirthError(null);
            }
            value = value ? value.getFullYear() + '-' + (value.getMonth()+1) + '-' + value.getDate() : null;
        }
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/' + field + '/' + value, {
            method: 'PUT',
        })
        .then(response => {
            if (response.ok) {
                const link = field === 'nickname' ? value : nickname;
                if (field === 'nickname' || field === 'first-name-en' || field === 'last-name-en') {
                    navigate(EMPLOYEE_PROFILE + '/' + link);
                    if (field === 'nickname') {
                        props.onDataUpdate();
                    }
                }
            } else if (response.status === VALIDATION_ERROR) {
                response.json().then((errors: ValidationError[]) => validate(errors));
            }
            setOldEmployee(deepCopy(props.employee));
        });
    }

    function deepCopy(obj: EmployeeFromApi) {
        return JSON.parse(JSON.stringify(obj));
    }

    useEffect(() => {
        setOldEmployee(deepCopy(props.employee));
        setFirstName(props.employee.firstName ?? '');
        setLastName(props.employee.lastName ?? '');
        setOtherName(props.employee.otherName ?? '');
        setFirstNameEn(props.employee.firstNameEn ?? '');
        setLastNameEn(props.employee.lastNameEn ?? '');
        setOtherNameEn(props.employee.otherNameEn ?? '');
        setDateOfBirth(props.employee.dateOfBirth ? dateFromStringWithDefaultTimezone(props.employee.dateOfBirth.substring(0, 10)) : null);
        setNickname(props.employee.nickname);
        getSexes();
        getGenders();
        setSex(props.employee.sexId && props.employee.sexName
            ? { label: props.employee.sexName, value: props.employee.sexId }
            : null);
        setGender(props.employee.genderId && props.employee.genderName
            ? { label: props.employee.genderName, value: props.employee.genderId }
            : null);
        fieldAutoResizer('nickname', nickname);
        fieldAutoResizer('first-name-en', firstNameEn);
        fieldAutoResizer('other-name-en', otherNameEn);
        fieldAutoResizer('last-name-en', lastNameEn);
        fieldAutoResizer('first-name', firstName);
        fieldAutoResizer('other-name', otherName);
        fieldAutoResizer('last-name', lastName);
    }, [props.employee])

    useEffect(() => {
        resetAll();
    }, [nickname])

    useEffect(() => {
        props.employee.firstNameEn = firstNameEn;
        props.employee.lastNameEn = lastNameEn;
        resetAll();
    }, [firstNameEn, lastNameEn])

    function getSexes() {
        fetch(SEXES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((sexes: SexFromApi[]) => {
                const sexOptions = sexes.map((sex: SexFromApi) => {
                    return {
                        label: sex.name,
                        value: sex.id
                    }
                });
                const noneOption = {
                    label: '',
                    value: ''
                };
                const sexOptionsWithNone = [noneOption].concat(sexOptions);
                setSexOptions(sexOptionsWithNone);
            });
    }

    function getGenders() {
        fetch(GENDERS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((sexes: GenderFromApi[]) => {
                const genderOptions = sexes.map((gender: GenderFromApi) => {
                    return {
                        label: gender.name,
                        value: gender.id
                    }
                });
                const noneOption = {
                    label: '',
                    value: ''
                };
                const genderOptionsWithNone = [noneOption].concat(genderOptions);
                setGenderOptions(genderOptionsWithNone);
            });
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            switch (error.property) {
                case 'employee.nickname':
                    setNicknameError(error.message);
                    break;
                case 'employee.first-name':
                    setFirstNameError(error.message);
                    break;
                case 'employee.last-name':
                    setLastNameError(error.message);
                    break;
                case 'employee.other-name':
                    setOtherNameError(error.message);
                    break;
                case 'employee.first-name-en':
                    setFirstNameEnError(error.message);
                    break;
                case 'employee.last-name-en':
                    setLastNameEnError(error.message);
                    break;
                case 'employee.other-name-en':
                    setOtherNameEnError(error.message);
                    break;
                case 'employee.date-of-birth':
                    setDateOfBirthError(error.message);
                    break;
                default:
                    alert(error.message)
                    break;
            }
        });
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const hasAdminRole = props.authEmployee.roles.includes(ROLE_ADMIN);

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
            const arrowDownEvent = new KeyboardEvent('keydown', { key: 'ArrowDown' });
            document.dispatchEvent(arrowDownEvent);
        }
    });

    return (
        <div className={'personal-info'}>
            <div className={'info'}>
                <div className={'info-header'}>
                    <div className={'icon'}><i className='material-icons'>person</i></div>
                    <h3>Personal Info</h3>
                </div>
                <div className={'info-body'}>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Nickname<span className={'required'}>*</span></p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                label=''
                                placeholder="—"
                                id={'nickname'}
                                className={`nickname ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                required={ false }
                                value={ nickname }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ nicknameError }
                                onChange={ value => {
                                    const isValid = /^([-'a-zA-Z_\s]+)?$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('nickname', value)
                                        setNickname(value)
                                    }
                                }}
                                onBlur={() => {
                                    setValue(nickname, 'nickname')
                                }}
                                maxLength={20}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>First Name (EN)<span className={'required'}>*</span></p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                label=''
                                placeholder="—"
                                className={`first-name-en ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                required={ false }
                                value={ firstNameEn }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ firstNameEnError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\s]+)?$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('first-name-en', value)
                                        setFirstNameEn(value.charAt(0).toUpperCase() + value.slice(1))
                                    }
                                }}
                                onBlur={() => {
                                    setValue(firstNameEn, 'first-name-en')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Other Name (EN)</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                label=''
                                className={`other-name-en ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                placeholder="—"
                                required={ false }
                                value={ otherNameEn }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ otherNameEnError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\s]+)?$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('other-name-en', value)
                                        setOtherNameEn(value.charAt(0).toUpperCase() + value.slice(1))
                                    }
                                }}
                                onBlur={() => {
                                    setValue(otherNameEn, 'other-name-en')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Last Name (EN)<span className={'required'}>*</span></p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                className={`last-name-en ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                label=''
                                placeholder="—"
                                required={ false }
                                value={ lastNameEn }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ lastNameEnError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\s]+)?$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('last-name-en', value)
                                        setLastNameEn(value.charAt(0).toUpperCase() + value.slice(1))
                                    }
                                }}
                                onBlur={() => {
                                    setValue(lastNameEn, 'last-name-en')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>First Name</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                className={`first-name ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                label=''
                                placeholder="—"
                                required={ false }
                                value={ firstName }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ firstNameError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\sа-яА-ЯІіЇїЄєҐґ]+|)$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('first-name', value)
                                        setFirstName(value.charAt(0).toUpperCase() + value.slice(1));
                                    }
                                }}
                                onBlur={() => {
                                    setValue(firstName, 'first-name')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Other Name</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                className={`other-name ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                label=''
                                placeholder="—"
                                required={ false }
                                value={ otherName }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ otherNameError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\sа-яА-ЯІіЇїЄєҐґ]+|)$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('other-name', value)
                                        setOtherName(value.charAt(0).toUpperCase() + value.slice(1))
                                    }
                                }}
                                onBlur={() => {
                                    setValue(otherName, 'other-name')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Last Name</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <TextField
                                className={`last-name ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                label=''
                                placeholder="—"
                                required={ false }
                                value={ lastName }
                                type={ Type.Textarea }
                                horizontal={ false }
                                error={ lastNameError }
                                onChange={ value => {
                                    const isValid = /^([-'(,)a-zA-Z\sа-яА-ЯІіЇїЄєҐґ]+|)$/.test(value);
                                    if (isValid) {
                                        fieldAutoResizer('last-name', value)
                                        setLastName(value.charAt(0).toUpperCase() + value.slice(1))
                                    }
                                }}
                                onBlur={() => {
                                    setValue(lastName, 'last-name')
                                }}
                                maxLength={30}
                                disabled={!hasRole || props.employee.isDeleted}
                            />
                        </div>
                    </div>
                    { hasRole &&
                        <div className={'profile-field'}>
                            <div className={'profile-field-title'}>
                                <p>Sex</p>
                            </div>
                            <div className={`profile-field-value ${(!hasRole && !props.isMyProfile) || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && (!hasAdminRole && !props.isMyProfile) ? 'disabled-pointer' : ''}`}>
                                <Select
                                    placeholder={(!hasRole && !props.isMyProfile) || props.employee.isDeleted ? '—' : 'Select'}
                                    isMulti={ false }
                                    id={ 'sex' }
                                    className={`profile-select sex-select ${!hasRole ? 'default-pointer' : ''}` }
                                    value={ sex }
                                    options={ sexOptions }
                                    isClearable={ false }
                                    onChange={ (e: any) => {
                                        if (!e.value) {
                                            setSex(null);
                                        } else {
                                            setSex(e)
                                        }
                                        setValue(e.value, 'sex')
                                    }}
                                    isSearchable={ false }
                                    isDisabled={ ((!hasRole && !props.isMyProfile) || props.employee.isDeleted) }
                                />
                            </div>
                        </div>
                    }
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Gender Pronounce</p>
                        </div>

                        <div className={`profile-field-value ${(!hasAdminRole && !props.isMyProfile) ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && (!hasAdminRole && !props.isMyProfile) ? 'disabled-pointer' : ''}`}>
                            <Select
                                isMulti={ false }
                                placeholder={(!hasAdminRole && !props.isMyProfile) || props.employee.isDeleted ? '—' : 'Select'}
                                id={ 'gender' }
                                className={ 'profile-select gender-select' }
                                value={ gender }
                                options={ genderOptions }
                                isClearable={ false }
                                onChange={ (e: any) => {
                                    if (!e.value) {
                                        setGender(null);
                                    } else {
                                        setGender(e)
                                    }
                                    setValue(e.value, 'gender')
                                }}
                                isSearchable={ false }
                                isDisabled={ ((!hasAdminRole && !props.isMyProfile) || props.employee.isDeleted) }
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Date of Birth</p>
                        </div>
                        <div className={'profile-field-value date-of-birth'}
                             onBlur={() => {
                                 setValue(dateOfBirth, 'date-of-birth')
                             }}
                        >
                            <i className="material-icons calendar-today">calendar_today</i>
                            <DatePicker
                                id={'date-of-birth'}
                                label={''}
                                required={ false }
                                horizontal={ false }
                                date = { dateOfBirth }
                                error = { dateOfBirthError }
                                className={`${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                onChange={ newDate => {
                                    if (dateOfBirth !== newDate) {
                                        setDateOfBirth(newDate);
                                    }
                                }}
                                maxDate={ null }
                                minDate={ null }
                                disabled={ !hasRole }
                                hideYear={ ((!hasRole && !props.isMyProfile) || props.employee.isDeleted) }
                            />
                            {!hasRole && !props.isMyProfile && (
                                <span>
                                    {dateOfBirth ? dateOfBirth.toLocaleString('en', { month: 'long' }) : null}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalInfo;