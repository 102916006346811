import {SelectValue} from "../../Common/data/SelectValue";

import {
    SPECIFIC_EMPLOYEE,
    DIRECT_MANAGER,
} from "../../../utils/constants/approvalOptions";

export const ApprovalEmployeeTypes = [
    { value: SPECIFIC_EMPLOYEE, label: 'SPECIFIC EMPLOYEE' },
    { value: DIRECT_MANAGER, label: 'DIRECT MANAGER' },
] as SelectValue[];
