import React, {useEffect, useState} from 'react';
import { EmployeeFromApi } from '../../../../../../../shared/Employee/data/EmployeeFromApi';
import Select from 'react-select';
import {EMPLOYEE} from "../../../../../../../utils/constants/api";
import {AccessLevelFromApi} from "../../../../../../../shared/Employee/Profile/OtherInfo/data/AccessLevelFromApi";
import {SelectValue} from "../../../../../../../shared/Common/data/SelectValue";
import {AuthEmployee} from "../../../../../../../shared/Security/data/AuthEmployee";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../../shared/Security/constants/AccessLevels";
import {VALIDATION_ERROR} from "../../../../../../../utils/constants/errors";
import {ValidationError} from "../../../../../../../shared/Common/Error/ValidationError";

type OtherInfo = {
    employee: EmployeeFromApi,
    authEmployee: AuthEmployee
}

function OtherInfo(props: OtherInfo) {
    const [accessLevels, setAccessLevels] = useState<SelectValue[]>([]);
    const [accessLevelsError, setAccessLevelsError] = useState<string | null>(null);
    const [accessLevelsOptions, setAccessLevelsOptions] = useState<SelectValue[]>([]);
    const [employeeRoleId, setEmployeeRoleId] = useState<string | null>(null);
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const hasRoleAdmin = props.authEmployee.roles.includes(ROLE_ADMIN);

    useEffect(() => {
        getAccessLevelsOptions();
        getEmployeeAccessLevels();
    }, [props.employee]);

    const scrollToAccessLevel = () => {
        setTimeout(() => {
            const accessLevelElement = document.getElementById('access-level');
            if (accessLevelElement) {
                accessLevelElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    };

    function getAccessLevelsOptions() {
        fetch(EMPLOYEE + '/access-levels', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((accessLevels: AccessLevelFromApi[]) => {
                const updatedAccessLevels = accessLevels.map(accessLevel => {
                    if (accessLevel.name === 'Employee') {
                        setEmployeeRoleId(accessLevel.id);
                    }
                    return {
                        value: accessLevel.id,
                        label: accessLevel.name
                    };
                });

                if (!hasRoleAdmin) {
                    const filteredAccessLevels = updatedAccessLevels.filter(item => item.label !== 'Admin');
                    setAccessLevelsOptions(filteredAccessLevels);
                } else {
                    setAccessLevelsOptions(updatedAccessLevels);
                }
            })
    }

    function getEmployeeAccessLevels() {
        fetch(EMPLOYEE + '/' + props.employee.id + '/access-levels', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((accessLevels: AccessLevelFromApi[]) =>
                setAccessLevels(
                    accessLevels.map(accessLevel => {
                        return {
                            value: accessLevel.id,
                            label: accessLevel.name
                        }
                    })
                )
            )
    }

    function addEmployeeAccessLevel(idsToAdd: string[]) {
        return fetch(EMPLOYEE + '/' + props.employee.id + '/access-levels', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            body: JSON.stringify({ accessLevelIds: idsToAdd })
        });
    }

    function removeEmployeeAccessLevel(idsToRemove: string[]) {
        const params = new URLSearchParams();
        idsToRemove.forEach(id => params.append('id', id));

        return fetch(EMPLOYEE + '/' + props.employee.id + '/access-levels?' + params.toString(), {
            method: 'DELETE',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => {
                if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            })
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'accessLevel') {
                setAccessLevelsError(error.message);
            }
        });
    }

    function handleAccessLevelsChange(newValue: readonly SelectValue[] | null) {
        const newAccessLevels = newValue ? Array.from(newValue) : [];

        const oldIds = accessLevels.map(accessLevel => accessLevel.value);
        const newIds = newAccessLevels.map(accessLevel => accessLevel.value);

        const idsToAdd = newIds.filter(id => !oldIds.includes(id));
        const idsToRemove = oldIds.filter(id => !newIds.includes(id));

        // Avoid removing Employee role
        if (employeeRoleId) {
            if (idsToRemove.includes(employeeRoleId)) {
                const index = idsToRemove.indexOf(employeeRoleId);
                if (index > -1) {
                    idsToRemove.splice(index, 1);
                }
            }
        }

        if (idsToAdd.length > 0) {
            addEmployeeAccessLevel(idsToAdd).then(getEmployeeAccessLevels);
        }

        if (idsToRemove.length > 0 && newAccessLevels.length > 0) {
            removeEmployeeAccessLevel(idsToRemove).then(getEmployeeAccessLevels);
        }
    }

    return (
        <div className={'other-info'}>
            <div className={'info'}>
                <div className={'info-header'}>
                    <div className={'icon'}><i className='material-icons'>security</i></div>
                    <h3>Other Info</h3>
                </div>
                <div className={'info-body'}>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Access Level</p>
                        </div>
                        <div className={`profile-field-value ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!accessLevels && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ true }
                                id={ 'access-level' }
                                className={ 'profile-select' }
                                value={ accessLevels }
                                options={ accessLevelsOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={(newValues) => {
                                    setAccessLevelsError(null)
                                    handleAccessLevelsChange(newValues as SelectValue[])
                                }}
                                onMenuOpen={scrollToAccessLevel}
                                onMenuClose={scrollToAccessLevel}
                                isSearchable={ false }
                            />
                            {accessLevelsError &&
                                <div className='error'>
                                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                                    <span>{ accessLevelsError }</span>
                                </div>
                            }
                        </div>
                    </div>
                    {/*<div className={'profile-field'}>*/}
                    {/*    <div className={'profile-field-title'}>*/}
                    {/*        <p>GLPI</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={`profile-field-value ${!hasRole ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>*/}
                    {/*        <Select*/}
                    {/*            placeholder={''}*/}
                    {/*            isMulti={ false }*/}
                    {/*            id={ 'glpi' }*/}
                    {/*            className={ 'profile-select' }*/}
                    {/*            value={ [] }*/}
                    {/*            options={ [] }*/}
                    {/*            isClearable={ false }*/}
                    {/*            isDisabled={ !hasRole }*/}
                    {/*            onChange={ (e: any) => {}}*/}
                    {/*            isSearchable={ false }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default OtherInfo;