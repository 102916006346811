import React from 'react';
import BaseModal  from './BaseModal';
import { TypeFromApi } from '../Types';
import { TYPES } from '../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';

type EditType = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    type: TypeFromApi
};

function EditType(props: EditType) {
    function edit(name: string, validate: (errors: ValidationError[]) => void): void {
        fetch(TYPES + '/' + props.type.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Employee Type'
            name={ props.type.name }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditType;