import React from 'react';
import BaseModal from './BaseModal';
import {PUSH_NOTIFICATIONS} from '../../../../../utils/constants/api';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../utils/constants/errors";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../Locations/Locations";

type CreatePushNotification = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any
    companies: CompanyFromApi[],
    locations: LocationFromApi[],
};

function CreatePushNotification(props: CreatePushNotification) {
    function create(
        subject: string,
        companies: string[],
        locations: string[],
        date: Date,
        content: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('pushNotification', JSON.stringify({
            subject: subject,
            sendAt: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
            text: content,
            companiesIds: companies,
            locationsIds: locations
        }));

        fetch(PUSH_NOTIFICATIONS, {method: 'POST', body: formData})
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'title.categoryId',
                        message: 'Category is required'
                    }]);
                }
            });
    }
    return (
        <BaseModal
            opened={props.opened}
            modalTitle='Create Push Notification'
            subject={null}
            companyValues={ props.companies }
            companies={ [] }
            locationValues={ props.locations }
            locations={ [] }
            date={new Date()}
            text={null}
            okText='Create'
            onCancel={props.onClose}
            onOk={create}
        />
    )
}

export default CreatePushNotification;