import React, {useEffect, useState, useMemo} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Header from './../../Page/Layer/Header/Header';
import Navbar from './../../Page/Layer/Navbar/Navbar';
import Requests from './Requests/Requests';
import Calendar from './Calendar/Calendar';
import Breadcrumbs from "../../Component/Breadcrumbs/Breadcrumbs";
import Head from "../../Component/Head/Head";
import {AuthEmployee} from '../../../shared/Security/data/AuthEmployee';

import './CalendarMain.scss';
import Footer from "../Layer/Footer/Footer";

type Employees = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function CalendarMain(props: Employees) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const location = useLocation();
    const navigate = useNavigate();

    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const indexFromUrl = params.get("index");

    const [activeIndex, setActiveIndex] = useState(() => {
        const savedIndex = localStorage.getItem("activeIndexCalendar");
        return savedIndex ? Number(savedIndex) : (indexFromUrl ? Number(indexFromUrl) : 0);
    });
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        const renderFromMenu = params.get("renderFromMenu") === 'true';
        setIsFirstRender(!renderFromMenu);

        if (isFirstRender && renderFromMenu && indexFromUrl) {
            const paramsCopy = new URLSearchParams(params.toString());
            paramsCopy.delete("index");
            paramsCopy.delete("renderFromMenu");
            navigate(location.pathname + "?" + paramsCopy.toString(), { replace: true });
            setActiveIndex(Number(indexFromUrl));
            setIsFirstRender(false);
        }
    }, [isFirstRender, navigate, location.pathname, params, indexFromUrl]);

    useEffect(() => {
        localStorage.setItem("activeIndexCalendar", String(activeIndex));
    }, [activeIndex]);

    const handleLiClick = (index: number) => {
        setActiveIndex(index);
        getContentComponent();
    };

    const getActiveClass = (index: number) => {
        return index === activeIndex ? 'active' : '';
    };

    const getContentComponent = () => {
        switch (activeIndex) {
            case 0:
                return <Requests
                    authEmployee={props.authEmployee}
                />;
            case 1:
                return <Calendar
                    authEmployee={props.authEmployee}
                />;
        }
    };

    return (
        <>
            <Head
                title="Time Off Calendar — Netpeak One"
                metaNameTitle="Time Off Calendar — Netpeak One"
                description="Time Off Calendar — Netpeak One"
                url="/calendar"
            />
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content calendar-main-content'>
                    <div className={'breadcrumbs-and-header'}>
                        <Breadcrumbs home={'Home'} firstElement={'Calendar'} />
                        <div className={'header'}>Calendar</div>
                    </div>
                    <div className='d-flex' style={{ position: 'relative' }}>
                        <div className='sub-content-block add-request d-flex'>
                            <div className='sub-content-main'>
                                <div className={'calendar-menu-block'}>
                                    <ul className={'calendar-menu'}>
                                        <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
                                            Requests
                                        </li>
                                        <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
                                            Calendar
                                        </li>
                                    </ul>
                                </div>
                                <div className={'content'}>{getContentComponent()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default CalendarMain;