/**
 * The Date object's methods convert from UTC to the user's timezone
 * Set minutes to current minutes (UTC) + offset from user's local time
 * Now we can use methods on the Date object without timezone conversion
 *
 * @param date - String to create the object or null
 * @return Date|null - A Date object with a fixed timezone if a string was passed, null otherwise
 */
export const dateFromStringWithDefaultTimezone = (date: string | null): Date | null => {
    if (!date) {
        return null;
    }

    const dateObject = new Date(date);
    dateObject.setMinutes(dateObject.getMinutes() + dateObject.getTimezoneOffset());

    return dateObject;
}