import React, {useEffect, useState} from 'react';
import parse from "html-react-parser";

import Modal from '../../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../../Component/Input/TextField/TextField';
import Select from '../../../../../Component/Input/SelectInput/SelectInput';
import TextFieldSimple from "../../../../../Component/Input/TextFieldSimple/TextFieldSimple";

import {SelectValue} from '../../../../../../shared/Common/data/SelectValue';
import {
    TimeOffAccruedDaysForProfileFromApi
} from "../../../../../../shared/Employee/Profile/JobInfo/data/TimeOffAccruedDaysForProfileFromApi";

import './BaseModal.scss';


type BaseModal = {
    opened: boolean,
    modalTitle: string,
    okText: string,
    accruedDays: TimeOffAccruedDaysForProfileFromApi[] | null,
    accruedDay: TimeOffAccruedDaysForProfileFromApi | null,
    onCancel: () => any,
    onOk: (
        timeOffPolicyId: string,
        quantity: number,
        comment: string,
    ) => void
};

function BaseModal(props: BaseModal) {
    const emptySelect = { label: '', value: 'empty' } as SelectValue;

    const [accruedDaysOptions, setAccruedDaysOptions] = useState<SelectValue[]>([]);
    const modifyBalanceOptions = [
        {value: 'add', label: 'Add to balance'},
        {value: 'subtract', label: 'Subtract from balance'},
    ] as SelectValue[];

    const [accruedDayOption, setAccruedDayOption] = useState<SelectValue>(emptySelect);
    const [modifyBalanceOption, setModifyBalanceOption] = useState<SelectValue>(modifyBalanceOptions[0]);

    const [comment, setComment] = useState<string>('');
    const [days, setDays] = useState<number>(0);

    const [balanceError, setBalanceError] = useState<string | null>(null);
    const [commentError, setCommentError] = useState<string | null>(null);
    const [daysError, setDaysError] = useState<string | null>(null);

    useEffect(() => {
        if (props.accruedDays) {
            setAccruedDaysOptions(props.accruedDays.map(timeOffAccruedDay => {
                return {
                    value: timeOffAccruedDay.timeOffPolicyId,
                    label: parse('<div class="available-block"><span>' + timeOffAccruedDay.timeOffPolicyName + '</span><span>Available ' + timeOffAccruedDay.quantity + ' days</span></div>') as string
                }
            }));
        }
    }, [props.accruedDays]);

    useEffect(() => {
        if (props.accruedDay) {
            setAccruedDayOption({
                value: props.accruedDay.timeOffPolicyId,
                label: parse('<div class="available-block"><span>' + props.accruedDay.timeOffPolicyName + '</span><span>Available ' + props.accruedDay.quantity + ' days</span></div>') as string
            });
        }
    }, [props.accruedDay]);

    function frontValidateHasErrors(): boolean {
        let hasErrors = false;
        if (days === 0) {
            setDaysError('We can not add or subtract 0 days');
            hasErrors = true;
        }
        if (modifyBalanceOption.value === 'add' && days > 50) {
            setDaysError('You can not add more than 50 days');
            hasErrors = true;
        }
        if (props.accruedDay && modifyBalanceOption.value === 'subtract' && days > props.accruedDay.quantity) {
            setDaysError('You can not subtract more than available days');
            hasErrors = true;
        }
        if (comment.length > 200) {
            setCommentError('Comment should be less than 200 symbols');
            hasErrors = true;
        }
        if (comment.length === 0) {
            setCommentError('Comment is required');
            hasErrors = true;
        }
        return hasErrors;
    }

    function resetAllValues(): void {
        setDays(0);
        setComment('');
        setModifyBalanceOption(modifyBalanceOptions[0]);
    }

    function resetAllErrors(): void {
        setDaysError(null);
        setCommentError(null);
    }

    function handleDaysChange(newCarryOverDays: number) {
        if (isNaN(newCarryOverDays)) {
            setDays(0);
        } else {
            setDays(newCarryOverDays);
            setDaysError('');
        }
    }

    return (
        <div className={'request-modal accrual-days-modal'}>
            <Modal
                opened={ props.opened }
                modalTitle={ props.modalTitle }
                cancelText='Cancel'
                okText={ props.okText }
                onCancel={() => {
                    props.onCancel();
                    resetAllValues();
                    resetAllErrors();
                }}
                onOk={() => {
                    if (!frontValidateHasErrors()) {
                        const trimmedComment = comment.trim();
                        if(trimmedComment.length === 0) {
                            setCommentError('Comment is required');
                            return;
                        }
                        props.onOk(
                            accruedDayOption.value,
                            modifyBalanceOption.value === 'add' ? days : -days,
                            trimmedComment,
                        );
                    }
                }}
            >
                <div>

                    <div className='accrued-days-info'>
                        <i className='material-icons'>info</i> { 'Manage your employees time off' }
                    </div>

                    <div className={'balance-field'}>
                        <p>Current balance</p>
                        <Select
                            required={ false }
                            isMulti={ false }
                            value={ accruedDayOption }
                            options={ accruedDaysOptions }
                            error={ balanceError }
                            onChange={ (newValue: SelectValue) => {
                                setAccruedDayOption(newValue);
                                setBalanceError(null);
                            }}
                            isSearchable={ false }
                        />
                    </div>

                    <div className={'modify-balance-container'}>
                        <Select
                            className={'modify-balance'}
                            label={'Modify balance'}
                            required={false}
                            isMulti={false}
                            isSearchable={false}
                            value={modifyBalanceOption}
                            options={modifyBalanceOptions}
                            error={''}
                            onChange={(newValue: SelectValue) => setModifyBalanceOption(newValue)}
                        />
                    </div>

                    <div className={'amount-field'}>
                        <div className={'amount'}>Amount</div>
                        <TextFieldSimple
                            placeholder={''}
                            required={false}
                            value={days}
                            error={daysError}
                            maxLength={2}
                            onChange={newCarryOverDays => handleDaysChange(parseInt(newCarryOverDays))}
                        />
                        <div className={'days'}>days</div>
                    </div>

                    <div className={'comment-field'}>
                        <p>Reason balance editing</p>
                        <TextField
                            placeholder=''
                            required={false}
                            value={comment}
                            type={Type.Textarea}
                            horizontal={true}
                            error={commentError}
                            onChange={newComment => {
                                setComment(newComment);
                                setCommentError(null);
                            }}
                            maxLength={200}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default BaseModal;