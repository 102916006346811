import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import {
    ADMIN,
    ADMIN_GREETINGS,
    ADMIN_COMPANIES,
    ADMIN_LINKS,
    ADMIN_SEXES_AND_GENDERS,
    ADMIN_LEVELS,
    ADMIN_TYPES,
    ADMIN_STATUSES,
    ADMIN_JOB_TITLES,
    ADMIN_HOLIDAYS,
    ADMIN_TEAMS,
    ADMIN_LOCATIONS,
    ADMIN_TIMEOFF_TYPES,
    ADMIN_PUSH_NOTIFICATIONS,
    ADMIN_TIMEOFF_POLICIES,
    MAIN,
    ADMIN_EMPLOYEES,
    EMPLOYEE_PROFILE,
    CALENDAR, FORBIDDEN, NOT_FOUND
} from '../utils/constants/routes';
import PrivateRoutes from './Common/Router/PrivateRoutes';
import Main from './Page/Main/Main';
import Admin from './Page/Admin/Admin';
import { AUTH } from '../utils/constants/api';
import Greetings from './Page/Admin/Greetings/Greetings';
import Links from './Page/Admin/Links/Links';
import Companies from './Page/Admin/Companies/Companies';
import SexesAndGenders from './Page/Admin/Employee/SexesAndGenders/SexesAndGenders';
import Levels from './Page/Admin/Employee/Levels/Levels';
import Statuses from './Page/Admin/Employee/Statuses/Statuses';
import JobTitles from './Page/Admin/JobTitles/JobTitles';
import Types from './Page/Admin/Employee/Types/Types';
import Teams from './Page/Admin/Teams/Teams';
import Locations from './Page/Admin/Locations/Locations';
import TimeOffTypes from './Page/Admin/TimeOffTypes/TimeOffTypes';
import PushNotifications from './Page/Admin/PushNotifications/PushNotifications';
import Holidays from './Page/Admin/Holidays/Holidays';
import Employees from './Page/Admin/Employee/Employee/Employees';
import Profile from './Page/Admin/Employee/Profile/Profile';
import TimeOffPolicies from './Page/Admin/TimeOffPolicies/TimeOffPolicies';
import { AUTHORIZATION_ERROR } from '../utils/constants/errors';
import { AuthEmployee } from '../shared/Security/data/AuthEmployee';
import CalendarMain from './Page/Calendar/CalendarMain';
import Forbidden from './Page/Forbidden/Forbidden';
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER,
    ROLE_EMPLOYEE
} from '../shared/Security/constants/AccessLevels';
import NotFound from "./Page/NotFound/NotFound";

function App() {
    const [authEmployee, setAuthEmployee] = useState<AuthEmployee | null>(null);

    useEffect(() => {
        if (!authEmployee) {
            localStorage.setItem('preAuthUrl', window.location.href);
            const preAuthUrl = encodeURIComponent(localStorage.getItem('preAuthUrl') || "");

            fetch(AUTH + '?redirectUrl=' + preAuthUrl)
                .then(response => {
                    if (response.status === AUTHORIZATION_ERROR) {
                        return response.text().then(url => {
                            window.location.href = url;
                        });
                    }

                    if (!response.ok) {
                        throw new Error('Network response was not ok: ' + response.statusText);
                    }

                    return response.json();
                })
                .then(data => {
                    setAuthEmployee(data as AuthEmployee);
                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });
        }
    }, [authEmployee]);

    if (authEmployee) {
        return (
            <Routes>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ MAIN } element={ <Main authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ FORBIDDEN } element={ <Forbidden authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN } element={ <Admin authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                {/*Admin Portal Settings*/}
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_GREETINGS } element={ <Greetings authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_LINKS } element={ <Links authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_PUSH_NOTIFICATIONS } element={ <PushNotifications authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                {/*Admin Corporate Settings*/}
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_COMPANIES } element={ <Companies authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_HOLIDAYS } element={ <Holidays authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_TEAMS } element={ <Teams authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_LOCATIONS } element={ <Locations authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                {/*Admin HR Settings*/}
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_STATUSES } element={ <Statuses authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_SEXES_AND_GENDERS } element={ <SexesAndGenders authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_TYPES } element={ <Types authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_LEVELS } element={ <Levels authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_TIMEOFF_TYPES } element={ <TimeOffTypes authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_JOB_TITLES } element={ <JobTitles authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_TIMEOFF_POLICIES } element={ <TimeOffPolicies authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                {/*Admin Employees*/}
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ ADMIN_EMPLOYEES } element={ <Employees authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                <Route element={<PrivateRoutes roles={ [ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, ROLE_OPERATIONAL_MANAGER] } authEmployee={authEmployee} />}>
                    <Route path={`${EMPLOYEE_PROFILE}/:nickname`} element={<Profile authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} />} />
                </Route>
                <Route element={ <PrivateRoutes roles={ [ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR] } authEmployee={ authEmployee } /> }>
                    <Route path={ CALENDAR } element={ <CalendarMain authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)} /> } />
                </Route>

                <Route path="*" element={<NotFound authEmployee={ authEmployee } signOut={() => setAuthEmployee(null)}/>} />
            </Routes>
        );
    } else {
        return <div />;
    }
}

export default App;