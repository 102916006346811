import React from 'react';
import BaseModal from './BaseModal';
import {RequestFromApi} from "../../../../../shared/Calendar/Request/data/RequestFromApi";
import {AuthEmployee} from "../../../../../shared/Security/data/AuthEmployee";

type PreviewRequest = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    request: RequestFromApi | null,
    requests: RequestFromApi[] | null,
    onChangeStatus?: (request: RequestFromApi[]) => void,
    authEmployee: AuthEmployee,
    checkConfirmRequest: (request: RequestFromApi) => void,
};

function PreviewRequest(props: PreviewRequest) {

    const handleStatusChange = (newRequests: RequestFromApi[]) => {
        if (props.onChangeStatus) {
            props.onChangeStatus(newRequests);
        }
    };

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Preview Request'
            okText='Send request'
            onCancel={ props.onClose }
            onOk={ props.onOk }
            request={props.request ? props.request : null}
            requests={props.requests ? props.requests : null}
            onChangeStatus={handleStatusChange}
            removeFooter={true}
            authEmployee={ props.authEmployee }
            checkConfirmRequest={ props.checkConfirmRequest }
        />
    )
}

export default PreviewRequest;