/**
 * Formats a Date object into a "YYYY-MM-DD" string format.
 * @param date - The Date object or null to be formatted.
 * @returns string in the "YYYY-MM-DD" format or null if the input is null.
 */
export const formatDate = (date: Date | null): string | null => {
    if (!date) {
        return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}