import React, {useEffect, useState} from 'react';

import Modal from '../../../../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../../../../Component/Input/TextField/TextField';
import Select from '../../../../../../../Component/Input/SelectInput/SelectInput';

import { getRegexForSocial } from '../../../../../../../../utils/helpers/EmployeeProfile/getRegexForSocial';

import { ValidationError } from '../../../../../../../../shared/Common/Error/ValidationError';
import { SelectValue } from '../../../../../../../../shared/Common/data/SelectValue';
import { ContactInfoType } from "../../../../../../../../shared/Employee/data/ContactInfoType";

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    okText: string,
    issetSocials: ContactInfoType[],
    onCancel: () => any,
    onOk: (
        social: SelectValue | null,
        link: string,
        validate: (errors: ValidationError[]) => void
    ) => void
};

function BaseModal(props: BaseModal) {
    const [link, setLink] = useState<string>('');
    const [linkError, setLinkError] = useState<string | null>(null);
    const [social, setSocial] = useState<SelectValue | null>(null);
    const [socialError, setSocialError] = useState<string | null>(null);

    const defaultSocialOptions = [
        { label: 'Telegram', value: 'Telegram' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Calendly', value: 'Calendly' },
        { label: 'TikTok', value: 'TikTok' },
        { label: 'Reddit', value: 'Reddit' },
        { label: 'Signal', value: 'Signal' },
        { label: 'Slack', value: 'Slack' },
        { label: 'Threads', value: 'Threads' },
        { label: 'Threema', value: 'Threema' },
        { label: 'WhatsApp', value: 'WhatsApp' },
        { label: 'YouTube', value: 'YouTube' },
        { label: 'Twitter', value: 'Twitter' },
        { label: 'Viber', value: 'Viber' },
        { label: 'Other', value: 'Other' },
    ] as SelectValue[];
    const [socialOptions, setSocialOptions] = useState<SelectValue[]>(defaultSocialOptions);


    useEffect(() => {
        setSocialOptions(defaultSocialOptions);
    }, [props.opened]);

    useEffect(() => {
        const filteredOptions = socialOptions.filter(option => !props.issetSocials.some((social: ContactInfoType) => social.field === option.label));
        setSocialOptions(filteredOptions);
    }, [socialOptions]);

    function resetAllValues(): void {
        setSocial(null);
        setLink('');
    }

    function resetAllErrors(): void {
        setSocialError(null);
        setLinkError(null);
    }

    function validate(errors: ValidationError[]): void {
        if (errors.length < 1) {
            resetAllValues()
        }
        errors.forEach((error: ValidationError) => {
            if (error.property.includes('employee.social')) {
                setSocialError(error.message);
            }
            if (error.property.includes('employee.link')) {
                setLinkError(error.message);
            }
        });
    }

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAllValues();
                resetAllErrors();
            }}
            onOk={ () => props.onOk(social, link, validate) }
        >
            <Select
                label='Platform'
                required={ true }
                isMulti={ false }
                value={ social }
                options={ socialOptions }
                error={ socialError }
                onChange={ (newSocial: SelectValue) => {
                    setSocial(newSocial);
                    setLink('')
                    resetAllErrors();
                }}
                isSearchable={ false }
            />

            <TextField
                label='Username/Phone'
                placeholder={social?.value === 'Other' ? '' : 'Add username, email or phone'}
                required={ true }
                value={ link }
                type={ Type.Textarea }
                horizontal={ true }
                error={ linkError }
                onChange={newLink => {
                    const regex = getRegexForSocial(social?.value);
                    const isValid = regex.test(newLink);
                    if (isValid) {
                        setLink(newLink);
                        resetAllErrors();
                    }
                }}
                maxLength={100}
            />
        </Modal>
    );
}

export default BaseModal;