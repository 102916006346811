import React, {useState, useEffect, useMemo} from 'react';
import Calendar from 'react-calendar';

import Filter from "./Modal/Filter";
import CalendarHeader from "./Component/CalendarHeader";
import CalendarList from "./Component/CalendarList";

import { EventForWidget } from '../../../../../shared/Holiday/Widget/data/EventForWidget';
import { EventType } from '../../../../../shared/Holiday/Widget/enum/EventType';
import { FilterValues } from "../../../../../shared/Main/Calendar/data/FilterValues";

import {CALENDAR_WIDGET, EMPLOYEE_SETTINGS} from '../../../../../utils/constants/api';

import arrow from '../../../../../assets/images/input/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './CalendarWidget.scss';
import {dateFromStringWithDefaultTimezone} from "../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

class DayStatus {
    dayOff: boolean = false;
    stateHoliday: boolean = false;
    professionalHoliday: boolean = false;
    corporateEvent: boolean = false;
}

type CalendarWidgetProps = {
    authEmployeeId: string;
}

function CalendarWidget(props: CalendarWidgetProps) {
    const today = new Date();
    const [events, setEvents] = useState<{[date: string]: EventForWidget[]}>({});
    const [calendarListSwitcher, setCalendarListSwitcher] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState(false);

    const [eventsVersion, setEventsVersion] = useState(0);

    const [filterValues, setFilterValues] = useState<FilterValues>({
        showCorporateEvents: true,
        showProfessionalHolidays: true,
        showStateHolidays: true,
        countryCodeFromFilter: '',
        companyIdFromFilter: '',
    });

    const [filterClosedFromInside, setFilterClosedFromInside] = useState<boolean>(false);

    useEffect(() => {
        fetch(EMPLOYEE_SETTINGS + '/' + props.authEmployeeId, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        })
            .then(response => response.json())
            .then(data => setCalendarListSwitcher(data.value.listMonthWidgetSwitcher))
            .catch(error => console.error('An error occurred fetching employee settings:', error));
    }, []);

    useEffect(() => {
        async function fetchAndSetFilterValues() {
            try {
                const newValues = await fetchCountryCodeAndCompanyIdForEmployee();
                setFilterValues((prevValues) => ({
                    ...prevValues,
                    countryCodeFromFilter: newValues.countryCode,
                    companyIdFromFilter: newValues.companyId,
                }));
            } catch (error) {
                console.error('An error occurred fetching new filter values:', error);
            }
        }
        fetchAndSetFilterValues();
    }, []);

    useEffect(() => {
        fetchEventsWithFilters(filterValues)
            .then(processEvents)
            .then(setEvents)
            .catch((error) => {
                console.error('An error occurred fetching events:', error);
            });
    }, [filterValues]);

    useEffect(() => {
        if (calendarListSwitcher) {
            const currentMonthElement = document.getElementById('current-month');
            const calendarListContainer = document.getElementById('calendar-list-container');
            if (currentMonthElement && calendarListContainer) {
                calendarListContainer.scrollTop = currentMonthElement.offsetTop - calendarListContainer.offsetTop;
            }
        }
    });

    function processEvents(eventsForWidget: EventForWidget[]) {
        const eventsForSave: {[key: string]: EventForWidget[]} = {};

        eventsForWidget.forEach((eventForWidget) => {
            const dateObject = dateFromStringWithDefaultTimezone(eventForWidget.date) || (new Date(eventForWidget.date));
            const dateKey = dateObject.toString().substring(0, 10);
            eventsForSave[dateKey] = eventsForSave[dateKey] || [];
            eventsForSave[dateKey].push(eventForWidget);
        });

        return eventsForSave;
    }

    function fetchEventsWithFilters(filters: FilterValues) {
        const queryParams = new URLSearchParams({
            show_corporate_events: filters.showCorporateEvents.toString().substring(0, 10),
            show_professional_holidays: filters.showProfessionalHolidays.toString().substring(0, 10),
            show_state_holidays: filters.showStateHolidays.toString().substring(0, 10),
            country_code_from_filter: filters.countryCodeFromFilter,
            company_id_from_filter: filters.companyIdFromFilter,
        });

        return fetch(`${CALENDAR_WIDGET}?${queryParams}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        }).then((response) => response.json());
    }

    function updateFilterValues(newValues : FilterValues) {
        setFilterValues((prevValues) => ({ ...prevValues, ...newValues }));
    }

    function fetchCountryCodeAndCompanyIdForEmployee() {
        return fetch(`${CALENDAR_WIDGET}/country-code-and-company-id`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        }).then((response) => response.json());
    }

    function sortAndGroupEvents(eventsForWidget: EventForWidget[]) {

        if (!eventsForWidget || eventsForWidget.length === 0) {
            return {};
        }

        const sortedEvents = eventsForWidget.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        const groupedEvents: { [key: string]: EventForWidget[] } = {};

        const startDate = new Date(sortedEvents[0].date);
        const endDate = new Date(sortedEvents[sortedEvents.length - 1].date);

        for (let d = startDate; d <= endDate; d.setMonth(d.getMonth() + 1)) {
            const monthYear = d.toLocaleString('en-US', { month: 'long' }) + "-" + d.getFullYear();
            if (!groupedEvents[monthYear]) {
                groupedEvents[monthYear] = [];
            }
        }

        sortedEvents.forEach((event) => {
            const date = dateFromStringWithDefaultTimezone(event.date) || (new Date(event.date));
            const monthYear = date.toLocaleString('en-US', { month: 'long' }) + "-" + date.getFullYear();

            if (typeof groupedEvents[monthYear] === 'undefined') {
                groupedEvents[monthYear] = [];
            }

            groupedEvents[monthYear].push(event);
        });

        return groupedEvents;
    }

    function onFilterApply(newFilterValues: FilterValues) {
        setShowFilter(false);
        fetchEventsWithFilters(newFilterValues)
            .then(processEvents)
            .then((newEvents) => {
                setEvents(newEvents);
                setEventsVersion((v) => v + 1);
            })
            .catch((error) => {
                console.error('An error occurred fetching events:', error);
            });
    }

    function onFilterCancel() {
        setFilterClosedFromInside(true);
        setShowFilter(false);
        setTimeout(() => {
            setFilterClosedFromInside(false);
        }, 400);
    }

    function onFilterClickFromHeader(value: boolean) {
        if (filterClosedFromInside) {
            setShowFilter(false);
        } else {
            setShowFilter(value);
        }
        setFilterClosedFromInside(false);
    }

    const groupedEvents = useMemo(
        () => sortAndGroupEvents(events ? Object.values(events).flat() : []), [events, eventsVersion]
    );

    function getEventTypeText(eventType: EventType): string {
        switch (eventType) {
            case EventType.stateHoliday:
                return "State Holiday";
            case EventType.professionalHoliday:
                return "Professional Holiday";
            default:
                return "";
        }
    }

    return (
        <div className='calendar-widget'>

            <CalendarHeader
                calendarListSwitcher={calendarListSwitcher}
                setCalendarListSwitcher={setCalendarListSwitcher}
                showFilter={showFilter}
                onFilterClickFromHeader={(value) => onFilterClickFromHeader(value)}
                authEmployeeId={props.authEmployeeId}
            />

            <div className='divider' />

            {calendarListSwitcher ?
                <CalendarList
                    groupedEvents={groupedEvents}
                />
                :
                <Calendar
                    view={'month'}
                    locale={'en'}
                    prevLabel={<img src={arrow} alt=''/>}
                    nextLabel={<img src={arrow} alt=''/>}
                    minDate={new Date(today.getFullYear(), 0, 1)}
                    maxDate={new Date(today.getFullYear() + 1, 11, 31)}
                    value={today}
                    showFixedNumberOfWeeks={true}
                    tileClassName={({ date, activeStartDate, view }) => {
                        let dayStatusClasses = [];

                        if (date.getMonth() !== activeStartDate.getMonth()) {
                            dayStatusClasses.push('calendar-outside-month');
                        }

                        if (!events[date.toString().substring(0, 10)]) {
                            return dayStatusClasses.join(' ');
                        }

                        const currentEvents = events[date.toString().substring(0, 10)];

                        let dayStatus: DayStatus = new DayStatus();

                        currentEvents.forEach(event => {
                            if (event.dayOff && date.getDay() % 6 !== 0) {
                                dayStatus.dayOff = true;
                            }

                            switch(event.type) {
                                case EventType.stateHoliday:
                                    dayStatus.stateHoliday = true;
                                    break;
                                case EventType.professionalHoliday:
                                    dayStatus.professionalHoliday = true;
                                    break;
                                case EventType.corporateEvent:
                                    dayStatus.corporateEvent = true;
                                    break;
                                default:
                                    break;
                            }
                        });

                        if (dayStatus.dayOff && date.setHours(0, 0, 0, 0) !== (new Date()).setHours(0, 0, 0, 0)) {
                            dayStatusClasses.push('calendar-not-workday');
                        }

                        if (dayStatus.stateHoliday && dayStatus.professionalHoliday && dayStatus.corporateEvent) {
                            dayStatusClasses.push('calendar-all-in');
                        } else if (dayStatus.stateHoliday && dayStatus.professionalHoliday) {
                            dayStatusClasses.push('calendar-state-holiday-and-professional-holiday');
                        } else if (dayStatus.stateHoliday && dayStatus.corporateEvent) {
                            dayStatusClasses.push('calendar-state-holiday-and-corporate-event');
                        } else if (dayStatus.professionalHoliday && dayStatus.corporateEvent) {
                            dayStatusClasses.push('calendar-professional-holiday-and-corporate-event');
                        } else if (dayStatus.stateHoliday) {
                            dayStatusClasses.push('calendar-state-holiday');
                        } else if (dayStatus.professionalHoliday) {
                            dayStatusClasses.push('calendar-professional-holiday');
                        } else if (dayStatus.corporateEvent) {
                            dayStatusClasses.push('calendar-corporate-event');
                        }

                        return dayStatusClasses.join(' ');
                    }}
                    tileContent={({activeStartDate, date, view}) => {
                        if (events[date.toString().substring(0, 10)] === undefined) {
                            return null;
                        }

                        let tooltips: JSX.Element[] = [];

                        events[date.toString().substring(0, 10)].forEach(event => {
                            let eventTypeClass = '';

                            if (event.type === EventType.stateHoliday) {
                                eventTypeClass = 'tooltip-state-holiday';
                            } else if (event.type === EventType.professionalHoliday) {
                                eventTypeClass = 'tooltip-professional-holiday';
                            } else if (event.type === EventType.corporateEvent) {
                                eventTypeClass = 'tooltip-corporate-event';
                            }

                            tooltips.push(
                                <div className={'tooltip-container ' + eventTypeClass}>
                                    <div className={'event-name'}>{event.name}</div>
                                    {getEventTypeText(event.type) !== '' &&
                                        <div className={(event.paidTimeOff || event.additionalPaidTimeOff) ? 'paid-time-off-text' : 'event-type-name'}>
                                        {getEventTypeText(event.type)}
                                    </div>}
                                    {(date.getDay() !== 6 && date.getDay() !== 0 && event.paidTimeOff && !event.additionalPaidTimeOff) ?
                                        <div className={'event-type-name'}>Paid Time Off</div> : null}
                                    {(event.additionalPaidTimeOff) ? <div className={'event-type-name'}>Additional Paid Time Off</div> : null}
                                </div>
                            );
                        });

                        return (
                            <div className='calendar-tooltip'>
                                {tooltips}
                            </div>
                        );
                    }}
                />
            }
            {showFilter && (
                <Filter
                    filterValues={filterValues}
                    updateFilterValues={updateFilterValues}
                    onOk={filterValues => onFilterApply(filterValues)}
                    onCancel={onFilterCancel}
                />
            )}
        </div>
    );
}

export default CalendarWidget;