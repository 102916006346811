import React from 'react';
import BaseModal from './BaseModal';
import { TEAMS } from '../../../../../utils/constants/api';
import { TeamFromApi } from '../../../../../shared/Team/data/TeamFromApi';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type CreateTeam = {
    opened: boolean,
    companyId: string,
    teams: TeamFromApi[],
    onOk: () => void,
    onClose: () => any
};

function CreateTeam(props: CreateTeam) {
    function create(
        name: string,
        parentId: string | null,
        companyId: string,
        leaderId: string | null,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();

        formData.append('team', JSON.stringify({
            name: name,
            parentId: parentId,
            companyId: props.companyId,
            leaderId: leaderId,
        }));

        fetch(TEAMS, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Team'
            teams={ props.teams }
            teamValue={ null }
            parentTeam={ null }
            leaderTeam={ null }
            companyId={ props.companyId }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateTeam;