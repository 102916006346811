import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { EmployeeDataFromApi } from "../../../../../../shared/Search/data/EmployeeDataFromApi";
import { EMPLOYEE_PROFILE } from "../../../../../../utils/constants/routes";
import {TimeOffTypeIconsCollection} from "../../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";

type SearchResultsProps = {
    results: EmployeeDataFromApi[];
    highlightedIndex: number | null;
    onLinkClick: () => void;
};

function SearchResults({ results, highlightedIndex, onLinkClick }: SearchResultsProps) {
    const resultRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        if (highlightedIndex !== null && resultRefs.current[highlightedIndex]) {
            resultRefs.current[highlightedIndex]!.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [highlightedIndex]);

    return (
        <div className='results'>
            <p className='results-title'>Search results</p>
            {results.map((result, index) => (
                <Link
                    style={{ textDecoration: 'none' }}
                    onClick={onLinkClick}
                    to={`${EMPLOYEE_PROFILE}/${result.nickname}`}
                    key={index}
                >
                    <div
                        className={`result-item ${highlightedIndex === index ? 'highlighted' : ''}`}
                        ref={el => resultRefs.current[index] = el}
                    >
                        <div className='avatar-container'>
                            {result.imageUrl ? (
                                <div className='avatar'>
                                    <img src={result.imageUrl} alt='Employee avatar'/>
                                </div>
                            ) : (
                                <div className='without-avatar'>
                                    <p>{result.nickname.charAt(0).toUpperCase()}</p>
                                </div>
                            )}
                        </div>
                        <div className='user-data-container'>
                            <div className='nickname-request-icon'>
                                <div className='nickname'>{result.nickname}</div>
                                <div
                                    className='request-icon'
                                    dangerouslySetInnerHTML={{
                                        __html: TimeOffTypeIconsCollection[
                                            result.requestIconName as keyof typeof TimeOffTypeIconsCollection]
                                    }}
                                />
                            </div>
                            <div className='user-names'>
                                {result.firstName} {result.lastName}
                            </div>
                        </div>
                        <div className='company-data-container'>
                            {result.companyName}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default SearchResults;