import React, { useState, useEffect } from 'react';
import './SexesAndGenders.scss';
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../../Page/Layer/SubMenu/SubMenu';
import CreateSex from './Sexes/Modal/CreateSex';
import DeleteSex from './Sexes/Modal/DeleteSex';
import EditSex from './Sexes/Modal/EditSex';
import CreateGender from './Genders/Modal/CreateGender';
import DeleteGender from './Genders/Modal/DeleteGender';
import EditGender from './Genders/Modal/EditGender';
import {GENDERS, SEXES} from '../../../../../utils/constants/api';
import Button, {Size, Type} from '../../../../Component/Button/Button';
import { SexFromApi } from '../../../../../shared/Employee/Sex/data/SexFromApi';
import { GenderFromApi } from '../../../../../shared/Employee/Gender/data/GenderFromApi';
import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';

type SexesAndGenders = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function SexesAndGenders(props: SexesAndGenders) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createSexModal, setCreateSexModal] = useState<boolean>(false);
    const [editSexModal, setEditSexModal] = useState<boolean>(false);
    const [deleteSexModal, setDeleteSexModal] = useState<boolean>(false);

    const [sexes, setSexes] = useState<SexFromApi[]>([]);
    const [sexToChange, setSexToChange] = useState<SexFromApi | null>(null);
    const [sexToDelete, deleteSex] = useState<SexFromApi | null>(null);

    const [createGenderModal, setCreateGenderModal] = useState<boolean>(false);
    const [editGenderModal, setEditGenderModal] = useState<boolean>(false);
    const [deleteGenderModal, setDeleteGenderModal] = useState<boolean>(false);

    const [genders, setGenders] = useState<GenderFromApi[]>([]);
    const [genderToChange, setGenderToChange] = useState<GenderFromApi | null>(null);
    const [genderToDelete, deleteGender] = useState<GenderFromApi | null>(null);


    useEffect(() => {
        getSexes();
        getGenders();
    }, [])

    function getSexes() {
        fetch(SEXES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((sexes: SexFromApi[]) => setSexes(sexes));
    }

    function getGenders() {
        fetch(GENDERS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((genders: GenderFromApi[]) => setGenders(genders));
    }

    function editSexModalVisibility(sex: SexFromApi): void {
        setSexToChange(sex);
        setEditSexModal(true);
    }

    function editGenderModalVisibility(gender: GenderFromApi): void {
        setGenderToChange(gender);
        setEditGenderModal(true);
    }

    function confirmDeleteSexModalVisibility(sex: SexFromApi): void {
        deleteSex(sex)
        setDeleteSexModal(true);
    }

    function confirmDeleteGenderModalVisibility(gender: GenderFromApi): void {
        deleteGender(gender)
        setDeleteGenderModal(true);
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Sexes and Genders</h3>
                                </div>

                                <div className='add-button-block'>
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add gender pronouns"
                                        size={ Size.Medium }
                                        type={ Type.Accent }
                                        onClick={ () => setCreateGenderModal(true) }
                                    />
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add sex"
                                        size={ Size.Medium }
                                        type={ Type.White }
                                        onClick={ () => setCreateSexModal(true) }
                                    />
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <table className="sexes-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Sex
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {sexes.map(sex => {
                                        return (
                                            <tr key={ sex.id }>
                                                <td title={ sex.name }>{ sex.name }</td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editSexModalVisibility(sex) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteSexModalVisibility(sex) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>

                                <table className="genders-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Gender
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {genders.map(gender => {
                                        return (
                                            <tr key={ gender.id }>
                                                <td title={ gender.name }>{ gender.name }</td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editGenderModalVisibility(gender) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteGenderModalVisibility(gender) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                <CreateSex
                    opened={ createSexModal }
                    onOk={ () => { getSexes(); setCreateSexModal(false); } }
                    onClose={ () => setCreateSexModal(false) }
                />
                {
                    sexToDelete ?
                        <DeleteSex
                            opened={ deleteSexModal }
                            onOk={ () => { getSexes(); setDeleteSexModal(false); deleteSex(null) } }
                            onClose={ () => { setDeleteSexModal(false); deleteSex(null) } }
                            sex={ sexToDelete }
                        /> : null
                }
                {
                    sexToChange
                        ?
                        <EditSex
                            opened={ editSexModal }
                            onOk={ () => { getSexes(); setEditSexModal(false); setSexToChange(null) } }
                            onClose={ () => { setEditSexModal(false); setSexToChange(null) } }
                            sex={ sexToChange }
                        />
                        : null
                }
                <CreateGender
                    opened={ createGenderModal }
                    onOk={ () => { getGenders(); setCreateGenderModal(false); } }
                    onClose={ () => setCreateGenderModal(false) }
                />
                {
                    genderToDelete ?
                        <DeleteGender
                            opened={ deleteGenderModal }
                            onOk={ () => { getGenders(); setDeleteGenderModal(false); deleteGender(null) } }
                            onClose={ () => { setDeleteGenderModal(false); deleteGender(null) } }
                            gender={ genderToDelete }
                        /> : null
                }
                {
                    genderToChange
                        ?
                        <EditGender
                            opened={ editGenderModal }
                            onOk={ () => { getGenders(); setEditGenderModal(false); setGenderToChange(null) } }
                            onClose={ () => { setEditGenderModal(false); setGenderToChange(null) } }
                            gender={ genderToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default SexesAndGenders;