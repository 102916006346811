export const AUTH: string = '/api/auth';
export const GREETINGS: string = '/api/greetings';
export const LINKS: string = '/api/links';
export const COMPANIES: string = '/api/companies';
export const SEXES: string = '/api/sexes';
export const GENDERS: string = '/api/genders';
export const LEVELS: string = '/api/employee-levels';
export const STATUSES: string = '/api/employee-statuses';
export const TYPES: string = '/api/employee-types';
export const LOCATIONS: string = '/api/locations';
export const TEAMS: string = '/api/teams';
export const COUNTRIES: string = '/api/countries';
export const TIME_OFF_TYPES: string = '/api/time-off-types';
export const TIME_OFF_TYPE_ICONS: string = '/api/time-off-type-icons';
export const GREETINGS_WIDGET: string = '/api/greetings/widget';
export const LINKS_WIDGET: string = '/api/links/widget';
export const JOB_TITLES: string = '/api/job-titles';
export const JOB_TITLES_CATEGORIES: string = '/api/job-title-categories';
export const PUSH_NOTIFICATIONS: string = '/api/push-notifications';
export const PROFESSIONAL_HOLIDAYS: string = '/api/professional-holidays';
export const CORPORATE_EVENTS: string = '/api/corporate-events';
export const STATE_HOLIDAYS: string = '/api/state-holidays';
export const EMPLOYEES: string = '/api/employees';
export const EMPLOYEE: string = '/api/employee';
export const TIME_OFF_POLICIES: string = '/api/time-off-policies';
export const CALENDAR: string = '/api/calendar';
export const HOLIDAYS: string = '/api/holidays';
export const SEARCH: string = '/api/employee-search';
export const ACCRUED_DAYS_BALANCES: string = '/api/accrued-days-balances';
export const CALENDAR_WIDGET: string = '/api/holidays/widget';
export const ACCESS_LEVELS: string = '/api/access-levels';
export const EMPLOYEE_SETTINGS: string = '/api/employee-settings';
export const MEMEGEN: string = '/api/memegen';