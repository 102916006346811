import React, {useEffect, useState} from 'react';

import BaseModal from './BaseModal';
import ConfirmApproveRequest from "./ConfirmApproveRequest";
import {checkSubstitute} from "../../../../../utils/helpers/EmployeeChecks";
import {formatDate} from "../../../../../utils/helpers/DateTime/formatDate";

import {ValidationError} from '../../../../../shared/Common/Error/ValidationError';
import {EmployeeFromApi} from "../../../../../shared/Employee/data/EmployeeFromApi";
import {AuthEmployee} from "../../../../../shared/Security/data/AuthEmployee";
import {RequestFromApi} from "../../../../../shared/Calendar/Request/data/RequestFromApi";

import {CALENDAR, EMPLOYEE, EMPLOYEES} from '../../../../../utils/constants/api';
import {VALIDATION_ERROR} from '../../../../../utils/constants/errors';
import {ROLE_ADMIN, ROLE_HR} from "../../../../../shared/Security/constants/AccessLevels";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";


type CreateRequest = {
    opened: boolean,
    requester?: EmployeeFromApi | null,
    authEmployee: AuthEmployee,
    onOk: () => void,
    onClose: () => any,
    onNewRequestCreated?: (request: RequestFromApi) => void
    origin? : string
};

function CreateRequest({opened, requester, authEmployee, onOk, onClose, onNewRequestCreated, origin}: CreateRequest) {
    const [isApproveModalOpened, setIsApproveModalOpened] = useState<boolean>(false);
    const [isApproveModalForRequesterOpened, setIsApproveModalForRequesterOpened] = useState<boolean>(false);
    const [pendingRequest, setPendingRequest] = useState<{formData: FormData, validate: (errors: ValidationError[]) => void} | null>(null);

    const [hierarchyChainIds, setHierarchyChainIds] = useState<string[]>([]);
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => authEmployee.roles.includes(role));

    const [employees, setEmployees] = useState<SelectValue[]>([]);
    const [whoRequester, setWhoRequester] = useState<SelectValue | null>(null);
    const [requesterSubstitute, setRequesterSubstitute] = useState<SelectValue | null>(null);
    const [substituterSubstitute, setSubstituterSubstitute] = useState<SelectValue | null>(null);
    const [startRequestDate, setStartRequestDate] = useState<string>('');

    useEffect(() => {
        if (authEmployee && authEmployee.id) {
            getHierarchyChainIds(authEmployee.id)
                .then((ids: string[]) => setHierarchyChainIds(ids));
        }
        getEmployees();
    }, [authEmployee]);

    function getEmployees() {
        fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then(async (employees: EmployeeFromApi[]) => {
                let options = employees.map(employee => {
                    return {
                        value: employee.id,
                        label: employee.nickname
                    }
                });
                setEmployees(options);
            });
    }

    function saveRequest(formData: FormData, validate: (errors: ValidationError[]) => void): void {
        fetch(CALENDAR + '/request', { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === VALIDATION_ERROR) {
                    return response.json().then((errors: ValidationError[]) => validate(errors));
                }
                return null;
            })
            .then(newRequest => {
                if (newRequest === null || newRequest === undefined) {
                    return;
                }
                if (onNewRequestCreated) {
                    onNewRequestCreated(newRequest);
                }
                validate([]);
                onOk();
            });
    }

    function create(
        requester: string,
        type: string,
        startDate: Date | null,
        endDate: Date | null,
        substitute: string,
        balance: string,
        comment: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        let status = 'New';
        if ((hasRole && requester !== authEmployee.id) || isHierarchyChain(requester)) {
            status = 'Approved';
        }

        let formData = new FormData();
        formData.append('request', JSON.stringify({
            requester: requester,
            type: type,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            substitute: substitute !== 'empty' ? substitute : null,
            balance: balance,
            comment: comment ? comment : null,
            status: status,
            statusChangeBy: authEmployee.id
        }));
        setStartRequestDate(startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + "-" + startDate.getDate() : '');
        let whoCreateRequest = employees.find(employee => employee.value === requester);
        setWhoRequester(whoCreateRequest ? whoCreateRequest : null);
        let newRequesterSubstitute = employees.find(employee => employee.value === substitute);
        setRequesterSubstitute(newRequesterSubstitute ? newRequesterSubstitute : null);
        checkSubstitute(
            requester,
            startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + "-" + startDate.getDate() : '',
            endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + "-" + endDate.getDate() : '',
            ''
        ).then(id => {
            if (id && startDate && endDate) {
                let newRequesterSubstitute = employees.find(employee => employee.value === id);
                setRequesterSubstitute(newRequesterSubstitute ? newRequesterSubstitute : null);
                setPendingRequest({formData, validate});
                if (newRequesterSubstitute !== undefined) {
                    setIsApproveModalForRequesterOpened(true);
                }
            } else {
                if (substitute && substitute !== 'empty') {
                    checkSubstitute(
                        substitute,
                        startDate ? startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + "-" + startDate.getDate() : '',
                        endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + "-" + endDate.getDate() : '',
                        ''
                    ).then(id  => {
                        if (id) {
                            let newSubstituterSubstitute = employees.find(employee => employee.value === id);
                            setSubstituterSubstitute(newSubstituterSubstitute ? newSubstituterSubstitute : null);
                            setPendingRequest({formData, validate});
                            if (newSubstituterSubstitute !== undefined) {
                                setIsApproveModalOpened(true);
                            }
                        } else {
                            saveRequest(formData, validate);
                        }
                    });
                } else {
                    saveRequest(formData, validate);
                }
            }
        });
    }

    function getHierarchyChainIds(employeeId: string): Promise<string[]> {
        return fetch(EMPLOYEE + '/' + employeeId + '/all-hierarchy-chains', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json());
    }

    function isHierarchyChain(employeeId: string): boolean {
        return hierarchyChainIds.includes(employeeId);
    }

    function handleModalOk(): void {
        setIsApproveModalOpened(false);
        setIsApproveModalForRequesterOpened(false);
        if(pendingRequest) {
            saveRequest(pendingRequest.formData, pendingRequest.validate);
            setPendingRequest(null);
        }
    }

    const approveModalLinkString = `<a class="calendar-link" target="_blank" href="calendar?index=1&renderFromMenu=true&selected-employee=${substituterSubstitute?.value}&start-date=${startRequestDate}">${substituterSubstitute?.label}</a>`;
    const approveModalForRequesterLinkString = `<a class="calendar-link" target="_blank" href="calendar?index=1&renderFromMenu=true&selected-employee=${requesterSubstitute?.value}&start-date=${startRequestDate}">${requesterSubstitute?.label}</a>`;


    return (
        <>
            <BaseModal
                opened={ opened }
                modalTitle='Create a Request'
                okText='Send request'
                requester={ requester ? requester : null }
                onCancel={ onClose }
                onOk={ create }
                request={ null }
                authEmployee={ authEmployee }
                origin={origin}
            />
            <ConfirmApproveRequest
                opened={isApproveModalOpened}
                modalTitle='Attention'
                confirmModalText={`Be aware that substitute <span class="bold">${requesterSubstitute?.label}</span> is already filling in for <span class="bold">${approveModalLinkString}</span> during the chosen period.`}
                okText='OK'
                onOk={handleModalOk}
                onClose={() => setIsApproveModalOpened(false)}
                request={null}
                hasCancelButton={true}
            />
            <ConfirmApproveRequest
                opened={isApproveModalForRequesterOpened}
                modalTitle='Attention'
                confirmModalText={`Be aware that requester <span class="bold">${whoRequester?.label}</span> is already filling in for <span class="bold">${approveModalForRequesterLinkString}</span> during the chosen period.`}
                okText='OK'
                onOk={handleModalOk}
                onClose={() => setIsApproveModalForRequesterOpened(false)}
                request={null}
                hasCancelButton={true}
            />
        </>
    )
}

export default CreateRequest;