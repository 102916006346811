import React, {useEffect, useRef} from 'react';
import {EMPLOYEE_PROFILE} from "../../../../../../utils/constants/routes";

import calendarIcon from '../../../../../../assets/images/mainPageIcons/calendar_icon.svg';
import yes from '../../../../../../assets/images/input/main-calendar/yes.svg';

import './OutgoingEmployeesWidget.scss';
import {
    EmployeesForEmployeeStatusWidget
} from '../../../../../../shared/Main/EmployeesForEmployeeStatusWidget/data/EmployeesForEmployeeStatusWidget';
import {dateFromStringWithDefaultTimezone} from "../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type OutgoingEmployeesWidget = {
    areThereAnyOutgoingEmployees: boolean,
    outgoingEmployees: EmployeesForEmployeeStatusWidget[],
}

function OutgoingEmployeesWidget(props: OutgoingEmployeesWidget) {
    function formatTerminationDate(date: string) {
        const dateObject = dateFromStringWithDefaultTimezone(date) || (new Date(date));
        const month = dateObject.toLocaleString('en-US', {month: 'short'});
        const day = dateObject.getDate();

        return `${month}, ${day}`;
    }

    const scrollableBlockRef = useRef<HTMLDivElement | null>(null);
    const isDraggingRef = useRef(false);
    const startXRef = useRef(0);
    const scrollLeftRef = useRef(0);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button === 0) {
            isDraggingRef.current = true;
            startXRef.current = e.clientX;
            if (scrollableBlockRef.current) {
                scrollLeftRef.current = scrollableBlockRef.current.scrollLeft;
                scrollableBlockRef.current.style.cursor = 'grabbing';
            }
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDraggingRef.current) {
            const deltaX = e.clientX - startXRef.current;
            if (scrollableBlockRef.current) {
                scrollableBlockRef.current.scrollLeft = scrollLeftRef.current - deltaX;
            }
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        if (scrollableBlockRef.current) {
            scrollableBlockRef.current.style.cursor = 'grab';
        }
    };

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <div className={'outgoing-employees-widget-main'}>
            <div className={
                (props.areThereAnyOutgoingEmployees ? 'outgoing-full-height ' : '')
                + (!props.areThereAnyOutgoingEmployees ? 'pb-20 ' : '')
                +'outgoing-employees-widget '}>
                <div className='outgoing-employees-block-description'>GOING TO QUIT</div>
                <div className='outgoing-employees-block-title'>Best of luck on your new adventure!</div>

                <div className='outgoing-employees-body'>
                    {!props.areThereAnyOutgoingEmployees ? (
                        <div className={'outgoing-employees-absence-text'}>
                            <p> Good news, everyone. There are no terminations to report this week. </p>
                            <p> Our team remains intact and focused 👍 </p>
                        </div>
                    ) : (
                        <>
                            <div className='outgoing-employees-block'
                                 ref={scrollableBlockRef}
                                 onMouseDown={handleMouseDown}
                                 onMouseMove={handleMouseMove}
                                 onMouseUp={handleMouseUp}
                            >
                                {props.outgoingEmployees.map((employee, index) => (
                                    <div className={'outgoing-employee-card'}>
                                        <div className='employee-card-link'>
                                            <div className='employee-card'>
                                                { employee.avatar ? (
                                                    <div
                                                        className='employee-card__avatar'
                                                        style={{backgroundImage: `url(${employee.avatar || 'default-avatar-url'})`}}
                                                    >
                                                        <div className='employee-card__avatar__company'>
                                                            {employee.companyName}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className='employee-card__no-avatar'
                                                    >
                                                        <img src={yes} alt="smile" style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: '96px',
                                                            backgroundColor: '#D98304'
                                                        }} />
                                                        <div className='employee-card__avatar__company'>
                                                            {employee.companyName}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='employee-card__details'>
                                                    <div className='employee-card__top-details'>
                                                        <div className='employee-card__nickname'>
                                                            <a href={EMPLOYEE_PROFILE + '/' + employee.nickname} target='_blank' className='employee-card-link'>{employee.nickname}</a>
                                                        </div>
                                                        <div
                                                            className='employee-card__jobtitle'>{employee.jobTitle || 'No job title'}</div>
                                                    </div>
                                                    {employee.terminationDate ? <div className='employee-card__termination-date'>
                                                        <img src={calendarIcon} alt='Event Image'/>
                                                        {formatTerminationDate(employee.terminationDate)}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OutgoingEmployeesWidget;