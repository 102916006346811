import React, { useState, useEffect } from 'react';

import { GREETINGS_WIDGET } from '../../../../../utils/constants/api';
import { GreetingForWidget } from '../../../../../shared/Greeting/data/GreetingForWidget';

import SpecialGreeting from '../../../../../assets/images/emoji/greeting-hi-2.png';
import SimpleGreeting from '../../../../../assets/images/emoji/cube.png';

import './GreetingsWidget.scss';

function GreetingsWidget() {
    const [greeting, setGreeting] = useState({ text: "", special: false });
    const [greetingsList, setGreetingsList] = useState<GreetingForWidget[]>([]);

    useEffect(() => {
        fetch(GREETINGS_WIDGET, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((greetings: GreetingForWidget[]) => {
                setGreetingsList(greetings);
                const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
                setGreeting({ text: randomGreeting.text, special: randomGreeting.special });
            });
    }, []);

    function handleIconClick() {
        if (greeting.special) return;
        const randomGreeting = greetingsList[Math.floor(Math.random() * greetingsList.length)];
        setGreeting({ text: randomGreeting.text, special: randomGreeting.special });
    }

    return (
        <div className='greetings-widget'>
            <div className='text'>
                <p>{ greeting.text }</p>
            </div>
            <div className='smiles'>
                <div className={`second-smile ${greeting.special ? 'not-pointer' : ''}`} onClick={handleIconClick}>
                    {greeting.special ? <img src={SpecialGreeting} alt="Hi"/> : <img src={SimpleGreeting} alt="Hi"/>}
                </div>
            </div>
        </div>
    );
}


export default GreetingsWidget;