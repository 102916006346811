import React, {useEffect, useRef, useState} from 'react';
import {EMPLOYEE_PROFILE} from "../../../../../../utils/constants/routes";
import {countryCodeToName} from "../../../../../../shared/Location/constants/CountryCodeToName";

import closeIcon from '../../../../../../assets/images/mainPageIcons/close_icon.svg';
import locationOnIcon from '../../../../../../assets/images/mainPageIcons/location_on_icon.svg';
import smile from '../../../../../../assets/images/input/main-calendar/Smile.svg';

import './NewbiesWidget.scss';
import {
    EmployeesForEmployeeStatusWidget
} from '../../../../../../shared/Main/EmployeesForEmployeeStatusWidget/data/EmployeesForEmployeeStatusWidget';

type NewbiesWidget = {
    areThereAnyNewbies: boolean,
    newbies: EmployeesForEmployeeStatusWidget[],
}

function NewbiesWidget(props: NewbiesWidget) {
    const [selectedNewbie, setSelectedNewbie] = useState<EmployeesForEmployeeStatusWidget | null>(null);
    const scrollableBlockRef = useRef<HTMLDivElement | null>(null);
    const isDraggingRef = useRef(false);
    const startXRef = useRef(0);
    const scrollLeftRef = useRef(0);
    const scrollLeftOldRef = useRef(0);

    function selectNewbie(newbie: EmployeesForEmployeeStatusWidget) {
        setSelectedNewbie(newbie);

        setTimeout(() => {
            const selectedElement = document.querySelector('.extended-newbie-card');
            selectedElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }, 100);
    }

    function getCountryName(code: string | null): string | null {
        return code ? countryCodeToName[code as keyof typeof countryCodeToName] || null : null;
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button === 0) {
            scrollLeftOldRef.current = scrollableBlockRef.current ? scrollableBlockRef.current.scrollLeft : 0;
            isDraggingRef.current = true;
            startXRef.current = e.clientX;
            if (scrollableBlockRef.current) {
                scrollLeftRef.current = scrollableBlockRef.current.scrollLeft;
                scrollableBlockRef.current.style.cursor = 'grabbing';
            }
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDraggingRef.current) {
            const deltaX = e.clientX - startXRef.current;
            if (scrollableBlockRef.current) {
                scrollableBlockRef.current.scrollLeft = scrollLeftRef.current - deltaX;
            }
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        if (scrollableBlockRef.current) {
            scrollableBlockRef.current.style.cursor = 'grab';
        }
    };

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <div className={'newbies-widget-main'}>
            <div className={
                (props.areThereAnyNewbies ? 'newbies-full-height ' : '')
                + (!props.areThereAnyNewbies ? 'pb-20 ' : '')
                + 'newbies-widget'}
            >
                <div className='newbies-block-description'>NEW EMPLOYEES</div>
                <div className='newbies-block-title'>Join the squad for an epic journey!</div>

                <div className={'newbies-body'}>
                    {!props.areThereAnyNewbies ? (
                        <div className={'newbies-absence-text'}>
                            <p> News flash: Our company's welcome committee is on a break today. Looks </p>
                            <p> like there are no new employees to initiate into our wacky world! 🙄 </p>
                        </div>
                    ) : (
                        <div className='newbies-container'
                             ref={scrollableBlockRef}
                             onMouseDown={handleMouseDown}
                             onMouseMove={handleMouseMove}
                             onMouseUp={handleMouseUp}
                        >
                            {props.newbies.map((newbie, index) =>
                                <div key={index} className='newbie-card-container'>
                                    {selectedNewbie && selectedNewbie.nickname === newbie.nickname ?
                                        <div className="extended-newbie-card"
                                             onClick={() => {
                                                 if (scrollableBlockRef.current && scrollableBlockRef.current.scrollLeft === scrollLeftOldRef.current) {
                                                     setSelectedNewbie(null)
                                                 }
                                                 scrollLeftOldRef.current = scrollableBlockRef.current ? scrollableBlockRef.current.scrollLeft : 0;
                                             }}
                                        >
                                            <div
                                                className={`newbie-card small ${!selectedNewbie.avatar ? 'no-avatar' : ''}`}
                                                style={selectedNewbie.avatar ? {backgroundImage: `url(${selectedNewbie.avatar})`} : {background: '#D98304'}}
                                            >
                                                <div className='newbie-card-content'>
                                                    <a href={EMPLOYEE_PROFILE + '/' + newbie.nickname}
                                                       className='employee-card-link'>
                                                        <div className="nickname">{selectedNewbie.nickname}</div>
                                                    </a>
                                                    <p className='company-name'>{selectedNewbie.companyName}</p>
                                                </div>
                                                {!selectedNewbie.avatar &&
                                                    <img src={smile} alt="smile" style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '110px',
                                                        backgroundColor: '#D98304'
                                                    }} />
                                                }
                                            </div>

                                            <div className='newbie-details'>
                                                <div>
                                                    <div className={'newbie-extended-card-names'}>{selectedNewbie.firstNameEn} {selectedNewbie.lastNameEn}</div>
                                                    <div className={'newbie-extended-card-job-title'}>{selectedNewbie.jobTitle}</div>
                                                </div>
                                                { (selectedNewbie.country || selectedNewbie.city) && (
                                                    <div className={'newbie-extended-card-location'}>
                                                        <img src={locationOnIcon} alt='Location'/>
                                                        {[selectedNewbie.city, getCountryName(selectedNewbie.country)].filter(Boolean).join(', ')}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="close-button" onClick={() => setSelectedNewbie(null)}>
                                                <img src={closeIcon} alt='Close'/>
                                            </div>
                                        </div>
                                        :
                                        newbie.avatar ?
                                            <div
                                                className='newbie-card'
                                                onClick={() => {
                                                    if (scrollableBlockRef.current && scrollableBlockRef.current.scrollLeft === scrollLeftOldRef.current) {
                                                        selectNewbie(newbie)
                                                    }
                                                    scrollLeftOldRef.current = scrollableBlockRef.current ? scrollableBlockRef.current.scrollLeft : 0;
                                                }}
                                                style={{backgroundImage: `url(${newbie.avatar})`}}
                                            >
                                                <div className='newbie-card-content'>
                                                    <a href={EMPLOYEE_PROFILE + '/' + newbie.nickname}
                                                       className='employee-card-link'>
                                                        <div className="nickname">{newbie.nickname}</div>
                                                    </a>
                                                    <p className='company-name'>{newbie.companyName}</p>
                                                </div>
                                            </div>
                                            :
                                            <div
                                                className='newbie-card no-avatar'
                                                onClick={() => {
                                                    if (scrollableBlockRef.current && scrollableBlockRef.current.scrollLeft === scrollLeftOldRef.current) {
                                                        selectNewbie(newbie)
                                                    }
                                                    scrollLeftOldRef.current = scrollableBlockRef.current ? scrollableBlockRef.current.scrollLeft : 0;
                                                }}
                                                style={{background: '#D98304', position: 'relative'}}
                                            >
                                                <div className='newbie-card-content'>
                                                    <a href={EMPLOYEE_PROFILE + '/' + newbie.nickname}
                                                       className='employee-card-link'>
                                                        <div className="nickname">{newbie.nickname}</div>
                                                    </a>
                                                    <p className='company-name'>{newbie.companyName}</p>
                                                </div>
                                                <img src={smile} alt="smile" style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '96px',
                                                    backgroundColor: '#D98304'
                                                }} />
                                            </div>
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NewbiesWidget;