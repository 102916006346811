import React from 'react';
import { EventForWidget } from '../../../../../../shared/Holiday/Widget/data/EventForWidget';
import {
    dateFromStringWithDefaultTimezone
} from "../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type CalendarListProps = {
    groupedEvents: { [key: string]: EventForWidget[] };
};

function CalendarList(props: CalendarListProps) {
    const { groupedEvents } = props;

    function isPreviousMonth(monthYear: string): boolean {
        const [monthName, year] = monthYear.split("-");

        const currentMonthIndex = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthIndex = monthNames.indexOf(monthName);

        if (parseInt(year) < currentYear) {
            return true;
        } else return parseInt(year) === currentYear && monthIndex < currentMonthIndex;
    }

    function isPastEvent(eventDate: Date): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // set to the start of today
        return eventDate < today;
    }

    function getMonthNameFromDate(date: Date): string {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[date.getMonth()];
    }

    function getMonthYearKey(date: Date): string {
        return getMonthNameFromDate(date) + "-" + date.getFullYear();
    }

    function isCurrentMonth(monthYear: string): boolean {
        return monthYear === getMonthYearKey(new Date());
    }

    return (
        <div className={'calendar-list-wrapper'}>
            <div className='calendar-list-container' id={'calendar-list-container'}>
                <div className={'calendar-list'}>
                    {Object.keys(groupedEvents).map((monthYear, index) => {
                        const [month, year] = monthYear.split("-");

                        return (
                            <div
                                className={'month-container'}
                                key={index}
                                id={isCurrentMonth(monthYear) ? 'current-month' : undefined}
                            >
                                <div className={isPreviousMonth(monthYear) ? 'month-header past-event' : 'month-header'}>{month} {year}</div>
                                <div className={'month-body'}>
                                    {
                                        groupedEvents[monthYear].length > 0 ? (
                                            groupedEvents[monthYear].map((event, eventIndex) => (
                                                <p
                                                    className={isPastEvent(dateFromStringWithDefaultTimezone(event.date) || (new Date(event.date))) ? 'past-event' : ''}
                                                    key={eventIndex}
                                                >
                                                <span className={event.dayOff ? 'day-off' : ''}>
                                                    {(dateFromStringWithDefaultTimezone(event.date) || new Date(event.date)).getDate().toString().padStart(2, '0')}
                                                </span>{' '} {event.name}
                                                </p>
                                            ))
                                        ) : (
                                            <p className={isPreviousMonth(monthYear) ? 'past-event' : ''}>No holidays
                                                and events</p>
                                        )
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CalendarList;