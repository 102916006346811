import React from 'react';
import { LEVELS } from '../../../../../../../utils/constants/api';
import { LevelFromApi } from '../../../../../../../shared/Employee/Level/data/LevelFromApi';
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal';

type DeleteLevel = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    level: LevelFromApi
};

function DeleteLevel(props: DeleteLevel) {
    function deleteLevel(): void {
        fetch(LEVELS + '/' + props.level.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Employee Level'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.level.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteLevel }
        />
    )
}

export default DeleteLevel;