import React from 'react';
import {EMPLOYEE_SETTINGS} from "../../../../../../utils/constants/api";
import './CalendarHeader.scss';

type CalendarHeaderProps = {
    calendarListSwitcher: boolean;
    setCalendarListSwitcher: (value: boolean) => void;
    showFilter: boolean;
    onFilterClickFromHeader: (value: boolean) => void;
    authEmployeeId: string;
};

function CalendarHeader(props: CalendarHeaderProps) {
    const { calendarListSwitcher, setCalendarListSwitcher, showFilter, onFilterClickFromHeader } = props;

    async function handleSwitchChange() {
        const newValue = !calendarListSwitcher;
        const employeeId = props.authEmployeeId;

        try {
            await fetch(EMPLOYEE_SETTINGS + '/' + employeeId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    listMonthWidgetSwitcher: newValue
                }),
            });

            setCalendarListSwitcher(newValue);
        } catch (error) {
            console.error(`Failed to update settings: ${error}`);
        }
    }

    return (
        <div className='calendar-header'>
            <p>Holidays and events</p>
            <div className='material-symbols-container'>
                <div className={'symbol-wrapper'}>
                    <span
                        className="material-symbols-outlined"
                        onClick={handleSwitchChange}
                    >
                    {calendarListSwitcher ? 'calendar_month' : 'list'}
                    </span>
                </div>
                <div className={'symbol-wrapper'}>
                    <span
                        className="material-symbols-outlined"
                        onClick={() => onFilterClickFromHeader(!showFilter)}
                    >
                    discover_tune
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CalendarHeader;