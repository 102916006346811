import {SelectValue} from "../../../../Common/data/SelectValue";

export const workScheduleOptions: SelectValue[] = [
    {value: '0', label: '0 hours per day'},
    {value: '4', label: '4 hours per day'},
    {value: '6', label: '6 hours per day'},
    {value: '8', label: '8 hours per day'},
];

export const employeeStatusOptions: SelectValue[] = [
    {value: '', label: 'Select'},
    {value: 'onProbationPeriod', label: 'On probation period'},
    {value: 'onStaff', label: 'Staff member'},
    {value: 'goingOnParentalLeave', label: 'Preparing for parental leave'},
    {value: 'onParentalLeave', label: 'On parental leave'},
    {value: 'underTermination', label: 'On termination stage'},
    {value: 'terminated', label: 'Ex-employee'},
];
