import React, { useState, useEffect } from 'react';
import './Links.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import CreateLink from './Modal/CreateLink';
import DeleteLink from './Modal/DeleteLink';
import EditLink from './Modal/EditLink';
import { COMPANIES, LINKS } from '../../../../utils/constants/api';
import { LinkFromApi } from '../../../../shared/Link/data/LinkFromApi';
import Button, {Size, Type} from '../../../Component/Button/Button';
import { CompanyFromApi } from '../../../../shared/Company/data/CompanyFromApi';
import { MultiSelect } from 'react-multi-select-component';
import { SelectValue } from '../../../../shared/Common/data/SelectValue';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {ROLE_ADMIN, ROLE_CONTENT_MANAGER} from "../../../../shared/Security/constants/AccessLevels";

type Links = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Links(props: Links) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createLinkModal, setCreateLinkModal] = useState<boolean>(false);
    const [editLinkModal, setEditLinkModal] = useState<boolean>(false);
    const [deleteLinkModal, setDeleteLinkModal] = useState<boolean>(false);

    const [links, setLinks] = useState<LinkFromApi[]>([]);
    const [linkToChange, setLinkToChange] = useState<LinkFromApi | null>(null);
    const [linkToDelete, deleteLink] = useState<LinkFromApi | null>(null);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);
    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectValue[]>([]);

    function filterLinksByCompany(links: LinkFromApi[], selectedCompanyOptions: SelectValue[]): LinkFromApi[] {
        const companyArr = getValuesFromSelectValues(selectedCompanyOptions);
        return links.filter(link => link.value.companies.some(company => companyArr.includes(company)));
    }

    function getValuesFromSelectValues(selectValues: SelectValue[]): string[] {
        return selectValues.map(selectValue => selectValue.value);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        getLinks();
    }, [selectedCompanyOptions]);

    function getLinks() {
        fetch(LINKS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((links: LinkFromApi[]) => setLinks(filterLinksByCompany(links, selectedCompanyOptions)));
    }

    function showLink({link}: { link: any }): void {
        fetch(LINKS + '/' + link.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: link.value.name,
                url: link.value.url,
                companies: link.value.companies,
                visible: !link.value.visible
            })
        })
            .then(response => {
                if (response.ok) {
                    getLinks();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    function editLinkModalVisibility(link: LinkFromApi): void {
        setLinkToChange(link);
        setEditLinkModal(true);
    }

    function confirmDeleteLinkModalVisibility(link: LinkFromApi): void {
        deleteLink(link)
        setDeleteLinkModal(true);
    }

    async function getCompanies() {
        const response = await fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });
        const companies = await response.json();
        setCompanies(companies);

        const companyOptions = companies.map((company: { id: string; }) => {
            let companyName = '';
            companies.map((companyValue: CompanyFromApi) => {
                if (companyValue.id === company.id) {
                    companyName = companyValue.name;
                }
            })
            return {
                label: companyName,
                value: company.id
            }
        });

        setCompanyOptions(companyOptions);
        setSelectedCompanyOptions(companyOptions);
    }

    // Access levels
    const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Links</h3>
                                </div>
                                <div className='sub-header-filter-block align-items-center d-flex'>
                                    <div>
                                        <p>Companies:</p>
                                    </div>
                                    <div className='filter'>
                                        <MultiSelect
                                            options={ companyOptions }
                                            hasSelectAll={ true }
                                            shouldToggleOnHover={ false }
                                            disableSearch={ true }
                                            value={ selectedCompanyOptions }
                                            disabled={ false }
                                            onChange={ setSelectedCompanyOptions }
                                            labelledBy={ 'All companies' }
                                            className={ 'multi-select-with-checkboxes'}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": "All companies",
                                                    "selectSomeItems": "Select companies",
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                {hasRole ?
                                    <div className='add-button-block'>
                                        <Button
                                            text="<i className='material-icons add-item'>add</i> Add link"
                                            size={ Size.Medium }
                                            type={ Type.Accent }
                                            onClick={ () => setCreateLinkModal(true) }
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div className='sub-content-main'>
                                <table className="links-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Link Name
                                            <a href='#'>
                                                <i className='material-icons'>arrow_downward</i>
                                            </a>
                                        </th>
                                        <th>
                                            Url
                                            <a href='#'>
                                                <i className='material-icons'>arrow_downward</i>
                                            </a>
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {links.map(link => {
                                        return (
                                            <tr key={ link.id }>
                                                <td title={ link.value.name }>{ link.value.name }</td>
                                                <td title={ link.value.url }>{ link.value.url }</td>
                                                <td>
                                                    {hasRole && (
                                                        <>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => editLinkModalVisibility(link)}
                                                            >
                                                                edit
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => confirmDeleteLinkModalVisibility(link)}
                                                            >
                                                                delete
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => showLink({link: link})}
                                                            >
                                                                {link.value.visible ? "visibility" : "visibility_off"}
                                                            </i>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateLink
                    companies={ companies }
                    opened={ createLinkModal }
                    onOk={ () => { getLinks(); setCreateLinkModal(false); } }
                    onClose={ () => setCreateLinkModal(false) }
                />
                {
                    linkToDelete ?
                        <DeleteLink
                            opened={ deleteLinkModal }
                            onOk={ () => { getLinks(); setDeleteLinkModal(false); deleteLink(null) } }
                            onClose={ () => { setDeleteLinkModal(false); deleteLink(null) } }
                            link={ linkToDelete }
                        /> : null
                }
                {
                    linkToChange
                        ?
                        <EditLink
                            companies={ companies }
                            opened={ editLinkModal }
                            onOk={ () => { getLinks(); setEditLinkModal(false); setLinkToChange(null) } }
                            onClose={ () => { setEditLinkModal(false); setLinkToChange(null) } }
                            link={ linkToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Links;