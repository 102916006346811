import Header from '../../Page/Layer/Header/Header';
import Navbar from '../../Page/Layer/Navbar/Navbar';
import SubMenu from '../Layer/SubMenu/SubMenu';
import React, { useState } from 'react';
import { AuthEmployee } from '../../../shared/Security/data/AuthEmployee';

type Admin = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Admin(props: Admin) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;