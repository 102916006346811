import React, { useEffect } from 'react';
import { EMPLOYEE_PROFILE } from "../../../../../../utils/constants/routes";

type KeyHandlersProps = {
    results: any[];
    highlightedIndexRef: React.MutableRefObject<number | null>;
    setHighlightedIndex: React.Dispatch<React.SetStateAction<number | null>>;
    navigateTo: (url: string) => void;
    handleEscape: () => void;
    isModalOpen: boolean;
    searchWrapperRef: React.RefObject<HTMLDivElement>;
};

export function useKeyHandlers({
                                   results,
                                   highlightedIndexRef,
                                   setHighlightedIndex,
                                   navigateTo,
                                   handleEscape,
                                   isModalOpen,
                                   searchWrapperRef,
                               }: KeyHandlersProps) {

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            switch (e.key) {
                case "ArrowDown":
                    if (results.length === 0) return;
                    e.preventDefault();
                    setHighlightedIndex((prev) => {
                        let nextIndex = (prev === null || prev === results.length - 1) ? 0 : prev + 1;
                        highlightedIndexRef.current = nextIndex;
                        return nextIndex;
                    });
                    break;
                case "ArrowUp":
                    if (results.length === 0) return;
                    e.preventDefault();
                    setHighlightedIndex((prev) => {
                        let nextIndex = (prev === null || prev === 0) ? results.length - 1 : prev - 1;
                        highlightedIndexRef.current = nextIndex;
                        return nextIndex;
                    });
                    break;
                case "Enter":
                    if (results.length !== 0 && highlightedIndexRef.current !== null) {
                        navigateTo(EMPLOYEE_PROFILE + `/${results[highlightedIndexRef.current].nickname}`);
                    }
                    break;
                case "Escape":
                    if (handleEscape) {
                        handleEscape();
                    }
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [results, highlightedIndexRef, navigateTo, setHighlightedIndex, handleEscape]);

    useEffect(() => {
        if (isModalOpen) {
            return;
        }

        function handleGlobalKeypress(e: KeyboardEvent) {
            if (document.activeElement &&
                (document.activeElement.tagName === 'INPUT' ||
                    document.activeElement.tagName === 'TEXTAREA')) {
                return;
            }
            searchWrapperRef.current?.querySelector('input')?.focus();
        }

        window.addEventListener('keypress', handleGlobalKeypress);

        return () => {
            window.removeEventListener('keypress', handleGlobalKeypress);
        };
    }, [isModalOpen, searchWrapperRef]);
}