import React, { useState, useEffect } from 'react';
import moment from "moment";
import {Popover} from "@headlessui/react";

import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import CreateGreeting from './Modal/CreateGreeting';
import EditGreeting from './Modal/EditGreeting';
import DeleteGreeting from "./Modal/DeleteGreeting";
import Button, {Size, Type} from '../../../Component/Button/Button';

import { MultiSelect } from 'react-multi-select-component';
import {getGMTOffsetByTimezoneName} from "../../../Common/Timezones/Services";

import { GreetingFromApi } from '../../../../shared/Greeting/data/GreetingFromApi';
import { LocationFromApi } from '../Locations/Locations';
import { SelectValue } from '../../../../shared/Common/data/SelectValue';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';

import {ROLE_ADMIN, ROLE_CONTENT_MANAGER} from "../../../../shared/Security/constants/AccessLevels";
import { GREETINGS, LOCATIONS } from '../../../../utils/constants/api';

import './Greetings.scss';


type Greetings = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Greetings(props: Greetings) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [locations, setLocations] = useState<LocationFromApi[]>([]);
    const [locationOptions, setLocationOptions] = useState<SelectValue[]>([]);

    const [createGreetingModal, setCreateGreetingModal] = useState<boolean>(false);
    const [editGreetingModal, setEditGreetingModal] = useState<boolean>(false);
    const [deleteGreetingModal, setDeleteGreetingModal] = useState<boolean>(false);
    const [greetings, setGreetings] = useState<GreetingFromApi[]>([]);
    const [greetingToChange, setGreetingToChange] = useState<GreetingFromApi | null>(null);
    const [greetingToDelete, deleteGreeting] = useState<GreetingFromApi | null>(null);
    const [selectedLocationOptions, setSelectedLocationOptions] = useState<SelectValue[]>([]);

    const [dailyChecked, setDailyChecked] = useState(true);
    const [specialChecked, setSpecialChecked] = useState(true);

    function handleCheckboxChange(event: { target: { id: string; checked: boolean | ((prevState: boolean) => boolean); }; }) {
        if (event.target.id === 'daily-checkbox') {
            setDailyChecked(event.target.checked);
        } else if (event.target.id === 'special-checkbox') {
            setSpecialChecked(event.target.checked);
        }
        getGreetings();
    }

    useEffect(() => {
        getLocations();
    }, []);

    useEffect(() => {
        getGreetings();
    }, [selectedLocationOptions, dailyChecked, specialChecked]);


    const [sortByName, setSortByName] = useState<string | null>(null);
    function handleSortClick() {
        if (sortByName === null || sortByName === 'desc') {
            setSortByName('asc');
            greetings.sort((a, b) => compareStrings(a, b));
        } else if (sortByName === 'asc') {
            setSortByName('desc');
            greetings.sort((a, b) => compareStrings(b, a));
        }
    }

    function filterGreetingsByLocation(greetings: GreetingFromApi[], selectedLocationOptions: SelectValue[]): GreetingFromApi[] {
        const locationArr = getValuesFromSelectValues(selectedLocationOptions);
        return greetings.filter(greeting => greeting.value.locations.some(location => locationArr.includes(location)));
    }

    function getValuesFromSelectValues(selectValues: SelectValue[]): string[] {
        return selectValues.map(selectValue => selectValue.value);
    }

    function compareStrings(a: GreetingFromApi, b: GreetingFromApi) {
        return a.value.text.localeCompare(b.value.text, undefined, { sensitivity: 'base' });
    }

    async function getLocations() {
        const response = await fetch(LOCATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });
        const locations = await response.json();
        setLocations(locations);

        const locationOptions = locations.map((location: { id: string; }) => {
            let locationName = '';
            locations.map((locationValue: LocationFromApi) => {
                if (locationValue.id === location.id) {
                    locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                }
            })
            return {
                label: locationName,
                value: location.id
            }
        });

        setLocationOptions(locationOptions);
        setSelectedLocationOptions(locationOptions);
    }

    function getGreetings() {
        fetch(GREETINGS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((greetings: GreetingFromApi[]) => setGreetings(filterGreetingsByLocation(greetings.filter(greeting =>
                (dailyChecked && !greeting.value.special) ||
                (specialChecked && greeting.value.special) ||
                (dailyChecked && specialChecked)
            ), selectedLocationOptions)));
    }

    function showGreeting({greeting}: { greeting: any }): void {
        fetch(GREETINGS + '/' + greeting.id, {
            method: 'PUT',
            body: JSON.stringify({
                text: greeting.value.text,
                locations: greeting.value.locations,
                special: greeting.value.special ? {
                    date: greeting.value.special.date,
                    repeatAnnually: greeting.value.special.repeatAnnually
                } : null,
                visible: !greeting.value.visible,
            })
        })
            .then(response => {
                if (response.ok) {
                    getGreetings();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    function editGreetingModalVisibility(greeting: GreetingFromApi): void {
        setGreetingToChange(greeting);
        setEditGreetingModal(true);
    }

    function confirmDeleteGreetingModalVisibility(greeting: GreetingFromApi): void {
        deleteGreeting(greeting)
        setDeleteGreetingModal(true);
    }

    // Access levels
    const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Greetings</h3>
                                </div>
                                <div className='sub-header-filter-block align-items-center d-flex'>
                                    <div>
                                        <p>Locations:</p>
                                    </div>
                                    <div className='filter'>
                                        <MultiSelect
                                            options={ locationOptions }
                                            hasSelectAll={ true }
                                            shouldToggleOnHover={ false }
                                            disableSearch={ true }
                                            value={ selectedLocationOptions }
                                            disabled={ false }
                                            onChange={ setSelectedLocationOptions }
                                            labelledBy={ 'All locations' }
                                            className={ 'multi-select-with-checkboxes'}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": "All locations",
                                                    "selectSomeItems": "Select locations",
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                { hasRole ?
                                    <div className='add-button-block'>
                                        <Button
                                            text="<i className='material-icons add-item'>add</i> Add greeting"
                                            size={ Size.Medium }
                                            type={ Type.Accent }
                                            onClick={ () => setCreateGreetingModal(true) }
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div className='sub-content-main'>
                                <table className="greetings-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Greeting
                                            <i onClick={handleSortClick} className={'material-icons'}>{sortByName === 'desc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                        </th>
                                        <th>
                                            <Popover className="relative">
                                                Type
                                                <Popover.Button id='popover-btn' className={'vert-menu-btn'}><i className='material-icons'>arrow_drop_down</i></Popover.Button>
                                                <Popover.Panel className="absolute z-10">
                                                    <label htmlFor="daily-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="daily-checkbox"
                                                            checked={dailyChecked}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        Daily
                                                    </label>
                                                    <label htmlFor="special-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id="special-checkbox"
                                                            checked={specialChecked}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        Special
                                                    </label>
                                                </Popover.Panel>
                                            </Popover>
                                        </th>
                                        <th>
                                            Date
                                            <i className='material-icons'>filter_list</i>
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {greetings.map(greeting => {
                                        return (
                                            <tr key={ greeting.id }>
                                                <td>{ greeting.value.text }</td>
                                                <td>{ greeting.value.special ? 'Special' : 'Daily' }</td>
                                                <td>{ greeting.value.special ? moment(greeting.value.special.date).format("DD.MM.YYYY") : '' }</td>
                                                <td>
                                                    {hasRole && (
                                                        <>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => editGreetingModalVisibility(greeting)}
                                                            >
                                                                edit
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => confirmDeleteGreetingModalVisibility(greeting)}
                                                            >
                                                                delete
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={() => showGreeting({greeting: greeting})}
                                                            >
                                                                {greeting.value.visible ? "visibility" : "visibility_off"}
                                                            </i>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateGreeting
                    locations={ locations }
                    opened={ createGreetingModal }
                    onOk={ () => { getGreetings(); setCreateGreetingModal(false); } }
                    onClose={ () => setCreateGreetingModal(false) }
                />
                {
                    greetingToDelete ?
                        <DeleteGreeting
                            opened={ deleteGreetingModal }
                            onOk={ () => { getGreetings(); setDeleteGreetingModal(false); deleteGreeting(null) } }
                            onClose={ () => { setDeleteGreetingModal(false); deleteGreeting(null) } }
                            greeting={ greetingToDelete }
                        /> : null
                }
                {
                    greetingToChange
                        ?
                        <EditGreeting
                            locations={ locations }
                            opened={ editGreetingModal }
                            onOk={ () => { getGreetings(); setEditGreetingModal(false); setGreetingToChange(null) } }
                            onClose={ () => { setEditGreetingModal(false); setGreetingToChange(null) } }
                            greeting={ greetingToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Greetings;