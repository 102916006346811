import React, {useEffect, useState} from 'react';
import moment from "moment";
import Select from "react-select";
import DatePicker from "../../../../../Component/Input/DatePicker/DatePicker";
import TextField, {Type} from "../../../../../Component/Input/TextField/TextField";
import RehireEmployee from "./Modal/RehireEmployee";
import './JobInfo.scss';

import {EmployeeFromApi} from "../../../../../../shared/Employee/data/EmployeeFromApi";
import {employeeStatusOptions} from "../../../../../../shared/Employee/Profile/JobInfo/data/constants";
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import {EmployeeStatusFromApi} from "../../../../../../shared/Employee/Profile/JobInfo/data/EmployeeStatusFromApi";
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";

import * as clipboard from "clipboard-polyfill";

import {
    GOING_ON_PARENTAL_LEAVE,
    ON_PARENTAL_LEAVE,
    ON_PROBATION_PERIOD,
    ON_STAFF,
    TERMINATED,
    UNDER_TERMINATION,
} from "../../../../../../utils/constants/employeeStatuses";
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../shared/Security/constants/AccessLevels";
import {EMPLOYEE} from "../../../../../../utils/constants/api";
import {dateFromStringWithDefaultTimezone} from "../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type EmployeeStatus = {
    id?: string,
    index: number,
    employee: EmployeeFromApi,
    status: EmployeeStatusFromApi,
    selectIsDisabled: boolean,
    previousStatus: EmployeeStatusFromApi | null,
    nextStatus: EmployeeStatusFromApi | null,
    createEmployeeStatus: (
        statusType: string,
        previousStatusType: string | null,
        dateStart: string | null,
        dateEnd: string | null,
        employeeLink: string,
        validate: (errors: ValidationError[]) => void,
        setCheckMarkIsVisible: (checkMarkIsVisible: boolean) => void,
        setSelectIsDisabled: (selectIsDisabled: boolean) => void,
        index: number
    ) => void,
    editEmployeeStatus: (
        statusId: string,
        statusType: string,
        previousStatusType: string | null,
        dateStart: string | null,
        dateEnd: string | null,
        employeeLink: string,
        validate: (errors: ValidationError[]) => void,
        setCheckMarkIsVisible: (checkMarkIsVisible: boolean) => void,
        index: number
    ) => void,
    updatePreviousStatusDateEnd: (
        index: number,
        dateEnd: string
    ) => void,
    updateNextStatusDateStart: (
        index: number,
        dateStart: string
    ) => void,
    authEmployee: AuthEmployee,
    setNewStatusVisible?: (newStatusVisible: boolean) => void,
    isSaved?: boolean,
    triggerParentUpdate: () => void;
};

function EmployeeStatus(props: EmployeeStatus) {
    const [employeeStatusOption, setEmployeeStatusOption] = useState<SelectValue>(employeeStatusOptions[0]);
    const [dateStart, setDateStart] = useState<Date | null>(
        props.status.employeeStatusValue.dateStart ?
            dateFromStringWithDefaultTimezone(props.status.employeeStatusValue.dateStart.substring(0, 10)) : null
    );
    const [dateEnd, setDateEnd] = useState<Date | null>(
        props.status.employeeStatusValue.dateEnd ?
            dateFromStringWithDefaultTimezone(props.status.employeeStatusValue.dateEnd.substring(0, 10)) : null
    );
    const [link, setLink] = useState<string>(props.status.employeeStatusValue.employeeLink || '');

    const [dateStartError, setDateStartError] = useState<string>('');
    const [dateEndError, setDateEndError] = useState<string>('');
    const [linkError, setLinkError] = useState<string>('');
    const [statusError, setStatusError] = useState<string>('');

    const [checkMarkIsVisible, setCheckMarkIsVisible] = useState<boolean>(false);
    const [dateEndIsDisabled, setDateEndIsDisabled] = useState<boolean>(false);
    const [selectIsDisabled, setSelectIsDisabled] = useState<boolean>(props.selectIsDisabled);
    const [showRehireButton, setShowRehireButton] = useState<boolean>(true);

    const [isRehireModalOpen, setIsRehireModalOpen] = useState(false);
    const [lastArchivedUnderTerminationDate, setLastArchivedUnderTerminationDate] = useState<Date | null>(null);

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const hasRoleAdmin = props.authEmployee.roles.includes(ROLE_ADMIN);

    const scrollToBottom = () => {
        setTimeout(() => {
            const selectElement = document.getElementById('employee-status-options');
            if (selectElement) {
                selectElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    };

    useEffect(() => {
        setEmployeeStatusOption(getEmployeeStatusOption());
    }, []);

    useEffect(() => {
        if (props.status.id === 'new' && employeeStatusOption.value === TERMINATED) {
            setDateStart(
                props.previousStatus?.employeeStatusValue.dateEnd ?
                addOneDay(new Date(props.previousStatus.employeeStatusValue.dateEnd.substring(0, 10))) : null
            );
        }
    }, [employeeStatusOption]);

    useEffect(() => {
        if (
            employeeStatusOption.value === ON_PROBATION_PERIOD ||
            employeeStatusOption.value === ON_STAFF ||
            employeeStatusOption.value === ON_PARENTAL_LEAVE
        ) {
            setDateEndIsDisabled(true);
        } else {
            setDateEndIsDisabled(false);
        }
    }, [employeeStatusOption]);

    useEffect(() => {
        if (props.status.id === 'new') {
            setCheckMarkIsVisible(true);
        }
    }, [props.status.id]);

    useEffect(() => {
        const handleClick = () => {
            if (dateEndError) {
                setDateEndError('');
            }
        }

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
    }, [dateEndError]);

    useEffect(() => {
        if (!props.previousStatus) {
            getLastArchivedUnderTerminationDate();
        }
    }, [props.previousStatus]);

    function getLastArchivedUnderTerminationDate() {
        fetch(EMPLOYEE + '/' + props.employee.id + '/employee-statuses/last-archived-under-termination', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    if (data !== null && data.employeeStatusValue.dateEnd) {
                        setLastArchivedUnderTerminationDate(new Date(data.employeeStatusValue.dateEnd));
                    } else {
                        setLastArchivedUnderTerminationDate(null);
                    }
                });
            }
        })
    }

    function addOneDay(date: Date) {
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }

    function getEmployeeStatusOption(): SelectValue {
        return employeeStatusOptions.find(
            (option: SelectValue) => option.value === props.status.employeeStatusValue.statusType
        ) || employeeStatusOptions[0];
    }

    function copyToClipboard(text: string) {
        clipboard.writeText(text).then(
            () => {
                console.log('Text copied to clipboard');
            },
            (err) => {
                console.error('Failed to copy text: ', err);
            }
        );
    }

    function frontValidateHasErrors(): boolean {
        let hasErrors = false;
        if (dateStart === null) {
            setDateStartError('Start Date is required');
            hasErrors = true;
        }
        if (dateEnd === null && employeeStatusOption.value === GOING_ON_PARENTAL_LEAVE) {
            setDateEndError('End Date is required in this Status');
            hasErrors = true;
        }
        if (dateEnd === null && employeeStatusOption.value === UNDER_TERMINATION) {
            setDateEndError('End Date is required in this Status');
            hasErrors = true;
        }
        if (props.index === 0 && employeeStatusOption.value !== ON_STAFF && employeeStatusOption.value !== ON_PROBATION_PERIOD) {
            setStatusError('First status must be either On Probation Period or On Staff');
            hasErrors = true;
        }
        if (props.previousStatus?.employeeStatusValue.statusType === UNDER_TERMINATION && employeeStatusOption.value !== TERMINATED) {
            setStatusError('After Under termination status only Terminated status is allowed');
            hasErrors = true;
        }
        return hasErrors;
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'dateStart') {
                setDateStartError(error.message);
            } else if (error.property === 'dateEnd') {
                setDateEndError(error.message);
            } else if (error.property === 'employeeLink') {
                setLinkError(error.message)
            } else if (error.property === 'statusType') {
                setStatusError(error.message);
            }
        });
    }

    function calculateMinStartDate(): Date | null {
        let minDate = null;

        if (props.index === 0 && lastArchivedUnderTerminationDate) {
            let lastArchiveDate = addOneDay(lastArchivedUnderTerminationDate);
            minDate = minDate && minDate > lastArchiveDate ? minDate : lastArchiveDate;
        }

        if (props.index !== 0 && props.previousStatus) {
            let prevStatus = addOneDay(new Date(props.previousStatus.employeeStatusValue.dateStart.substring(0, 10)));
            minDate = minDate && minDate > prevStatus ? minDate : prevStatus;
        }

        if (props.employee.lastSalaryDate && !hasRoleAdmin) {
            let lastSalaryDate = addOneDay(new Date(props.employee.lastSalaryDate.substring(0, 10)));
            minDate = minDate && minDate > lastSalaryDate ? minDate : lastSalaryDate;
        }

        return minDate;
    }

    function calculateMinEndDate(): Date {
        let minDate = new Date(props.employee.appearanceDate.substring(0, 10));

        if (props.index === 0 && lastArchivedUnderTerminationDate) {
            minDate = minDate > lastArchivedUnderTerminationDate ? minDate : lastArchivedUnderTerminationDate;
        }

        if (props.index !== 0 && props.previousStatus && props.previousStatus.employeeStatusValue.dateEnd) {
            let prevStatus =  addOneDay(new Date(props.previousStatus.employeeStatusValue.dateEnd.substring(0, 10)));
            minDate = minDate > prevStatus ? minDate : prevStatus;
        }

        if (props.employee.lastSalaryDate && !hasRoleAdmin) {
            let lastSalaryDate = new Date(props.employee.lastSalaryDate.substring(0, 10));
            minDate = minDate > lastSalaryDate ? minDate : lastSalaryDate;
        }
        return minDate;
    }

    function calculateMaxEndDate(): Date | null {
        if (props.nextStatus && props.nextStatus.employeeStatusValue.previousStatusType === 'underTermination') {
            return null;
        }
        if (props.nextStatus && props.nextStatus.employeeStatusValue.dateStart) {
            let date = new Date(props.nextStatus.employeeStatusValue.dateStart.substring(0, 10));
            date.setDate(date.getDate() - 1);
            if (props.employee.lastSalaryDate && !hasRoleAdmin) {
                let lastSalaryDate = new Date(props.employee.lastSalaryDate.substring(0, 10));
                date = lastSalaryDate > date ? lastSalaryDate : date;
            }
            return date;
        } else {
            return null;
        }
    }

    function clearAllErrors(): void {
        setLinkError('');
        setDateStartError('');
        setDateEndError('');
        setStatusError('');
    }

    function clearDates(): void {
        setDateStart(null);
        setDateEnd(null);
        setDateStartError('');
        setDateEndError('');
    }

    function handleEmployeeStatusChange (newValue: SelectValue): void  {
        if(props.previousStatus?.employeeStatusValue.statusType === ON_PROBATION_PERIOD && newValue.value === GOING_ON_PARENTAL_LEAVE) {
            setStatusError('Invalid status transition. Try to select other one.');
            return;
        }

        if (!props.isSaved && newValue.value === TERMINATED) {
            setDateEnd(null);
        }

        setEmployeeStatusOption(newValue);
        setStatusError('');
        clearAllErrors();
        setCheckMarkIsVisible(true);
    }

    function handleDateStartChange (newDate: Date | null): void  {
        setDateStart(newDate);
        setDateStartError('');
        setCheckMarkIsVisible(true);

        if (props.previousStatus && newDate) {
            const prevDateEnd = new Date(newDate.getTime());
            prevDateEnd.setDate(newDate.getDate() - 1);
            props.previousStatus.employeeStatusValue.dateEnd = moment(prevDateEnd).format('YYYY-MM-DD');

            props.updatePreviousStatusDateEnd(props.index, moment(prevDateEnd).format('YYYY-MM-DD'));
        }
    }

    function handleDateEndChange (newDate: Date | null): void  {
        setDateEnd(newDate);
        setDateEndError('');
        setCheckMarkIsVisible(true);

        if (props.nextStatus && newDate) {
            const nextDateStart = new Date(newDate.getTime());
            nextDateStart.setDate(newDate.getDate() + 1);
            props.nextStatus.employeeStatusValue.dateStart = moment(nextDateStart).format('YYYY-MM-DD');

            props.updateNextStatusDateStart(props.index, moment(nextDateStart).format('YYYY-MM-DD'));
        }
    }

    function handleLinkChange (newValue: string): void  {
        setLink(newValue);
        setLinkError('');
        setCheckMarkIsVisible(true);
    }

    function handleCheckMarkClick (): void  {
        if (!frontValidateHasErrors()) {
            setCheckMarkIsVisible(false);
            if (props.status.id === 'new') {
                props.createEmployeeStatus(
                    employeeStatusOption.value,
                    props.previousStatus?.employeeStatusValue.statusType || null,
                    dateStart ? moment(dateStart).format('YYYY-MM-DD') : null,
                    dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : null,
                    link,
                    validate,
                    setCheckMarkIsVisible,
                    setSelectIsDisabled,
                    props.index
                );
                if (employeeStatusOption.value === UNDER_TERMINATION) {
                    props.createEmployeeStatus(
                        TERMINATED,
                        UNDER_TERMINATION,
                        dateStart ? moment(dateEnd).add(1, 'days').format('YYYY-MM-DD') : null,
                        dateEnd ? moment('2100-12-12').format('YYYY-MM-DD') : null,
                        '',
                        validate,
                        setCheckMarkIsVisible,
                        setSelectIsDisabled,
                        props.index + 1
                    );
                }
            } else {
                props.editEmployeeStatus(
                    props.status.id,
                    employeeStatusOption.value,
                    props.previousStatus?.employeeStatusValue.statusType || null,
                    dateStart ? moment(dateStart).format('YYYY-MM-DD') : null,
                    dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : null,
                    link,
                    validate,
                    setCheckMarkIsVisible,
                    props.index
                );
            }
            if (props.previousStatus) {
                props.editEmployeeStatus(
                    props.previousStatus.id,
                    props.previousStatus.employeeStatusValue.statusType,
                    props.previousStatus.employeeStatusValue.previousStatusType,
                    moment(props.previousStatus.employeeStatusValue.dateStart).format('YYYY-MM-DD'),
                    moment(props.previousStatus.employeeStatusValue.dateEnd).format('YYYY-MM-DD'),
                    props.previousStatus.employeeStatusValue.employeeLink,
                    validate,
                    setCheckMarkIsVisible,
                    props.index - 1
                );
            }
            if (props.nextStatus) {
                props.editEmployeeStatus(
                    props.nextStatus.id,
                    props.nextStatus.employeeStatusValue.statusType,
                    props.nextStatus.employeeStatusValue.previousStatusType,
                    moment(props.nextStatus.employeeStatusValue.dateStart).format('YYYY-MM-DD'),
                    props.nextStatus.employeeStatusValue.dateEnd ?
                        moment(props.nextStatus.employeeStatusValue.dateEnd).format('YYYY-MM-DD') : null,
                    props.nextStatus.employeeStatusValue.employeeLink,
                    validate,
                    setCheckMarkIsVisible,
                    props.index + 1
                );
            }
        }
    }

    function handleRehireClick () {
        setIsRehireModalOpen(true);
        setShowRehireButton(false);
    }

    function handleDateStartClick () {
        if (employeeStatusOption.value === '') {
            setDateStartError('Please select Status');
            setDateEndError('');
            setLinkError('');
        }
    }

    function handleDateEndClickOnDisabled() {
        if (!dateStart && (employeeStatusOption.value === GOING_ON_PARENTAL_LEAVE || employeeStatusOption.value === UNDER_TERMINATION)) {
            setDateEndError('Please enter Start Date first');
        }
    }

    function handleDateEndClick () {
        if (employeeStatusOption.value === '') {
            setDateEndError('Please select Status');
            setDateStartError('');
            setLinkError('');
        } else if (dateEndIsDisabled && !dateEnd && employeeStatusOption.value !== '') {
            setDateEndError('Please add next Status');
        }
    }

    function handleLinkClick () {
        if (employeeStatusOption.value === '') {
            setLinkError('Please select Status');
            setDateStartError('');
            setDateEndError('');
        }
    }

    return (
        <div
            className={'profile-field-group'}
            id={props.id ? props.id : ''}
        >
            <div className={'profile-field'}>
                <div className={'profile-field-title'}>
                    <p>Employee Status</p>
                </div>
                <div className={`profile-field-value`}>
                    <Select
                        isDisabled={selectIsDisabled}
                        placeholder={''}
                        isMulti={false}
                        id={'employee-status-options'}
                        className={`profile-select ${selectIsDisabled ? 'default-pointer' : ''}`}
                        value={employeeStatusOption}
                        options={employeeStatusOptions}
                        isClearable={false}
                        onChange={(newValue: any) => {
                            handleEmployeeStatusChange(newValue)
                            clearDates()
                        }}
                        isSearchable={false}
                        onMenuOpen={scrollToBottom}
                    />
                    {statusError && (
                        <div className='status-error'>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                            <span>{statusError}</span>
                        </div>
                    )}
                </div>
            </div>

            <div className={'profile-field'}>
                <div className={'profile-field-title'}>
                    <p>Dates</p>
                </div>
                <div className={'profile-field-value'}>
                    <div className={'hire-date'}>
                        <i className="material-symbols-outlined">date_range</i>
                        <DatePicker
                            label={''}
                            className={`${selectIsDisabled && !hasRole ? 'default-pointer ' : 'cursor-pointer'} ${props.employee.id === props.authEmployee.id && selectIsDisabled && !hasRole ? 'disabled-pointer' : ''}`}
                            id={'employment-status-first'}
                            required={false}
                            horizontal={false}
                            date={dateStart}
                            error={dateStartError}
                            onChange={newDate => {
                                handleDateStartChange(newDate)
                            }}
                            onClick={handleDateStartClick}
                            minDate={calculateMinStartDate()}
                            maxDate={dateEnd ? dateEnd : null}
                            disabled={ (employeeStatusOption.value === TERMINATED || (props.employee.lastSalaryDate && dateStart && new Date(props.employee.lastSalaryDate) > dateStart && !hasRoleAdmin) || !hasRole || employeeStatusOption.value === '') || props.employee.isDeleted }
                        />
                    </div>

                    { (employeeStatusOption.value !== TERMINATED && !props.employee.isDeleted) && (
                        <>
                            <div className={'hire-date'}>
                                {(!dateStart && (employeeStatusOption.value === GOING_ON_PARENTAL_LEAVE || employeeStatusOption.value === UNDER_TERMINATION)) && (
                                    <div className={'disabled-block'} onClick={handleDateEndClickOnDisabled}></div>
                                )}
                                <i className="material-symbols-outlined">date_range</i>
                                <DatePicker
                                    label={''}
                                    className={`${(selectIsDisabled 
                                    && (employeeStatusOption.value !== GOING_ON_PARENTAL_LEAVE && employeeStatusOption.value !== UNDER_TERMINATION))
                                    || (!dateStart && (employeeStatusOption.value === GOING_ON_PARENTAL_LEAVE || employeeStatusOption.value === UNDER_TERMINATION))? 'default-pointer ' : 'cursor-pointer'} ${props.employee.id === props.authEmployee.id && selectIsDisabled && !hasRole ? 'disabled-pointer' : ''}`}
                                    id={'employment-status-second'}
                                    required={false}
                                    horizontal={false}
                                    date={dateEnd}
                                    error={dateEndError}
                                    onChange={newDate => {
                                        handleDateEndChange(newDate)
                                    }}
                                    onClick={handleDateEndClick}
                                    maxDate={calculateMaxEndDate()}
                                    minDate={calculateMinEndDate()}
                                    disabled={ (dateEndIsDisabled || !hasRole || employeeStatusOption.value === '' || (!dateStart && (employeeStatusOption.value === GOING_ON_PARENTAL_LEAVE || employeeStatusOption.value === UNDER_TERMINATION))) }
                                />
                            </div>
                            {/*hided for a while, check 143974*/}
                            {false && hasRole && (
                                <>
                                    <div className={'user-link'}>
                                        <TextField
                                            label=''
                                            className={`${selectIsDisabled ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && selectIsDisabled ? 'disabled-pointer' : ''}`}
                                            placeholder=""
                                            required={false}
                                            value={link}
                                            type={Type.Text}
                                            horizontal={false}
                                            error={linkError}
                                            onChange={newValue => {
                                                handleLinkChange(newValue);
                                            }}
                                            onClick={handleLinkClick}
                                            disabled={ (employeeStatusOption.value === '') }
                                            maxLength={500}
                                        />
                                    </div>
                                    <i
                                        title={"Copy"}
                                        className="material-icons content-copy"
                                        onClick={() => copyToClipboard(link)}
                                    >
                                        content_copy
                                    </i>
                                </>
                            )}
                        </>
                    )}
                    { employeeStatusOption.value === TERMINATED
                        && showRehireButton
                        && hasRole && !props.employee.isDeleted && (
                        <>
                            <button
                                className={'rehire-button'}
                                onClick={() => handleRehireClick()}
                            >
                                Rehire
                            </button>
                        </>
                    )}
                    {checkMarkIsVisible && employeeStatusOption.value !== '' && (
                        <>
                            <i
                                className={'material-icons check-element'}
                                onClick={() => handleCheckMarkClick()}
                            >
                                check
                            </i>
                        </>
                    )}
                </div>

            </div>
            <RehireEmployee
                opened={isRehireModalOpen}
                onOk={() => {
                    setIsRehireModalOpen(false);
                    props.triggerParentUpdate();
                }}
                onClose={() => {
                    setIsRehireModalOpen(false);
                    setShowRehireButton(true);
                }}
                employee={props.employee}
            />

        </div>
    );
}

export default EmployeeStatus;