import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import EditAccruedDays from "./Modal/EditAccruedDays";
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import General from './General/General';
import EmergencyContact from "./EmergencyInfo/EmergencyContact";
import JobInfo from "./JobInfo/JobInfo";
import ProfilePhoto from "./MainProfileInfo/ProfilePhoto";
import ProfileContact from "./MainProfileInfo/ProfileContact";
import ProfileHierarchyChain from "./MainProfileInfo/ProfileHierarchyChain";
import ConfirmModal from "../../../Layer/Modal/ConfirmModal";
import Head from "../../../../Component/Head/Head";

import {getPreviousSalaryPeriodDates} from "../../../../../utils/helpers/EmployeeProfile/getPreviousSalaryPeriodDates";

import {
    ACCRUED_DAYS_BALANCES,
    CALENDAR,
    EMPLOYEE,
    EMPLOYEES,
    JOB_TITLES,
    STATUSES
} from "../../../../../utils/constants/api";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../shared/Security/constants/AccessLevels";

import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';
import { EmployeeFromApi } from "../../../../../shared/Employee/data/EmployeeFromApi";
import { CurrentJobInfoFromApi } from "../../../../../shared/Employee/Profile/JobInfo/data/CurrentJobInfoFromApi";
import { JobTitleFromApi } from "../../../../../shared/JobTitle/data/JobTitleFromApi";

import './Profile.scss';
import Footer from "../../../Layer/Footer/Footer";
import Breadcrumbs from "../../../../Component/Breadcrumbs/Breadcrumbs";
import {NOT_FOUND} from "../../../../../utils/constants/routes";
import {NOT_FOUND_ERROR} from "../../../../../utils/constants/errors";
import CreateRequest from "../../../Calendar/Requests/Modal/CreateRequest";
import {
    TimeOffAccruedDaysForProfileFromApi
} from "../../../../../shared/Employee/Profile/JobInfo/data/TimeOffAccruedDaysForProfileFromApi";
import {TimeOffTypeIconsCollection} from "../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import Button, {Size, Type} from "../../../../Component/Button/Button";
import {Tooltip as ReactTooltip} from "react-tooltip";

type Profile = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Profile(props: Profile) {
    const profileTopBlock = document.querySelector('.profile-top-block') as HTMLElement | null;
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);
    const navigate = useNavigate();
    const { nickname } = useParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const [employee, setEmployee] = useState<EmployeeFromApi | null>(null);
    const today: Date = new Date();
    const [currentJobInfo, setCurrentJobInfo] = useState<CurrentJobInfoFromApi | null>(null);
    const [jobTitle, setJobTitle] = useState<JobTitleFromApi | null>(null);
    const [hireDate, setHireDate] = useState<Date>();
    const [years, setYears] = useState<number>(0);
    const [months, setMonths] = useState<number>(0);
    const [days, setDays] = useState<number>(0);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [createRequestModal, setCreateRequestModal] = useState<boolean>(false);
    const [editAccruedDaysModal, setEditAccruedDaysModal] = useState<boolean>(false);
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const [accruedDays, setAccruedDays] = useState<TimeOffAccruedDaysForProfileFromApi[]>([]);
    const [currentEmployeeIsActive, setCurrentEmployeeIsActive] = useState<boolean>(false);
    const [accruedDay, setAccruedDay] = useState<TimeOffAccruedDaysForProfileFromApi | null>(null);

    const [isMyProfile, setIsMyProfile] = useState<boolean | null>(null);
    const [isProfileOfMyHierarchyChain, setIsProfileOfMyHierarchyChain] = useState<boolean>(false);
    const link = props.authEmployee.email.includes('inweb.ua')
        ? process.env.REACT_APP_INWEB_BASE_URL
        : process.env.REACT_APP_PUP_BASE_URL;

    const [showRequestSalaryButton, setShowRequestSalaryButton] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [baseLink, setBaseLink] = useState<string>("");
    let resizeObserver = new ResizeObserver(handleResize);

    useEffect(() => {
        if (props.authEmployee.nickname === nickname) {
            setIsMyProfile(true);
            setIsProfileOfMyHierarchyChain(false);
        } else {
            setIsMyProfile(false);
        }
    }, [props.authEmployee, employee, nickname]);

    window.addEventListener('resize', stickyBlock);
    window.addEventListener('orientationchange', stickyBlock);
    function handleResize(entries:any) {
        entries.forEach((entry:any) => {
            stickyBlock();
        });
    }

    if (profileTopBlock) {
        resizeObserver.observe(profileTopBlock);
    }

    useEffect(() => {
        getHierarchyChains();
        getCurrentJobInfo();
        getProfileHierarchyChain();
        getProfileContact();
        getAccruedDays();
        getCurrentEmployeeStatus();
        getProfilePhoto();
        getContentComponent();
        stickyBlock();
    }, [employee]);

    function stickyBlock() {
        if (profileTopBlock) {
            let screenHeight = window.innerHeight;
            let profileTopBlockHeight = profileTopBlock.offsetHeight + 100;

            if (screenHeight < profileTopBlockHeight) {
                profileTopBlock.classList.add('relative-block');
            } else {
                profileTopBlock.classList.remove('relative-block');
            }
        }
    }

    useEffect(() => {
        setActiveIndex(0)
        getEmployee();
    }, [nickname]);

    useEffect (() => {
        if (dataUpdated) {
            getEmployee();
            getAccruedDays();
            getCurrentEmployeeStatus();
            getHeader();
            setDataUpdated(false);
        }
    }, [dataUpdated]);

    useEffect (() => {
        getAccruedDays();
        getCurrentEmployeeStatus();
    }, [isProfileOfMyHierarchyChain]);

    useEffect(() => { getJobTitle() }, [currentJobInfo]);

    useEffect(() => {
        const dates = currentJobInfo?.currentJobInfoValue?.dates;
        const hireDateString= dates?.hireDate ? dates.hireDate.substring(0, 10) : null;
        if (hireDateString) {
            setHireDate(new Date(hireDateString));
        }
    }, [currentJobInfo]);

    useEffect(() => {
        if (hireDate && employee) {
            let endDate = employee.leavingDate ? new Date(employee.leavingDate.substring(0, 10)) : today;
            if (endDate > today) {
                endDate = today;
            }
            getDifferenceInYearsMonthsDays(hireDate, endDate);
        }
    }, [hireDate]);

    useEffect(() => {
        if (employee) {
            requestSalaryButtonData(props.authEmployee.nickname, employee.nickname, employee.email)
                .then(status => {
                    setShowRequestSalaryButton(status || false);
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
    }, [employee]);

    async function requestSalaryButtonData(authNickname: string, profileNickname: string, profileEmail: string): Promise<boolean | undefined> {
        try {
            const queryParams = new URLSearchParams({
                auth_user_nickname: authNickname,
                profile_nickname: profileNickname,
                profile_email: profileEmail,
            });

            const response = await fetch(STATUSES + '/request-salary-button-relevant-data?' + queryParams, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            });

            if (!response.ok) {
                console.error('Network response was not ok');
                return;
            }

            const data = await response.json();

            if (data.error || typeof data.can_salary_request_btn !== 'boolean') {
                console.error('Invalid data format or failed request');
                return;
            }

            if (data.base_url) {
                setBaseLink(data.base_url);
            }

            return data.can_salary_request_btn;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function getHierarchyChains() {
        if(employee) {
            let hierarchyChainIds: string[] = await getHierarchyChainIds(props.authEmployee.id);
            if (hierarchyChainIds.includes(employee.id)) {
                setIsProfileOfMyHierarchyChain(true);
            } else {
                setIsProfileOfMyHierarchyChain(false);
            }
        }
    }

    function getHierarchyChainIds(employeeId: string): Promise<string[]> {
        return fetch(EMPLOYEE + '/' + employeeId + '/all-hierarchy-chains', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json());
    }

    function getDifferenceInYearsMonthsDays(startDate: Date, endDate: Date): void {
        const start = moment(startDate);
        const end = moment(endDate);

        const years = end.diff(start, 'years');
        start.add(years, 'years');
        setYears(years);

        const months = end.diff(start, 'months');
        start.add(months, 'months');
        setMonths(months);

        const days = end.diff(start, 'days');
        setDays(days);
    }

    function hasNewRequestsForEmployee(employeeId: string, startDate: string, endDate: string): Promise<boolean> {

        const employeesParam = encodeURIComponent(JSON.stringify([employeeId]));
        const statusesParam = ['New'].join(',');

        return fetch(CALENDAR + '/requests-included?employees=' + employeesParam + '&start-date=' + startDate + '&end-date=' + endDate + '&statuses=' + statusesParam + '&limit=1', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                return data && data.length > 0;
            });
    }

        const handleLiClick = (index: React.SetStateAction<number>) => {
        setActiveIndex(index);
        getContentComponent();
    };

    const getActiveClass = (index: number) => {
        return index === activeIndex ? 'active' : '';
    };

    async function getEmployee() {
        setLoadingBarProgress(loadingBarProgress + 30);

        await fetch(EMPLOYEES + '/' + nickname, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((employee: EmployeeFromApi) => setEmployee(employee));
                } else if (response.status === NOT_FOUND_ERROR) {
                    navigate(NOT_FOUND);
                }
            });
    }

    function getCurrentJobInfo() {
        if (!employee) return;

        setLoadingBarProgress(loadingBarProgress + 30);
        fetch(EMPLOYEE + '/' + employee.id + '/current-job-info', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then((jobInfo: CurrentJobInfoFromApi | null) => {
                setCurrentJobInfo(jobInfo);
            })
            .finally(() => setLoadingBarProgress(100));
    }

    function getJobTitle() {
        if(currentJobInfo && currentJobInfo.currentJobInfoValue.jobTitleId) {
            fetch(JOB_TITLES + '/' + currentJobInfo.currentJobInfoValue.jobTitleId, {
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
            })
                .then(response => response.json())
                .then((value: JobTitleFromApi | null) => setJobTitle(value));
        } else {
            setJobTitle(null);
        }
    }

    function handleButtonClick() {
        if (employee) {
            const { startDate, endDate } = getPreviousSalaryPeriodDates();
            hasNewRequestsForEmployee(employee.id, startDate, endDate)
                .then(status => {
                    if (status) {
                        setShowConfirmModal(true);
                    } else {
                        window.location.href = baseLink;
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        } else {
            window.location.href = baseLink;
        }
    }

    useEffect(() => {
        if (activeIndex == 1) {
            getCurrentJobInfo();
        }
    }, [activeIndex]);

    function handleDataUpdated(): void {
        setDataUpdated(true);
    }

    function handleModal(): void {
        setCreateRequestModal(true);
    }

    function handleModalAccrue(): void {
        setEditAccruedDaysModal(true);
    }

    const handleBeforeUnload = () => {
        localStorage.setItem('activeIndex', activeIndex.toString());
    };

    const getProfilePhoto = () => {
        if (employee && isMyProfile !== null) {
            return <ProfilePhoto employee={employee} authEmployee={props.authEmployee} isMyProfile={isMyProfile} onDataUpdate={handleDataUpdated}/>
        }
    }

    const getProfileContact = () => {
        if (employee) {
            return <ProfileContact employee={employee}/>
        }
    }

    const getProfileHierarchyChain = () => {
        if (employee) {
            return <ProfileHierarchyChain employee={employee}/>
        }
    }

    const getHeader = () => {
        if (props.authEmployee) {
            return <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
                origin={'profile'}
            />;
        }
        return null;
    };

    const getContentComponent = () => {
        if (employee) {
            switch (activeIndex) {
                case 0:
                    return <General
                        employee={employee}
                        onDataUpdate={handleDataUpdated}
                        authEmployee={props.authEmployee}
                        isMyProfile={isMyProfile}
                    />;
                case 1:
                    return <JobInfo
                        currentJobInfo={currentJobInfo}
                        getCurrentJobInfo={getCurrentJobInfo}
                        employee={employee}
                        onDataUpdate={handleDataUpdated}
                        authEmployee={props.authEmployee}
                    />;
                case 2:
                    return <EmergencyContact
                        employee={employee}
                        authEmployee={props.authEmployee}
                        isMyProfile={isMyProfile}
                        isProfileOfMyHierarchyChain={isProfileOfMyHierarchyChain}
                        setLoadingBarProgress={(progress: number) => setLoadingBarProgress(progress)}
                    />;
                default:
                    return null;
            }
        }
    };

    function getAccruedDays() {
        if (employee) {
            return fetch(ACCRUED_DAYS_BALANCES + '/' + employee.id, {
                method: 'GET',
                headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
            })
                .then(response => response.json())
                .then((accruedDaysData: TimeOffAccruedDaysForProfileFromApi[]) => {
                    setAccruedDays(accruedDaysData);
                });
        }
    }

    function getCurrentEmployeeStatus() {
        return fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employees: EmployeeFromApi[]) => {
                const currentEmployeeIsActive = employees.some(employee => capitalizeFirstLetter(employee.nickname) === capitalizeFirstLetter(nickname));
                setCurrentEmployeeIsActive(currentEmployeeIsActive);
            });
    }

    function capitalizeFirstLetter(str: any) {
        const [firstLetter, ...rest] = str;
        return firstLetter.toUpperCase() + rest.join('');
    }

    return (
        <>
        <div className={'profile-wrapper'}>
            <Head
                title={`${nickname}'s Profile — Netpeak One`}
                metaNameTitle={`${nickname}'s Profile — Netpeak One`}
                description={`${nickname} — ${jobTitle?.name}, ${employee?.companyName}`}
                url={`/employee/${nickname}`}
            />
            {getHeader()}
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content block-mid-max-size'>
                    <div className='profile-container'>
                        <Breadcrumbs
                            home={'Home'}
                            firstElement={nickname ? nickname : ''}
                        />
                        <div className='d-flex flex-block-main'>
                            <div>
                                <div className={'profile-top-block'}>
                                    { getProfilePhoto()}
                                    <div className={'profile-info'}>
                                        <h2>{nickname}</h2>
                                        <p className={'position'}>
                                            {jobTitle?.name} {jobTitle ? 'at' : ''} {employee?.companyName}
                                        </p>
                                        {(years || months || days) !== 0 &&
                                            <p className={'employee-start'}>
                                                Employed for
                                                {years > 0 ? ` ${years} Y` : ''}
                                                {months > 0 ? ` ${months} M` : ''}
                                                {days > 0 ? ` ${days} D` : ''}
                                            </p>
                                        }
                                        <div className={'profile-btns'}>
                                            {(showRequestSalaryButton) && (
                                                <button
                                                    className={'request-salary'}
                                                    onClick={handleButtonClick}
                                                >
                                            <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M11.0242 21V18.85C10.1409 18.65 9.37822 18.2667 8.73622 17.7C8.09422 17.1333 7.62355 16.3333 7.32422 15.3L9.17422 14.55C9.42422 15.35 9.79522 15.9583 10.2872 16.375C10.7792 16.7917 11.4249 17 12.2242 17C12.9076 17 13.4869 16.8457 13.9622 16.537C14.4376 16.2283 14.6749 15.7493 14.6742 15.1C14.6742 14.5167 14.4909 14.0543 14.1242 13.713C13.7576 13.3717 12.9076 12.984 11.5742 12.55C10.1409 12.1 9.15755 11.5623 8.62422 10.937C8.09089 10.3117 7.82422 9.54933 7.82422 8.65C7.82422 7.56667 8.17422 6.725 8.87422 6.125C9.57422 5.525 10.2909 5.18333 11.0242 5.1V3H13.0242V5.1C13.8576 5.23333 14.5452 5.53767 15.0872 6.013C15.6292 6.48833 16.0249 7.06733 16.2742 7.75L14.4242 8.55C14.2242 8.01667 13.9409 7.61667 13.5742 7.35C13.2076 7.08333 12.7076 6.95 12.0742 6.95C11.3409 6.95 10.7826 7.11267 10.3992 7.438C10.0159 7.76333 9.82422 8.16733 9.82422 8.65C9.82422 9.2 10.0742 9.63333 10.5742 9.95C11.0742 10.2667 11.9409 10.6 13.1742 10.95C14.3242 11.2833 15.1952 11.8127 15.7872 12.538C16.3792 13.2633 16.6749 14.1007 16.6742 15.05C16.6742 16.2333 16.3242 17.1333 15.6242 17.75C14.9242 18.3667 14.0576 18.75 13.0242 18.9V21H11.0242Z" fill="#049ED4"/>
                                                    </svg>
                                                </span>
                                                    Request Salary
                                                </button>
                                            )}
                                            <button
                                                className={'additional-pays'}
                                                onClick={() => window.open(link + '/bonus', '_blank')}
                                            >
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M14 13C13.1667 13 12.4583 12.7083 11.875 12.125C11.2917 11.5417 11 10.8333 11 10C11 9.16667 11.2917 8.45833 11.875 7.875C12.4583 7.29167 13.1667 7 14 7C14.8333 7 15.5417 7.29167 16.125 7.875C16.7083 8.45833 17 9.16667 17 10C17 10.8333 16.7083 11.5417 16.125 12.125C15.5417 12.7083 14.8333 13 14 13ZM7 16C6.45 16 5.979 15.804 5.587 15.412C5.195 15.02 4.99934 14.5493 5 14V6C5 5.45 5.196 4.979 5.588 4.587C5.98 4.195 6.45067 3.99934 7 4H21C21.55 4 22.021 4.196 22.413 4.588C22.805 4.98 23.0007 5.45067 23 6V14C23 14.55 22.804 15.021 22.412 15.413C22.02 15.805 21.5493 16.0007 21 16H7ZM9 14H19C19 13.45 19.196 12.979 19.588 12.587C19.98 12.195 20.4507 11.9993 21 12V8C20.45 8 19.979 7.804 19.587 7.412C19.195 7.02 18.9993 6.54934 19 6H9C9 6.55 8.804 7.021 8.412 7.413C8.02 7.805 7.54934 8.00067 7 8V12C7.55 12 8.021 12.196 8.413 12.588C8.805 12.98 9.00067 13.4507 9 14ZM3 20C2.45 20 1.979 19.804 1.587 19.412C1.195 19.02 0.999335 18.5493 1 18V8C1 7.71667 1.096 7.479 1.288 7.287C1.48 7.095 1.71733 6.99934 2 7C2.28333 7 2.521 7.096 2.713 7.288C2.905 7.48 3.00067 7.71734 3 8V18H19C19.2833 18 19.521 18.096 19.713 18.288C19.905 18.48 20.0007 18.7173 20 19C20 19.2833 19.904 19.521 19.712 19.713C19.52 19.905 19.2827 20.0007 19 20H3Z" fill="#049ED4"/>
                                                </svg>
                                            </span>Additional Pays</button>
                                        </div>
                                        <div className={'bottom-part'}>
                                            {getProfileContact()}
                                            {getProfileHierarchyChain()}
                                            {currentJobInfo?.currentJobInfoValue.teamName ?
                                                <div className={'team'}>
                                                    <h4>Team:</h4>
                                                    <span>{currentJobInfo.currentJobInfoValue.teamName}</span>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {(accruedDays.length > 0 || (currentEmployeeIsActive && (isMyProfile || hasRole || isProfileOfMyHierarchyChain))) &&
                                            <div className={'profile-sub-top-block'}>
                                                { accruedDays.length > 0 ?
                                                    <>
                                                        <h4>Time Off Balance:</h4>
                                                        <div className={'time-off-block'}>
                                                            {accruedDays.map(timeOffDay => (
                                                                <div style={{background: timeOffDay.timeOffPolicyColor}}
                                                                     id={timeOffDay.timeOffPolicyName}
                                                                     className={`time-off-type-block ${hasRole && !employee?.isDeleted ? 'clickable' : ''}`}
                                                                     key={timeOffDay.timeOffPolicyId}
                                                                     onClick={() => {
                                                                         if (hasRole && !employee?.isDeleted) {
                                                                             setEditAccruedDaysModal(true);
                                                                             setAccruedDay(timeOffDay);
                                                                         }
                                                                     }}
                                                                >
                                                                    <ReactTooltip
                                                                        className={'std-tooltip'}
                                                                        anchorId={timeOffDay.timeOffPolicyName}
                                                                        place="bottom"
                                                                        content={timeOffDay.timeOffPolicyName}
                                                                        noArrow={true}
                                                                    />
                                                                    <div dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[timeOffDay.iconId as keyof typeof TimeOffTypeIconsCollection] }} />
                                                                    <div><p>{timeOffDay.quantity} days</p></div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </> : null }
                                                { currentEmployeeIsActive && (isMyProfile || hasRole || isProfileOfMyHierarchyChain) && (
                                                    <Button
                                                        text="<i className='material-icons add-item'>add</i> Request Time Off"
                                                        size={ Size.Medium }
                                                        type={ Type.LightBlue }
                                                        onClick={ () => {
                                                            setCreateRequestModal(true)
                                                        }}
                                                    />
                                                )}
                                            </div> }
                                    </div>
                                </div>
                            </div>
                            <div className='sub-content-block profile-block d-flex'>
                                <div className='sub-content-main block-small-max-size'>
                                    <ul className={'employee-profile-menu'}>
                                        <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
                                            General Info
                                        </li>
                                        <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
                                            Job Info
                                        </li>
                                        {(isMyProfile || isProfileOfMyHierarchyChain || hasRole) && (
                                            <li className={getActiveClass(2)} onClick={() => handleLiClick(2)}>
                                                Emergency Contact
                                            </li>
                                        )}
                                        {/*<li className={getActiveClass(3)} onClick={() => handleLiClick(3)}>*/}
                                        {/*    HR Log*/}
                                        {/*</li>*/}
                                        {/*<li className={getActiveClass(4)} onClick={() => handleLiClick(4)}>*/}
                                        {/*    PUP Info*/}
                                        {/*</li>*/}
                                        {/*<li className={getActiveClass(5)} onClick={() => handleLiClick(5)}>*/}
                                        {/*    Employment and Salary*/}
                                        {/*</li>*/}
                                    </ul>
                                    <div className={'content'}>{getContentComponent()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showConfirmModal && (
                    <ConfirmModal
                        opened={showConfirmModal}
                        modalTitle="Attention!"
                        okText="OK"
                        confirmModalText="You have unapproved time off. Contact your direct manager."
                        onCancel={() => setShowConfirmModal(false)}
                        onOk={() => setShowConfirmModal(false)}
                        hasCancelButton={false}
                        cancelText={''}
                    />
                )}
            </div>
        </div>
        <CreateRequest
            opened={ createRequestModal }
            requester={ employee }
            authEmployee={ props.authEmployee }
            onOk={() => {
                setCreateRequestModal(false);
                handleDataUpdated();
            }}
            onClose={ () => setCreateRequestModal(false) }
            origin={'profile'}
        />
        <EditAccruedDays
            opened={ editAccruedDaysModal }
            requester={employee }
            authEmployee={ props.authEmployee }
            accruedDays={ accruedDays }
            accruedDay={ accruedDay }
            onOk={() => {
                setEditAccruedDaysModal(false);
                handleDataUpdated();
            }}
            onClose={ () => setEditAccruedDaysModal(false) }
        />
        <Footer/>
    </>
    );
}

export default Profile;