import React, {useEffect, useState} from 'react';
import Modal from '../../../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../../../Component/Input/TextField/TextField';
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError';

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    name: string | null,
    okText: string,
    onCancel: () => any,
    onOk: (name: string, validate: (errors: ValidationError[]) => void) => void,
};

function BaseModal(props: BaseModal) {

    const [name, setName] = useState<string>(props.name ?? '');
    const [nameError, setNameError] = useState<string | null>(null);

    function resetAll(): void {
        setName('');
        setNameError(null);
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'level.name') {
                setNameError(error.message);
            }
        });
    }

    useEffect(() => {
        if (props.modalTitle === 'Create Employee Level') {
            resetAll()
        }
    }, [props.opened]);

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={ () => props.onOk(name, validate) }

        >
            <TextField
                label='Name of the level'
                placeholder='Level name...'
                required={ true }
                value={ name }
                type={ Type.Textarea }
                horizontal={ true }
                error={ nameError }
                onChange={ newName => {
                    setName(newName);
                    setNameError(null);
                }}
                maxLength={50}
            />
        </Modal>
    );
}

export default BaseModal;