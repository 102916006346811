export const TimeOffTypeIconsCollection =
    {
            businessTrip: `<svg style="background: linear-gradient(139.53deg, #FFD28F 1.52%, #FEB701 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.18182C0 0.976833 0.976833 0 2.18182 0H21.8182C23.0232 0 24 0.976833 24 2.18182V21.8182C24 23.0232 23.0232 24 21.8182 24H2.18182C0.976833 24 0 23.0232 0 21.8182V2.18182Z" fill="url(#paint0_linear_12421_74807)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5703 8.283H14.7176H17.4939C18.2574 8.283 18.882 8.908 18.882 9.671L18.8814 12.612C18.8814 13.375 18.2567 14 17.4932 14H13.3288V13.684C13.3288 13.302 13.0165 12.99 12.6347 12.99H11.2466C10.8649 12.99 10.5525 13.302 10.5525 13.684V14H6.38814C5.61772 14 5 13.382 5 12.612L5.00068 9.671C5.00068 8.908 5.62534 8.283 6.38881 8.283H9.31V7.315C9.31 6.585 9.89521 6 10.6251 6H13.2552C13.9851 6 14.5703 6.585 14.5703 7.315V8.283ZM10.6251 8.283V7.315H13.2552V8.283H10.6251ZM11.2466 16.0539H12.6347C13.0165 16.0539 13.3288 15.7418 13.3288 15.3598H18.8814L18.882 16.612C18.882 17.375 18.2574 18 17.4939 18H6.38881C5.62534 18 5.00068 17.375 5.00068 16.612L5 15.3598H10.5525C10.5525 15.7418 10.8649 16.0539 11.2466 16.0539Z" fill="white"/>           
                <defs>
                <linearGradient id="paint0_linear_12421_74807" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFD28F"/>
                <stop offset="1" stop-color="#FEB701"/>
                </linearGradient>
                </defs>
                </svg>`,
            carryOver: `<svg style="background: linear-gradient(139.53deg, #A9A7FF 1.52%, #945DED 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.18182C0 0.976833 0.976833 0 2.18182 0H21.8182C23.0232 0 24 0.976833 24 2.18182V21.8182C24 23.0232 23.0232 24 21.8182 24H2.18182C0.976833 24 0 23.0232 0 21.8182V2.18182Z" fill="url(#paint0_linear_12421_74824)"/>
                <path d="M13.7537 8.64384V7.37884C13.7537 6.67084 14.6132 6.31284 15.1145 6.81384L18.7672 10.4668C19.0776 10.7768 19.0776 11.2778 18.7672 11.5888L15.1145 15.2408C14.6132 15.7428 13.7537 15.3928 13.7537 14.6838V13.3388C9.77476 13.3388 6.98948 14.6128 5 17.3978C5.79579 13.4188 8.18317 9.43984 13.7537 8.64384Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_12421_74824" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A9A7FF"/>
                <stop offset="1" stop-color="#945DED"/>
                </linearGradient>
                </defs>
                </svg>`,
            dayOff: `<svg style="background: linear-gradient(139.53deg, #FFC58F 1.52%, #FE9901 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8285_45596)"/>
                <path d="M13.1294 8.56349L13.1416 8.70727V13.0484L14.849 15.3742L16.2104 19.2255L16.2599 19.3767C16.2752 19.4576 16.2752 19.4818 16.2752 19.5366C16.2752 20.0136 15.8897 20.4006 15.4147 20.4006C15.0885 20.4006 14.8053 20.218 14.6596 19.9491L14.6116 19.8444L13.1416 16.0126L10.64 13.0354L10.6396 9.50477L9.32265 10.5097L9.35475 13.0055C9.40217 13.3917 9.1289 13.7432 8.74438 13.7904C8.35986 13.8376 8.00968 13.5627 7.96225 13.1765L7.95764 13.08L7.8796 9.76121L10.9886 7.23793C11.0061 7.22234 11.0622 7.17507 11.0766 7.16507C11.2826 7.02212 12.9692 7.62173 13.1294 8.56349ZM10.184 14.0126L11.4444 15.4743L11.0165 16.4247L8.91536 19.844C8.76217 20.0826 8.49597 20.241 8.19234 20.241C7.71739 20.241 7.33203 19.854 7.33203 19.3768C7.33203 19.206 7.38171 19.0468 7.46714 18.9127L7.5377 18.817L9.51162 15.3907L10.184 14.0126ZM16.1216 11.7378C16.3346 11.9257 16.4766 12.0874 16.4766 12.3497C16.4766 12.7389 16.1623 13.0543 15.7753 13.0543C15.6176 13.0543 15.4725 13.0018 15.3548 12.9132L15.2358 12.8157L15.0601 12.687L14.7239 12.4569L14.4929 12.3061L14.2074 12.131C14.1334 12.0887 14.0859 12.066 14.081 12.0735L14.0734 12.002L14.0698 11.7888L14.081 10.1723L16.1216 11.7378ZM12.0351 3.60059C12.9011 3.60059 13.6031 4.30575 13.6031 5.1756C13.6031 6.04545 12.9011 6.75061 12.0351 6.75061C11.1692 6.75061 10.4672 6.04545 10.4672 5.1756C10.4672 4.30575 11.1692 3.60059 12.0351 3.60059Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_8285_45596" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFC58F"/>
                <stop offset="1" stop-color="#FE9901"/>
                </linearGradient>
                </defs>
                </svg>`,
            education: `<svg style="background: linear-gradient(139.53deg, #FF8FE6 1.52%, #FE01B7 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.18182C0 0.976833 0.976833 0 2.18182 0H21.8182C23.0232 0 24 0.976833 24 2.18182V21.8182C24 23.0232 23.0232 24 21.8182 24H2.18182C0.976833 24 0 23.0232 0 21.8182V2.18182Z" fill="url(#paint0_linear_12421_74816)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49971 14.5121V12.9091L10.6539 15.1791C11.0666 15.4061 11.5618 15.4061 11.9744 15.1791L16.1286 12.9091V14.5121C16.1286 15.0141 15.8535 15.4821 15.4133 15.7221L11.9744 17.6001C11.5618 17.8271 11.0666 17.8271 10.6539 17.6001L7.215 15.7221C6.77482 15.4821 6.49971 15.0141 6.49971 14.5121ZM4.85593 9.4291L10.6539 6.2651C11.0666 6.0451 11.5618 6.0451 11.9744 6.2651L18.5221 9.8351C18.7422 9.9581 18.8797 10.1851 18.8797 10.4401V14.2911C19.2521 14.5201 19.5004 14.9311 19.5004 15.4001C19.5004 16.1181 18.9184 16.7001 18.2004 16.7001C17.4824 16.7001 16.9004 16.1181 16.9004 15.4001C16.9004 14.9381 17.1412 14.5331 17.5042 14.3021V10.7841L11.9744 13.8031C11.5618 14.0301 11.0666 14.0301 10.6539 13.8031L4.85593 10.6391C4.38136 10.3781 4.38136 9.6901 4.85593 9.4291Z" fill="white"/>                
                <defs>
                <linearGradient id="paint0_linear_12421_74816" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF8FE6"/>
                <stop offset="1" stop-color="#FE01B7"/>
                </linearGradient>
                </defs>
                </svg>`,
            remoteWork: `<svg style="background: linear-gradient(139.53deg, #91D4FA 1.52%, #0194FE 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.18182C0 0.976833 0.976833 0 2.18182 0H21.8182C23.0232 0 24 0.976833 24 2.18182V21.8182C24 23.0232 23.0232 24 21.8182 24H2.18182C0.976833 24 0 23.0232 0 21.8182V2.18182Z" fill="url(#paint0_linear_12421_74799)"/>
                <path d="M17.6867 6C18.3679 6 18.9261 6.51565 18.9932 7.17881L19 7.3133V16.6867C19 17.3679 18.4843 17.9261 17.8212 17.9932L17.6867 18H15.6667V15.3334H17V14H15.6667V12.6667H17V11.3334H15.58C15.4378 10.8059 15.1375 10.326 14.7167 9.97296L14.5533 9.8467L11.22 7.4667L11.06 7.3734L11 7.3134C11 6.63212 11.5156 6.07388 12.1788 6.00677L12.3133 6H17.6867ZM8.89333 8.5533C9.315 8.24777 9.87111 8.22231 10.3203 8.47692L10.44 8.5533L13.7733 10.9333C14.0874 11.1585 14.2856 11.4996 14.3258 11.877L14.3333 12.02V17.3333C14.3333 17.6667 14.0854 17.9449 13.7648 17.9927L13.6667 18H11V14H8.33333V18H5.66667C5.33333 18 5.0551 17.7521 5.00726 17.4315L5 17.3333V12.02C5 11.6348 5.16329 11.276 5.44774 11.023L5.56 10.9333L8.89333 8.5533ZM17 8.6667H15.6667V10H17V8.6667Z" fill="white"/>                
                <defs>
                <linearGradient id="paint0_linear_12421_74799" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#91D4FA"/>
                <stop offset="1" stop-color="#0194FE"/>
                </linearGradient>
                </defs>
                </svg>`,
            sickDay: `<svg style="background: linear-gradient(139.53deg, #F68888 1.52%, #FE5961 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8285_45597)"/>
                <path d="M16.6667 6H7.33333C6.6 6 6.00667 6.6 6.00667 7.3333L6 16.6667C6 17.4 6.6 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.3333C18 6.6 17.4 6 16.6667 6ZM15.3333 13.3333H13.3333V15.3333C13.3333 15.7 13.0333 16 12.6667 16H11.3333C10.9667 16 10.6667 15.7 10.6667 15.3333V13.3333H8.66667C8.3 13.3333 8 13.0333 8 12.6667V11.3333C8 10.9667 8.3 10.6667 8.66667 10.6667H10.6667V8.6667C10.6667 8.3 10.9667 8 11.3333 8H12.6667C13.0333 8 13.3333 8.3 13.3333 8.6667V10.6667H15.3333C15.7 10.6667 16 10.9667 16 11.3333V12.6667C16 13.0333 15.7 13.3333 15.3333 13.3333Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_8285_45597" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F68888"/>
                <stop offset="1" stop-color="#FE5961"/>
                </linearGradient>
                </defs>
                </svg>`,
            vacation: `<svg style="background: linear-gradient(139.53deg, #91F2B5 1.52%, #55D484 97.72%);border-radius: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8285_45595)"/>
                <g clip-path="url(#clip0_8285_45595)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.76638 9.18868L10.7807 11.3828L8.59735 13.5667L7.06075 13.1793C6.83998 13.124 6.61071 13.1972 6.46014 13.3477L6.18683 13.6211C5.89593 13.9121 5.95143 14.4037 6.30665 14.6142L6.30783 14.6149L8.02582 15.6461L8.23737 15.7702L8.33298 15.9251L8.47725 16.1719L9.14772 17.2959L9.38316 17.6916L9.38371 17.6925C9.59984 18.0528 10.0862 18.1015 10.3762 17.8115L10.6495 17.5381C10.8071 17.3805 10.8723 17.1551 10.8179 16.9374L10.435 15.4054L12.6233 13.2165L14.8153 17.2288C15.1326 17.8155 15.9223 17.9294 16.3937 17.458C16.6417 17.2099 16.7449 16.8408 16.6504 16.4987L15.1483 10.6908L17.6188 8.21972C18.1271 7.71129 18.1271 6.88966 17.6188 6.38133C17.1106 5.87289 16.2892 5.87289 15.7808 6.38133L13.3104 8.85239L7.50397 7.34999C7.16194 7.25546 6.79301 7.35869 6.54496 7.60676C6.07297 8.07888 6.18901 8.8663 6.76638 9.18868Z" fill="white"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_8285_45595" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#91F2B5"/>
                <stop offset="1" stop-color="#55D484"/>
                </linearGradient>
                <clipPath id="clip0_8285_45595">
                <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
                </clipPath>
                </defs>
                </svg>`,
            holiday: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8604_46415)"/>
                <g clip-path="url(#clip0_8604_46415)">
                <path d="M15.02 11.3737L14.3133 10.667L11.06 13.9203L9.64667 12.507L8.94 13.2137L11.06 15.3337L15.02 11.3737ZM16.6667 6.00033H16V4.66699H14.6667V6.00033H9.33333V4.66699H8V6.00033H7.33333C6.59333 6.00033 6.00667 6.60033 6.00667 7.33366L6 16.667C6 17.4003 6.59333 18.0003 7.33333 18.0003H16.6667C17.4 18.0003 18 17.4003 18 16.667V7.33366C18 6.60033 17.4 6.00033 16.6667 6.00033ZM16.6667 16.667H7.33333V9.33366H16.6667V16.667Z" fill="white"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_8604_46415" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#91D4FA"/>
                <stop offset="1" stop-color="#0194FE"/>
                </linearGradient>
                <clipPath id="clip0_8604_46415">
                <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                </clipPath>
                </defs>
                </svg>`,
            birthday: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8285_45593)"/>
                <g clip-path="url(#clip0_8285_45593)">
                <path d="M11.0894 12.1078V17.6887H7.14998C6.74925 17.6887 6.417 17.3854 6.36825 16.9938L6.3621 16.8942V12.1078H11.0894ZM17.3924 12.1078V16.8942C17.3924 17.299 17.0916 17.6334 16.7033 17.6825L16.6045 17.6887H12.6651V12.1078H17.3924ZM15.4423 5.49088C16.1346 6.19239 16.1346 7.33353 15.4428 8.03452C15.3498 8.12973 15.2246 8.23388 15.0271 8.35709L14.8996 8.41873H17.3924C17.7939 8.41873 18.1255 8.72266 18.1741 9.11372L18.1803 9.21317V10.7364C18.1803 10.9283 18.0452 11.0885 17.8657 11.1256L17.7864 11.1337H12.6651V9.21317H11.0894V11.1337H5.96816C5.77789 11.1337 5.61898 10.9974 5.58223 10.8165L5.57422 10.7364V9.21317C5.57422 8.8091 5.87565 8.47409 6.26347 8.42493L6.3621 8.41873H8.77292C8.58214 8.28737 8.42391 8.1493 8.31129 8.03491C7.62061 7.33328 7.62087 6.1923 8.31213 5.49096C8.9859 4.80723 10.1547 4.80723 10.8292 5.49087C11.2216 5.88836 11.6657 6.65775 11.8782 7.36998C12.0881 6.65746 12.5326 5.88773 12.9252 5.49096C13.5995 4.80669 14.7684 4.80614 15.4423 5.49088ZM13.6901 6.25782C13.2589 6.69517 12.7636 7.88699 12.8025 8.12543L12.8082 8.13971H12.8683L12.9713 8.13209C13.4606 8.06331 14.3429 7.60773 14.6775 7.26805C14.9519 6.99001 14.9519 6.53591 14.6775 6.25787C14.4143 5.99119 13.9515 5.99161 13.6901 6.25782ZM9.57113 6.05004C9.38445 6.05004 9.20963 6.12351 9.07714 6.25776C8.80333 6.53582 8.80333 6.9901 9.07713 7.26816C9.41147 7.60763 10.2937 8.06329 10.7834 8.13139L10.9142 8.13979L10.9463 8.13678L10.952 8.12543C10.9884 7.90189 10.5554 6.84042 10.1459 6.34829L10.0644 6.25787C9.93191 6.12357 9.75711 6.05004 9.57113 6.05004Z" fill="white"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_8285_45593" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A9A7FF"/>
                <stop offset="1" stop-color="#945DED"/>
                </linearGradient>
                <clipPath id="clip0_8285_45593">
                <rect width="14" height="14" fill="white" transform="translate(5 4)"/>
                </clipPath>
                </defs>
                </svg>`,
            anniversary: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8285_45594)"/>
                <path d="M11.6688 5.45576C11.806 5.18142 12.1975 5.18141 12.3347 5.45576L18.7138 18.2112C18.8376 18.4587 18.6576 18.75 18.3809 18.75H5.62275C5.346 18.75 5.16601 18.4587 5.28979 18.2112L11.6688 5.45576ZM16.596 17.5074C16.7689 17.5074 16.8814 17.3253 16.8041 17.1706L12.2098 7.98416C12.1241 7.8127 11.8794 7.8127 11.7936 7.98416L7.19948 17.1706C7.12211 17.3253 7.2346 17.5074 7.40757 17.5074H16.596ZM13.8045 13.6751C13.875 13.6751 13.9395 13.7149 13.971 13.778L15.1858 16.2072C15.2477 16.331 15.1577 16.4766 15.0194 16.4766H8.98417C8.8458 16.4766 8.75581 16.331 8.8177 16.2072L10.0326 13.778C10.0641 13.7149 10.1285 13.6751 10.199 13.6751H13.8045ZM11.8353 10.1733C11.9039 10.0361 12.0996 10.0361 12.1682 10.1733L13.2629 12.3618C13.3248 12.4856 13.2348 12.6312 13.0964 12.6312H10.9073C10.7689 12.6312 10.6789 12.4856 10.7408 12.3618L11.8353 10.1733Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_8285_45594" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#91D4FA"/>
                <stop offset="1" stop-color="#0194FE"/>
                </linearGradient>
                </defs>
                </svg>`,
            vacationNoConfirm: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8938_47547)"/>
                <g clip-path="url(#clip0_8938_47547)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.76638 9.18868L10.7807 11.3828L8.59735 13.5667L7.06075 13.1793C6.83998 13.124 6.61071 13.1972 6.46014 13.3477L6.18683 13.6211C5.89593 13.9121 5.95143 14.4037 6.30665 14.6142L6.30783 14.6149L8.02582 15.6461L8.23737 15.7702L8.33298 15.9251L8.47725 16.1719L9.14772 17.2959L9.38316 17.6916L9.38371 17.6925C9.59984 18.0528 10.0862 18.1015 10.3762 17.8115L10.6495 17.5381C10.8071 17.3805 10.8723 17.1551 10.8179 16.9374L10.435 15.4054L12.6233 13.2165L14.8153 17.2288C15.1326 17.8155 15.9223 17.9294 16.3937 17.458C16.6417 17.2099 16.7449 16.8408 16.6504 16.4987L15.1483 10.6908L17.6188 8.21972C18.1271 7.71129 18.1271 6.88966 17.6188 6.38133C17.1106 5.87289 16.2892 5.87289 15.7808 6.38133L13.3104 8.85239L7.50397 7.34999C7.16194 7.25546 6.79301 7.35869 6.54496 7.60676C6.07297 8.07888 6.18901 8.8663 6.76638 9.18868Z" fill="white"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_8938_47547" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EFF1F3"/>
                <stop offset="1" stop-color="#C8C9CB"/>
                </linearGradient>
                <clipPath id="clip0_8938_47547">
                <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
                </clipPath>
                </defs>
                </svg>`,
            sickDayNoConfirm: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8613_46997)"/>
                <path d="M16.6667 6H7.33333C6.6 6 6.00667 6.6 6.00667 7.3333L6 16.6667C6 17.4 6.6 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V7.3333C18 6.6 17.4 6 16.6667 6ZM15.3333 13.3333H13.3333V15.3333C13.3333 15.7 13.0333 16 12.6667 16H11.3333C10.9667 16 10.6667 15.7 10.6667 15.3333V13.3333H8.66667C8.3 13.3333 8 13.0333 8 12.6667V11.3333C8 10.9667 8.3 10.6667 8.66667 10.6667H10.6667V8.6667C10.6667 8.3 10.9667 8 11.3333 8H12.6667C13.0333 8 13.3333 8.3 13.3333 8.6667V10.6667H15.3333C15.7 10.6667 16 10.9667 16 11.3333V12.6667C16 13.0333 15.7 13.3333 15.3333 13.3333Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_8613_46997" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EFF1F3"/>
                <stop offset="1" stop-color="#C8C9CB"/>
                </linearGradient>
                </defs>
                </svg>`,
            dayOffNoConfirm: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="url(#paint0_linear_8938_47544)"/>
                <path d="M13.1294 8.56349L13.1416 8.70727V13.0484L14.849 15.3742L16.2104 19.2255L16.2599 19.3767C16.2752 19.4576 16.2752 19.4818 16.2752 19.5366C16.2752 20.0136 15.8897 20.4006 15.4147 20.4006C15.0885 20.4006 14.8053 20.218 14.6596 19.9491L14.6116 19.8444L13.1416 16.0126L10.64 13.0354L10.6396 9.50477L9.32265 10.5097L9.35475 13.0055C9.40217 13.3917 9.1289 13.7432 8.74438 13.7904C8.35986 13.8376 8.00968 13.5627 7.96225 13.1765L7.95764 13.08L7.8796 9.76121L10.9886 7.23793C11.0061 7.22234 11.0622 7.17507 11.0766 7.16507C11.2826 7.02212 12.9692 7.62173 13.1294 8.56349ZM10.184 14.0126L11.4444 15.4743L11.0165 16.4247L8.91536 19.844C8.76217 20.0826 8.49597 20.241 8.19234 20.241C7.71739 20.241 7.33203 19.854 7.33203 19.3768C7.33203 19.206 7.38171 19.0468 7.46714 18.9127L7.5377 18.817L9.51162 15.3907L10.184 14.0126ZM16.1216 11.7378C16.3346 11.9257 16.4766 12.0874 16.4766 12.3497C16.4766 12.7389 16.1623 13.0543 15.7753 13.0543C15.6176 13.0543 15.4725 13.0018 15.3548 12.9132L15.2358 12.8157L15.0601 12.687L14.7239 12.4569L14.4929 12.3061L14.2074 12.131C14.1334 12.0887 14.0859 12.066 14.081 12.0735L14.0734 12.002L14.0698 11.7888L14.081 10.1723L16.1216 11.7378ZM12.0351 3.60059C12.9011 3.60059 13.6031 4.30575 13.6031 5.1756C13.6031 6.04545 12.9011 6.75061 12.0351 6.75061C11.1692 6.75061 10.4672 6.04545 10.4672 5.1756C10.4672 4.30575 11.1692 3.60059 12.0351 3.60059Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_8938_47544" x1="0.4" y1="0.333333" x2="21.5252" y2="25.0955" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EFF1F3"/>
                <stop offset="1" stop-color="#C8C9CB"/>
                </linearGradient>
                </defs>
                </svg>`,
    } as const;