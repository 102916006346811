import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import {ADMIN, CALENDAR} from '../../../../utils/constants/routes';
import {Link} from 'react-router-dom';
import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER
} from "../../../../shared/Security/constants/AccessLevels";
import {CALENDAR as CALENDAR_API} from '../../../../utils/constants/api';
type Navbar = {
    authEmployee: AuthEmployee,
};

function Navbar(props: Navbar) {
    const [countRequests, setCountNewRequests] = useState<number>(0);
    const roles = [ROLE_ADMIN, ROLE_HR, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const isOperationalManagerOnly = props.authEmployee.roles.length === 1 && props.authEmployee.roles[0] === ROLE_OPERATIONAL_MANAGER;
    const link = props.authEmployee.email.includes('inweb.ua')
        ? process.env.REACT_APP_INWEB_BASE_URL
        : process.env.REACT_APP_PUP_BASE_URL;

    useEffect(() => {
        getCountNewRequests()
    }, []);

    function getCountNewRequests() {
        fetch(CALENDAR_API + '/employee-new-requests-counter/' + props.authEmployee.id, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((newCountRequests: number) => setCountNewRequests(newCountRequests));
    }

    return (
        <>
            <div className='nav-hidden'></div>
            <nav>
                {!isOperationalManagerOnly && <Link title={"Time Off Calendar"} to={`${CALENDAR}?index=1&renderFromMenu=true`}><span className="material-symbols-outlined">calendar_month</span></Link>}
                {!isOperationalManagerOnly && <div><Link title={"Time Off Requests"} to={`${CALENDAR}?index=0&renderFromMenu=true`}>
                    <i className="material-symbols-outlined">library_books</i>
                </Link>
                    { countRequests ?
                        <span className={'new-requests-counter'}>{ countRequests }</span> : null
                    }
                </div>
                }
                <a target="_blank" rel="noopener"  title={"PUP Time Sheet"} href={link + '/attendance/general'}><i className="material-symbols-outlined">newspaper</i></a>
                <a target="_blank" rel="noopener"  title={"PUP Expenses"} href={link + '/expenses/external_expenses'}><i className="material-symbols-outlined">assignment_add</i></a>
                {hasRole && <Link title={"Admin Settings"} to={ADMIN}><i className="material-symbols-outlined">settings</i></Link>}
            </nav>
        </>
    );
}

export default Navbar;