import React, { useState, useEffect } from 'react';
import './PushNotifications.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import {COMPANIES, LOCATIONS, PUSH_NOTIFICATIONS} from '../../../../utils/constants/api';
import Button, {Size, Type} from '../../../Component/Button/Button';
import CreatePushNotification from './Modal/CreatePushNotification';
import DeletePushNotification from './Modal/DeletePushNotification';
import EditPushNotification from './Modal/EditPushNotification';
import {PushNotificationFromApi} from '../../../../shared/PushNotification/data/PushNotificationFromApi';
import {CompanyFromApi} from "../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../Locations/Locations";
import moment from "moment/moment";
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER} from "../../../../shared/Security/constants/AccessLevels";

type PushNotifications = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function PushNotifications(props: PushNotifications) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createPushNotificationModal, setCreatePushNotificationModal] = useState<boolean>(false);
    const [editPushNotificationModal, setEditPushNotificationModal] = useState<boolean>(false);
    const [deletePushNotificationModal, setDeletePushNotificationModal] = useState<boolean>(false);

    const [pushNotifications, setPushNotifications] = useState<PushNotificationFromApi[]>([]);
    const [pushNotificationToChange, setPushNotificationToChange] = useState<PushNotificationFromApi | null>(null);
    const [pushNotificationToDelete, deletePushNotification] = useState<PushNotificationFromApi | null>(null);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);
    const [locations, setLocations] = useState<LocationFromApi[]>([]);

    useEffect(getPushNotifications, []);
    useEffect(getCompanies, []);
    useEffect(getLocations, []);

    function getPushNotifications() {
        fetch(PUSH_NOTIFICATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((pushNotifications: PushNotificationFromApi[]) => setPushNotifications(pushNotifications));
    }

    function getCompanies(): void {
        fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((companies: CompanyFromApi[]) => setCompanies(companies));
    }

    function getLocations(): void {
        fetch(LOCATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((locations: LocationFromApi[]) => setLocations(locations));
    }

    function editPushNotificationModalVisibility(pushNotification: PushNotificationFromApi): void {
        setPushNotificationToChange(pushNotification);
        setEditPushNotificationModal(true);
    }

    function confirmDeletePushNotificationModalVisibility(pushNotification: PushNotificationFromApi): void {
        deletePushNotification(pushNotification)
        setDeletePushNotificationModal(true);
    }

    function isSentOrScheduled(pushNotification: PushNotificationFromApi): boolean {
        const now = new Date();
        const sendAt = new Date(pushNotification.value.sendAt);
        return sendAt > now;
    }

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    // Access levels
    const roles = [ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Push Notifications</h3>
                                </div>
                                <div className='add-button-block'>
                                    {hasRole && (
                                        <div>
                                            <Button
                                                text="<i className='material-icons add-item'>add</i> Add Push Notification"
                                                size={Size.Medium}
                                                type={Type.Accent}
                                                onClick={() => setCreatePushNotificationModal(true)}
                                            />
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div className='sub-content-main'>
                                <table className='push-notifications-table'>
                                    <thead>
                                    <tr>
                                        <th>
                                            Push Notification
                                        </th>
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pushNotifications.map(notification => {
                                        const isSentColor = isSentOrScheduled(notification) ? '$blackBaseColor' : 'lightgrey';
                                        const date = new Date(notification.value.sendAt);
                                        return (
                                            <tr key={ notification.id }>
                                                <td
                                                    title={ notification.value.subject }
                                                    style={{ color: isSentColor }}
                                                >
                                                    { notification.value.subject }</td>
                                                <td
                                                    style={{ color: isSentColor }}
                                                >
                                                    { moment(date).format("DD.MM.YYYY H:mm") }</td>
                                                <td
                                                    style={{ color: isSentColor }}
                                                >
                                                    {isSentOrScheduled(notification) ? 'Scheduled' : 'Sent'} </td>
                                                <td
                                                    style={{ color: isSentColor }}
                                                >
                                                    {hasRole && (
                                                        <>
                                                            <i
                                                                className='material-icons'
                                                                onClick={ () => editPushNotificationModalVisibility(notification) }
                                                            >
                                                                edit
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={ () => confirmDeletePushNotificationModalVisibility(notification) }
                                                            >
                                                                delete
                                                            </i>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <CreatePushNotification
                    opened={ createPushNotificationModal }
                    onOk={ () => { getPushNotifications(); setCreatePushNotificationModal(false); } }
                    onClose={ () => setCreatePushNotificationModal(false) }
                    companies={ companies }
                    locations={ locations }
                />
                {
                    pushNotificationToDelete ?
                        <DeletePushNotification
                            opened={ deletePushNotificationModal }
                            onOk={ () => { getPushNotifications(); setDeletePushNotificationModal(false); deletePushNotification(null) } }
                            onClose={ () => { setDeletePushNotificationModal(false); deletePushNotification(null) } }
                            pushNotification={ pushNotificationToDelete }
                        /> : null
                }
                {
                    pushNotificationToChange
                        ?
                        <EditPushNotification
                            opened={ editPushNotificationModal }
                            onOk={ () => { getPushNotifications(); setEditPushNotificationModal(false); setPushNotificationToChange(null) } }
                            onClose={ () => { setEditPushNotificationModal(false); setPushNotificationToChange(null) } }
                            companies={ companies }
                            locations={ locations }
                            pushNotification={ pushNotificationToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default PushNotifications;