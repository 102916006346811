import React, { useState, useEffect, useRef } from 'react';
import './ProfessionalHolidays.scss';
import CreateProfessionalHoliday from './Modal/CreateProfessionalHoliday';
import EditProfessionalHoliday from './Modal/EditProfessionalHoliday';
import moment from "moment";
import {COMPANIES, PROFESSIONAL_HOLIDAYS} from '../../../../../utils/constants/api';
import DeleteProfessionalHoliday from "./Modal/DeleteProfessionalHoliday";
import Button, {Size, Type} from '../../../../Component/Button/Button';
import { ProfessionalHolidayFromApi } from '../../../../../shared/Holiday/ProfessionalHoliday/data/ProfessionalHolidayFromApi';
import { Popover } from '@headlessui/react'
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER
} from "../../../../../shared/Security/constants/AccessLevels";
import {AuthEmployee} from "../../../../../shared/Security/data/AuthEmployee";

type ProfessionalHolidays = {
    year: string,
    authEmployee: AuthEmployee,
}

function ProfessionalHolidays(props: ProfessionalHolidays) {
    const [createProfessionalHolidayModal, setCreateProfessionalHolidayModal] = useState<boolean>(false);
    const [editProfessionalHolidayModal, setEditProfessionalHolidayModal] = useState<boolean>(false);
    const [deleteProfessionalHolidayModal, setDeleteProfessionalHolidayModal] = useState<boolean>(false);

    const [professionalHolidays, setProfessionalHolidays] = useState<ProfessionalHolidayFromApi[]>([]);
    const [professionalHolidayToChange, setProfessionalHolidayToChange] = useState<ProfessionalHolidayFromApi | null>(null);
    const [professionalHolidayToDelete, deleteProfessionalHoliday] = useState<ProfessionalHolidayFromApi | null>(null);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);

    function getCompanies() {
        fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((companies: CompanyFromApi[]) => setCompanies(companies));
    }

    useEffect(() => {
        getCompanies();
        if (props.year) {
            getProfessionalHolidays(props.year);
        }
    }, [props.year]);

    function getProfessionalHolidays(year: string) {
        return fetch(PROFESSIONAL_HOLIDAYS + '?year=' + year, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((professionalHolidays: ProfessionalHolidayFromApi[]) => setProfessionalHolidays(professionalHolidays));
    }

    function showProfessionalHoliday({professionalHoliday}: { professionalHoliday: any }): void {
        fetch(PROFESSIONAL_HOLIDAYS + '/' + professionalHoliday.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: professionalHoliday.value.name,
                companies: professionalHoliday.value.companies,
                movingType: professionalHoliday.value.movingType,
                date: professionalHoliday.value.date,
                repeatAnnually: professionalHoliday.value.repeatAnnually,
                text: professionalHoliday.value.text,
                visible: !professionalHoliday.value.visible,
            })
        })
            .then(response => {
                if (response.ok) {
                    getProfessionalHolidays(props.year);
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    function editProfessionalHolidayModalVisibility(professionalHoliday: ProfessionalHolidayFromApi): void {
        setProfessionalHolidayToChange(professionalHoliday);
        document.getElementById('popover-btn')?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        setEditProfessionalHolidayModal(true);
    }

    function confirmDeleteProfessionalHolidayModalVisibility(professionalHoliday: ProfessionalHolidayFromApi): void {
        deleteProfessionalHoliday(professionalHoliday)
        document.getElementById('popover-btn')?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        setDeleteProfessionalHolidayModal(true);
    }

    // Access levels
    const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div className='professional-holidays'>
            { hasRole ?
                <div className='add-button-block'>
                    { props.year ?
                        <Button
                            text="<i className='material-icons add-item'>add</i> Add professional holiday"
                            size={ Size.Medium }
                            type={ Type.Accent }
                            onClick={ () => setCreateProfessionalHolidayModal(true) }
                        /> : null }
                </div> : null }
            <table className="professional-holidays-table">
                <thead>
                <tr>
                    <th>
                        Name
                        <a href='#'>
                            <i className='material-icons'>arrow_downward</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Company
                        <a className={'zero-padding'} href='#'>
                            <i className='material-icons'>arrow_drop_down</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Date
                        <a href='#'>
                            <i className='material-icons'>filter_list</i>
                        </a>
                    </th>
                    <th className={'center'}>
                        Repeat Annualy
                    </th>
                    <th className={'center'}>
                        Text
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    {professionalHolidays.map(professionalHoliday => {
                        return (
                            <tr key={ professionalHoliday.id }>
                                <td>{ professionalHoliday.value.name }</td>
                                <td className={'center'}><div className={'count-margin-fix'}>{ professionalHoliday.value.companies.length }</div></td>
                                <td className={'center'}>{ professionalHoliday.value.date ? moment(professionalHoliday.value.date).format("DD.MM.YYYY") : '' }</td>
                                <td className={'center'}>{ professionalHoliday.value.repeatAnnually ? <i className='material-icons check-success'>done</i> : <i className='material-icons'>remove</i> }</td>
                                <td className={'center'}>{ professionalHoliday.value.text ? <i className='material-icons check-success'>done</i> : <i className='material-icons'>remove</i> }</td>
                                <td className={'center'}>
                                    { hasRole ?
                                        <Popover className="relative">
                                            <Popover.Button id='popover-btn' className={'vert-menu-btn'}><i className='material-icons'>more_vert</i></Popover.Button>

                                            <Popover.Panel className="absolute z-10">
                                                <i className='material-icons'
                                                   onClick={ () => {
                                                       editProfessionalHolidayModalVisibility(professionalHoliday)
                                                   }}>
                                                    edit
                                                </i>
                                                <i className='material-icons'
                                                   onClick={ () => {
                                                       confirmDeleteProfessionalHolidayModalVisibility(professionalHoliday)
                                                   }}>
                                                    delete
                                                </i>
                                                <i className='material-icons'
                                                   onClick={ () => showProfessionalHoliday({professionalHoliday: professionalHoliday}) }>
                                                    {professionalHoliday.value.visible ? "visibility" : "visibility_off"}
                                                </i>
                                            </Popover.Panel>
                                        </Popover> : null
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <CreateProfessionalHoliday
                companies={ companies }
                opened={ createProfessionalHolidayModal }
                onOk={ () => { getProfessionalHolidays(props.year); setCreateProfessionalHolidayModal(false); } }
                onClose={ () => setCreateProfessionalHolidayModal(false) }
            />
            {
                professionalHolidayToDelete ?
                    <DeleteProfessionalHoliday
                        opened={ deleteProfessionalHolidayModal }
                        onOk={ () => { getProfessionalHolidays(props.year); setDeleteProfessionalHolidayModal(false); deleteProfessionalHoliday(null) } }
                        onClose={ () => { setDeleteProfessionalHolidayModal(false); deleteProfessionalHoliday(null) } }
                        professionalHoliday={ professionalHolidayToDelete }
                    /> : null
            }
            {
                professionalHolidayToChange
                    ?
                    <EditProfessionalHoliday
                        companies={ companies }
                        opened={ editProfessionalHolidayModal }
                        onOk={ () => { getProfessionalHolidays(props.year); setEditProfessionalHolidayModal(false); setProfessionalHolidayToChange(null) } }
                        onClose={ () => { setEditProfessionalHolidayModal(false); setProfessionalHolidayToChange(null) } }
                        professionalHoliday={ professionalHolidayToChange }
                    />
                    : null
            }
        </div>
    );
}

export default ProfessionalHolidays;