import React from 'react';
import BaseModal from './BaseModal';
import { GREETINGS } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { LocationFromApi}  from '../../Locations/Locations';

type CreateGreeting = {
    locations: LocationFromApi[],
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
};

function CreateGreeting(props: CreateGreeting) {
    function create(
        text: string,
        locations: string[],
        date: Date | null,
        type: string,
        repeatAnnually: boolean,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('greeting', JSON.stringify({
            text: text,
            locations: locations,
            special:  type === 'special' ? date ? {
                date: date ? date.getFullYear() + '-' + (date.getMonth()+1) + "-" + date.getDate() : null,
                repeatAnnually: repeatAnnually
            } : false : null,
            visible: visible,
        }));

        fetch(GREETINGS, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Greeting'
            text={ null }
            date={ null }
            locations={ [] }
            selectedLocations={ props.locations }
            repeatAnnually={ false }
            visible={ true }
            type={ 'daily' }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateGreeting;