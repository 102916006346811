import React, {useEffect, useState} from 'react';
import BaseModal from '../BaseModal/BaseModal';
import Radio from "../../../../Component/Input/Radio/Radio";
import Checkbox from "../../../../Component/Input/Checkbox/Checkbox";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";
import {ApprovalEmployeeTypes} from "../../../../../shared/TimeOffPolicy/data/ApprovalOptions";
import {EMPLOYEES} from "../../../../../utils/constants/api";
import Select from "react-select";


type ApprovalOptionsModal = {
    source: string,
    opened: boolean,
    modalTitle: string,
    okText: string,
    automaticallyApproveRequests: string,
    approvalEmployeeType: string[]|null,
    approvalEmployeeIds: string[]|null,
    allowToAddReplacementPerson: string,
    allowToAddReplacementPersonIsMandatory: boolean,
    commentFieldIsMandatory: boolean,
    companiesFromGeneralInfo: SelectValue[],
    onOk: (
        automaticallyApproveRequests: string,
        approvalEmployeeType: string[]|null,
        approvalEmployeeIds: string[]|null,
        allowToAddReplacementPerson: string,
        allowToAddReplacementPersonIsMandatory: boolean,
        commentFieldIsMandatory: boolean,
    ) => void,
    onCancel?: () => any,
    style : string,
};

function ApprovalOptionsModal(props: ApprovalOptionsModal) {
    const [automaticallyApproveRequests, setAutomaticallyApproveRequests] = useState(props.automaticallyApproveRequests);
    const [approvalEmployeeType, setApprovalEmployeeType] = useState<SelectValue[]>([]);
    const [approvalEmployees, setApprovalEmployees] = useState<SelectValue[]>([]);
    const [allowToAddReplacementPerson, setAllowToAddReplacementPerson] = useState(props.allowToAddReplacementPerson);
    const [allowToAddReplacementPersonIsMandatory, setAllowToAddReplacementPersonIsMandatory] = useState(props.allowToAddReplacementPersonIsMandatory);
    const [commentFieldIsMandatory, setCommentFieldIsMandatory] = useState(props.commentFieldIsMandatory);

    const [approvalEmployeeTypeOptions, setApprovalEmployeeTypeOptions] = useState<SelectValue[]>(ApprovalEmployeeTypes);
    const [approvalEmployeeOptions, setApprovalEmployeeOptions] = useState<SelectValue[]>([]);

    const [approvalEmployeeTypeError, setApprovalEmployeeTypeError] = useState<string>('');
    const [approvalEmployeesError, setApprovalEmployeesError] = useState<string>('');

    const [companyIds, setCompanyIds] = useState<string>('');

    useEffect(() => {
        setCompanyIds(props.companiesFromGeneralInfo.map(company => company.value).join(','));
    }, [props.approvalEmployeeIds, props.companiesFromGeneralInfo]);

    useEffect(() => {
        getApprovalEmployeeType(props.approvalEmployeeType ? props.approvalEmployeeType : null);
    }, [props.approvalEmployeeType]);

    useEffect(() => {
        getApprovalEmployeesOptions();
    }, [companyIds]);

    useEffect(() => {
        getApprovalEmployees(props.approvalEmployeeIds ? props.approvalEmployeeIds : null);
    }, [approvalEmployeeOptions]);

    function getApprovalEmployeesOptions() {
        if (companyIds) {
            fetch(EMPLOYEES + '/type/active', {
                method: 'GET',
                headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
            })
                .then(response => response.json())
                .then((employees: any[]) =>
                    setApprovalEmployeeOptions(
                        employees.map(employee => {
                                return {
                                    value: employee.id,
                                    label: employee.nickname
                                }
                            }
                        ))
                )
        }
    }

    function getApprovalEmployeeType(approvalEmployeeTypeValues: string[]|null): void {
        if (approvalEmployeeTypeValues) {
            const approvalEmployeeTypes = approvalEmployeeTypeOptions.filter(
                approvalEmployeeType => approvalEmployeeTypeValues.includes(approvalEmployeeType.value)
            );
            setApprovalEmployeeType(approvalEmployeeTypes);
        }
    }

    function getApprovalEmployees(approvalEmployeesIds: string[]|null) {
        if (approvalEmployeesIds) {
            const approvalEmployees = approvalEmployeeOptions.filter(
                approvalEmployee => approvalEmployeesIds.includes(approvalEmployee.value)
            );
            setApprovalEmployees(approvalEmployees);
        }
    }

    function handleAutomaticallyApproveRequestsChange (newValue: string) {
        setAutomaticallyApproveRequests(newValue);
        if (newValue === 'true') {
            setApprovalEmployees([]);
            setApprovalEmployeeType([]);
        }
    }

    function handleAllowToAddReplacementPersonChange (newAllowToAddReplacementPerson: string) {
        setAllowToAddReplacementPerson(newAllowToAddReplacementPerson);
        if (newAllowToAddReplacementPerson === 'true') {
            setAllowToAddReplacementPersonIsMandatory(false);
        }
    }

    function handleAllowToAddReplacementPersonIsMandatoryChange (newAllowToAddReplacementPersonIsMandatory: boolean) {
        setAllowToAddReplacementPersonIsMandatory(newAllowToAddReplacementPersonIsMandatory);
    }

    function handleCommentFieldIsMandatoryChange (newCommentFieldIsMandatory: boolean) {
        setCommentFieldIsMandatory(newCommentFieldIsMandatory);
    }

    function handleApprovalEmployeeTypeChange (newApprovalEmployeeType: SelectValue[]) {
        setApprovalEmployeeType(newApprovalEmployeeType);
    }

    function handleApprovalEmployeesChange (newApprovalEmployees: SelectValue[]) {
        setApprovalEmployees(newApprovalEmployees);
    }

    return (
        <BaseModal
            opened={props.opened}
            modalTitle={props.modalTitle}
            okText={props.okText}
            onOk={() => {
                if (approvalEmployeeType && approvalEmployeeType.some(item => item.value === 'specific_employee') && approvalEmployees.length <= 0) {
                    setApprovalEmployeesError('Please choose specific employee')
                    return;
                }
                if (automaticallyApproveRequests === 'false' && approvalEmployeeType.length <= 0) {
                    setApprovalEmployeeTypeError('Please choose type employee')
                    return;
                }
                props.onOk(
                    automaticallyApproveRequests,
                    approvalEmployeeType.map(approvalEmployeeType => approvalEmployeeType.value),
                    approvalEmployees.map(approvalEmployee => approvalEmployee.value),
                    allowToAddReplacementPerson,
                    allowToAddReplacementPersonIsMandatory,
                    commentFieldIsMandatory,
                )}
            }
            hasCancel={props.source === 'edit'}
            onCancel={props.source === 'edit'? props.onCancel : undefined}
            cancelText={props.source === 'edit' ? 'Cancel' : undefined}
            style={props.style}
        >
            <div className='wide-page-input-container'>
                <span className='subtitle-black-dark'>Employees receive accrued time…</span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'automaticallyApproveRequests'}
                        value={automaticallyApproveRequests}
                        onChange={newAutomaticallyApproveRequests =>
                            handleAutomaticallyApproveRequestsChange(newAutomaticallyApproveRequests)}
                        radios={[
                            {label: 'Yes', value: 'true'},
                            {label: 'No, approval needed from', value: 'false'}
                        ]}
                    />
                </div>
                {automaticallyApproveRequests === 'false' && (
                    <>
                        <div className='wide-page-modal-line-short'></div>
                        <div className='wide-page-input-group'>
                            <div className={'input-container'}>
                                <Select
                                    placeholder={'-'}
                                    isMulti={true}
                                    id={'approval-employee-type'}
                                    className={'approval-employee-select'}
                                    value={approvalEmployeeType}
                                    options={approvalEmployeeTypeOptions}
                                    isClearable={false}
                                    classNamePrefix='react-select'
                                    onChange={(newValue: any) => {
                                        setApprovalEmployeeTypeError('');
                                        handleApprovalEmployeeTypeChange(newValue);
                                    }}
                                    isSearchable={true}
                                />
                                {approvalEmployeeTypeError &&
                                    <div className='error'>
                                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                                        <span>{ approvalEmployeeTypeError }</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='wide-page-input-group'>
                            {approvalEmployeeType && approvalEmployeeType.some(item => item.value === 'specific_employee') && (
                                <div className={'input-container'}>
                                    <Select
                                        placeholder={'-'}
                                        isMulti={true}
                                        id={'approval-employee'}
                                        className={'approval-employee-select'}
                                        value={approvalEmployees}
                                        options={approvalEmployeeOptions}
                                        isClearable={false}
                                        classNamePrefix='react-select'
                                        onChange={(newValue: any) => {
                                            setApprovalEmployeesError('')
                                            handleApprovalEmployeesChange(newValue)
                                        }}
                                        isSearchable={true}
                                    />
                                    {approvalEmployeesError &&
                                        <div className='error'>
                                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                                            <span>{ approvalEmployeesError }</span>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            <div className='wide-page-input-container'>
                <span className='subtitle-black-dark'>Allow to add replacement person</span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'allowToAddReplacementPerson'}
                        value={allowToAddReplacementPerson}
                        onChange={newAllowToAddReplacementPerson =>
                            handleAllowToAddReplacementPersonChange(newAllowToAddReplacementPerson)}
                        radios={[
                            {label: 'Yes', value: 'true'},
                            {label: 'No', value: 'false'}
                        ]}
                    />
                </div>
                {allowToAddReplacementPerson === 'true' && (
                    <>
                        <div className='wide-page-modal-line-short'></div>
                        <div className='only-input-container'>
                            <Checkbox
                                disabled={ false }
                                label={''}
                                required={ false }
                                checked={ allowToAddReplacementPersonIsMandatory }
                                checkBoxLabel='Make it mandatory'
                                onChange={newAllowToAddReplacementPersonIsMandatory =>
                                    handleAllowToAddReplacementPersonIsMandatoryChange(newAllowToAddReplacementPersonIsMandatory)}
                                switch={ false }
                            />
                        </div>
                    </>
                )}
            </div>

            <div className='wide-page-input-container'>
                <div className='only-input-container'>
                    <Checkbox
                        disabled={ false }
                        label={''}
                        required={ false }
                        checked={ commentFieldIsMandatory }
                        checkBoxLabel='Make comment field mandatory'
                        onChange={newCommentFieldIsMandatory =>
                            handleCommentFieldIsMandatoryChange(newCommentFieldIsMandatory)}
                        switch={ false }
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default ApprovalOptionsModal;