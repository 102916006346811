import React, {useEffect, useState} from 'react';
import NewbiesWidget from './Newbies/NewbiesWidget';
import OutgoingEmployeesWidget from './OutgoingEmployees/OutgoingEmployeesWidget';
import { EMPLOYEES } from '../../../../../utils/constants/api';
import {
    EmployeesForEmployeeStatusWidget
} from '../../../../../shared/Main/EmployeesForEmployeeStatusWidget/data/EmployeesForEmployeeStatusWidget';

function EmployeesStatusWidget() {
    const [areThereAnyNewbies, setAreThereAnyNewbies] = useState(false);
    const [newbies, setNewbies] = useState<EmployeesForEmployeeStatusWidget[]>([]);

    const [areThereAnyOutgoingEmployees, setAreThereAnyOutgoingEmployees] = useState(false);
    const [outgoingEmployees, setOutgoingEmployees] = useState<EmployeesForEmployeeStatusWidget[]>([]);

    useEffect(() => {
        fetch(EMPLOYEES + '/employees-status-widget', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                const newbies = data.newbies;
                const outgoing = data.outgoing;

                setNewbies(newbies);
                setAreThereAnyNewbies(newbies.length > 0);

                setOutgoingEmployees(outgoing);
                setAreThereAnyOutgoingEmployees(outgoing.length > 0);
            });
    }, []);

    return (
        <>
            <NewbiesWidget areThereAnyNewbies={areThereAnyNewbies} newbies={newbies} />
            <OutgoingEmployeesWidget areThereAnyOutgoingEmployees={areThereAnyOutgoingEmployees} outgoingEmployees={outgoingEmployees} />
        </>
    );
}

export default EmployeesStatusWidget;