import React, { useEffect, useState } from 'react';
import {EmployeeFromApi} from "../../../../../../shared/Employee/data/EmployeeFromApi";
import {EMPLOYEES} from "../../../../../../utils/constants/api";
import Telegram from "../../../../../../assets/images/socialNetworkIcons/Telegram.svg";
import Gmail from "../../../../../../assets/images/socialNetworkIcons/Gmail.svg";
import {Tooltip as ReactTooltip} from "react-tooltip";


type ProfileContact = {
    employee: EmployeeFromApi,
}

function ProfileContact(props: ProfileContact) {
    const [telegram, setTelegram] = useState<string | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const hasHierarchyChainClass = document.querySelector('.hierarchy-chain') !== null;
    const hasTeamClass = document.querySelector('.team') !== null;
    const [copiedLinks, setCopiedLinks] = useState<Record<string, boolean>>({});

    function copyToClipboard(text: string) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }

    useEffect(() => {
        getTelegramInfo();
    }, [props.employee]);

    const handleCopyClick = (field: string, value: string) => {
        copyToClipboard(value);
        setCopiedLinks({ ...Object.fromEntries(Object.keys(copiedLinks).map(key => [key, false])), [field]: true });
        setTimeout(() => {
            setCopiedLinks({});
        }, 5000);
    };

    function getTelegramInfo() {
        if(props.employee) {
            fetch(EMPLOYEES + '/employee/' + props.employee.id + '/contact-info/social/Telegram', {
                method: 'GET',
                headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
            })
                .then(response => response.json())
                .then((contactInfo: string | null) => {
                    setTelegram(contactInfo);
                });
        }
    }

    return (
        <div className={(hasHierarchyChainClass || hasTeamClass ? 'border-line' : '') + ' contact-me'}>
            <h4>Contact me:</h4>
            <div className={'link'}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
            >
                <img
                    onClick={() => {
                        if (telegram) {
                            window.open('https://t.me/' + telegram, '_blank');
                        } else {
                            window.open(`mailto:${props.employee?.email}`, '_blank');
                        }
                    }}
                    src={telegram ? Telegram : Gmail}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.src = '';
                    }}
                />
                <span onClick={() => {
                    if (telegram) {
                        window.open('https://t.me/' + telegram, '_blank');
                    } else {
                        window.open(`mailto:${props.employee?.email}`, '_blank');
                    }
                }}>{telegram ? telegram : props.employee?.email}</span>
                {isHovered && (
                    <div className={'copy-block'}>
                        <>
                            <i
                                id={"contact-info"}
                                onClick={() => handleCopyClick('contact-info', telegram ? `https://t.me/${telegram}` : props.employee ? props.employee?.email : '')}
                                className="material-icons edit-icon copy-to-clipboard"
                            >
                                content_copy
                            </i>
                            <ReactTooltip
                                className={'std-tooltip'}
                                anchorId={'contact-info'}
                                place="bottom"
                                content={copiedLinks['contact-info'] ? "Copied" : "Copy"}
                                noArrow={true}
                            />
                        </>
                        {telegram &&
                            <>
                            <i
                                id={'open-new-window'}
                                onClick={() => {
                                    window.open(`https://t.me/${telegram}`, '_blank')
                                }}
                                className={'material-icons edit-icon open-in-window'}
                            >
                                open_in_new
                            </i>
                                <ReactTooltip
                                    className={'std-tooltip'}
                                    anchorId={'open-new-window'}
                                    place="bottom"
                                    content={'Open in new window'}
                                    noArrow={true}
                                />
                            </>
                        }
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProfileContact;