import React, {useEffect, useState} from 'react';
import BaseModal from '../BaseModal/BaseModal';
import Radio from "../../../../Component/Input/Radio/Radio";
import TextFieldSimple from "../../../../Component/Input/TextFieldSimple/TextFieldSimple";
import SelectInputSimple from "../../../../Component/Input/SelectInputSimple/SelectInputSimple";
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {TIME_OFF_POLICIES} from "../../../../../utils/constants/api";
import {daysOfMonthOptions, monthsOptions} from "../../../../../shared/TimeOffPolicy/constants/AccrualSchedule";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";


type AccrualOptionsModal = {
    opened: boolean,
    modalTitle: string,
    okText: string,
    hiddenBlockByType: boolean,
    employeesReceiveAccruedTimeAt: string,
    firstAccrualShouldBe: string,
    recalculateTheRemainingDays: string,
    hasCarryOverAfterTheCarryOverDate: string,
    carryOverDays: number,
    carryOverDaysAreUnlimited: string,
    carryOverDateType: string,
    carryOverDay: number | null,
    carryOverMonth: number | null,
    hasWaitingPeriodBeforeAccruingTimeStarts: string,
    waitingPeriodDays: number,
    untilEmploymentDate: string,
    capOnTheBalanceAnEmployeeCanAccrue: string,
    maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
    onOk: (
        employeesReceiveAccruedTimeAt: string,
        firstAccrualShouldBe: string,
        recalculateTheRemainingDays: string,
        hasCarryOverAfterTheCarryOverDate: string,
        carryOverDays: number,
        carryOverDaysAreUnlimited: string,
        carryOverDateType: string,
        carryOverDay: number | null,
        carryOverMonth: number | null,
        hasWaitingPeriodBeforeAccruingTimeStarts: string,
        waitingPeriodDays: number,
        untilEmploymentDate: string,
        capOnTheBalanceAnEmployeeCanAccrue: string,
        maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
        validate: (errors: ValidationError[]) => void
    ) => void,
    style : string,
};

function AccrualOptionsModal(props: AccrualOptionsModal) {

    const [employeesReceiveAccruedTimeAt, setEmployeesReceiveAccruedTimeAt] = useState(props.employeesReceiveAccruedTimeAt);
    const [firstAccrualShouldBe, setFirstAccrualShouldBe] = useState(props.firstAccrualShouldBe);
    const [recalculateTheRemainingDays, setRecalculateTheRemainingDays] = useState(props.recalculateTheRemainingDays);
    const [hasCarryOverAfterTheCarryOverDate, setHasCarryOverAfterTheCarryOverDate] = useState(props.hasCarryOverAfterTheCarryOverDate);
    const [carryOverDays, setCarryOverDays] = useState(props.carryOverDays);
    const [carryOverDaysAreUnlimited, setCarryOverDaysAreUnlimited] = useState(props.carryOverDaysAreUnlimited);
    const [carryOverDateType, setCarryOverDateType] = useState(props.carryOverDateType);
    const [carryOverDay, setCarryOverDay] = useState(props.carryOverDay);
    const [carryOverMonth, setCarryOverMonth] = useState(props.carryOverMonth);
    const [hasWaitingPeriodBeforeAccruingTimeStarts, setHasWaitingPeriodBeforeAccruingTimeStarts] = useState(props.hasWaitingPeriodBeforeAccruingTimeStarts);
    const [untilEmploymentDate, setUntilEmploymentDate] = useState(props.untilEmploymentDate);
    const [waitingPeriodDays, setWaitingPeriodDays] = useState(props.waitingPeriodDays);
    const [capOnTheBalanceAnEmployeeCanAccrue, setCapOnTheBalanceAnEmployeeCanAccrue] = useState(props.capOnTheBalanceAnEmployeeCanAccrue);
    const [maxNumberOfDaysForTheCapEmployeeCanAccrue, setMaxNumberOfDaysForTheCapEmployeeCanAccrue] = useState(props.maxNumberOfDaysForTheCapEmployeeCanAccrue);

    const [carryOverDayOption, setCarryOverDayOption] = useState<SelectValue | null>(null);
    const [carryOverMonthOption, setCarryOverMonthOption] = useState<SelectValue | null>(null);
    const [daysOfMonthLocalOptions, setDaysOfMonthLocalOptions] = useState<SelectValue[]>([]);

    const [carryOverDaysError, setCarryOverDaysError] = useState('');
    const [waitingPeriodDaysError, setWaitingPeriodDaysError] = useState('');
    const [maxNumberOfDaysForTheCapEmployeeCanAccrueError, setMaxNumberOfDaysForTheCapEmployeeCanAccrueError] = useState('');
    const [carryOverDayError, setCarryOverDayError] = useState('');
    const [carryOverMonthError, setCarryOverMonthError] = useState('');

    useEffect (() => {
        if (props.carryOverMonth) {
            handleMonthChange({value: props.carryOverMonth ? props.carryOverMonth.toString().padStart(2, '0') : '01', label: ''})
        }
    }, []);

    useEffect(() => {
        if (props.hiddenBlockByType) {
            setFirstAccrualShouldBe('fullAmount')
        }
    }, [props.hiddenBlockByType])

    useEffect(() => {
        if (daysOfMonthLocalOptions.some(dayOption => dayOption.value === carryOverDayOption?.value)) {
            setCarryOverDayOption(carryOverDayOption || null);
            setCarryOverDay(carryOverDayOption ? parseInt(carryOverDayOption.value) : null);

        } else {
            const lastDayOption = daysOfMonthLocalOptions[daysOfMonthLocalOptions.length - 1];
            setCarryOverDayOption(lastDayOption || null);
            setCarryOverDay(lastDayOption ? parseInt(lastDayOption.value) : null);


        }
    }, [daysOfMonthLocalOptions])

    useEffect(() => {
        if (carryOverDay) {
            const dayOfMonthOption = daysOfMonthOptions
                .find(option => option.value === changeCarryDayOrMonthToString(carryOverDay));
            if (dayOfMonthOption) {
                setCarryOverDayOption(dayOfMonthOption);
            }
        }
    }, [carryOverDay]);

    useEffect(() => {
        if (carryOverMonth) {
            const monthOption = monthsOptions
                .find(option => option.value === changeCarryDayOrMonthToString(carryOverMonth));
            if (monthOption) {
                setCarryOverMonthOption(monthOption);
            }
        }
    }, [carryOverMonth]);

    function changeCarryDayOrMonthToString(carryOverDay: number | null) {
        if (carryOverDay && carryOverDay < 10) {
            return '0' + carryOverDay.toString();
        } else {
            return carryOverDay?.toString();
        }
    }

    function handleEmployeesReceiveAccruedTimeAtChange(newEmployeesReceiveAccruedTimeAt: string) {
        setEmployeesReceiveAccruedTimeAt(newEmployeesReceiveAccruedTimeAt);
    }

    function handleFirstAccrualShouldBeChange(newFirstAccrualShouldBe: string) {
        setFirstAccrualShouldBe(newFirstAccrualShouldBe);
    }

    function handleRecalculateTheRemainingDaysChange(newRecalculateTheRemainingDays: string) {
        setRecalculateTheRemainingDays(newRecalculateTheRemainingDays);
    }

    function handleHasCarryOverAfterTheCarryOverDateChange(newHasCarryOverAfterTheCarryOverDate: string) {
        setHasCarryOverAfterTheCarryOverDate(newHasCarryOverAfterTheCarryOverDate);
        if (newHasCarryOverAfterTheCarryOverDate === 'false') {
            setCarryOverDays(0);
            setCarryOverDaysAreUnlimited('false');
        }
    }

    function handleCarryOverDaysChange(newCarryOverDays: number) {
        if (isNaN(newCarryOverDays)) {
            setCarryOverDays(0);
        } else {
            setCarryOverDays(newCarryOverDays);
            setCarryOverDaysError('');
        }
    }

    function handleCarryOverDaysAreUnlimitedChange(newCarryOverDaysAreUnlimited: string) {
        setCarryOverDaysAreUnlimited(newCarryOverDaysAreUnlimited);
        if (newCarryOverDaysAreUnlimited === 'true') {
            setCarryOverDays(0);
        }
    }

    function handleCarryOverDateTypeChange(newCarryOverDateType: string) {
        setCarryOverDateType(newCarryOverDateType);
    }

    function handleHasWaitingPeriodBeforeAccruingTimeStartsChange(newHasWaitingPeriodBeforeAccruingTimeStarts: string) {
        setHasWaitingPeriodBeforeAccruingTimeStarts(newHasWaitingPeriodBeforeAccruingTimeStarts);
        if (newHasWaitingPeriodBeforeAccruingTimeStarts === 'false') {
            setWaitingPeriodDays(0);
            setUntilEmploymentDate('false');
        }
    }

    function handleUntilEmploymentDateChange(newUntilEmploymentDate: string) {
        setUntilEmploymentDate(newUntilEmploymentDate);
        if (newUntilEmploymentDate === 'true') {
            setWaitingPeriodDays(0);
        }
    }

    function handleWaitingPeriodDaysChange(newWaitingPeriodDays: number) {
        if (isNaN(newWaitingPeriodDays)) {
            setWaitingPeriodDays(0);
        } else {
            setWaitingPeriodDays(newWaitingPeriodDays);
            setWaitingPeriodDaysError('');
        }
    }

    function handleCapOnTheBalanceAnEmployeeCanAccrueChange(newCapOnTheBalanceAnEmployeeCanAccrue: string) {
        setCapOnTheBalanceAnEmployeeCanAccrue(newCapOnTheBalanceAnEmployeeCanAccrue);
        if (newCapOnTheBalanceAnEmployeeCanAccrue === 'false') {
            setMaxNumberOfDaysForTheCapEmployeeCanAccrue(0);
        }
    }

    function handleMaxNumberOfDaysForTheCapEmployeeCanAccrueChange(newMaxNumberOfDaysForTheCapEmployeeCanAccrue: number) {
        if (isNaN(newMaxNumberOfDaysForTheCapEmployeeCanAccrue)) {
            setMaxNumberOfDaysForTheCapEmployeeCanAccrue(0);
        } else {
            const cappedValue = Math.min(newMaxNumberOfDaysForTheCapEmployeeCanAccrue, 365);
            setMaxNumberOfDaysForTheCapEmployeeCanAccrue(cappedValue);
            setMaxNumberOfDaysForTheCapEmployeeCanAccrueError('');
        }
    }

    function clearAllErrors(): void {
        setCarryOverDaysError('');
        setWaitingPeriodDaysError('');
        setMaxNumberOfDaysForTheCapEmployeeCanAccrueError('');
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'carryOverDays') {
                setCarryOverDaysError(error.message);
            } else if (error.property === 'waitingPeriodDays') {
                setWaitingPeriodDaysError(error.message);
            } else if (error.property === 'MaxNumberOfDaysForTheCapEmployeeCanAccrue') {
                setMaxNumberOfDaysForTheCapEmployeeCanAccrueError(error.message);
            }
        });
    }

    function apiValidate(): void {
        const accrualOptionsData = {
            employeesReceiveAccruedTimeAt: employeesReceiveAccruedTimeAt,
            firstAccrualShouldBe: firstAccrualShouldBe,
            recalculateTheRemainingDays: recalculateTheRemainingDays === 'true',
            hasCarryOverAfterTheCarryOverDate: hasCarryOverAfterTheCarryOverDate === 'true',
            carryOverDays: carryOverDays,
            carryOverDaysAreUnlimited: carryOverDaysAreUnlimited === 'true',
            carryOverDateType: carryOverDateType,
            carryOverDay: carryOverDay,
            carryOverMonth: carryOverMonth,
            hasWaitingPeriodBeforeAccruingTimeStarts: hasWaitingPeriodBeforeAccruingTimeStarts === 'true',
            untilEmploymentDate: untilEmploymentDate === 'true',
            waitingPeriodDays: waitingPeriodDays,
            capOnTheBalanceAnEmployeeCanAccrue: capOnTheBalanceAnEmployeeCanAccrue === 'true',
            maxNumberOfDaysForTheCapEmployeeCanAccrue: maxNumberOfDaysForTheCapEmployeeCanAccrue
        };
        fetch (TIME_OFF_POLICIES + '/validation/accrual-options', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accrualOptionsData)
        }).then(response => {
            if (response.ok) {
                props.onOk(
                    employeesReceiveAccruedTimeAt,
                    firstAccrualShouldBe,
                    recalculateTheRemainingDays,
                    hasCarryOverAfterTheCarryOverDate,
                    carryOverDays,
                    carryOverDaysAreUnlimited,
                    carryOverDateType,
                    carryOverDay,
                    carryOverMonth,
                    hasWaitingPeriodBeforeAccruingTimeStarts,
                    waitingPeriodDays,
                    untilEmploymentDate,
                    capOnTheBalanceAnEmployeeCanAccrue,
                    maxNumberOfDaysForTheCapEmployeeCanAccrue,
                    validate
                );
            } else {
                response.json().then((errors: ValidationError[]) => {
                    validate(errors);
                });
            }
        });
    }

    function handleDayOfMonthChange(newDayOfMonth: SelectValue) {
        if (newDayOfMonth) {
            const dayOfMonth = parseInt(newDayOfMonth.value);
            setCarryOverDay(dayOfMonth);
            setCarryOverDayError('');
        } else {
            setCarryOverDay(null);
        }
    }

    function handleMonthChange(newMonth: SelectValue): void {
        if (newMonth) {
            const month = parseInt(newMonth.value);
            setCarryOverMonth(month);
            setCarryOverMonthError('');

            let daysOfMonthOptionsForMonth: SelectValue[];

            switch (newMonth.value) {
                case '04':
                case '06':
                case '09':
                case '11':
                    daysOfMonthOptionsForMonth = daysOfMonthOptions.slice(0, 30);
                    break;
                case '02':
                    daysOfMonthOptionsForMonth = daysOfMonthOptions.slice(0, 28);
                    break;
                default:
                    daysOfMonthOptionsForMonth = daysOfMonthOptions;
            }

            setDaysOfMonthLocalOptions(daysOfMonthOptionsForMonth);
        } else {
            setCarryOverMonth(null);
        }
    }

    function handleOk(): void {
        if (capOnTheBalanceAnEmployeeCanAccrue === 'true' && maxNumberOfDaysForTheCapEmployeeCanAccrue <= 0) {
            setMaxNumberOfDaysForTheCapEmployeeCanAccrueError('The number of days that the capped employee can accrue may be greater than 0.');
            return;
        }
        if (hasWaitingPeriodBeforeAccruingTimeStarts === 'true' && untilEmploymentDate === 'false' && waitingPeriodDays <= 0) {
            setWaitingPeriodDaysError('The number of days after hire date may be greater than 0.');
            return;
        }
        apiValidate();
        clearAllErrors();
    }

    return (
        <BaseModal
            opened={props.opened}
            modalTitle={props.modalTitle}
            okText={props.okText}
            onOk={handleOk}
            hasCancel={false}
            style={props.style}
        >
            <div className='wide-page-input-container'>
                <span className='subtitle-black-dark'>Employees receive accrued time…</span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'employeesReceiveAccruedTimeAt'}
                        value={employeesReceiveAccruedTimeAt}
                        onChange={newEmployeesReceiveAccruedTimeAt =>
                            handleEmployeesReceiveAccruedTimeAtChange(newEmployeesReceiveAccruedTimeAt)}
                        radios={[
                            {label: 'At the start of the accrual period', value: 'atTheStart'},
                            {label: 'At the end of the accrual period', value: 'atTheEnd'}
                        ]}
                    />
                </div>
                {!props.hiddenBlockByType && (
                    <>
                        <div className='wide-page-modal-line-short'></div>
                        <span className='subtitle-black-dark'>Employees’ first accrual should be…</span>
                        <div className="only-input-container">
                            <Radio
                                label=''
                                required={false}
                                name={'firstAccrualShouldBe'}
                                value={firstAccrualShouldBe}
                                onChange={newFirstAccrualShouldBe =>
                                    handleFirstAccrualShouldBeChange(newFirstAccrualShouldBe)}
                                radios={[
                                    {label: 'Prorated, based on the accrual period', value: 'prorated'},
                                    {label: 'The full amount for the accrual period', value: 'fullAmount'}
                                ]}
                            />
                        </div>
                        <span className='subtitle-black-dark'>
                            When employee is terminated, recalculate the remaining days in the current period?
                        </span>
                        <div className="only-input-container">
                            <Radio
                                label=''
                                required={false}
                                name={'recalculateTheRemainingDays'}
                                value={recalculateTheRemainingDays}
                                onChange={newRecalculateTheRemainingDays =>
                                    handleRecalculateTheRemainingDaysChange(newRecalculateTheRemainingDays)}
                                radios={[
                                    {label: 'Yes', value: 'true'},
                                    {label: 'No', value: 'false'}
                                ]}
                            />
                        </div>
                </>
                )}
            </div>

            <div className='wide-page-input-container height-limit'>
                <span className='subtitle-black-dark'>
                    Can unused time off be carried over after the carryover date?
                </span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'hasCarryOverAfterTheCarryOverDate'}
                        value={hasCarryOverAfterTheCarryOverDate}
                        onChange={newHasCarryOverAfterTheCarryOverDate =>
                            handleHasCarryOverAfterTheCarryOverDateChange(newHasCarryOverAfterTheCarryOverDate)}
                        radios={[
                            {label: 'No, it’s “use it or lose it”', value: 'false'},
                            {label: 'Yes, time can be carried over', value: 'true'}
                        ]}
                    />
                    {hasCarryOverAfterTheCarryOverDate === 'true' && ( <div className='wide-page-modal-line-short'></div> )}
                </div>
                {hasCarryOverAfterTheCarryOverDate === 'true' && (
                    <>
                        <span className='subtitle-black-dark'>How many days can be carried over?</span>
                        <div className="only-input-container">
                            <Radio
                                label=''
                                required={false}
                                name={'carryOverDaysAreUnlimited'}
                                value={carryOverDaysAreUnlimited}
                                onChange={newCarryOverDaysAreUnlimited =>
                                    handleCarryOverDaysAreUnlimitedChange(newCarryOverDaysAreUnlimited)}
                                radios={[
                                    {label: '', value: 'false'},
                                    {label: 'Unlimited', value: 'true'}
                                ]}
                            />
                            <div className='manually-adjusted-element'>
                                <TextFieldSimple
                                    placeholder={''}
                                    required={false}
                                    value={carryOverDays}
                                    error={carryOverDaysError}
                                    maxLength={3}
                                    onChange={newCarryOverDays => handleCarryOverDaysChange(parseInt(newCarryOverDays))}
                                />
                                <span>days</span>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className='wide-page-input-container'>
                <span className='subtitle-black-dark'>What should the carryover date be?</span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'carryOverDateType'}
                        value={carryOverDateType}
                        onChange={newCarryOverDateType => {
                            handleCarryOverDateTypeChange(newCarryOverDateType);
                            if (newCarryOverDateType === 'fixedDate') {
                                setCarryOverDayOption(daysOfMonthOptions[0]);
                                setCarryOverMonthOption(monthsOptions[0]);
                                setCarryOverMonth(parseInt(monthsOptions[0].value));
                                setCarryOverDay(parseInt(daysOfMonthOptions[0].value));
                            } else {
                                setCarryOverDayOption(null)
                                setCarryOverMonthOption(null)
                                setCarryOverDay(null);
                            }
                        }}
                        radios={[
                            {label: 'Hire date', value: 'hireDate'},
                            {label: 'Employee date', value: 'employeeDate'},
                            {label: 'Fixed date', value: 'fixedDate'},
                        ]}
                    />
                </div>

                {carryOverDateType === 'fixedDate' && (
                    <>
                        <div className='wide-page-modal-line-short'></div>
                        <div className='wide-page-input-group'>
                            <SelectInputSimple
                                isSearchable={false}
                                required={false}
                                isMulti={false}
                                value={carryOverDayOption}
                                options={daysOfMonthLocalOptions}
                                error={carryOverDayError}
                                onChange={(newDayOfMonth) =>
                                    handleDayOfMonthChange(newDayOfMonth)
                                }
                            />
                            <span>of</span>
                            <SelectInputSimple
                                isSearchable={false}
                                required={false}
                                isMulti={false}
                                value={carryOverMonthOption}
                                options={monthsOptions}
                                error={carryOverMonthError}
                                onChange={(newMonth) =>
                                    handleMonthChange(newMonth)
                                }
                            />
                        </div>
                    </>
                )}
            </div>

            <div className='wide-page-input-container height-limit'>
                <span className='subtitle-black-dark'>
                    Is there a waiting period before new employees begin accruing time?
                </span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'hasWaitingPeriodBeforeAccruingTimeStarts'}
                        value={hasWaitingPeriodBeforeAccruingTimeStarts}
                        onChange={newHasWaitingPeriodBeforeAccruingTimeStarts => {
                            setWaitingPeriodDaysError('')
                            handleHasWaitingPeriodBeforeAccruingTimeStartsChange(newHasWaitingPeriodBeforeAccruingTimeStarts)}
                        }
                        radios={[
                            {label: 'No, accrue immediately on hire date', value: 'false'},
                            {label: 'Yes, there is a waiting period', value: 'true'}
                        ]}
                    />
                    {hasWaitingPeriodBeforeAccruingTimeStarts === 'true' && ( <div className='wide-page-modal-line-short'></div> )}
                </div>
                {hasWaitingPeriodBeforeAccruingTimeStarts === 'true' && (
                    <>
                        <span className='subtitle-black-dark'>A waiting period is:</span>
                        <div className="only-input-container">
                            <Radio
                                label=''
                                required={false}
                                name={'untilEmploymentDate'}
                                value={untilEmploymentDate}
                                onChange={newUntilEmploymentDate => {
                                    if (newUntilEmploymentDate === 'false') {
                                        setWaitingPeriodDaysError('')
                                    }
                                    handleUntilEmploymentDateChange(newUntilEmploymentDate)}
                                }
                                radios={[
                                    {label: '', value: 'false'},
                                    {label: 'Until employment date', value: 'true'}
                                ]}
                            />
                            <div className='manually-adjusted-element'>
                                <TextFieldSimple
                                    placeholder={''}
                                    required={false}
                                    value={waitingPeriodDays}
                                    error={waitingPeriodDaysError}
                                    maxLength={3}
                                    onChange={newWaitingPeriodDays => {
                                        setWaitingPeriodDaysError('');
                                        handleWaitingPeriodDaysChange(parseInt(newWaitingPeriodDays))}
                                    }
                                />
                                <span>days after hire date</span>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className='wide-page-input-container height-limit'>
                <span className='subtitle-black-dark'>
                    Is there a cap on the balance an employee can accrue?
                </span>
                <div className="only-input-container">
                    <Radio
                        label=''
                        required={false}
                        name={'capOnTheBalanceAnEmployeeCanAccrue'}
                        value={capOnTheBalanceAnEmployeeCanAccrue}
                        onChange={newCapOnTheBalanceAnEmployeeCanAccrue =>
                            handleCapOnTheBalanceAnEmployeeCanAccrueChange(newCapOnTheBalanceAnEmployeeCanAccrue)}
                        radios={[
                            {label: 'No, they will continue to accrue time, regardless of balance', value: 'false'},
                            {label: 'Yes, they stop accruing at a certain balance', value: 'true'}
                        ]}
                    />
                    {capOnTheBalanceAnEmployeeCanAccrue === 'true' && ( <div className='wide-page-modal-line-short'></div> )}
                </div>
                {capOnTheBalanceAnEmployeeCanAccrue === 'true' && (
                    <>
                        <span className='subtitle-black-dark'>The maximum number of days:</span>
                        <div className="wide-page-input-group">
                            <TextFieldSimple
                                placeholder={''}
                                required={false}
                                value={maxNumberOfDaysForTheCapEmployeeCanAccrue}
                                error={maxNumberOfDaysForTheCapEmployeeCanAccrueError}
                                maxLength={3}
                                onChange={newMaxNumberOfDaysForTheCapEmployeeCanAccrue => {
                                    setMaxNumberOfDaysForTheCapEmployeeCanAccrueError('');
                                    handleMaxNumberOfDaysForTheCapEmployeeCanAccrueChange(parseInt(newMaxNumberOfDaysForTheCapEmployeeCanAccrue))}
                                }
                            />
                            <span>days</span>
                        </div>
                    </>
                )}
            </div>
        </BaseModal>
    );
}

export default AccrualOptionsModal;