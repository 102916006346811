import React from 'react';
import {TIME_OFF_POLICIES} from '../../../../../utils/constants/api';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';
import { TimeOffPolicyFromApi } from '../../../../../shared/TimeOffPolicy/data/TimeOffPolicyFromApi';

type DeleteTimeOffPolicy = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    timeOffPolicy: TimeOffPolicyFromApi
};

function DeleteTimeOffPolicy(props: DeleteTimeOffPolicy) {
    function deleteTitle(): void {
        fetch(TIME_OFF_POLICIES + '/' + props.timeOffPolicy.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Time Off Policy'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.timeOffPolicy.value.generalInfo.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteTitle }
        />
    )
}

export default DeleteTimeOffPolicy;