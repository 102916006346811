import React from 'react';
import { TYPES } from '../../../../../../utils/constants/api';
import  {TypeFromApi } from '../Types';
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal';

type DeleteType = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    type: TypeFromApi
};

function DeleteType(props: DeleteType) {
    function deleteType(): void {
        fetch(TYPES + '/' + props.type.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Employee Type'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.type.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteType }
        />
    )
}

export default DeleteType;