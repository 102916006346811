import React, { useState } from 'react';
import './Employees.scss';
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../../Page/Layer/SubMenu/SubMenu';
import FormerEmployees from './Former/FormerEmployees';
import CurrentEmployees from './Current/CurrentEmployees';
import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';
import DeletedEmployees from "./Deleted/DeletedEmployees";

type Employees = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Employees(props: Employees) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleLiClick = (index: React.SetStateAction<number>) => {
        setActiveIndex(index);
        getContentComponent();
    };

    const getActiveClass = (index: number) => {
        return index === activeIndex ? 'active' : '';
    };

    const getContentComponent = () => {
        switch (activeIndex) {
            case 0:
                return <CurrentEmployees authEmployee={props.authEmployee} />;
            case 1:
                return <FormerEmployees authEmployee={props.authEmployee} />;
            case 2:
                return <DeletedEmployees authEmployee={props.authEmployee} />;
            default:
                return null;
        }
    };
    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block add-employee d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Employees</h3>
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <ul className={'employee-menu'}>
                                    <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
                                        Current Employees
                                    </li>
                                    <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
                                        Former Employees
                                    </li>
                                    <li className={getActiveClass(2)} onClick={() => handleLiClick(2)}>
                                        Deleted Employees
                                    </li>
                                </ul>
                                <div className={'content'}>{getContentComponent()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employees;