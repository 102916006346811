import React, {useEffect, useState} from 'react';
import Select from 'react-select';

import { EmployeeFromApi } from '../../../../../../../shared/Employee/data/EmployeeFromApi';
import {SelectValue} from '../../../../../../../shared/Common/data/SelectValue';
import {ContactInfoType} from '../../../../../../../shared/Employee/data/ContactInfoType';
import {CountryFromApi} from "../../../../../../../shared/Location/data/CountryFromApi";
import {CityFromApi} from "../../../../../../../shared/Location/data/CityFromApi";
import {ValidationError} from '../../../../../../../shared/Common/Error/ValidationError';
import {ContactTypeFromApi} from "../../../../../../../shared/Employee/data/ContactTypeFromApi";
import {AuthEmployee} from "../../../../../../../shared/Security/data/AuthEmployee";

import {EMPLOYEES, COUNTRIES} from "../../../../../../../utils/constants/api";
import {VALIDATION_ERROR} from '../../../../../../../utils/constants/errors';
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../../shared/Security/constants/AccessLevels";

import TextField, {Type} from '../../../../../../Component/Input/TextField/TextField';
import Button, {Size, Type as ButtonType} from "../../../../../../Component/Button/Button";
import CreateSocial from "./Modal/CreateSocial";

import {formatPhoneNumber} from '../../../../../../../utils/helpers/EmployeeProfile/formatPhoneNumber';
import {fieldAutoResizer} from "../../../../../../../utils/helpers/EmployeeProfile/fieldAutoResizer";
import {getRegexForSocial} from "../../../../../../../utils/helpers/EmployeeProfile/getRegexForSocial";

import Telegram from './../../../../../../../assets/images/socialNetworkIcons/Telegram.svg';
import Facebook from './../../../../../../../assets/images/socialNetworkIcons/Facebook.svg';
import Instagram from './../../../../../../../assets/images/socialNetworkIcons/Instagram.svg';
import TikTok from './../../../../../../../assets/images/socialNetworkIcons/TikTok.svg';
import LinkedIn from './../../../../../../../assets/images/socialNetworkIcons/LinkedIn.svg';
import Reddit from './../../../../../../../assets/images/socialNetworkIcons/Reddit.svg';
import Calendly from './../../../../../../../assets/images/socialNetworkIcons/Calendly.svg';
import Signal from "../../../../../../../assets/images/socialNetworkIcons/Signal.svg";
import Slack from "../../../../../../../assets/images/socialNetworkIcons/Slack.svg";
import Threads from "../../../../../../../assets/images/socialNetworkIcons/Threads.svg";
import Threema from "../../../../../../../assets/images/socialNetworkIcons/Threema.svg";
import WhatsApp from "../../../../../../../assets/images/socialNetworkIcons/WhatsApp.svg";
import YouTube from "../../../../../../../assets/images/socialNetworkIcons/YouTube.svg";
import Twitter from "../../../../../../../assets/images/socialNetworkIcons/Twitter.svg";
import Viber from "../../../../../../../assets/images/socialNetworkIcons/Viber.svg";
import {Tooltip as ReactTooltip} from "react-tooltip";

type ContactInfo = {
    employee: EmployeeFromApi,
    authEmployee: AuthEmployee,
    isMyProfile: boolean | null,
    onDataUpdate: () => void,
}

type SocialNetworkIcons = {
    [key: string]: string;
    Telegram: string;
    Facebook: string;
    Instagram: string;
    TikTok: string;
    LinkedIn: string;
    Reddit: string;
    Calendly: string;
    Signal: string;
    Slack: string;
    Threads: string;
    Threema: string;
    WhatsApp: string;
    YouTube: string;
}

function ContactInfo(props: ContactInfo) {
    const [contactInfos, setContactInfos] = useState<ContactTypeFromApi[] | null>(null);
    const [contactInfosForValidate, setContactInfosForValidate] = useState<ContactTypeFromApi[] | null>(null);
    const [country, setCountry] = useState<SelectValue | null>(null);
    const [emailMain, setEmailMain] = useState<string>(props.employee.email);
    const [emailMainError, setEmailMainError] = useState<string | null>(null);
    const [city, setCity] = useState<SelectValue | null>(null);
    const [countryOptions, setCountryOptions] = useState<SelectValue[]>([]);
    const [cityOptions, setCityOptions] = useState<SelectValue[]>([]);
    const [phones, setPhones] = useState<ContactInfoType[]>([]);
    const [emails, setEmails] = useState<ContactInfoType[]>([]);
    const [socials, setSocials] = useState<ContactInfoType[]>([]);
    const [phoneErrors, setPhoneErrors] = useState<Record<string, string | null>>({
        phoneError1: null,
        phoneError2: null,
        phoneError3: null,
        phoneError4: null,
        phoneError5: null,
    });
    const [emailErrors, setEmailErrors] = useState<Record<string, string | null>>({
        emailError1: null,
        emailError2: null,
        emailError3: null,
        emailError4: null,
        emailError5: null,
    });
    const [socialErrors, setSocialErrors] = useState<Record<string, string | null>>({
        TelegramError: null,
        FacebookError: null,
        InstagramError: null,
        TikTokError: null,
        LinkedInError: null,
        RedditError: null,
        CalendlyError: null,
        SignalError: null,
        SlackError: null,
        ThreadsError: null,
        ThreemaError: null,
        WhatsAppError: null,
        YouTubeError: null,
        TwitterError: null,
        ViberError: null,
    });

    const SocialNetworkIcons: SocialNetworkIcons = {
        Telegram: Telegram,
        Facebook: Facebook,
        Instagram: Instagram,
        TikTok: TikTok,
        LinkedIn: LinkedIn,
        Reddit: Reddit,
        Calendly: Calendly,
        Signal: Signal,
        Slack: Slack,
        Threads: Threads,
        Threema: Threema,
        WhatsApp: WhatsApp,
        YouTube: YouTube,
        Twitter: Twitter,
        Viber: Viber,
    };

    const [createSocialModal, setCreateSocialModal] = useState<boolean>(false);
    const [allSocialUsed, setAllSocialUsed] = useState<boolean>(false);

    const [isFocused, setIsFocused] = useState(false);
    const [focusedField, setFocusedField] = useState('');

    const socialLinks: any[] = [
        { Telegram: 'https://t.me/' },
        { LinkedIn: 'https://linkedin.com/in/' },
        { Facebook: 'https://www.facebook.com/' },
        { Instagram: 'https://www.instagram.com/' },
        { Calendly: 'https://calendly.com/' },
        { TikTok: 'https://www.tiktok.com/@' },
        { Reddit: 'https://www.reddit.com/user/' },
        { Signal: '' },
        { Slack: '' },
        { Threads: 'https://threads.net/@' },
        { Threema: '' },
        { WhatsApp: '' },
        { YouTube: 'https://www.youtube.com/@' },
        { Twitter: 'https://twitter.com/' },
        { Viber: '' },
        { Other: '' },
    ];

    const socialLinksWithoutUrl: any[] = ['Signal', 'Slack', 'Threema', 'WhatsApp', 'Viber'];

    const [hoveredSocialIndexes, setHoveredSocialIndexes] = useState<number[]>([]);
    const [hoveredPhoneIndexes, setHoveredPhoneIndexes] = useState<number[]>([]);
    const [hoveredEmailIndexes, setHoveredEmailIndexes] = useState<number[]>([]);

    const [focusedPhoneIndexes, setFocusedPhoneIndexes] = useState<number[]>([]);

    const [isHoveredMainEmail, setIsHoveredMainEmail] = useState(false);

    const [copiedLinks, setCopiedLinks] = useState<Record<string, boolean>>({});

    const handleSocialMouseEnter = (index: number) => {
        setHoveredSocialIndexes((prevIndexes) => [...prevIndexes, index]);
    };

    const handleSocialMouseLeave = (index: number) => {
        setHoveredSocialIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    };

    const handlePhoneMouseEnter = (index: number) => {
        setHoveredPhoneIndexes((prevIndexes) => [...prevIndexes, index]);
    };

    const handlePhoneMouseLeave = (index: number) => {
        setHoveredPhoneIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    };

    const handlePhoneFocus = (index: number) => {
        setFocusedPhoneIndexes((prevIndexes) => [...prevIndexes, index]);
    };

    const handlePhoneBlur = (index: number) => {
        setFocusedPhoneIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    };

    const handleEmailMouseEnter = (index: number) => {
        setHoveredEmailIndexes((prevIndexes) => [...prevIndexes, index]);
    };

    const handleEmailMouseLeave = (index: number) => {
        setHoveredEmailIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    };

    const handleCopyClick = (field: string, value: string) => {
        copyToClipboard(value.replace(/\s+/g, ''));
        setCopiedLinks({ ...Object.fromEntries(Object.keys(copiedLinks).map(key => [key, false])), [field]: true });
        setTimeout(() => {
            setCopiedLinks(prevLinks => ({
                ...prevLinks,
                [field]: false
            }));
        }, 5000);
    };

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property.includes('phone')) {
                const field = error.property.slice(-1);
                setPhoneErrors((prevState) => ({
                    ...prevState,
                    [`phoneError${field}`]: error.message,
                }));
            } else if (error.property.includes('email')) {
                const field = error.property.slice(-1);
                setEmailErrors((prevState) => ({
                    ...prevState,
                    [`emailError${field}`]: error.message,
                }));
            } else if (error.property.includes('social')) {
                const social = error.property.lastIndexOf(".");
                const field = error.property.substring(social + 1)
                setSocialErrors((prevState) => ({
                    ...prevState,
                    [`${field}Error`]: error.message,
                }));
            }
            if (error.property === 'employee.email') {
                setEmailMainError(error.message);
            }
        });
    }

    function setAdditionalPersonalInfo(value: any, field: any) {
        if (!value) {
            value = null;
        }
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/' + field + '/' + value, {
            method: 'PUT',
        })
            .then(response => {
                 if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function setContactValue(value: string, field: string, type: string) {
        if (value) {
            let action = 'POST';
            const hasField = contactInfosForValidate?.some(info =>
                info.type === type && info.values.some(contact => contact.field === field && contact.value !== '' && contact.field === field && contact.value !== '+')
            );

            if (hasField) {
                action = 'PUT';
            }

            fetch(EMPLOYEES + '/employee/' + props.employee.id + '/contact-info/' + type + '/' + field + '/' + (value).replace(/\//g, '|'), {
                method: action,
            }).then(response => {
                if (response.ok) {
                    getContactInfosForValidate();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
        } else {
            deleteContactValue(field, type);
        }
        if (field === 'Telegram') {
            props.onDataUpdate();
        }
    }

    function getContactInfosForValidate() {
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/contact-info/', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((contactInfosForValidate: ContactTypeFromApi[]) => {
                setContactInfosForValidate(contactInfosForValidate);
            });
    }

    function getContactInfos() {
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/contact-info/', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((contactInfos: ContactTypeFromApi[]) => {
                setContactInfos(contactInfos);
                setContactInfosForValidate(contactInfos);

                const phoneContacts = contactInfos.filter(contact => contact.type === 'phone');

                const hasFirstPhone = phoneContacts.find(contact => {
                    const firstPhoneValue = contact.values.find(value => value.field === '1');
                    return !!firstPhoneValue;
                });

                if (!hasFirstPhone) {
                    phoneContacts.push({
                        type: 'phone',
                        values: [{
                            field: '1',
                            value: '',
                        }],
                    });
                }

                const phoneValues = phoneContacts.flatMap(contact => contact.values);
                const sortedPhoneValues = phoneValues.sort((a, b) => (a.field === '1' ? -1 : b.field === '1' ? 1 : 0));
                setPhones(sortedPhoneValues);
                const emailContacts = contactInfos.filter(contact => contact.type === 'email');
                setEmails(emailContacts.flatMap(contact => contact.values));
                const socialContacts = contactInfos.filter(contact => contact.type === 'social');
                setSocials(socialContacts.flatMap(contact => contact.values));
            });
    }

    function deleteContactValue(field: string, type: string) {
        if (field) {
            fetch(EMPLOYEES + '/employee/' + props.employee.id + '/contact-info/' + type + '/' + field, {
                method: 'DELETE',
            }).then(response => {
                if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
                getContactInfosForValidate();
            });
        }
    }

    useEffect(() => {
        if (country) {
            getCityOptionsByCountryCode(country.value);
        }
    }, [country]);

    useEffect(() => {
        setCountry(null);
        setCity(null);
        getContactInfos();
        getCountryOptions();
        setEmailMain(props.employee.email)
    }, [props.employee]);

    useEffect(() => {
        fieldAutoResizer('main-mail', emailMain, 25, emailMain ? 25 : 228)
    }, [emailMain])

    useEffect(() => {
        phones.map(phone => {
            fieldAutoResizer('contact-phone-' + phone.field, formatPhoneNumber(phone.value), 25, phone.value ? 25 : 228)
        })
    }, [phones]);

    useEffect(() => {
        emails.map(email => {
            fieldAutoResizer('contact-email-' + email.field, email.value, 25, email.value ? 25 : 228)
        })
    }, [emails]);

    useEffect(() => {
        const socialKeys = Object.keys(socials.map(social => social.field)).sort();
        const linkKeys = Object.keys(socialLinks.map(link => Object.keys(link)[0])).sort();
        if (socialKeys.length === linkKeys.length && socialKeys.every((key, index) => key === linkKeys[index])) {
            setAllSocialUsed(true);
        }
        socials.map(social => {
            fieldAutoResizer('contact-social-' + social.field, social.value, 25, social.value ? 25 : 228)
        })
    }, [socials]);

    function getCountryOptions() {
        fetch(COUNTRIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((countries: CountryFromApi[]) => {
                const options: SelectValue[] = countries.map((country: CountryFromApi) => {
                    if (country.code === props.employee.country) {
                        setCountry({ value: country.code, label: country.name })
                    }
                    return { value: country.code, label: country.name }
                });
                const noneOption = {
                    label: '',
                    value: ''
                };
                const countryOptionsWithNone = [noneOption].concat(options);
                setCountryOptions(countryOptionsWithNone);
            });
    }

    function getCityOptionsByCountryCode(countryCode: string): void {
        fetch(COUNTRIES + '/' + countryCode + '/cities', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((cities: CityFromApi[]) => {
                if(countryCode === 'UA') {
                    cities = cities.map(city => {
                        if(city.name === 'Odessa') {
                            city.name = 'Odesa';
                        }
                        return city;
                    });
                }

                const options: SelectValue[] = cities.map((city: CityFromApi) => {
                    if (city.name === props.employee.city) {
                        setCity({ value: city.name, label: city.name })
                    }
                    return { value: city.name, label: city.name }
                });
                const noneOption = {
                    label: '',
                    value: ''
                };
                const cityOptionsWithNone = [noneOption].concat(options);
                setCityOptions(cityOptionsWithNone);
            });
    }

    function handlePhoneChange(value: string, field: string) {
        const newPhones = [...phones];
        const phoneIndex = newPhones.findIndex(phone => phone.field === field);
        newPhones[phoneIndex].value = value;
        setPhones(newPhones);
    }

    function addPhone() {
        const newPhones = [...phones];
        const existingFields = newPhones.map(phone => parseInt(phone.field));
        const missingFields = [1, 2, 3, 4, 5].filter(field => !existingFields.includes(field));

        if (missingFields.length > 0) {
            const newField = missingFields[0];
            newPhones.push({ field: newField.toString(), value: '' });
            setPhones(newPhones);
            const elementId = 'contact-phone-' + newField.toString();
            setTimeout(() => {
                const element = document.getElementById(elementId) as HTMLInputElement;
                if (element) {
                    handlePhoneFocus(newField)
                    element.focus();
                    element.setSelectionRange(1, 1);
                }
            }, 0);
        }
    }


    function removePhone(field: string) {
        const newPhones = phones.filter(phone => phone.field !== field);
        setPhones(newPhones);
        setContactValue('', field, 'phone');
        getContactInfosForValidate();
    }

    function handleEmailChange(value: string, field: string) {
        const newEmails = [...emails];
        const emailIndex = newEmails.findIndex(email => email.field === field);
        newEmails[emailIndex].value = value;
        setEmails(newEmails);
    }

    function addEmail() {
        const newEmails = [...emails];
        const existingFields = newEmails.map(email => parseInt(email.field));
        const missingFields = [1, 2, 3, 4].filter(field => !existingFields.includes(field));

        if (missingFields.length > 0) {
            const newField = missingFields[0];
            newEmails.push({ field: newField.toString(), value: '' });
            setEmails(newEmails);
            const elementId = 'contact-email-' + newField.toString();
            setTimeout(() => {
                const element = document.getElementById(elementId);
                if (element) {
                    element.focus();
                }
            }, 0);

        }
    }

    function removeEmail(field: string) {
        const newEmails = emails.filter(email => email.field !== field);
        setEmails(newEmails);
        setContactValue('', field, 'email');
        getContactInfosForValidate();
    }

    function removeSocial(field: string) {
        const newSocials = socials.filter(social => social.field !== field);
        setSocials(newSocials);
        setAllSocialUsed(false);
        setContactValue('', field, 'social');
        getContactInfosForValidate();
        if (field === 'Telegram') {
            props.onDataUpdate();
        }
    }

    const handleFocus = (field: string) => {
        setIsFocused(true);
        setFocusedField(field);
    };

    const handleBlur = (value: string, field: string) => {
        const link = socialLinks.find(item => item.hasOwnProperty(field));
        const newValue = value.replace(link[field], '');
        setIsFocused(false);
        setFocusedField('');
        fieldAutoResizer('contact-social-' + field, newValue, 25, newValue ? 25 : 228)
        setContactValue(newValue, field, 'social');
    };

    const handleChange = (value: string, field: string) => {
        const newSocials = [...socials];
        const socialIndex = newSocials.findIndex(social => social.field === field);
        newSocials[socialIndex].value = value;
        setSocials(newSocials);
    };

    function copyToClipboard(text: string) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }

    function setEmail(value: any, field: any) {
        if (!value) {
            value = null;
        }
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/' + field + '/' + value, {
            method: 'PUT',
        })
            .then(response => {
                if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const hasAdminRole = props.authEmployee.roles.includes(ROLE_ADMIN);
    const hasHRRole = props.authEmployee.roles.includes(ROLE_HR);

    function hasEditRole (socialField: string): boolean {
        const isTelegramOrLinkedin = socialField === 'Telegram' || socialField === 'LinkedIn';
        return isTelegramOrLinkedin
            ? !!(hasAdminRole || hasHRRole || props.isMyProfile)
            : !!(hasAdminRole || props.isMyProfile);
    }

    function textWidth(text: string) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let textWidth = 0;
        if (context) {
            context.font = '16.1px sans-serif';
            textWidth = context.measureText(text).width;
        }
        canvas.remove();
        return textWidth;
    }

    return (
        <div className={'contact-info'}>
            <div className={'info contact-info'}>
                <div className={'info-header'}>
                    <div className={'icon'}><i className='material-icons'>devices</i></div>
                    <h3>Contact Info</h3>
                </div>
                <div className={'info-body'}>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Country</p>
                        </div>

                        <div className={'profile-field-value'}>
                            <Select
                                placeholder={(!hasRole && !props.isMyProfile) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'country' }
                                className={`profile-select ${(!hasRole && !props.isMyProfile) ? 'default-pointer' : ''}`}
                                value={ country }
                                options={ countryOptions }
                                isClearable={ false }
                                onChange={ (e: any) => {
                                    if (e.value !== country?.value) {
                                        if (!e.value) {
                                            setCountry(null);
                                        } else {
                                            setCountry(e)
                                            setCity(null)
                                        }
                                        setAdditionalPersonalInfo(e.value, 'country')
                                        setAdditionalPersonalInfo(null, 'city')
                                    }
                                }}
                                isSearchable={ true }
                                isDisabled={ (!hasRole && !props.isMyProfile) || props.employee.isDeleted }
                            />
                        </div>
                    </div>
                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>City</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <Select
                                placeholder={(!hasRole && !props.isMyProfile) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'city' }
                                className={`profile-select ${((!hasRole && !props.isMyProfile) || props.employee.isDeleted) ? 'default-pointer' : ''}`}
                                value={ city }
                                options={ cityOptions }
                                isClearable={ false }
                                onChange={ (e: any) => {
                                    if (!e.value) {
                                        setCity(null);
                                    } else {
                                        setCity(e)
                                    }
                                    setAdditionalPersonalInfo(e.value, 'city')
                                }}
                                isSearchable={ true }
                                isDisabled={ (!hasRole && !props.isMyProfile) || props.employee.isDeleted }
                            />
                        </div>
                    </div>
                    <div className="profile-field phone-field field-pad-zero">
                        <div className="profile-field-title pad">
                            <p>Phone</p>
                        </div>
                        <div className={'container-row'}>
                            {phones.map((phone, index) => {
                                const isHoveredPhone = hoveredPhoneIndexes.includes(index);
                                const isFocusedPhone = focusedPhoneIndexes.includes(index);
                                return (
                                <div
                                    onMouseEnter={() => handlePhoneMouseEnter(index)}
                                    onMouseLeave={() => handlePhoneMouseLeave(index)}
                                    onBlur={() => {
                                        if (phone.value === '' || phone.value === '+') {
                                            removePhone(phone.field);
                                        }
                                    }}
                                    key={phone.field} className={'profile-field-value pad'}>
                                        <TextField
                                            label=""
                                            placeholder="—"
                                            id={`contact-phone-${phone.field}`}
                                            className={`contact-phone-${phone.field} ${((!hasRole && !props.isMyProfile) || props.employee.isDeleted) ? 'default-pointer' : ''}`}
                                            required={false}
                                            value={formatPhoneNumber(phone.value, isFocusedPhone)}
                                            type={Type.Textarea}
                                            horizontal={false}
                                            error={phoneErrors[`phoneError${phone.field}`]}
                                            onChange={(event) => {
                                                phoneErrors[`phoneError${phone.field}`] = null;
                                                const input = document.getElementById(`contact-phone-${phone.field}`) as HTMLInputElement;
                                                const cursorPosition = input.selectionStart;
                                                const formatted = formatPhoneNumber(event, isFocusedPhone);
                                                handlePhoneChange(formatted, phone.field);
                                                setTimeout(() => {
                                                    if (cursorPosition !== null) {
                                                        let cursorFix = 0;
                                                        if (cursorPosition === 0 || ((cursorPosition === 2 || cursorPosition === 5 || cursorPosition === 8 || cursorPosition === 12 || cursorPosition === 15) && cursorPosition === event.length)) {
                                                            cursorFix = 1;
                                                        }
                                                        input.setSelectionRange(cursorPosition + cursorFix, cursorPosition + cursorFix);
                                                    }
                                                }, 0);
                                            }}
                                            onBlur={() => {
                                                if (phone.value !== '' && phone.value !== '+') {
                                                    handlePhoneBlur(index)
                                                    setContactValue(phone.value, phone.field, 'phone')
                                                }
                                            }}
                                            onFocus={(event:string) => {
                                                handlePhoneFocus(index)
                                                if (event === '') {
                                                    phone.value = '+';
                                                }
                                            }}
                                            maxLength={17}
                                            disabled={ (!hasRole && !props.isMyProfile) || props.employee.isDeleted }
                                        />
                                    {isHoveredPhone && <>
                                    {phone.value &&
                                        <>
                                            <i
                                                id={'phone' + phone.field}
                                                onClick={() => handleCopyClick(phone.field, phone.value)}
                                                className={'material-icons edit-icon copy-to-clipboard'}>
                                                content_copy
                                            </i>
                                            <ReactTooltip
                                                className={'std-tooltip'}
                                                anchorId={'phone' + phone.field}
                                                place="bottom"
                                                content={copiedLinks[phone.field] ? "Copied" : "Copy"}
                                                noArrow={true}
                                            />
                                        </>
                                    }
                                    {(hasRole || props.isMyProfile) && phone.field !== '1' ?
                                        <><i onClick={() => {
                                            removePhone(phone.field);
                                            phoneErrors[`phoneError${phone.field}`] = null;
                                        }}
                                           id={'delete-phone' + phone.field}
                                           className='remove-button material-icons edit-icon'>close</i>
                                            <ReactTooltip
                                                className={'std-tooltip'}
                                                anchorId={'delete-phone' + phone.field}
                                                place="bottom"
                                                content={'Delete'}
                                                noArrow={true}
                                            />
                                        </>
                                    : null }
                                    </>}
                                </div>
                            )})}
                            {((phones.length < 5 && (hasRole || props.isMyProfile)) && !props.employee.isDeleted) && (
                                <button onClick={addPhone} className="add-button">
                                    <div className={'add-button'}><i className='material-icons icon-add'>add</i> Add phone</div>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="profile-field email-field field-pad-zero">
                        <div className="profile-field-title pad">
                            <p>Email</p>
                        </div>
                        <div className={'container-row'}>
                            <div
                                onMouseEnter={() => setIsHoveredMainEmail(true)}
                                onMouseLeave={() => setIsHoveredMainEmail(false)}
                                className={'profile-field-value pad'}>
                                <TextField
                                    placeholder='—'
                                    className={`main-mail ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                    required={ true }
                                    value={emailMain}
                                    type={ Type.Textarea }
                                    horizontal={ true }
                                    error={ emailMainError }
                                    onChange={ newEmail => {
                                        setEmailMainError(null)
                                        const isValid = /^[a-zA-Z0-9@._-]*$/.test(newEmail);
                                        if (isValid) {
                                            fieldAutoResizer('main-mail', newEmail, 25, newEmail ? 25 : 228);
                                            setEmailMain(newEmail);
                                        }
                                    }}
                                    onBlur={() => {
                                        setEmail(emailMain, 'email');
                                        props.onDataUpdate();
                                    }}
                                    maxLength={50}
                                    disabled={!hasRole || props.employee.isDeleted}
                                />
                                {isHoveredMainEmail && emailMain && (
                                    <>
                                        <i
                                            id={'emailMain'}
                                            onClick={() => handleCopyClick('email', emailMain)}
                                            className={'material-icons edit-icon copy-to-clipboard'}>content_copy
                                        </i>
                                        <ReactTooltip
                                            className={'std-tooltip'}
                                            anchorId={'emailMain'}
                                            place="bottom"
                                            content={copiedLinks['email'] ? "Copied" : "Copy"}
                                            noArrow={true}
                                        />
                                    </>
                                )}
                            </div>
                            {emails.map((email, index) =>
                            {
                                const isHoveredEmail = hoveredEmailIndexes.includes(index);
                                return (

                                <div
                                    onMouseEnter={() => handleEmailMouseEnter(index)}
                                    onMouseLeave={() => handleEmailMouseLeave(index)}
                                    onBlur={() => {
                                        if (email.value === '') {
                                            removeEmail(email.field);
                                        }
                                    }}
                                    key={email.field}
                                    className={'profile-field-value pad'}>
                                    <TextField
                                        label=""
                                        id={`contact-email-${email.field}`}
                                        placeholder="—"
                                        className={`additional-email contact-email-${email.field} ${(!hasAdminRole && !props.isMyProfile) ? 'default-pointer' : ''}`}
                                        required={false}
                                        value={email.value}
                                        type={Type.Textarea}
                                        horizontal={false}
                                        error={emailErrors[`emailError${email.field}`]}
                                        onChange={(event) => {
                                            emailErrors[`emailError${email.field}`] = null;
                                            const isValid = /^[a-zA-Z0-9@._-]*$/.test(event);
                                            if (isValid) {
                                                fieldAutoResizer('contact-email-' + email.field, email.value, 25, email.value ? 25 : 228);
                                                handleEmailChange(event, email.field);
                                            }
                                        }}
                                        onBlur={() => {
                                            if (email.value !== '') {
                                                handlePhoneBlur(index)
                                                setContactValue(email.value, email.field, 'email')
                                            }
                                        }}
                                        maxLength={50}
                                        disabled={ (!hasAdminRole && !props.isMyProfile) || props.employee.isDeleted }
                                    />
                                    {isHoveredEmail && <>
                                    {email.field &&
                                        <>
                                            <i
                                                id={'email' + email.field}
                                                onClick={() => handleCopyClick(email.field, email.value)}
                                                className={'material-icons edit-icon copy-to-clipboard'}>
                                                content_copy
                                            </i>
                                            <ReactTooltip
                                                className={'std-tooltip'}
                                                anchorId={'email' + email.field}
                                                place="bottom"
                                                content={copiedLinks[email.field] ? "Copied" : "Copy"}
                                                noArrow={true}
                                            />
                                        </>
                                    }
                                    {(hasAdminRole || props.isMyProfile) && !props.employee.isDeleted ?
                                        <><i onClick={() => {
                                            removeEmail(email.field);
                                            emailErrors[`emailError${email.field}`] = null;
                                        }}
                                           id={'delete-email' + email.field}
                                           className='remove-button material-icons edit-icon'>close</i>
                                            <ReactTooltip
                                                className={'std-tooltip'}
                                                anchorId={'delete-email' + email.field}
                                                place="bottom"
                                                content={'Delete'}
                                                noArrow={true}
                                            />
                                        </>
                                    : null }
                                    </>}
                                </div>
                            )})}

                            {((emails.length < 4 && (hasAdminRole || props.isMyProfile)) && !props.employee.isDeleted) && (
                                <button onClick={addEmail} className="add-button">
                                    <div className={'add-button'}><i className='material-icons icon-add'>add</i> Add email</div>
                                </button>
                            )}
                        </div>
                    </div>
                    {socials.map((social, index) => {
                        const isHoveredSocial = hoveredSocialIndexes.includes(index);
                        const link = socialLinks.find(item => item.hasOwnProperty(social.field));
                        const isTelegramOrLinkedIn = (social.field === 'Telegram' || social.field === 'LinkedIn');
                        const disabled = isTelegramOrLinkedIn ? (!hasRole && !props.isMyProfile) : (!hasAdminRole && !props.isMyProfile);
                        return (
                            <div className={'profile-field social-field field-pad-zero'} key={social.field}>
                                <div className={'profile-field-title pad'}>
                                    <p>{social.field}</p>
                                </div>
                                <div className={'profile-field-value pad'}>
                                    <div
                                        onMouseEnter={() => handleSocialMouseEnter(index)}
                                        onMouseLeave={() => handleSocialMouseLeave(index)}
                                        onBlur={() => {
                                            if (social.value === '') {
                                                removeSocial(social.field);
                                            }
                                        }}
                                        className='row-container'>
                                        <img
                                            className='social-network-icon'
                                            src={social.field !== 'Other' ? SocialNetworkIcons[social.field] : ''}
                                            alt={social.field !== 'Other' ? `${social.field} icon` : ''}
                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                e.currentTarget.src = '';
                                            }}
                                        />
                                        <TextField
                                            label=''
                                            placeholder="—"
                                            className={`contact-social-${social.field} ${disabled || props.employee.isDeleted ? 'default-pointer' : ''}`}
                                            required={false}
                                            value={ social.value }
                                            type={Type.Textarea}
                                            horizontal={false}
                                            error={socialErrors[`${social.field}Error`]}
                                            onChange={(e: any) => {
                                                socialErrors[`${social.field}Error`] = null;

                                                for (const socialLink of socialLinks) {
                                                    const key = Object.keys(socialLink)[0];
                                                    const value = socialLink[key];

                                                    if (e.includes(value)) {
                                                        e = e.replace(value, '');
                                                    }
                                                }

                                                const regex = getRegexForSocial(social.field);

                                                const isValid = regex.test(e);
                                                if (isValid) {
                                                    fieldAutoResizer('contact-social-' + social.field, social.value, 25, social.value ? 25 : 228);
                                                    handleChange(e, social.field);
                                                }
                                            }}
                                            onFocus={() => {
                                                handleFocus(social.field)
                                            }}
                                            onBlur={() => {
                                                if (social.value !== '') {
                                                    handleBlur(social.value, social.field)
                                                }
                                            }}
                                            maxLength={100}
                                            disabled={disabled || props.employee.isDeleted}
                                        />
                                        {textWidth(social.value) > 300 && <span className={'social-elepsis'}>...</span>}
                                        {isHoveredSocial && social.value && <>
                                            <>
                                                <i
                                                    id={'social' + social.field}
                                                    onClick={() => handleCopyClick(social.field, link[social.field] + social.value)}
                                                    className={'material-icons edit-icon copy-to-clipboard'}>
                                                    content_copy
                                                </i>
                                                <ReactTooltip
                                                    className={'std-tooltip'}
                                                    anchorId={'social' + social.field}
                                                    place="bottom"
                                                    content={copiedLinks[social.field] ? "Copied" : "Copy"}
                                                    noArrow={true}
                                                />
                                            </>

                                            {(!socialLinksWithoutUrl.includes(social.field)) && (
                                                <>
                                                    <i
                                                        id={"open-new-window" + social.field}
                                                        onClick={() => {window.open(link[social.field] + social.value, '_blank')}}
                                                        className={'material-icons edit-icon'}
                                                    >
                                                        open_in_new
                                                    </i>
                                                    <ReactTooltip
                                                        className={'std-tooltip'}
                                                        anchorId={"open-new-window" + social.field}
                                                        place="bottom"
                                                        content={'Open in new window'}
                                                        noArrow={true}
                                                    />
                                                </>
                                            )}

                                            {((hasEditRole(social.field) || props.employee.isDeleted) && !props.employee.isDeleted) && (
                                                <><i id={'delete-social' + social.field} onClick={() => removeSocial(social.field)}
                                                   className='remove-button material-icons edit-icon'>close</i>
                                                    <ReactTooltip
                                                        className={'std-tooltip'}
                                                        anchorId={'delete-social' + social.field}
                                                        place="bottom"
                                                        content={'Delete'}
                                                        noArrow={true}
                                                    />
                                                </>
                                            )}
                                        </>}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {
                        (!allSocialUsed && (hasAdminRole || props.isMyProfile) && !props.employee.isDeleted) && (
                            <div className={'profile-field add-social-btn'}>
                                <p>Want your colleagues find you on messaging platform or social media?</p>
                                <Button
                                    text="Add contact"
                                    size={Size.Medium}
                                    type={ButtonType.White}
                                    onClick={() => setCreateSocialModal(true)}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <CreateSocial
                opened={ createSocialModal }
                issetSocials={socials}
                employeeId={props.employee.id}
                onOk={ () => {
                    setCreateSocialModal(false);
                    getContactInfos();
                    getContactInfosForValidate();
                    props.onDataUpdate();
                }}
                onClose={ () => setCreateSocialModal(false) }
            />
        </div>
    );
}

export default ContactInfo;