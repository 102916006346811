import React, { useEffect, useState } from 'react';
import {EmployeeFromApi} from "../../../../../../shared/Employee/data/EmployeeFromApi";
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";
import {Tooltip} from "react-tooltip";
import {TimeOffTypeIconsCollection} from "../../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../shared/Security/constants/AccessLevels";
import ProfileImageLoader from "../../../../../Component/Input/ProfileImageLoader/ProfileImageLoader";
import {RequestFromApi} from "../../../../../../shared/Calendar/Request/data/RequestFromApi";
import {CALENDAR} from "../../../../../../utils/constants/api";
import {
    dateFromStringWithDefaultTimezone
} from "../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";


type ProfilePhoto = {
    employee: EmployeeFromApi,
    authEmployee: AuthEmployee,
    isMyProfile: boolean,
    onDataUpdate: () => void,
}

function ProfilePhoto(props: ProfilePhoto) {
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const [imageUploadModal, setImageUploadModal] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmployeeFromApi | null>(null);
    const [showWithoutImage, setShowWithoutImage] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [approvedRequest, setApprovedRequest] = useState<RequestFromApi | null>(null);

    const handleDataUpdated = () => {
        props.onDataUpdate();
    };

    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        return `${year}-${month}-${day}`;
    }

    function getApprovedDayOffRequestsForToday() {
        return fetch(CALENDAR + '/requests?start-date=' + getTodayDateString() + '&end-date=' + getTodayDateString(), {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json());
    }

    useEffect(() => {
        getApprovedDayOffRequestsForToday().then(data => {
            if (data.length > 0 && employee) {
                    setApprovedRequest(data.find((request: RequestFromApi) => request.requesterId === employee.id));
            }})

    }, [employee]);

    useEffect(() => {
        setEmployee(props.employee)
    }, [props.employee]);

    const handleImageUpload = () => {
        setForceUpdate(forceUpdate + 1);
    };

    useEffect(() => {
        const fetchImageUrls = () => {
            if (employee?.nickname) {
                fetch(`/api/employees/${employee.nickname}/images`)
                    .then(response => {
                        if (!response.ok) {
                            setShowWithoutImage(true);
                        } else {
                            setShowWithoutImage(false);
                        }
                        return response.json();
                    })
                    .then(data => {
                        setCroppedImageUrl(data.cropped || "");
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        };

        if (employee?.nickname) {
            if (employee.nickname !== ""){
                fetchImageUrls();
            }
        }
    }, [employee?.nickname, employee, croppedImageUrl, forceUpdate]);

    function formatDateToFriendly(date: string): string {
        const dateObject = dateFromStringWithDefaultTimezone(date) || (new Date(date));
        const monthName = dateObject.toLocaleString('en-US', { month: 'long' });
        const dayNumber = dateObject.getDate();

        return `${monthName} ${dayNumber}`;
    }

    return (
            <>

                    <div className='request-icon'>
                        {approvedRequest && <div
                            data-tooltip-id={'tooltip-key'}
                            className="svg-icon"
                            dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[
                                    approvedRequest.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                    ]}} /> }
                    </div>
                    <div
                        className={(props.isMyProfile || hasRole ? 'for-hover' : '') + ' profile-photo'}
                        onClick={() => {
                            if (props.isMyProfile || hasRole) {
                                setImageUploadModal(true);
                            }
                        }}
                    >
                    {croppedImageUrl && (
                        <img
                            src={croppedImageUrl} alt="cropped"
                            style={{width: "140px", borderRadius: "50%"}}
                        />
                    )}
                    {showWithoutImage && (
                        <p>{ employee?.nickname.charAt(0).toUpperCase() }</p>
                    )}
                    { props.isMyProfile || hasRole ?
                    <div className={"hover-svg"}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11161_63436)">
                                <path d="M20.0013 26.9997C22.9468 26.9997 25.3346 24.6119 25.3346 21.6663C25.3346 18.7208 22.9468 16.333 20.0013 16.333C17.0558 16.333 14.668 18.7208 14.668 21.6663C14.668 24.6119 17.0558 26.9997 20.0013 26.9997Z" fill="white"/>
                                <path d="M15.0007 5L11.9507 8.33333H6.66732C4.83398 8.33333 3.33398 9.83333 3.33398 11.6667V31.6667C3.33398 33.5 4.83398 35 6.66732 35H33.334C35.1673 35 36.6673 33.5 36.6673 31.6667V11.6667C36.6673 9.83333 35.1673 8.33333 33.334 8.33333H28.0507L25.0007 5H15.0007ZM20.0007 30C15.4007 30 11.6673 26.2667 11.6673 21.6667C11.6673 17.0667 15.4007 13.3333 20.0007 13.3333C24.6007 13.3333 28.334 17.0667 28.334 21.6667C28.334 26.2667 24.6007 30 20.0007 30Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_11161_63436">
                                    <rect width="40" height="40" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div> : null}
                    { approvedRequest ?
                        <Tooltip
                            id={'tooltip-key'}
                            place="right"
                            noArrow={false}
                        >
                            <div className={'type-block'} style={{
                                background: approvedRequest.typeColor
                            }}>
                                <div className={'vacation-block'} dangerouslySetInnerHTML={{
                                    __html: TimeOffTypeIconsCollection[
                                        approvedRequest.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                        ]

                                }} />
                                <span className={'event-name'}>{approvedRequest.typeName}</span>
                            </div>

                            <div className={"tool-date"}>
                                <i className={"material-symbols-outlined calendar-today"}>date_range</i>
                                {formatDateToFriendly(approvedRequest.startDate)} {approvedRequest.endDate ? ' — ' + formatDateToFriendly(approvedRequest.endDate) : ''}
                            </div>

                            {approvedRequest.substituteNickname && (
                                <span className="replacing">Replacing: {approvedRequest.substituteNickname} </span>
                            )}
                        </Tooltip> : null }
                </div>
                <ProfileImageLoader
                    opened={ imageUploadModal }
                    modalTitle={ 'Upload image' }
                    nickname={ employee?.nickname ? employee.nickname : ''}
                    authEmployee={ props.authEmployee }
                    croppedImageUrl={croppedImageUrl}
                    onOk={() => {
                        handleImageUpload();
                        handleDataUpdated();
                    }}
                    onClose={ () => {
                        setImageUploadModal(false)
                    } }
                    okText={ 'Save' }
                    cancelText={ 'Cancel' }
                />
            </>

    );
}

export default ProfilePhoto;