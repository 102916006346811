import React, { useState, useEffect } from 'react';
import './Holidays.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import SubHeaderFilterBlock from "./SubHeaderFilterBlock";
import ProfessionalHolidays from "./ProfessionalHolidays/ProfessionalHolidays";
import CorporateEvents from "./CorporateEvents/CorporateEvents";
import StateHolidays from './StateHolidays/StateHolidays';
import {COUNTRIES, LOCATIONS} from "../../../../utils/constants/api";
import {LocationFromApi} from "../Locations/Locations";
import {CountryFromApi} from "../../../../shared/Location/data/CountryFromApi";
import {SelectValue} from "../../../../shared/Common/data/SelectValue";
import Select from '../../../Component/Input/SelectInput/SelectInput';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {useLocation} from "react-router-dom";

type Holidays = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Holidays(props: Holidays) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let yearParam = searchParams.get('year');
    if (yearParam && parseInt(yearParam) > (new Date().getFullYear() + 1)) {
        yearParam = (new Date().getFullYear() + 1).toString();
        searchParams.set('year', yearParam);
        const updatedURL = `${location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', updatedURL);
    } else if (yearParam && parseInt(yearParam) < (new Date().getFullYear() - 1)) {
        yearParam = (new Date().getFullYear() - 1).toString();
        searchParams.set('year', yearParam);
        const updatedURL = `${location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', updatedURL);
    }

    const emptySelectValue = { label: '', value: '' } as SelectValue;
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedYear, setSelectedYear] = useState<string>(yearParam ? yearParam : (new Date().getFullYear()).toString());
    const [locations, setLocations] = useState<LocationFromApi[]>([]);
    const [countries, setCountries] = useState<SelectValue[]>([]);
    const [country, setCountry] = useState<SelectValue>(emptySelectValue);

    const handleLiClick = (index: React.SetStateAction<number>) => {
        setActiveIndex(index);
        getContentComponent();
    };

    const handleYearChange = (newYear: string) => {
        setSelectedYear(newYear);
        getContentComponent();
    }

    const getActiveClass = (index: number) => {
        return index === activeIndex ? 'active' : '';
    };

    function getLocations() {
        fetch(LOCATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((locations: LocationFromApi[]) => setLocations(locations));
    }

    function getCountries() {
        fetch(COUNTRIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((countries: CountryFromApi[]) => setCountries(
                countries.filter(country => {
                    return locations.some(location => {
                        return location.value.countryCode === country.code;
                    });
                }).map((country, index) => {
                    if (country.code === 'UA' || index === 0) {
                        setCountry({
                            label: country.name,
                            value: country.code}
                        )
                    }
                    return {
                        label: country.name,
                        value: country.code
                    };
                })
            ));

    }

    useEffect(getLocations, []);

    useEffect(() => {
        getCountries();
    }, [locations]);

    const getContentComponent = () => {
        switch (activeIndex) {
            case 0:
                return <StateHolidays year={ selectedYear } country={ country.value } authEmployee={props.authEmployee}/>;
            case 1:
                return <ProfessionalHolidays year={ selectedYear } authEmployee={props.authEmployee}/>;
            case 2:
                return <CorporateEvents year={ selectedYear } authEmployee={props.authEmployee}/>;
            default:
                return null;
        }
    };
    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Holidays {countries && activeIndex === 0 ? 'in' : '' }</h3>
                                    {
                                        countries && activeIndex === 0 &&
                                        <div className={'country-filter'}>
                                            <Select
                                                label={''}
                                                required={false}
                                                error={''}
                                                isMulti={ false }
                                                value={ country }
                                                options={ countries }
                                                onChange={ (e: any) => {
                                                    setCountry(e);
                                                }}
                                                isSearchable={ false }
                                            />
                                        </div>
                                    }
                                </div>
                                <SubHeaderFilterBlock selectedYear={selectedYear} onYearChange={handleYearChange}/>
                            </div>
                            <div className='sub-content-main'>
                                <ul className={'holiday-menu'}>
                                    <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
                                        State Holidays
                                    </li>
                                    <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
                                        Professional Holidays
                                    </li>
                                    <li className={getActiveClass(2)} onClick={() => handleLiClick(2)}>
                                        Corporate Events
                                    </li>
                                </ul>
                                <div className={'content holidays-content'}>{getContentComponent()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Holidays;