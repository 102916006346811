import React from 'react';
import './Button.scss';
import parse from 'html-react-parser';

export enum Size {
    Big = 'big',
    Medium = 'medium'
}

export enum Type {
    Accent = 'accent',
    Ghost = 'ghost',
    Delete = 'delete',
    White = 'white',
    Grey = 'grey',
    Disabled = 'disabled',
    LightBlue = 'light-blue',
}

type Button = {
    text: string,
    size: Size,
    type: Type,
    onClick: () => any,
    isDisabled?: boolean,
    icon?: string,
};

function Button(props: Button) {
    return (
        <button
            className={ 'button ' + props.size + '-button ' + props.type }
            onClick={ props.onClick }
            disabled={props.isDisabled}
        >
            { props.icon && <img src={props.icon} alt="" className="button-icon" /> }
            { parse(props.text) }
        </button>
    );
}

export default Button;