import React from 'react';
import BaseModal  from './BaseModal';
import { GREETINGS } from '../../../../../utils/constants/api';
import { GreetingFromApi } from '../../../../../shared/Greeting/data/GreetingFromApi';
import moment from 'moment/moment';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { LocationFromApi } from '../../Locations/Locations';
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";

type EditGreeting = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    greeting: GreetingFromApi,
    locations: LocationFromApi[],
};

function EditGreeting(props: EditGreeting) {
    function edit(
        text: string,
        locations: string[],
        date: Date | null,
        type: string,
        repeatAnnually: boolean | null,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(GREETINGS + '/' + props.greeting.id, {
            method: 'PUT',
            body: JSON.stringify({
                text: text,
                locations: locations,
                special: type === 'special' ? date ? {
                    date: date ? date.getFullYear() + '-' + (date.getMonth()+1) + "-" + date.getDate() : null,
                    repeatAnnually: repeatAnnually
                } : false : null,
                visible: visible,
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Greeting'
            text={ props.greeting.value.text }
            selectedLocations={ props.locations }
            locations={props.greeting.value.locations.map(location => {
                let locationName = '';
                props.locations.map(locationValue => {
                    if (locationValue.id === location) {
                        locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                    }
                })
                return {
                    label: locationName,
                    value: location
                }
            })}
            date={ props.greeting.value.special ? new Date(moment(props.greeting.value.special.date).format('YYYY-MM-DD HH:mm')) : null }
            repeatAnnually={props.greeting.value.special ? props.greeting.value.special.repeatAnnually : false }
            visible={props.greeting.value.visible }
            type={ props.greeting.value.special ? 'special' : 'daily' }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditGreeting;