import React, {useEffect, useRef, useState} from 'react';
import './DeletedEmployees.scss';
import Button, {Size, Type} from '../../../../../Component/Button/Button';
import CreateEmployee from '../Current/Modal/CreateEmployee';
import { EMPLOYEES } from '../../../../../../utils/constants/api';
import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi';
import {Link} from "react-router-dom";
import {EMPLOYEE_PROFILE} from "../../../../../../utils/constants/routes";
import moment from "moment";
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";
import Select from "../../../../../Component/Input/SelectInput/SelectInput";
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import Pagination from "../../../../../../utils/helpers/Pagination";

type DeletedEmployees = {
    authEmployee: AuthEmployee,
};

function DeletedEmployees(props: DeletedEmployees) {
    const perPageOptions: SelectValue[] = [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
    ];
    const [perPage, setPerPage] = useState({ label: '50', value: '50' });
    const [page, setPage] = useState<string>('1');
    const [createEmployeeModal, setCreateEmployeeModal] = useState<boolean>(false);
    const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);
    const [deleted, setDeleted] = useState<string>('0');
    const fetchIdRef = useRef(0);

    useEffect(() => {
        getEmployees();
    }, [page, perPage])

    useEffect(() => {
        getDeleted();
    },[])

    useEffect(() => {
        setPage('1');
    }, [perPage]);

    function getEmployees() {
        const currentFetchId = ++fetchIdRef.current;
        const pageParam = page ? page : "1";
        const perPageParam = perPage ? perPage.value : "50";
        return fetch(EMPLOYEES + '/type/deleted' + '?page=' + pageParam + '&per-page=' + perPageParam, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employees: EmployeeFromApi[]) => {
                if (currentFetchId === fetchIdRef.current) {
                    setEmployees(employees);
                }
            });
    }

    function getDeleted() {
        return fetch(EMPLOYEES + '/total?status=deleted', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((deleted: string) => setDeleted(deleted));
    }

    return (
        <div className={'deleted-employees'}>
            <div className='add-button-block'>
            <Button
                text="<i className='material-icons add-item'>add</i> Add Employee"
                size={ Size.Medium }
                type={ Type.Accent }
                onClick={ () => setCreateEmployeeModal(true) }
            />
            </div>
            <table className="deleted-employees-table">
                <thead>
                <tr>
                    <th>
                        Employee
                    </th>
                    <th>
                        Company
                    </th>
                    <th>
                        Deleted date
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {employees.map(employee => {
                    return (
                        <tr key={employee.id}>
                            <td>
                                {employee.avatar ?
                                    <div className='with-avatar'>
                                        <img
                                            src={employee.avatar} alt="cropped"
                                        />
                                    </div> :
                                    <div className='without-avatar'>
                                        <p>{employee.nickname.charAt(0).toUpperCase()}</p>
                                    </div>
                                }
                                <Link to={EMPLOYEE_PROFILE + '/' + employee.nickname} target={'_blank'}>
                                    {employee.nickname}
                                </Link>
                            </td>

                            <td>{employee.companyName}</td>
                            <td>{employee.deletedAt ? moment(employee.deletedAt).format("DD.MM.YYYY") : ''}</td>
                            <td>
                                <Link to={EMPLOYEE_PROFILE + '/' + employee.nickname}>
                                    <i title="view profile" className='material-icons'>account_box</i>
                                </Link>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination">
                <div className="pagination-info">
                    <span>
                        <i onClick={() => parseInt(page) > 1 && setPage((parseInt(page) - 1).toString())}
                           className={`material-icons left ${page === '1' ? 'disabled-icon' : ''}`}
                        >keyboard_arrow_left</i>
                    </span>

                    {page} / {Math.ceil(parseInt(deleted) / parseInt(perPage.value))}

                    <span>
                      <i onClick={() => parseInt(page) < Math.ceil(parseInt(deleted) / parseInt(perPage.value)) && setPage((parseInt(page) + 1).toString())}
                         className={`material-icons right ${page === Math.ceil(parseInt(deleted) / parseInt(perPage.value)).toString() ? 'disabled-icon' : ''}`}
                      >keyboard_arrow_right</i>
                    </span>

                    <span className="pagination-total">{deleted} <span>results</span></span>
                </div>
                <Pagination currentPage={parseInt(page)} totalPages={Math.ceil(parseInt(deleted) / parseInt(perPage.value))} setPage={(page) => setPage(page.toString())} />
                <div className={'rows-per-page'}>
                    <span>Rows per page:</span>
                    <div className={'rows-per-page-filter'}>
                        <Select
                            label={''}
                            required={false}
                            error={''}
                            isMulti={ false }
                            value={ perPage }
                            options={ perPageOptions }
                            onChange={ (e: any) => {
                                setPerPage(e);
                            }}
                            isSearchable={ false }
                        />
                    </div>
                </div>
            </div>
            <CreateEmployee
                opened={ createEmployeeModal }
                onOk={ () => { getEmployees(); setCreateEmployeeModal(false); } }
                onClose={ () => setCreateEmployeeModal(false) }
                authEmployee={ props.authEmployee }
            />
        </div>
    );
}

export default DeletedEmployees;