import React, {useState, useEffect, useRef} from 'react';
import './SubMenu.scss';
import {
    ADMIN_COMPANIES,
    ADMIN_GREETINGS,
    ADMIN_LINKS,
    ADMIN_SEXES_AND_GENDERS,
    ADMIN_LEVELS,
    ADMIN_TYPES,
    ADMIN_STATUSES,
    ADMIN_JOB_TITLES,
    ADMIN_LOCATIONS,
    ADMIN_TEAMS,
    ADMIN_TIMEOFF_TYPES,
    ADMIN_PUSH_NOTIFICATIONS,
    ADMIN_EMPLOYEES,
    ADMIN_HOLIDAYS,
    ADMIN_TIMEOFF_POLICIES,
} from '../../../../utils/constants/routes';
import {Link} from 'react-router-dom';
import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER
} from "../../../../shared/Security/constants/AccessLevels";

type SubMenu = {
    authEmployee: AuthEmployee,
}

function SubMenu(props: SubMenu) {
    const [activePortalSetting, setActivePortalSetting] = useState<boolean>(false);
    const [activeCorporateSetting, setActiveCorporateSetting] = useState<boolean>(false);
    const [activeHrSetting, setActiveHrSetting] = useState<boolean>(false);

    function toggleActivePortalSettings(open:boolean) {
        if (activePortalSetting === open) {
            setActivePortalSetting(false);
        } else {
            setActivePortalSetting(true);
        }
    }

    function toggleActiveCorporateSetting(open:boolean) {
        if (activeCorporateSetting === open) {
            setActiveCorporateSetting(false);
        } else {
            setActiveCorporateSetting(true);
        }
    }

    function toggleActiveHrSetting(open:boolean) {
        if (activeHrSetting === open) {
            setActiveHrSetting(false);
        } else {
            setActiveHrSetting(true);
        }
    }

    useEffect(() => {
        const activePortalSetting = window.localStorage.getItem('activePortalSetting');
        const activeCorporateSetting = window.localStorage.getItem('activeCorporateSetting');
        const activeHrSetting = window.localStorage.getItem('activeHrSetting');

        if ( activePortalSetting != null) {
            toggleActivePortalSettings(activePortalSetting === 'true');
        }
        if (activeCorporateSetting != null) {
            toggleActiveCorporateSetting(activeCorporateSetting === 'true');
        }
        if (activeHrSetting != null) {
            toggleActiveHrSetting(activeHrSetting === 'true');
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('activePortalSetting', JSON.stringify(activePortalSetting));
        window.localStorage.setItem('activeCorporateSetting', JSON.stringify(activeCorporateSetting));
        window.localStorage.setItem('activeHrSetting', JSON.stringify(activeHrSetting));
        const storedScrollTop = localStorage.getItem("scroll-top");
        if (storedScrollTop !== null) {
            if (scroll.current) {
                scroll.current.scrollTop = parseInt(storedScrollTop);
            }
        }
    }, [activePortalSetting, activeCorporateSetting, activeHrSetting]);

    const [scrollTop, setScrollTop] = useState(0);
    const scroll = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scroll.current) {
                setScrollTop(scroll.current.scrollTop);
            }
        };

        const div = scroll.current;
        if (div) {
            div.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (div) {
                div.removeEventListener("scroll", handleScroll);
            }
        };
    }, [scroll]);

    useEffect(() => {
        if (scrollTop != 0) {
            localStorage.setItem("scroll-top", scrollTop.toString());
        }
    }, [scrollTop]);

    type RolePermissions = Record<string, string[]>;

    // Access levels
    const rolePermissions: RolePermissions = {
        [ROLE_ADMIN]: ['view_hr', 'view_companies', 'view_locations', 'view_teams', 'view_greetings', 'view_links', 'view_employees'],
        [ROLE_HR]: ['view_hr', 'view_companies', 'view_locations', 'view_teams', 'view_greetings', 'view_links', 'view_employees'],
        [ROLE_OPERATIONAL_MANAGER]: ['view_locations', 'view_teams', 'view_companies'],
        [ROLE_CONTENT_MANAGER]: ['view_greetings', 'view_links'],
    }

    function hasPermission(userRoles: string[], permission: string) {
        return userRoles.some(role =>
            rolePermissions[role] && rolePermissions[role].includes(permission)
        );
    }

    return (
        <div className="sub-menu" ref={scroll}>
            <ul>
                <li className="sub-menu-active">
                    <div onClick={ () => toggleActivePortalSettings(true) }>
                        <span>Portal settings</span>
                        <i className={"material-icons " + (!activePortalSetting ? 'material-icons-arrow-right' : '')}>arrow_drop_down</i>
                    </div>
                    <ul className={activePortalSetting ? '' : 'hide' }>
                        {hasPermission(props.authEmployee.roles, 'view_greetings') && (
                            <>
                                <li className={ window.location.pathname === ADMIN_GREETINGS ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_GREETINGS }>Greetings</Link>
                                </li>
                            </>
                        )}
                        {hasPermission(props.authEmployee.roles, 'view_links') && (
                            <>
                                <li className={ window.location.pathname === ADMIN_LINKS ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_LINKS }>Links</Link>
                                </li>
                            </>
                        )}
                        <li className={ window.location.pathname === ADMIN_PUSH_NOTIFICATIONS ? 'inner-sub-menu-active' : '' }>
                            <Link to={ ADMIN_PUSH_NOTIFICATIONS }>Push Notifications</Link>
                        </li>
                    </ul>
                </li>
                <li className="sub-menu-active">
                    <div onClick={ () => toggleActiveCorporateSetting(true) }>
                        <span>Corporate settings</span>
                        <i className={"material-icons " + (!activeCorporateSetting ? 'material-icons-arrow-right' : '')}>arrow_drop_down</i>
                    </div>
                    <ul className={activeCorporateSetting ? '' : 'hide' }>
                        {hasPermission(props.authEmployee.roles, 'view_companies') && (
                            <>
                                <li className={window.location.pathname === ADMIN_COMPANIES ? 'inner-sub-menu-active' : ''}>
                                    <Link to={ADMIN_COMPANIES}>Companies</Link>
                                </li>
                            </>
                        )}
                        <li className={ window.location.pathname === ADMIN_HOLIDAYS ? 'inner-sub-menu-active' : '' }>
                            <Link to={ ADMIN_HOLIDAYS + '?year=' + new Date().getFullYear() }>Holidays</Link>
                        </li>
                        {hasPermission(props.authEmployee.roles, 'view_teams') && (
                            <>
                                <li className={window.location.pathname === ADMIN_TEAMS ? 'inner-sub-menu-active' : ''}>
                                    <Link to={ADMIN_TEAMS}>Teams</Link>
                                </li>
                            </>
                        )}
                        {hasPermission(props.authEmployee.roles, 'view_locations') && (
                            <>
                                <li className={window.location.pathname === ADMIN_LOCATIONS ? 'inner-sub-menu-active' : ''}>
                                    <Link to={ADMIN_LOCATIONS}>Locations</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </li>
                { hasPermission(props.authEmployee.roles, 'view_hr') &&
                    <>
                        <li>
                            <div onClick={ () => toggleActiveHrSetting(true) }>
                                <span>HR settings</span>
                                <i className={"material-icons " + (!activeHrSetting ? 'material-icons-arrow-right' : '')}>arrow_drop_down</i>
                            </div>
                            <ul className={activeHrSetting ? '' : 'hide' }>
                                <li className={ window.location.pathname === ADMIN_STATUSES ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_STATUSES }>Employee Statuses</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_SEXES_AND_GENDERS ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_SEXES_AND_GENDERS }>Sexes and Genders</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_TYPES ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_TYPES }>Employee Types</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_LEVELS ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_LEVELS }>Employee Levels</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_TIMEOFF_TYPES ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_TIMEOFF_TYPES }>Time Off Types</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_JOB_TITLES ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_JOB_TITLES }>Job Titles</Link>
                                </li>
                                <li className={ window.location.pathname === ADMIN_TIMEOFF_POLICIES ? 'inner-sub-menu-active' : '' }>
                                    <Link to={ ADMIN_TIMEOFF_POLICIES }>Times Off Policy</Link>
                                </li>
                            </ul>
                        </li>
                    </>
                }
                {hasPermission(props.authEmployee.roles, 'view_employees') && (
                    <li>
                        <div>
                            <span><Link to={ADMIN_EMPLOYEES}>Employees</Link></span>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default SubMenu;