import React, {useEffect, useState} from 'react';
import Select from "react-select";

import {fetchCompanyOptions} from "../../../../../../shared/FetchOptions/fetchCompanyOptions";
import {fetchJobTitleOptions} from "../../../../../../shared/FetchOptions/fetchJobTitleOptions";

import {EmployeeFromApi} from '../../../../../../shared/Employee/data/EmployeeFromApi';
import {CurrentJobInfoFromApi} from '../../../../../../shared/Employee/Profile/JobInfo/data/CurrentJobInfoFromApi';
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import {TeamFromApi} from "../../../../../../shared/Team/data/TeamFromApi";
import {LevelFromApi} from "../../../../../../shared/Employee/Level/data/LevelFromApi";
import {LocationFromApi} from "../../../Locations/Locations";
import {TypeFromApi} from "../../Types/Types";
import {TimeOffPolicyFromApi} from "../../../../../../shared/TimeOffPolicy/data/TimeOffPolicyFromApi";
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";

import {workScheduleOptions} from "../../../../../../shared/Employee/Profile/JobInfo/data/constants";

import EmployeeStatusHistory from "./EmployeeStatusHistory";

import {
    EMPLOYEE,
    EMPLOYEES,
    LEVELS,
    LOCATIONS, TIME_OFF_POLICIES,
    TYPES,
    TEAMS
} from "../../../../../../utils/constants/api";
import {VALIDATION_ERROR} from "../../../../../../utils/constants/errors";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../shared/Security/constants/AccessLevels";

import './JobInfo.scss';
import {getGMTOffsetByTimezoneName} from "../../../../../Common/Timezones/Services";
import {PolicyType} from "../../../../../../shared/Employee/Profile/JobInfo/data/PolicyType";

type Profile = {
    employee: EmployeeFromApi,
    currentJobInfo: CurrentJobInfoFromApi | null,
    getCurrentJobInfo: () => void,
    onDataUpdate: () => void,
    authEmployee: AuthEmployee,
}

function JobInfo(props: Profile) {
    const [company, setCompany] = useState<SelectValue | null>(null);
    const [team, setTeam] = useState<SelectValue | null>(null);
    const [jobTitle, setJobTitle] = useState<SelectValue | null >(null);
    const [employeeLevel, setEmployeeLevel] = useState<SelectValue | null>(null);
    const [location, setLocation] = useState<SelectValue | null>(null);
    const [workSchedule, setWorkSchedule] = useState<SelectValue | null>(null);
    const [timeOffPolicies, setTimeOffPolicies] = useState<SelectValue[]>([]);
    const [directManager, setDirectManager] = useState<SelectValue | null>(null);
    const [functionalManager, setFunctionalManager] = useState<SelectValue | null>(null);
    const [employeeType, setEmployeeType] = useState<SelectValue | null>(null);

    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [teamOptions, setTeamOptions] = useState<SelectValue[]>([]);
    const [jobTitleOptions, setJobTitleOptions] = useState<SelectValue[]>([]);
    const [employeeLevelOptions, setEmployeeLevelOptions] = useState<SelectValue[]>([]);
    const [locationOptions, setLocationOptions] = useState<SelectValue[]>([]);
    const [timeOffPolicyOptions, setTimeOffPolicyOptions] = useState<SelectValue[]>([]);
    const [employeeOptions, setEmployeeOptions] = useState<SelectValue[]>([]);
    const [employeeTypeOptions, setEmployeeTypeOptions] = useState<SelectValue[]>([]);

    const [companyError, setCompanyError] = useState('');
    const [teamError, setTeamError] = useState('');
    const [jobTitleError, setJobTitleError] = useState('');
    const [employeeLevelError, setEmployeeLevelError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [workScheduleError, setWorkScheduleError] = useState('');
    const [timeOffPoliciesError, setTimeOffPoliciesError] = useState('');
    const [directManagerError, setDirectManagerError] = useState('');
    const [functionalManagerError, setFunctionalManagerError] = useState('');
    const [employeeTypeError, setEmployeeTypeError] = useState('');

    const [isHired, setIsHired] = useState<boolean>(true);

    useEffect(() => {
        fetchCompanyOptions().then(options => setCompanyOptions(options));
        fetchJobTitleOptions().then(options => {
            options.unshift({
                value: "",
                label: ""
            });
            setJobTitleOptions(options);
        });
        getEmployeeLevelOptions();
        getLocationOptions();
        getEmployeeTypeOptions();
    }, []);

    useEffect(() => {
        if (company) {
            getTeamOptions(company);
            getEmployeeOptions(company);
            getTimeOffPolicyOptions();
        }
    }, [company]);

    useEffect(() => { setEmployeeCompany() }, [props.employee]);

    useEffect(() => {
        const hireDate :string|undefined = props.currentJobInfo?.currentJobInfoValue?.dates?.hireDate;
        const terminationDate :string|undefined = props.currentJobInfo?.currentJobInfoValue?.dates?.terminationDate;

        if (typeof hireDate !== 'undefined' && typeof terminationDate !== 'undefined') {
            setIsHired(!!hireDate && !terminationDate);
        }
    }, [props.currentJobInfo]);

    useEffect(() => {
        getTeam(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.teamId : null);
    }, [props.currentJobInfo, teamOptions]);
    useEffect(() => {
        getJobTitle(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.jobTitleId : null)
    }, [props.currentJobInfo, jobTitleOptions]);
    useEffect(() => {
        getEmployeeLevel(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.employeeLevelId : null)
    }, [props.currentJobInfo, employeeLevelOptions]);
    useEffect(() => {
        getLocation(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.workLocationId : null)
    }, [props.currentJobInfo, locationOptions]);
    useEffect(() => {
        getWorkSchedule(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.workSchedule : null)
    }, [props.currentJobInfo]);
    useEffect(() => {
        getTimeOffPolicies(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.timeOffPolicyIds : null)
    }, [props.currentJobInfo]);
    useEffect(() => {
        getDirectManager(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.directManagerId : null)
    }, [props.currentJobInfo, employeeOptions]);
    useEffect(() => {
        getFunctionalManager(
            props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.functionalManagerId : null
        )
    }, [props.currentJobInfo, employeeOptions]);
    useEffect(() => {
        getEmployeeType(props.currentJobInfo ? props.currentJobInfo.currentJobInfoValue.employeeTypeId : null)
    }, [props.currentJobInfo, employeeTypeOptions]);

    function setEmployeeCompany() {
        const company = {
            value: props.employee.companyId,
            label: props.employee.companyName
        } as SelectValue;
        setCompany(company);
    }

    function clearCompanyDependencies() {
        setTeam(null);
        setValue(null, 'team_id');
        setDirectManager(null);
        setValue(null, 'direct_manager_id');
        setFunctionalManager(null);
        setValue(null, 'functional_manager_id');
        setTimeOffPolicies([]);
        setValue([], 'time_off_policy_ids');
    }

    function getTeamsByCompany(id: string) {
        return  fetch(TEAMS + '?company_ids=' + JSON.stringify([id]), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        })
            .then((response) => response.json())
    }

    function getTeamOptions(company: SelectValue) {
        getTeamsByCompany(company.value)
            .then((teams: TeamFromApi[]) => {
                const options = teams.map(team => {
                    return {
                        value: team.id,
                        label: team.name
                    }
                });
                options.unshift({
                    value: "",
                    label: ""
                });
                setTeamOptions(options);
            })
    }

    function getEmployeeLevelOptions() {
        fetch(LEVELS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employeeLevels: LevelFromApi[]) => {
                const options = employeeLevels.map(employeeLevel => {
                    return {
                        value: employeeLevel.id,
                        label: employeeLevel.name
                    }
                });
                options.unshift({
                    value: "",
                    label: ""
                });
                setEmployeeLevelOptions(options);
            })
    }

    function getLocationOptions() {
        fetch(LOCATIONS, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then((locations: LocationFromApi[]) => {
                const options = locations.map(location => {
                    return {
                        value: location.id,
                        label: location.name + ' ' + getGMTOffsetByTimezoneName(location.value.timezone)
                    }
                });
                setLocationOptions(options);
            })
    }

    function getTimeOffPolicyOptions() {
        fetch(TIME_OFF_POLICIES, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then((timeOffPolicies: TimeOffPolicyFromApi[]) =>
                setTimeOffPolicyOptions(
                    timeOffPolicies.map(timeOffPolicy => {
                            return {
                                value: timeOffPolicy.id,
                                label: timeOffPolicy.isVisible ?
                                    timeOffPolicy.value.generalInfo.name :
                                    '(inactive) ' + timeOffPolicy.value.generalInfo.name
                            }
                        }
                    ))
            )
    }

    function getAllActiveEmployees() {
        return fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
    }

    function getHierarchyChainIds(employeeId: string): Promise<string[]> {
        return fetch(EMPLOYEE + '/' + employeeId + '/all-hierarchy-chains', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json());
    }

    async function getEmployeeOptions(company: SelectValue) {
        let hierarchyChainIds: string[] = await getHierarchyChainIds(props.employee.id);
        getAllActiveEmployees()
            .then((employees: EmployeeFromApi[]) => {
                const options = employees.map(employee => {
                    return {
                        value: employee.id,
                        label: employee.nickname
                    }
                });
                options.unshift({
                    value: "",
                    label: ""
                });
                const filteredOptions = options.filter(
                    option => option.value !== props.employee.id && !hierarchyChainIds.includes(option.value)
                );
                setEmployeeOptions(filteredOptions);
            })
    }

    function getEmployeeTypeOptions() {
        fetch(TYPES, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then((employeeTypes: TypeFromApi[]) => {
                const options = employeeTypes.map(employeeType => {
                    return {
                        value: employeeType.id,
                        label: employeeType.name
                    }
                });
                options.unshift({
                    value: "",
                    label: ""
                });
                setEmployeeTypeOptions(options);
            })
    }

    function getTeam(teamId: string|null) {
        if (teamId) {
            const team = teamOptions.find(team => team.value === teamId);
            setTeam(team ? team : null);
        }
    }

    function getJobTitle(jobTitleId: string|null) {
        if (jobTitleId) {
            const jobTitle = jobTitleOptions.find(jobTitle => jobTitle.value === jobTitleId);
            setJobTitle(jobTitle ? jobTitle : null);
        }
    }

    function getEmployeeLevel(employeeLevelId: string|null) {
        if (employeeLevelId) {
            const employeeLevel = employeeLevelOptions.find(
                employeeLevel => employeeLevel.value === employeeLevelId
            );
            setEmployeeLevel(employeeLevel ? employeeLevel : null);
        }
    }

    function getLocation(locationId: string|null) {
        if (locationId) {
            const location = locationOptions.find(location => location.value === locationId);
            setLocation(location ? location : null);
        }
    }

    function getWorkSchedule(workScheduleId: string|null) {
        if (workScheduleId) {
            const workSchedule = workScheduleOptions.find(
                workSchedule => workSchedule.value === workScheduleId
            );
            setWorkSchedule(workSchedule ? workSchedule : null);
        }
    }

    function getTimeOffPolicies(timeOffPoliciesIds: PolicyType[]|null) {
        if (timeOffPoliciesIds) {
            let resultPolicies = timeOffPoliciesIds.map(timeOffPolicy => {
                return timeOffPolicy.policyId;
            })
            const timeOffPolicies = timeOffPolicyOptions.filter(
                timeOffPolicy => resultPolicies.includes(timeOffPolicy.value)
            );
            setTimeOffPolicies(timeOffPolicies);
        }
    }

    function getDirectManager(directManagerId: string|null) {
        if (directManagerId) {
            const directManager = employeeOptions.find(
                directManager => directManager.value === directManagerId
            );
            setDirectManager(directManager ? directManager : null);
        }
    }

    function getFunctionalManager(functionalManagerId: string|null) {
        if (functionalManagerId) {
            const functionalManager = employeeOptions.find(
                functionalManager => functionalManager.value === functionalManagerId
            );
            setFunctionalManager(functionalManager ? functionalManager : null);
        }
    }

    function getEmployeeType(employeeTypeId: string|null) {
        if (employeeTypeId) {
            const employeeType = employeeTypeOptions.find(
                employeeType => employeeType.value === employeeTypeId
            );
            setEmployeeType(employeeType ? employeeType : null);
        }
    }

    function resetAllErrors() {
        setTeamError('');
        setJobTitleError('');
        setEmployeeLevelError('');
        setWorkScheduleError('');
        setLocationError('');
        setDirectManagerError('');
        setFunctionalManagerError('');
        setEmployeeTypeError('');
        setCompanyError('');
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            switch (error.property) {
                case 'current_job_info.team_id':
                    setTeamError(error.message);
                    break;
                case 'current_job_info.job_title_id':
                    setJobTitleError(error.message);
                    break;
                case 'current_job_info.employee_level_id':
                    setEmployeeLevelError(error.message);
                    break;
                case 'current_job_info.work_schedule':
                    setWorkScheduleError(error.message);
                    break;
                case 'current_job_info.work_location_id':
                    setLocationError(error.message);
                    break;
                case 'current_job_info.time_off_policy_ids':
                    setTimeOffPoliciesError(error.message);
                    break;
                case 'current_job_info.direct_manager_id':
                    setDirectManagerError(error.message);
                    break;
                case 'current_job_info.functional_manager_id':
                    setFunctionalManagerError(error.message);
                    break;
                case 'current_job_info.employee_type_id':
                    setEmployeeTypeError(error.message);
                    break;
            }
        });
    }

    function saveCompany(company: SelectValue): void {
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/company/' + company.value, {
            method: 'PUT',
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((currentJobInfo: CurrentJobInfoFromApi) => {
                        props.getCurrentJobInfo();
                    });
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function setValue(value: any, field: any) {
        resetAllErrors();
        switch (field) {
            case 'job_title_id':
                value = value ? value.value : null;
                break;
            case 'team_id':
                value = value ? value.value : null;
                break;
            case 'employee_level_id':
                value = value ? value.value : null;
                break;
            case 'work_schedule':
                value = value ? value.value : null;
                break;
            case 'work_location_id':
                value = value ? value.value : null;
                break;
            case 'time_off_policy_ids':
                const scopeValue = value.map((policy: SelectValue) => policy.value);
                if (scopeValue.length > 0) {
                    value = scopeValue;
                } else {
                    value = null;
                }
                break;
            case 'direct_manager_id':
                value = value ? value.value : null;
                break;
            case 'functional_manager_id':
                value = value ? value.value : null;
                break;
            case 'employee_type_id':
                value = value ? value.value : null;
                break;
        }

        fetch(EMPLOYEE + '/' + props.employee.id + '/current-job-info/' + field + '/' + value, {
            method: 'PUT',
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(() => {
                    });
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });

    }

    function handleCompanyChange(company: SelectValue) {
        resetAllErrors();
        setCompany(company);
        saveCompany(company);
        clearCompanyDependencies();
        props.onDataUpdate();
    }

    function triggerParentUpdate() {
        setTeam(null);
        setJobTitle(null);
        setEmployeeLevel(null);
        setWorkSchedule(null);
        setLocation(null);
        setDirectManager(null);
        setFunctionalManager(null);
        setTimeOffPolicies([]);
        setEmployeeType(null);
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const hasRoleAdmin = props.authEmployee.roles.includes(ROLE_ADMIN);

    return (
        <div className={'job-info'}>
            <div className={'info'}>
                <div className={'info-header'}>
                    <div className={'icon'}><i className='material-symbols-outlined'>work</i></div>
                    <h3>Current Job Info</h3>
                </div>
                <div className={'info-body'}>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Company</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!company && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'company' }
                                className={`profile-select `}
                                value={ company }
                                options={ companyOptions }
                                isClearable={ false }
                                isDisabled={ (!hasRole || (isHired && !hasRoleAdmin) || props.employee.isDeleted) }
                                onChange={ (newValue: any) => {
                                    handleCompanyChange(newValue);
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Team</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!team && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'team' }
                                className={`profile-select `}
                                value={ team }
                                options={ teamOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={ (newValue: any) => {
                                    if (newValue && newValue.value !== '') {
                                        setTeam(newValue);
                                        setValue(newValue, 'team_id');
                                        props.onDataUpdate();
                                    } else {
                                        setTeam(null);
                                        setValue(null, 'team_id');
                                        props.onDataUpdate();
                                    }
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Job Title</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!jobTitle && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'job-title' }
                                className={`profile-select `}
                                value={ jobTitle }
                                options={ jobTitleOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={(newValue: any) => {
                                    if (newValue && newValue.value !== '') {
                                        setJobTitle(newValue);
                                        setValue(newValue, 'job_title_id');
                                        props.onDataUpdate();
                                    } else {
                                        setJobTitle(null);
                                        setValue(null, 'job_title_id');
                                        props.onDataUpdate();
                                    }
                                    setEmployeeLevel(null);
                                    setValue(null, 'employee_level_id');
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Employee Level</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!employeeLevel && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'employee-level' }
                                className={`profile-select `}
                                value={ employeeLevel }
                                options={ employeeLevelOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={ (newValue: any) => {
                                    if (newValue && newValue.value !== '') {
                                        setEmployeeLevel(newValue);
                                        setValue(newValue, 'employee_level_id');
                                } else {
                                        setEmployeeLevel(null);
                                        setValue(null, 'employee_level_id');
                                    }
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Direct Manager</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!directManager && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'direct-managers' }
                                className={`profile-select `}
                                value={ directManager }
                                options={ employeeOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={(newValue: any) => {
                                    if (newValue && newValue.value !== '') {
                                        setDirectManager(newValue);
                                        setValue(newValue, 'direct_manager_id');
                                        props.onDataUpdate();
                                    } else {
                                        setDirectManager(null);
                                        setValue(null, 'direct_manager_id');
                                        props.onDataUpdate();
                                    }
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    {/*<div className={'profile-field'}>*/}
                    {/*    <div className={'profile-field-title'}>*/}
                    {/*        <p>Functional Manager</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={'profile-field-value job-info-select'}>*/}
                    {/*        <Select*/}
                    {/*            placeholder={'Select'}*/}
                    {/*            isMulti={ false }*/}
                    {/*            id={ 'functional-managers' }*/}
                    {/*            className={ 'profile-select' }*/}
                    {/*            value={ functionalManager }*/}
                    {/*            options={ employeeOptions }*/}
                    {/*            isClearable={ false }*/}
                    {/*            isDisabled={ !hasRole }*/}
                    {/*            classNamePrefix='react-select'*/}
                    {/*            onChange={ (newValue: any) => {*/}
                    {/*                if(newValue && newValue.value !== '') {*/}
                    {/*                    setFunctionalManager(newValue);*/}
                    {/*                    setValue(newValue, 'functional_manager_id');*/}
                    {/*                    props.onDataUpdate();*/}
                    {/*                } else {*/}
                    {/*                    setFunctionalManager(null);*/}
                    {/*                    setValue(null, 'functional_manager_id');*/}
                    {/*                    props.onDataUpdate();*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            isSearchable={ true }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    { hasRole && (
                        <div className={'profile-field'}>
                            <div className={'profile-field-title'}>
                                <p>Employee Type</p>
                            </div>
                            <div className={`profile-field-value job-info-select ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                                <Select
                                    placeholder={(!employeeType && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                    isMulti={ false }
                                    id={ 'employee-type' }
                                    className={`profile-select `}
                                    value={ employeeType }
                                    options={ employeeTypeOptions }
                                    isClearable={ false }
                                    onChange={ (newValue: any) => {
                                        if(newValue && newValue.value !== '') {
                                            setEmployeeType(newValue);
                                            setValue(newValue, 'employee_type_id');
                                        } else {
                                            setEmployeeType(null);
                                            setValue(null, 'employee_type_id');
                                        }
                                    }}
                                    isSearchable={ true }
                                    isDisabled={props.employee.isDeleted}
                                />
                            </div>
                        </div>
                    )}

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Work Location</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!location && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'location' }
                                className={`profile-select `}
                                value={ location }
                                options={ locationOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={ (newValue: any) => {
                                    setLocation(newValue);
                                    setValue(newValue, 'work_location_id');
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={'profile-field'}>
                        <div className={'profile-field-title'}>
                            <p>Work Schedule</p>
                        </div>
                        <div className={`profile-field-value job-info-select ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}>
                            <Select
                                placeholder={(!workSchedule && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ false }
                                id={ 'work-schedule' }
                                className={`profile-select `}
                                value={ workSchedule }
                                options={ workScheduleOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={ (newValue: any) => {
                                    setWorkSchedule(newValue);
                                    setValue(newValue, 'work_schedule');
                                }}
                                isSearchable={ true }
                            />
                        </div>
                    </div>

                    <div className={`profile-field profile-field-multi-dropdown`}>
                        <div className={'profile-field-title'}>
                            <p>Time Off Policy</p>
                        </div>
                        <div className={'profile-field-value'}>
                            <Select
                                placeholder={(timeOffPolicies.length < 1 && !hasRole) || props.employee.isDeleted ? '—' : 'Select'}
                                isMulti={ true }
                                id={ 'time-off-policy' }
                                className={`profile-select ${!hasRole || props.employee.isDeleted ? 'default-pointer' : ''} ${props.employee.id === props.authEmployee.id && !hasRole ? 'disabled-pointer' : ''}`}
                                value={ timeOffPolicies }
                                options={ timeOffPolicyOptions }
                                isClearable={ false }
                                isDisabled={ !hasRole || props.employee.isDeleted }
                                onChange={ (newValue: any) => {
                                    setTimeOffPolicies(newValue);
                                    setValue(newValue, 'time_off_policy_ids');
                                    props.onDataUpdate();
                                }}
                                isSearchable={ false }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <EmployeeStatusHistory
                employee={ props.employee }
                onDataUpdate={ props.onDataUpdate }
                authEmployee={ props.authEmployee }
                triggerParentUpdate={ triggerParentUpdate }
                currentJobInfo={ props.currentJobInfo }
                updateCurrentJobInfo={props.getCurrentJobInfo}
                setIsHired={setIsHired}
            />
        </div>
    );
}

export default JobInfo;