import React from 'react';
import BaseModal from './BaseModal';
import {JOB_TITLES_CATEGORIES} from '../../../../../../utils/constants/api';
import {JobTitlesCategoryFromApi} from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../../utils/constants/errors";

type CreateJobTitle = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    categories: JobTitlesCategoryFromApi[]
};

function CreateJobTitle(props: CreateJobTitle) {
    function create(name: string, categoryId: string|null, validate: (errors: ValidationError[]) => void): void {
        let formData = new FormData();
        formData.append('title', JSON.stringify({
            name: name
        }));

        fetch(JOB_TITLES_CATEGORIES + '/' + categoryId + '/job-titles', {method: 'POST', body: formData})
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'title.categoryId',
                        message: 'Category is required'
                    }]);
                }
            });
    }

    return (
        <BaseModal
            opened={props.opened}
            modalTitle='Create Job Title'
            name={null}
            category={null}
            okText='Create'
            onCancel={props.onClose}
            onOk={create}
            categories={props.categories}
        />
    )
}

export default CreateJobTitle;