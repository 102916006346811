import React from 'react';

import ConfirmModal from '../../../Layer/Modal/ConfirmModal';

import {RequestFromApi} from "../../../../../shared/Calendar/Request/data/RequestFromApi";


type ConfirmApproveRequest = {
    opened: boolean,
    modalTitle: string,
    confirmModalText: string,
    okText: string,
    onOk: () => void,
    onClose: () => any,
    request: RequestFromApi | null,
    hasCancelButton?: boolean
};

function ConfirmApproveRequest(props: ConfirmApproveRequest) {
    return (
        <ConfirmModal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            confirmModalText={ props.confirmModalText }
            okText={ props.okText }
            onCancel={ props.onClose }
            onOk={ props.onOk }
            hasCancelButton={ props.hasCancelButton ? props.hasCancelButton : false }
            cancelText={'Cancel'}
        />
    )
}

export default ConfirmApproveRequest;