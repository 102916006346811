import React from 'react';
import './General.scss';
import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import ContactInfo from './ContactInfo/ContactInfo';
import OtherInfo from './OtherInfo/OtherInfo';
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";

type Profile = {
    employee: EmployeeFromApi,
    authEmployee: AuthEmployee,
    isMyProfile: boolean | null,
    onDataUpdate: () => void,
}

function General(props: Profile) {
    const handleDataUpdated = () => {
        props.onDataUpdate();
    };

    return (
        <div>
            <PersonalInfo employee={props.employee} authEmployee={props.authEmployee} isMyProfile={props.isMyProfile} onDataUpdate={handleDataUpdated}/>
            <ContactInfo employee={props.employee} authEmployee={props.authEmployee} isMyProfile={props.isMyProfile} onDataUpdate={handleDataUpdated}/>
            <OtherInfo employee={props.employee} authEmployee={props.authEmployee}/>
        </div>
    );
}

export default General;