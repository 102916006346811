import React, {useEffect, useState} from 'react';

import BaseModal from '../BaseModal/BaseModal';
import TextField, {Type} from '../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../Component/Input/SelectInput/SelectInput';
import Select from "../../../../Component/Input/SelectInput/SelectInput";

import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {LocationFromApi} from "../../Locations/Locations";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";

import {TimeOffTypeIconsCollection} from "../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import {TIME_OFF_POLICIES} from "../../../../../utils/constants/api";
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";


type GeneralInfoModal = {
    source: string,
    opened: boolean,
    modalTitle: string,
    name: string | null,
    locations: SelectValue[],
    companies: SelectValue[],
    locationValues: LocationFromApi[],
    companyValues: CompanyFromApi[],
    iconId: string | null,
    description: string | null,
    okText: string,
    infoText: string,
    onOk: (
        name: string,
        companies: SelectValue[],
        locations: SelectValue[],
        iconId: string,
        description: string,
        validate: (errors: ValidationError[]) => void
    ) => void,
    style : string,
};

type SelectValue = {
    value: string,
    label: any
};

function GeneralInfoModal(props: GeneralInfoModal) {
    const companyOptions = props.companyValues.map(companyValue => {
        return {
            label: companyValue.name,
            value: companyValue.id
        } as SelectValue
    });
    const locationOptions = props.locationValues.map(locationValue => {
        return {
            label: locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone),
            value: locationValue.id
        } as SelectValue
    });

    const [name, setName] = useState<string>(props.name || '');
    const [companies, setCompanies] = useState<SelectValue[]>(props.companies || []);
    const [locations, setLocations] = useState<SelectValue[]>(props.locations || []);
    const [iconId, setIconId] = useState<string | null>(props.iconId || null);
    const [iconOption, setIconOption] = useState<SelectValue | null>(null);
    const [description, setDescription] = useState<string>(props.description || '');

    const [nameError, setNameError] = useState<string | null>(null);
    const [locationError, setLocationError] = useState<string | null>(null);
    const [companyError, setCompanyError] = useState<string | null>(null);
    const [descriptionError, setDescriptionError] = useState<string | null>(null);
    const [iconError, setIconError] = useState<string | null>(null);

    const iconOptions = Object.keys(TimeOffTypeIconsCollection)
        .map((key) => {
            if (!key.includes('NoConfirm')) {
                return {
                    label: <div dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[key as keyof typeof TimeOffTypeIconsCollection] }} />,
                    value: key,
                } as SelectValue;
            }
            return null;
        })
        .filter((iconOption): iconOption is SelectValue => iconOption !== null);


    function resetAll(): void {
        setName('');
        setCompanies([]);
        setLocations([]);
        setIconId(null);
        setDescription('');
        setNameError(null);
        setLocationError(null);
        setCompanyError(null);
        setDescriptionError(null);
        setIconError(null);
    }

    function clearAllErrors(): void {
        setNameError(null);
        setLocationError(null);
        setCompanyError(null);
        setDescriptionError(null);
        setIconError(null);
    }

    useEffect(() => {
        if (props.companies.length === 0 && props.okText != 'Edit') {
            setCompanies(companyOptions);
        }
        if (props.locations.length === 0 && props.okText != 'Edit') {
            setLocations(locationOptions);
        }
    }, [props.opened]);

    useEffect(() => {
        if (iconId) {
            const iconOption = iconOptions.find(option => option.value === iconId);
            if (iconOption) {
                setIconOption(iconOption);
            }
        }
    }, [iconId]);

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'name') {
                setNameError(error.message);
            } else if (error.property === 'companiesIds') {
                setCompanyError(error.message);
            } else if (error.property === 'locationsIds') {
                setLocationError(error.message);
            } else if (error.property === 'iconId') {
                setIconError(error.message);
            } else if (error.property === 'description') {
                setDescriptionError(error.message);
            }
        });
    }

    async function apiValidate(): Promise<void> {
        if (props.name !== name && name !== '') {
            const response = await fetch(TIME_OFF_POLICIES + "/validation/general-info/name/" + name, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                validate([{ property: "name", message: "Name already exists" }]);
                return;
            }
        }
        apiValidateRestFields();
    }

    function apiValidateRestFields() {
        const generalInfoData = {
            name: name,
            companiesIds: companies.map(company => company.value),
            locationsIds: locations.map(location => location.value),
            iconId: iconOption?.value || '',
            description: description
        }

        fetch(TIME_OFF_POLICIES + '/validation/general-info', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(generalInfoData)
        }).then(response => {
            if (response.ok) {
                props.onOk(
                    name,
                    companies,
                    locations,
                    iconOption?.value || '',
                    description,
                    validate
                )
            } else {
                response.json().then((errors: ValidationError[]) => {
                    validate(errors);
                });
            }
        });
    }

    function handleOk(): void {
        clearAllErrors();
        apiValidate();
    }

    function handleCompaniesChange(newCompanies: SelectValue[]): void {
        setCompanies(newCompanies);
        setCompanyError(null);
    }

    function handleLocationsChange(newLocations: SelectValue[]): void {
        setLocations(newLocations);
        setLocationError(null);
    }

    function handleNameChange(newName: string): void {
        setName(newName);
        setNameError(null);
    }

    function handleDescriptionChange(newDescription: string): void {
        setDescription(newDescription);
        setDescriptionError(null);
    }

    function handleIconChange(newIcon: SelectValue): void {
        setIconId(newIcon.value);
        setIconError(null);
    }

    return (
        <BaseModal
            opened={props.opened}
            modalTitle={props.modalTitle}
            okText={props.okText}
            onOk={handleOk}
            hasCancel={false}
            style={props.style}
        >
            <TextField
                label='Name'
                placeholder='Name...'
                required={true}
                value={name}
                type={Type.Textarea}
                horizontal={true}
                error={nameError}
                onChange={newName => handleNameChange(newName)}
                maxLength={200}
            />
            <SelectInput
                isSearchable={true}
                label={'Companies'}
                required={true}
                isMulti={true}
                value={companies}
                options={companyOptions}
                error={companyError}
                onChange={newCompanies => handleCompaniesChange(newCompanies)}
            />
            <SelectInput
                isSearchable={true}
                label={'Locations'}
                required={true}
                isMulti={true}
                value={locations}
                options={locationOptions}
                error={locationError}
                onChange={newLocations => handleLocationsChange(newLocations)}
            />
            <Select
                label='Icon'
                isSearchable={false}
                required={true}
                isMulti={false}
                value={iconOption}
                options={iconOptions}
                error={iconError}
                onChange={ newIcon => handleIconChange(newIcon)}
            />
            <TextField
                label='Policy Description'
                placeholder=''
                required={false}
                value={description}
                type={Type.Textarea}
                horizontal={true}
                error={descriptionError}
                onChange={newDescription => handleDescriptionChange(newDescription)}
                maxLength={1000}
            />
        </BaseModal>
    );
}

export default GeneralInfoModal;