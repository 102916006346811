import React from 'react';
import BaseModal from './BaseModal';
import { EMPLOYEES } from '../../../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../../../shared/Common/Error/ValidationError';
import {SelectValue} from '../../../../../../../../shared/Common/data/SelectValue';
import {ContactInfoType} from '../../../../../../../../shared/Employee/data/ContactInfoType';

type CreateSocial = {
    issetSocials: ContactInfoType[],
    employeeId: string,
    opened: boolean,
    onOk: () => void,
    onClose: () => any
};

function CreateSocial(props: CreateSocial) {
    function create(
        social: SelectValue | null,
        link: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        if (social && link) {
            fetch(EMPLOYEES + '/employee/' + props.employeeId + '/contact-info/social/' + social.label + '/' + link.replace(/\//g, '|'), {
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    validate([]);
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
        } else {
            const errors = [];

            if (!social) {
                errors.push({
                    property: 'employee.social',
                    message: 'Please select a platform'
                });
            }
            if (!link) {
                errors.push({
                    property: 'employee.link',
                    message: 'Please enter a username or phone number'
                });
            }

            if (errors.length > 0) {
                validate(errors)
            }
        }
    }

    return (
        <BaseModal
            opened={ props.opened }
            issetSocials={props.issetSocials}
            modalTitle='New contact'
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateSocial;