import React, {useEffect, useState} from 'react';
import './LinksWidget.scss';
import {LINKS_WIDGET} from '../../../../../utils/constants/api';
import { LinkForWidget } from '../../../../../shared/Link/data/LinkForWidget';

function LinksWidget() {
    const [links, setLinks] = useState<LinkForWidget[]>([]);

    useEffect(() => {
        fetch(LINKS_WIDGET, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((links: LinkForWidget[]) => {
                let newLinks: any[] = [];
                links.forEach((link: LinkForWidget) => newLinks.push(link));
                setLinks(newLinks);
            });
    }, []);
    if (links.length > 0) {
        return (
            <div className='links-widget'>
                <div className='header'>
                    <h2>Useful links</h2>
                </div>
                <ul className='widget-body'>
                    {links.map(link => {
                        return (
                            <li key={link.name}>
                                <a target="_blank" rel="noopener noreferrer" href={link.url}>
                                    { link.name.length > 40 ? link.name.substring(0, 37) + '...' : link.name }
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        )
    } else {
        return <div></div>;
    }
}

export default LinksWidget;