import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {BirthdayFromApi} from "../../../../../shared/Main/Celebrations/data/BirthdayFromApi";
import {AnniversaryFromApi} from "../../../../../shared/Main/Celebrations/data/AnniversaryFromApi";
import {EMPLOYEE_PROFILE} from "../../../../../utils/constants/routes";
import {EMPLOYEES} from "../../../../../utils/constants/api";

import birthdayIcon from '../../../../../assets/images/mainPageIcons/birthday_icon.svg';
import pyramidIcon from '../../../../../assets/images/mainPageIcons/pyramid_icon.svg';

import './CelebrationsWidget.scss';
import {
    dateFromStringWithDefaultTimezone
} from "../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

function CelebrationsWidget() {
    const [areThereAnyAnniversaries, setAreThereAnyAnniversaries] = useState(false);
    const [areThereAnyBirthdays, setAreThereAnyBirthdays] = useState(false);

    const [birthdays, setBirthdays] = useState<BirthdayFromApi[]>([]);
    const [anniversaries, setAnniversaries] = useState<AnniversaryFromApi[]>([]);

    useEffect(() => {
        fetch(EMPLOYEES + '/celebrations', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                const birthdaysFromJson = JSON.parse(data.birthdays);
                const anniversariesFromJson = JSON.parse(data.anniversaries);

                setBirthdays(birthdaysFromJson);
                setAreThereAnyBirthdays(birthdaysFromJson.length > 0);

                setAnniversaries(anniversariesFromJson);
                setAreThereAnyAnniversaries(anniversariesFromJson.length > 0);
            });
    }, []);

    function getMonthName(monthNumber: number) {
        const monthNames = [
            'zero','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthNames[monthNumber];
    }

    function isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()
            && date1.getDate() === date2.getDate();
    }

    function getBirthdayGreeting(birthdayDate: string): {firstLine: string, secondLine?: string} {
        const today = new Date();
        const birthday = dateFromStringWithDefaultTimezone(birthdayDate) || new Date(birthdayDate);

        if (isSameDay(today, birthday)) {
            return {firstLine: "Happy Birthday,"};
        }

        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (isSameDay(yesterday, birthday)) {
            return {firstLine: "It was your birthday yesterday!", secondLine: "Happy Birthday,"};
        }

        const lastSaturday = new Date();
        lastSaturday.setDate(today.getDate() - ((today.getDay() + 1) % 7));

        if (isSameDay(lastSaturday, birthday)) {
            return {firstLine: "It was your birthday on Saturday!", secondLine: "Happy Birthday,"};
        }

        return {firstLine: "Happy Birthday,"};
    }

    return (
        <div className='celebrations-widget'>
            <div className='celebrations-block-description'>BIRTHDAYS & ANNIVERSARIES</div>
            <div className='celebrations-block-title'>Cheers to another trip around the sun!</div>

            <div className='celebrations-body'>
                {!areThereAnyAnniversaries && !areThereAnyBirthdays ? (
                    <div className={'celebrations-absence-text'}>
                        <p> Looks like we forgot to order the confetti! <p>
                        </p> No birthdays or anniversaries today, folks! </p>
                    </div>
                ) : (
                    <>
                        <div className='column birthdays'>
                            {!areThereAnyBirthdays ? (
                                <div className={'celebrations-absence-text'}>
                                    <p> Looks like we forgot to order the confetti! </p>
                                    <p> No birthdays today, folks! </p>
                                </div>
                            ) : (
                                birthdays.map((birthday, index) => {
                                    const greeting = getBirthdayGreeting(birthday.value.currentBirthdayDate);
                                    const employeeName = <span className={'employee-name'}>{birthday.value.nickname}!</span>;
                                    const employeeProfileLink = `${EMPLOYEE_PROFILE}/${birthday.value.nickname}`;

                                    const EmployeeLink = (
                                        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={employeeProfileLink}>
                                            <span className={'employee-name'}>{employeeName}</span>
                                        </Link>
                                    );

                                    return (
                                        <div key={index} className='event-card'>
                                            <div className='event-image-wrapper'>
                                                <Link style={{ textDecoration: 'none' }} to={EMPLOYEE_PROFILE + '/' + (birthday.value.nickname)}>
                                                    {birthday.linkToTheImage ? (
                                                        <div className='event-image-container'>
                                                            <img className='event-image image-birthday'
                                                                 src={birthday.linkToTheImage}
                                                                 alt='Event Image'/>
                                                            <div className='gift-icon'>
                                                                <img src={birthdayIcon} alt='Event Image'/>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='empty-image'>
                                                            <p>{ (birthday.value.nickname) ? (birthday.value.nickname).charAt(0).toUpperCase() : "" }</p>
                                                            <div className='gift-icon'>
                                                                <img src={birthdayIcon} alt='Event Image'/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Link>
                                            </div>
                                            <div className='event-info'>
                                                <div className='event-info-first-line'>
                                                    <p>{greeting.firstLine} {greeting.secondLine ? "" : EmployeeLink}</p>
                                                </div>
                                                {greeting.secondLine && (
                                                    <div className='event-info-second-line'>
                                                        <p style={{marginRight: '5px'}}>{greeting.secondLine} {EmployeeLink}</p>
                                                        <div className='event-date'>{birthday.value.day} {getMonthName(birthday.value.month)}</div>
                                                    </div>
                                                )}
                                                {!greeting.secondLine && (
                                                    <div className='event-info-second-line'>
                                                        <div className='event-date'>{birthday.value.day} {getMonthName(birthday.value.month)}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                        <div className='column anniversaries'>
                            {!areThereAnyAnniversaries ? (
                                <div className={'celebrations-absence-text'}>
                                    <p> Looks like we forgot to order the confetti! </p>
                                    <p> No anniversaries today, folks! </p>
                                </div>
                            ) : (
                                anniversaries.map((anniversary, index) => {
                                    const employeeProfileLink = `${EMPLOYEE_PROFILE}/${anniversary.value.nickname}`;
                                    const EmployeeLink = (
                                        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={employeeProfileLink}>
                                            <span className={'employee-name'}>{anniversary.value.nickname}</span>
                                        </Link>
                                    );

                                    return (
                                        <div key={index} className='event-card'>
                                            <div className='event-image-wrapper'>
                                                <Link style={{ textDecoration: 'none' }} to={employeeProfileLink}>
                                                    {anniversary.linkToTheImage ? (
                                                        <div className='event-image-container'>
                                                            <img className='event-image image-anniversary'
                                                                 src={anniversary.linkToTheImage}
                                                                 alt='Event Image'/>
                                                            <div className='pyramid-icon'>
                                                                <img src={pyramidIcon} alt='Event Image'/>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='empty-image'>
                                                            <p>{ (anniversary.value.nickname) ? (anniversary.value.nickname).charAt(0).toUpperCase() : "" }</p>
                                                            <div className='pyramid-icon'>
                                                                <img src={pyramidIcon} alt='Event Image'/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Link>
                                            </div>
                                            <div className='event-info'>
                                                <div className='event-info-first-line'>
                                                    <p>Happy Anniversary, {EmployeeLink}</p> {/* Use EmployeeLink here */}
                                                </div>
                                                <div className='event-info-second-line'>
                                                    <p>You've been with us for</p>
                                                    <div className='event-years'>
                                                        {anniversary.value.years} {anniversary.value.years > 1 ? 'years' : 'year'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default CelebrationsWidget;