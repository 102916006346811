import React, { useState, useEffect } from 'react';
import { TEAMS } from '../../../../utils/constants/api';
import { TeamFromApi } from '../../../../shared/Team/data/TeamFromApi';
import DeleteTeam from './Modal/DeleteTeam';
import EditTeam from './Modal/EditTeam';
import CreateTeam from './Modal/CreateTeam';
import Button, {Size, Type} from '../../../Component/Button/Button';
import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {ROLE_ADMIN, ROLE_HR} from "../../../../shared/Security/constants/AccessLevels";

type InnerTab = {
    id: string | null,
    authEmployee: AuthEmployee,
};

function InnerTab(props: InnerTab) {
    const [teams, setTeams] = useState<TeamFromApi[]>([]);
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [editTeamModal, setEditTeamModal] = useState<boolean>(false);
    const [deleteTeamModal, setDeleteTeamModal] = useState<boolean>(false);
    const [teamToChange, setTeamToChange] = useState<TeamFromApi | null>(null);
    const [teamToDelete, deleteTeam] = useState<TeamFromApi | null>(null);
    const [createTeamModal, setCreateTeamModal] = useState<boolean>(false);

    function getTeamsByCompany(id: string) {
        return fetch(TEAMS + '?company_ids=' + JSON.stringify([id]), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        })
            .then((response) => response.json())
            .then((teams: TeamFromApi[]) => setTeams(teams));
    }

    useEffect(() => {
        if (props.id) {
            getTeamsByCompany(props.id);
        }
    }, [props.id]);

    const toggleExpand = (id: string) => {
        setExpanded({
            ...expanded,
            [id]: !expanded[id],
        });
    };

    function editTeamModalVisibility(team: TeamFromApi): void {
        setTeamToChange(team);
        setEditTeamModal(true);
    }

    function confirmDeleteTeamModalVisibility(team: TeamFromApi): void {
        deleteTeam(team)
        setDeleteTeamModal(true);
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    const renderTeam = (team: TeamFromApi) => {
        const hasChildren = teams.some((u) => u.parentId === team.id);
        return (
            <React.Fragment key={team.id}>
                <li onClick={() => toggleExpand(team.id)}
                    onMouseEnter={() => {
                        const elem = document.getElementById(team.id);
                        if (elem) {
                            elem.style.visibility = 'visible';
                        }
                    }}
                    onMouseLeave={() => {
                        const elem = document.getElementById(team.id);
                        if (elem) {
                            elem.style.visibility = "hidden";
                        }
                    }}>
                    <div className={`${!hasChildren ? 'without-child' : ''} team-block`}>
                        {hasChildren && (
                            <i className={`material-icons ${
                                expanded[team.id] ? '' : 'material-icons-arrow-right'
                            }`} >
                                arrow_drop_down
                            </i>
                        )}
                        <span className={`${hasChildren ? 'show-click' : ''}`}>{team.name}</span>
                        {hasRole ?
                            <div id={team.id} className={'btns-block'} style={{ visibility: "hidden" }}>
                                <i onClick={ (event) => {
                                    editTeamModalVisibility(team);
                                    event.stopPropagation()
                                }} className={`material-icons`}>edit</i>
                                <i onClick={ (event) => {
                                    confirmDeleteTeamModalVisibility(team);
                                    event.stopPropagation()
                                }} className={`material-icons`}>delete</i>
                            </div>
                        : null}
                    </div>
                </li>
                {hasChildren && (
                    <ul style={{ display: expanded[team.id] ? 'block' : 'none' }}>
                        {teams.map((u) => {
                            if (u.parentId === team.id) {
                                return renderTeam(u);
                            }
                            return null;
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className="vertical-tabs-content">
            {hasRole ?
                <div className='add-button-block'>
                    {props.id ?
                        <Button
                            text="<i className='material-icons add-item'>add</i> Add team"
                            size={ Size.Medium }
                            type={ Type.Accent }
                            onClick={ () => setCreateTeamModal(true) }
                        /> : null
                    }
                </div>
            : null}
            <ul>
                {teams.map((team) => {
                    if (!team.parentId) {
                        return renderTeam(team);
                    }
                    return null;
                })}
            </ul>
            <CreateTeam
                teams={ teams }
                companyId={ props.id ?? ''}
                opened={ createTeamModal }
                onOk={ () => {
                    const result = props.id ? getTeamsByCompany(props.id) : null;
                    setCreateTeamModal(false)
                }}
                onClose={ () => setCreateTeamModal(false) }
            />
            {
                teamToDelete ?
                    <DeleteTeam
                        opened={ deleteTeamModal }
                        onOk={ () => {
                            const result = props.id ? getTeamsByCompany(props.id) : null;
                            setDeleteTeamModal(false);
                            deleteTeam(null)
                        }}
                        onClose={ () => { setDeleteTeamModal(false); deleteTeam(null) } }
                        team={ teamToDelete }
                    /> : null
            }
            {
                teamToChange
                    ?
                    <EditTeam
                        teams={ teams.filter(team => team.id !== teamToChange.id) }
                        opened={ editTeamModal }
                        onOk={ () => {
                            const result = props.id ? getTeamsByCompany(props.id) : null;
                            setEditTeamModal(false);
                            setTeamToChange(null)
                        }}
                        onClose={ () => { setEditTeamModal(false); setTeamToChange(null) } }
                        team={ teamToChange }
                    />
                    : null
            }
        </div>

    );
}

export default InnerTab;