import { JOB_TITLES } from "../../utils/constants/api";
import { SelectValue } from "../Common/data/SelectValue";
import { JobTitleFromApi } from "../JobTitle/data/JobTitleFromApi";

export const fetchJobTitleOptions = async (): Promise<SelectValue[]> => {
    const response = await fetch(JOB_TITLES, {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
    });
    const jobTitles: JobTitleFromApi[] = await response.json();

    return jobTitles.map(jobTitle => ({
        value: jobTitle.id,
        label: jobTitle.name
    }));
};
