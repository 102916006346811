import React, {useEffect, useState} from 'react';
import Modal from '../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../Component/Input/SelectInput/SelectInput';
import { TeamFromApi } from '../../../../../shared/Team/data/TeamFromApi';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import {EMPLOYEES} from "../../../../../utils/constants/api";
import {EmployeeFromApi} from "../../../../../shared/Employee/data/EmployeeFromApi";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    teamValue: {
        id: string,
        name: string
    } | null,
    teams: TeamFromApi[],
    parentTeam: Team | null,
    leaderTeam: SelectValue | null,
    companyId: string,
    okText: string,
    onCancel: () => any,
    onOk: (
        name: string,
        parentId: string | null,
        companyId: string,
        leaderId: string | null,
        validate: (errors: ValidationError[]) => void
    ) => void
};

type Team = {
    label: string,
    value: string
};

function BaseModal(props: BaseModal) {
    const [name, setName] = useState<string>(props.teamValue ? props.teamValue.name : '');
    const [nameError, setNameError] = useState<string | null>(null);
    const [parentTeam, setParentTeam] = useState<Team | null>(props.parentTeam);
    const [parentTeamError, setParentTeamError] = useState<string | null>(null);
    const [leaderTeam, setLeaderTeam] = useState<SelectValue | null>(props.leaderTeam);
    const [leaderTeamError, setLeaderTeamError] = useState<string | null>(null);
    const [leaderOptions, setLeaderOptions] = useState<SelectValue[]>([]);

    const childFilter = (teams: any[], parentId?: any) => {
        if (!parentId) {
            return teams.map(({ id, name }) => ({ label: name, value: id }));
        }

        const childIds = new Set<number>();
        const getChildIds = (parentId: any) => {
            teams.forEach((team) => {
                if (team.parentId === parentId) {
                    childIds.add(team.id);
                    getChildIds(team.id);
                }
            });
        };
        getChildIds(parentId);

        const allowedTeams = teams.filter((team) => {
            if (team.id === parentId || team.parentId === parentId || childIds.has(team.id)) {
                return false;
            }
            return true;
        });

        return allowedTeams.map(({ id, name }) => ({ label: name, value: id }));
    };


    const parentTeamOptions = childFilter(
        props.teams,
        props.teamValue ? props.teamValue.id : null
    );

    parentTeamOptions.unshift({ label: 'not chosen', value: '' });

    function getLeaders() {
        return fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employees: EmployeeFromApi[]) => {
                let options = employees.map(employee => {
                    return {
                        value: employee.id,
                        label: employee.nickname
                    }
                });
                options = [{label: 'not chosen', value: ''}].concat(options);
                setLeaderOptions(options)
            });
    }

    useEffect(() => {
        getLeaders();
        if (props.modalTitle === 'Create Team') {
            resetAll()
        }
    }, [props.opened]);

    function resetAll(): void {
        setName('');
        setNameError(null);
        setParentTeam(null);
        setParentTeamError(null);
        setLeaderTeam(null);
        setLeaderTeamError(null);
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'team.name') {
                setNameError(error.message);
            } else if (error.property === 'team.leaderId') {
                setLeaderTeamError(error.message);
            } else if (error.property === 'team.parentId') {
                setParentTeamError(error.message);
            }
        });
    }

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={() => {
                props.onOk(
                    name,
                    parentTeam ? parentTeam.value : null,
                    props.companyId,
                    leaderTeam ? leaderTeam.value : null,
                    validate
                    );
            }}
        >
            <TextField
                label='Team name'
                placeholder='Team name...'
                required={ true }
                value={ name }
                type={ Type.Textarea }
                horizontal={ true }
                error={ nameError }
                onChange={ newName => {
                    setName(newName)
                    setNameError(null)
                }}
                maxLength={50}
            />
            <SelectInput
                label='Parent Team'
                required={ false }
                isMulti={ false }
                value={ parentTeam }
                options={ parentTeamOptions }
                error={ parentTeamError }
                onChange={ newParentTeam => {
                    setParentTeam(newParentTeam);
                    setParentTeamError(null);
                }}
                isSearchable={ true }
            />
            <SelectInput
                label='Leader'
                required={ false }
                isMulti={ false }
                value={ leaderTeam }
                options={ leaderOptions }
                error={ leaderTeamError }
                onChange={ newLeader => {
                    setLeaderTeam(newLeader);
                    setLeaderTeamError(null)
                }}
                isSearchable={ true }
            />
        </Modal>
    );
}

export default BaseModal;