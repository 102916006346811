import React, {useState, createRef, useEffect, useRef} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";

import Modal from "../../../Page/Layer/Modal/Modal";
import Button, {Size, Type} from "../../Button/Button";

import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {EMPLOYEES} from "../../../../utils/constants/api";
import {MAX_IMAGE_SIZE} from "../../../../utils/constants/imageProcess";

import "cropperjs/dist/cropper.css";
import "./ProfileImageLoader.scss";


type ProfileImageLoader = {
    opened: boolean,
    modalTitle: string,
    okText: string,
    nickname: string,
    cancelText: string,
    onClose: () => any,
    onOk: (images: {croppedImage: string }) => void,
    croppedImageUrl: string,
    authEmployee: AuthEmployee,
};

function ProfileImageLoader(props: ProfileImageLoader) {

    const [uploadedImage, setUploadedImage] = useState("");
    const [croppedImage, setCroppedImage] = useState("");
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [imageKey, setImageKey] = useState(0);
    const cropperRef = useRef<ReactCropperElement | null>(null);
    const [isCropButtonClicked, setIsCropButtonClicked] = useState(false);
    const [deleteImg, setDeleteImg] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setUploadedImage("");
        setCroppedImage("");
        setIsCropButtonClicked(false);
        setDeleteImg(false);
        if (cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.clear();
        }
        setImageKey(imageKey + 1);
        setCroppedImageUrl(props.croppedImageUrl);
    }, [props.opened])

    const onChange = (e: any) => {
        setDeleteImg(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        let reader = new FileReader();
        reader.onload = () => {
            setUploadedImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setIsCropButtonClicked(true);
        setDeleteImg(false);
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCroppedImage(
                cropperRef.current?.cropper.getCroppedCanvas({
                    width: MAX_IMAGE_SIZE,
                    height: MAX_IMAGE_SIZE,
                }).toDataURL()
            );
        }
    };

    const onOk = () => {
        setIsCropButtonClicked(false);
        if (deleteImg) {
            fetch(EMPLOYEES + '/' + props.nickname + '/images', {
                method: "DELETE",
                headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
            })
                .then(response => {
                    if (response.ok) {
                        props.onOk({croppedImage});
                        props.onClose();
                        if (props.nickname === props.authEmployee.nickname) {
                            props.authEmployee.imageUrl = '';
                        }
                    }
                })
        } else {
            fetch(croppedImage)
                .then(res => res.blob())
                .then(croppedBlob => {
                    const formData = new FormData();
                    formData.append("croppedImage", croppedBlob, "cropped.jpg");

                    fetch(`/api/employees/${props.nickname}/images`, {
                        method: "POST",
                        body: formData,
                    })
                        .then(response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                throw new Error("Failed to upload images.");
                            }
                        })
                        .then(data => {
                            console.log("Image upload succesfully", );

                            props.onOk({ croppedImage });
                            props.onClose();
                            if (props.nickname === props.authEmployee.nickname) {
                                props.authEmployee.imageUrl = data.imgPath;
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                });
        }
    };

    const handleDeleteClick = () => {
        setDeleteImg(true);
        setUploadedImage("");
        setCroppedImage("");
        setIsCropButtonClicked(false);

        if (cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.destroy();
        }

        setImageKey(prevKey => prevKey + 1);

        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    return (
            <Modal
                opened={props.opened}
                modalTitle={props.modalTitle}
                cancelText='Cancel'
                okText={props.okText}
                onCancel={() => {
                    props.onClose();
                }}
                onOk={onOk}
                isDisabled={!isCropButtonClicked && !deleteImg}
            >
                <div className="profile-image-loader">
                    <div className="column-element">
                        <div className="row-element">
                            <div className="column-element">
                                <Cropper
                                    key={imageKey}
                                    ref={cropperRef}
                                    style={{width: "250px", height: "100%", margin: "10px"}}
                                    zoomTo={0.1}
                                    aspectRatio={1}
                                    preview=".img-preview"
                                    src={uploadedImage}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false}
                                    guides={true}
                                />
                            </div>
                            {croppedImage && (
                                <>
                                    <div>
                                        <img
                                            style={{width: "250px", borderRadius: "50%", margin:"10px"}}
                                            src={croppedImage} alt="cropped"
                                        />
                                    </div>
                                </>
                            )}

                        </div>
                        {(croppedImageUrl || uploadedImage) &&
                            <label
                                className="button delete-btn medium-button white"
                                onClick={handleDeleteClick}
                            >
                                Delete
                            </label>
                        }
                        {uploadedImage && (
                            <>
                                <div className="column-element">
                                    <Button
                                        text="Crop image"
                                        size={Size.Medium}
                                        type={Type.Accent}
                                        onClick={getCropData}
                                    />
                                </div>
                            </>
                        )}
                        <label
                            className="button medium-button accent"
                            style={{ marginTop: "10px" }}
                        >
                            Upload Image
                            <input
                                key={imageKey}
                                ref={inputRef}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onChange}
                                onClick={() => {
                                    if (inputRef) {
                                        const newInput = document.createElement("input");
                                        inputRef.current = newInput;
                                    }}}
                            />
                        </label>
                    </div>
                </div>
            </Modal>
    );
}

export default ProfileImageLoader;