import {EMPLOYEES} from "../constants/api";

export function checkSubstitute(employeeId: string, dateStart: string, dateEnd: string, requestId?: string) {
    const url = EMPLOYEES + '/employee/' + employeeId + '/substitute?date_start=' +
        dateStart + '&date_end=' + dateEnd + '&except_request_id=' + requestId;

    return fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
    })
        .then(response => response.json())
        .then(substitute => {
            return substitute ? substitute : null;
        })
        .catch(error => {
            console.error('Error:', error);
            return false;
        });
}