import React from 'react';
import BaseModal  from './BaseModal';
import { LinkFromApi } from '../../../../../shared/Link/data/LinkFromApi';
import { LINKS } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';

type EditLink = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    link: LinkFromApi,
    companies: CompanyFromApi[],
};

function EditLink(props: EditLink) {
    function edit(name: string, url: string, companies: string[], visible: boolean, validate: (errors: ValidationError[]) => void): void {
        fetch(LINKS + '/' + props.link.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                url: url,
                companies: companies,
                visible: visible
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Link'
            name={ props.link.value.name }
            url={ props.link.value.url }
            visible={props.link.value.visible }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
            selectedCompanies={ props.companies }
            companies={props.link.value.companies.map(company => {
                    let companyName = '';
                    props.companies.map(companyValue => {
                        if (companyValue.id === company) {
                            companyName = companyValue.name;
                        }
                    })
                    return {
                        label: companyName,
                        value: company
                    }
                })
            }
         />
    )
}

export default EditLink;