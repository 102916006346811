import React, {useEffect, useState} from 'react';
import './JobInfo.scss';
import {EmployeeFromApi} from "../../../../../../shared/Employee/data/EmployeeFromApi";
import EmployeeStatus from "./EmployeeStatus";
import {EMPLOYEE} from "../../../../../../utils/constants/api";
import {EmployeeStatusFromApi} from "../../../../../../shared/Employee/Profile/JobInfo/data/EmployeeStatusFromApi";
import {VALIDATION_ERROR} from "../../../../../../utils/constants/errors";
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import DatePicker from "../../../../../Component/Input/DatePicker/DatePicker";
import {CurrentJobInfoFromApi} from "../../../../../../shared/Employee/Profile/JobInfo/data/CurrentJobInfoFromApi";
import {AuthEmployee} from "../../../../../../shared/Security/data/AuthEmployee";

import {
    ON_PROBATION_PERIOD,
    ON_STAFF,
    TERMINATED,
    UNDER_TERMINATION
} from "../../../../../../utils/constants/employeeStatuses";

import {EMPLOYMENT_DATE, HIRE_DATE, TERMINATION_DATE,} from "../../../../../../utils/constants/hireDateTypes";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../shared/Security/constants/AccessLevels";
import {dateFromStringWithDefaultTimezone} from "../../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type EmployeeStatusHistory = {
    employee: EmployeeFromApi,
    currentJobInfo: CurrentJobInfoFromApi | null,
    onDataUpdate: () => void,
    authEmployee: AuthEmployee,
    triggerParentUpdate: () => void;
    updateCurrentJobInfo: () => void;
    setIsHired: (isHired: boolean) => void;
};

function EmployeeStatusHistory(props: EmployeeStatusHistory) {

    const [employeeStatuses, setEmployeeStatuses] = useState<EmployeeStatusFromApi[]>([]);
    const [hireDate, setHireDate] = useState<string | null>(null);
    const [employmentDate, setEmploymentDate] = useState<string | null>(null);
    const [terminationDate, setTerminationDate] = useState<string | null>(null);

    const [newStatusVisible, setNewStatusVisible] = useState<boolean>(false);
    const [addNewStatusButtonClicked, setAddNewStatusButtonClicked] = useState<boolean>(false);

    const [forceUpdateKey, setForceUpdateKey] = useState<number>(0);

    const [creatingNewStatus, setCreatingNewStatus] = useState<boolean>(false);

    const [dateError, setDateError] = useState<string>('');

    const newStatus: EmployeeStatusFromApi = {
        id: 'new',
        employeeId: props.employee.id,
        employeeStatusValue: {
            statusType: '',
            previousStatusType: null,
            dateStart: '',
            dateEnd: '',
            employeeLink: '',
        }
    };

    const scrollToNewStatus = () => {
        setTimeout(() => {
            const newStatusElement = document.getElementById('new-status');
            if (newStatusElement) {
                newStatusElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    };


    useEffect(() => {
        getEmployeeStatuses();
    }, []);

    useEffect(() => {
        if (!creatingNewStatus) {
            if (employeeStatuses.length > 0) {
                setNewStatusVisible(false);
            } else {
                setNewStatusVisible(true);
            }
        }
    }, [employeeStatuses]);

    useEffect(() => {
        if (addNewStatusButtonClicked) {
            scrollToNewStatus();
        }
    }, [addNewStatusButtonClicked]);

    useEffect(() => {
        const dates = props.currentJobInfo?.currentJobInfoValue?.dates;

        setHireDate(dates?.hireDate ? dates.hireDate.substring(0, 10) : null);
        setEmploymentDate(dates?.employmentDate ? dates.employmentDate.substring(0, 10) : null);
        setTerminationDate(dates?.terminationDate ? dates.terminationDate.substring(0, 10) : null);
    }, [props.currentJobInfo, hireDate]);

    useEffect(() => {
        if(hireDate !== null) {
            props.setIsHired(true);
            props.updateCurrentJobInfo();
        }
    }, [hireDate]);

    function createEmployeeStatus(
        statusType: string,
        previousStatusType: string | null,
        dateStart: string | null,
        dateEnd: string | null,
        employeeLink: string,
        validate: (errors: ValidationError[]) => void,
        setCheckMarkIsVisible: (isVisible: boolean) => void,
        setSelectIsDisabled: (isDisabled: boolean) => void,
        index: number
    ) {

        let formData = new FormData();
        formData.append('employee-status', JSON.stringify({
            statusType: statusType,
            previousStatusType: previousStatusType,
            dateStart: dateStart,
            dateEnd: dateEnd,
            employeeLink: employeeLink,
            authorEmail: props.authEmployee.email
        }));

        fetch(EMPLOYEE + '/' + props.employee.id + '/employee-statuses', {
            method: 'POST', body: formData
        })
            .then(response => {
                if (response.ok) {
                    updateHireDates(
                        statusType,
                        statusType === 'underTermination' ? dateEnd : dateStart,
                        index,
                    );
                    getEmployeeStatuses();
                    setNewStatusVisible(false);
                    setCreatingNewStatus(false);
                    setCheckMarkIsVisible(false);
                    setSelectIsDisabled(true);
                } else if (response.status === VALIDATION_ERROR) {
                    setCreatingNewStatus(true);
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function editEmployeeStatus(
        statusId: string,
        statusType: string,
        previousStatusType: string | null,
        dateStart: string | null,
        dateEnd: string | null,
        employeeLink: string,
        validate: (errors: ValidationError[]) => void,
        setCheckMarkIsVisible: (isVisible: boolean) => void,
        index: number
    ) {
        fetch(EMPLOYEE + '/' + props.employee.id + '/employee-statuses/' + statusId, {
            method: 'PUT',
            body: JSON.stringify({
                statusType: statusType,
                previousStatusType: previousStatusType,
                dateStart: dateStart,
                dateEnd: dateEnd,
                employeeLink: employeeLink,
                authorEmail: props.authEmployee.email
            })
        })
            .then(response => {
                if (response.ok) {
                    updateHireDates(
                        statusType,
                        statusType === 'underTermination' ? dateEnd : dateStart,
                        index,
                    );
                    getEmployeeStatuses();
                    setCheckMarkIsVisible(false);
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function getEmployeeStatuses() {
        fetch(EMPLOYEE + '/' + props.employee.id + '/employee-statuses', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employeeStatuses: EmployeeStatusFromApi[]) => {
                setEmployeeStatuses(employeeStatuses);
                setForceUpdateKey(forceUpdateKey + 1);
            });
    }

    function updatePreviousStatusDateEnd(index: number, dateEnd: string) {
        if(index > 0) {
            setCreatingNewStatus(true);
            setEmployeeStatuses((prevState) => {
                const updatedStatuses = [...prevState];
                updatedStatuses[index - 1].employeeStatusValue.dateEnd = dateEnd;
                return updatedStatuses;
            });
        }
    }

    function updateNextStatusDateStart(index: number, dateStart: string) {
        if(index < employeeStatuses.length - 1) {
            setCreatingNewStatus(true);
            setEmployeeStatuses((prevState) => {
                const updatedStatuses = [...prevState];
                updatedStatuses[index + 1].employeeStatusValue.dateStart = dateStart;
                return updatedStatuses;
            });
        }
    }

    function getLastStatusType() {
        if (employeeStatuses.length > 0) {
            return employeeStatuses[employeeStatuses.length - 1].employeeStatusValue.statusType;
        }
    }

    function setDateValue(value: string | null, field: string) {
        fetch(EMPLOYEE + '/' + props.employee.id + '/employee-dates/' + field + '/' + value, {
            method: 'PUT',
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((currentJobInfo: CurrentJobInfoFromApi) => {
                        props.updateCurrentJobInfo();
                    });
                } else if (response.status === VALIDATION_ERROR) {
                    console.log('validation error');
                }
            });
    }

    function updateHireDates(statusType: string, date: string|null, index: number) {

        if (date) {
            if (statusBelongsToTheFirstHirePeriod(index)) {
                if (statusType === ON_PROBATION_PERIOD && index === 0) {
                    setDateValue(date, HIRE_DATE);
                    props.onDataUpdate();
                } else if (statusType === ON_STAFF && index === 0) {
                    setDateValue(date, HIRE_DATE);
                    setDateValue(date, EMPLOYMENT_DATE);
                    props.onDataUpdate();
                } else if (statusType === ON_STAFF && index === 1) {
                    setDateValue(date, EMPLOYMENT_DATE);
                } else if (statusType === UNDER_TERMINATION) {
                    setDateValue(date, TERMINATION_DATE);
                    props.onDataUpdate();
                }
            }
        }
    }

    function statusBelongsToTheFirstHirePeriod(index: number) :boolean {

        let firstTerminatedStatusIndex = getFirstTerminatedStatusIndex();

        if (firstTerminatedStatusIndex === -1) {
            return true;
        }

        return index <= firstTerminatedStatusIndex;
    }

    function getFirstTerminatedStatusIndex() {
        let statusTypesSequence = getEmployeeStatusesTypesSequence();

        return statusTypesSequence
            .findIndex((status: string) => status === TERMINATED);
    }

    function getEmployeeStatusesTypesSequence(): string[] {
        return employeeStatuses.map((status: EmployeeStatusFromApi) => status.employeeStatusValue.statusType);
    }

    function triggerEmployeeStatusesUpdate() {
        getEmployeeStatuses();
        props.triggerParentUpdate();
    }

    function currentStatusIndex(): number {
        return employeeStatuses.findIndex(
            (status: EmployeeStatusFromApi) => {
                const dateStart = new Date(status.employeeStatusValue.dateStart);
                const dateEnd = status.employeeStatusValue.dateEnd ? new Date(status.employeeStatusValue.dateEnd) : new Date();
                const today = new Date();
                return dateStart <= today && (dateEnd >= today || status.employeeStatusValue.dateEnd === '');
            });
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div
            className={'info'}
        >

            <div className={'info-header'}>
                <div className={'icon'}><i className='material-symbols-outlined'>work_history</i></div>
                <h3>Employee Status History</h3>
            </div>

            <div className={'info-body status-history'}>

                <div className={'profile-field-group'}>
                    { hireDate && (
                        <div className={'profile-field'}>
                            <div className={'profile-field-title'}>
                                <p>Hire Date</p>
                            </div>
                            <div className={'profile-field-value'}>
                                <i className="material-symbols-outlined">date_range</i>
                                <DatePicker
                                    label={''}
                                    required={false}
                                    horizontal={false}
                                    className={`default-pointer ${props.employee.id === props.authEmployee.id ? 'disabled-pointer' : ''}`}
                                    date={dateFromStringWithDefaultTimezone(hireDate)}
                                    error={dateError}
                                    onChange={() => {
                                    }}
                                    minDate={null}
                                    maxDate={null}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    )}

                    {employmentDate && (
                        <div className={'profile-field'}>
                            <div className={'profile-field-title'}>
                                <p>Employment Date</p>
                            </div>
                            <div className={'profile-field-value'}>
                                <div className={'profile-field-value'}>
                                    <i className="material-symbols-outlined">date_range</i>
                                    <DatePicker
                                        label={''}
                                        required={false}
                                        className={`default-pointer ${props.employee.id === props.authEmployee.id ? 'disabled-pointer' : ''}`}
                                        horizontal={false}
                                        date={dateFromStringWithDefaultTimezone(employmentDate)}
                                        error={dateError}
                                        onChange={() => {
                                        }}
                                        minDate={null}
                                        maxDate={null}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    { terminationDate && (
                        <div className={'profile-field'}>
                            <div className={'profile-field-title'}>
                                <p>Termination Date</p>
                            </div>
                            <div className={'profile-field-value'}>
                                <div className={'profile-field-value'}>
                                    <i className="material-symbols-outlined">date_range</i>
                                    <DatePicker
                                        label={''}
                                        className={`default-pointer ${props.employee.id === props.authEmployee.id ? 'disabled-pointer' : ''}`}
                                        required={false}
                                        horizontal={false}
                                        date={dateFromStringWithDefaultTimezone(terminationDate)}
                                        error={dateError}
                                        onChange={() => {
                                        }}
                                        minDate={null}
                                        maxDate={null}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {hasRole ? (
                    <>
                        <div key={forceUpdateKey}>
                            {employeeStatuses.map((status: EmployeeStatusFromApi, index: number) => {
                                return (
                                    <EmployeeStatus
                                        index={index}
                                        status={status}
                                        employee={props.employee}
                                        selectIsDisabled={true}
                                        previousStatus={index > 0 ? employeeStatuses[index - 1] : null}
                                        nextStatus={index > 0 ? employeeStatuses[index + 1] : null}
                                        createEmployeeStatus={createEmployeeStatus}
                                        editEmployeeStatus={editEmployeeStatus}
                                        updatePreviousStatusDateEnd={(index: number, dateEnd: string) => updatePreviousStatusDateEnd(index, dateEnd)}
                                        updateNextStatusDateStart={(index: number, dateStart: string) => updateNextStatusDateStart(index, dateStart)}
                                        setNewStatusVisible={(isVisible: boolean) => setNewStatusVisible(isVisible)}
                                        isSaved = {true}
                                        authEmployee={props.authEmployee}
                                        triggerParentUpdate={triggerEmployeeStatusesUpdate}
                                    />
                                );
                            })}
                        </div>

                        {newStatusVisible && (
                            <>
                                <EmployeeStatus
                                    id="new-status"
                                    index={employeeStatuses.length}
                                    status={newStatus}
                                    employee={props.employee}
                                    previousStatus={employeeStatuses[employeeStatuses.length-1]}
                                    nextStatus={null}
                                    selectIsDisabled={false}
                                    createEmployeeStatus={createEmployeeStatus}
                                    editEmployeeStatus={editEmployeeStatus}
                                    updatePreviousStatusDateEnd={(index: number, dateEnd: string) => updatePreviousStatusDateEnd(employeeStatuses.length-1, dateEnd)}
                                    updateNextStatusDateStart={(index: number, dateStart: string) => updateNextStatusDateStart(employeeStatuses.length-1, dateStart)}
                                    isSaved = {false}
                                    authEmployee={props.authEmployee}
                                    triggerParentUpdate={triggerEmployeeStatusesUpdate}
                                />
                            </>
                        )}

                        {(newStatusVisible || getLastStatusType() !== TERMINATED) && !props.employee.isDeleted && (
                            <>
                                <div className={'profile-field-group'}>
                                    <div className={'profile-field'}>
                                        <div className={'profile-field-title'}>
                                        </div>
                                        <div className={'profile-field-value'}>
                                            <button
                                                className={'add-status-button'}
                                                onClick={() => {
                                                    setNewStatusVisible(true);
                                                    setAddNewStatusButtonClicked(true);
                                                }}
                                            >
                                                <span className={'plus-sign'}>+</span>
                                                Add status
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : currentStatusIndex() !== -1 && (
                    <>
                        <div>
                            {employeeStatuses.length > 0 && (
                                <EmployeeStatus
                                    index={currentStatusIndex()}
                                    status={employeeStatuses[currentStatusIndex()]}
                                    employee={props.employee}
                                    selectIsDisabled={true}
                                    previousStatus={employeeStatuses.length > 1 ? employeeStatuses[employeeStatuses.length - 2] : null}
                                    nextStatus={null}
                                    createEmployeeStatus={createEmployeeStatus}
                                    editEmployeeStatus={editEmployeeStatus}
                                    updatePreviousStatusDateEnd={(index: number, dateEnd: string) => updatePreviousStatusDateEnd(index, dateEnd)}
                                    updateNextStatusDateStart={(index: number, dateStart: string) => updateNextStatusDateStart(index, dateStart)}
                                    setNewStatusVisible={(isVisible: boolean) => setNewStatusVisible(isVisible)}
                                    isSaved = {true}
                                    authEmployee={props.authEmployee}
                                    triggerParentUpdate={triggerEmployeeStatusesUpdate}
                                />
                            )}
                        </div>
                    </>
                )}

            </div>

        </div>
    );
}

export default EmployeeStatusHistory;