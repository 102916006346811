import React, {useEffect} from 'react';
import { useContext } from 'react';

import Button, { Size, Type } from '../../../Component/Button/Button';
import {ModalContext} from '../../../Common/Context/ModalContext';

import './Modal.scss';

type Modal = {
    children: React.ReactNode,
    opened: boolean,
    modalTitle: string
    cancelText: string,
    okText: string,
    onCancel: () => any,
    onOk: (...value: any) => any,
    isDisabled?: boolean
    removeFooter?: boolean
};

function Modal(props: Modal) {
    const { setModalOpen } = useContext(ModalContext);

    useEffect(() => {
        setModalOpen(props.opened);
    }, [props.opened]);

    return (
        <div className={ 'modal modal-' + (props.opened ? 'opened' : 'closed') }>
            <div className='modal-content' aria-disabled={props.isDisabled}>
                <div className='modal-header'>
                    <h2>{ props.modalTitle }</h2>
                    <i onClick={ props.onCancel } className='material-icons'>close</i>
                </div>
                <div className='modal-body'>
                    { props.children }
                </div>
                {!props.removeFooter ?
                <div className='modal-footer'>
                    <Button
                        text={ props.cancelText }
                        size={ Size.Medium }
                        type={ Type.Ghost }
                        onClick={ props.onCancel }
                    />
                    <Button
                        isDisabled = {props.isDisabled}
                        text={ props.okText }
                        size={ Size.Medium }
                        type={ props.isDisabled ? Type.Disabled : Type.Accent }
                        onClick={ props.onOk }
                    />
                </div> : null }
            </div>
        </div>                
    );
}

export default Modal;