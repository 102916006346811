import React from 'react';
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal';
import {EmployeeFromApi} from "../../../../../../../shared/Employee/data/EmployeeFromApi";
import {EMPLOYEES} from "../../../../../../../utils/constants/api";

type RehireEmployee = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    employee: EmployeeFromApi,
};

function RehireEmployee(props: RehireEmployee) {
    function rehireEmployee(): void {
        fetch(EMPLOYEES + '/employee/' + props.employee.id + '/rehired', {
            method: 'POST',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Rehire Employee'
            cancelText='Cancel'
            deleteText={props.employee.nickname}
            okText='Rehire'
            onCancel={ props.onClose }
            onOk={ rehireEmployee }
            isRehire={true}
        />
    )
}

export default RehireEmployee;