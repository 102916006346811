import React from 'react';
import BaseModal from './BaseModal';
import { EMPLOYEES } from '../../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError';
import {AuthEmployee} from "../../../../../../../shared/Security/data/AuthEmployee";

type CreateEmployee = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    authEmployee: AuthEmployee,
};

function CreateEmployee(props: CreateEmployee) {
    function create(
        nickname: string,
        lastNameEn: string,
        firstNameEn: string,
        company: string | null,
        email: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('employee', JSON.stringify({
            nickname: nickname,
            lastNameEn: lastNameEn,
            firstNameEn: firstNameEn,
            companyId: company,
            email: email,
        }));
        formData.append('whoCreateEmail', props.authEmployee.email);

        fetch(EMPLOYEES, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Employee'
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateEmployee;