import React from 'react';
import BaseModal  from './BaseModal';
import { SexFromApi } from '../../../../../../../shared/Employee/Sex/data/SexFromApi';
import {SEXES} from '../../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError';

type EditSex = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    sex: SexFromApi,
};

function EditSex(props: EditSex) {
    function edit(name: string, validate: (errors: ValidationError[]) => void): void {
        fetch(SEXES + '/' + props.sex.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Sex'
            name={ props.sex.name }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditSex;