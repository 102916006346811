import React, {useEffect, useState} from 'react';
import Modal from '../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../Component/Input/SelectInput/SelectInput';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import DragAndDrop from '../../../../Component/Input/DragAndDrop/DragAndDrop';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    name: string | null,
    companies: CompanyFromApi[],
    parentCompany: Company | null,
    logotype: File | null,
    domain: string,
    okText: string,
    onCancel: () => any,
    onOk: (
        text: string,
        parentCompanyId: string | null,
        logotype: File | null,
        domain: string,
        validate: (errors: ValidationError[]) => void
    ) => void
};

type Company = {
    label: string,
    value: string
};

function BaseModal(props: BaseModal) {

    const childFilter = (companies: any[], parentName?: any) => {
        const options = [];
        if (parentName) {
            const childs = new Set<number>();
            const getChilds = (name: string) => {
                for (let i = 0; i < companies.length; i++) {
                    if (companies[i].parentName === name) {
                        childs.add(companies[i].id);
                        getChilds(companies[i].name);
                    }
                }
            };
            getChilds(parentName);
            for (let i = 0; i < companies.length; i++) {
                if (companies[i].name !== parentName && !childs.has(companies[i].id)) {
                    options.push({
                        label: companies[i].name,
                        value: companies[i].id
                    });
                }
            }
        } else {
            for (let i = 0; i < companies.length; i++) {
                options.push({
                    label: companies[i].name,
                    value: companies[i].id
                });
            }
        }

        return options;
    };

    const parentCompanyOptions = childFilter(props.companies, props.name);

    if (parentCompanyOptions) {
        parentCompanyOptions.unshift({label: 'not chosen', value: ''});
    }

    const [name, setName] = useState<string>(props.name ?? '');
    const [nameError, setNameError] = useState<string | null>(null);
    const [logotype, setLogotype] = useState<File | null>(props.logotype);
    const [logotypeError, setLogotypeError] = useState<string | null>(null);
    const [domain, setDomain] = useState<string>(props.domain);
    const [domainError, setDomainError] = useState<string | null>(null);
    const [parentCompany, setParentCompany] = useState<Company | null>(props.parentCompany);
    const [parentCompanyError, setParentCompanyError] = useState<string | null>(null);

    useEffect(() => {
        if (props.modalTitle === 'Create Company') {
            resetAll()
        }
    }, [props.opened]);

    function resetAll(): void {
        setName('');
        setLogotype(null);
        setLogotypeError(null);
        setDomain('');
        setDomainError(null);
        setNameError(null);
        setParentCompany(null);
        setParentCompanyError(null);
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'company.name') {
                setNameError(error.message);
            } else if (error.property === 'company.logotype') {
                setLogotypeError(error.message);
            } else if (error.property === 'company.parentId') {
                setParentCompanyError(error.message);
            } else if (error.property === 'company.domain') {
                setDomainError(error.message);
            }
        });
    }

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={() => {
                props.onOk(name, parentCompany ? parentCompany.value : null, logotype, domain, validate);
            }}
        >
            <TextField
                label='Company name'
                placeholder='Company name...'
                required={ true }
                value={ name }
                type={ Type.Textarea }
                horizontal={ true }
                error={ nameError }
                onChange={ newName => {
                    setName(newName)
                    setNameError(null)
                }}
                maxLength={50}
            />
            <TextField
                label='Domain'
                placeholder='Enter domain'
                required={ true }
                value={ domain }
                type={ Type.Textarea }
                horizontal={ true }
                error={ domainError }
                onChange={ newDomain => {
                    setDomain(newDomain)
                    setDomainError(null)
                }}
                maxLength={63}
            />
            <SelectInput
                label='Parent company'
                required={ false }
                isMulti={ false }
                value={ parentCompany }
                options={ parentCompanyOptions }
                isSearchable={ false }
                error={ parentCompanyError }
                onChange={ newParentCompany => {
                    setParentCompany(newParentCompany);
                    setParentCompanyError(null)
                }}
            />
            <DragAndDrop
                label='Upload logotype'
                horizontal={ true }
                required={ false }
                image={ logotype }
                onChange={ newLogotype => {
                    setLogotype(newLogotype);
                }}
                preview={ true }
                error={ logotypeError }
            />
        </Modal>
    );
}

export default BaseModal;