import React from 'react';
import BaseModal  from './BaseModal';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import { COMPANIES } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type EditCompany = {
    opened: boolean,
    companies: CompanyFromApi[],
    onOk: () => void,
    onClose: () => any,
    company: CompanyFromApi
};

function EditCompany(props: EditCompany) {
    const parentCompany = props.company.parentId && props.company.parentName ?
        { label: props.company.parentName, value: props.company.parentId } : null;

    function edit(
        name: string,
        parentId: string | null,
        logotype: File | null,
        domain: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        if (logotype) {
            // @ts-ignore
            formData.append('logotype', logotype.file ? datatoFile(logotype.file, 'logotype') : logotype)
        }

        formData.append('company', JSON.stringify({
            name: name,
            parentId: parentId,
            domain: domain
        }));

        fetch(COMPANIES + '/' + props.company.id, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function datatoFile(data:any, filename:string) {
        let arr = data.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Company'
            companies={ props.companies }
            name={ props.company.name }
            parentCompany={ parentCompany }
            logotype={ props.company.logotype }
            domain={ props.company.domain }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditCompany;