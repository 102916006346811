import React from 'react';
import Button, { Size, Type } from '../../../Component/Button/Button';

import './ConfirmModal.scss';
import parse from "html-react-parser";


type ConfirmModal = {
    opened: boolean,
    modalTitle: string,
    okText: string,
    cancelText: string,
    confirmModalText: string,
    onCancel: () => any,
    onOk: (...value: any) => any
    hasCancelButton: boolean,
};

function ConfirmModal(props: ConfirmModal) {
    return (
        <div className={ 'confirm-modal modal-' + (props.opened ? 'opened' : 'closed') }>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{ props.modalTitle }</h2>
                    <i onClick={ props.onCancel } className='material-icons'>close</i>
                </div>
                <div className='modal-body'>
                    <p>{parse(props.confirmModalText)}</p>
                </div>
                <div className='modal-footer'>
                    {props.hasCancelButton &&
                        <Button
                            text={props.cancelText}
                            size={Size.Medium}
                            type={Type.Ghost}
                            onClick={props.onCancel}
                        />
                    }
                    <Button
                        text={ props.okText }
                        size={ Size.Medium }
                        type={ Type.Delete }
                        onClick={ props.onOk }
                    />
                </div>
            </div>
        </div>                
    );
}

export default ConfirmModal;