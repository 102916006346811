import React, { useState, useEffect } from 'react';
import Modal from '../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../Component/Input/TextField/TextField';
import Checkbox from '../../../../Component/Input/Checkbox/Checkbox';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import Select from '../../../../Component/Input/SelectInput/SelectInput';
import { SelectValue } from "../../../../../shared/Common/data/SelectValue";

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    name: string | null,
    url: string | null,
    selectedCompanies: CompanyFromApi[],
    companies: SelectValue[],
    visible: boolean,
    okText: string,
    onCancel: () => any,
    onOk: (text: string, url: string, companies: string[], visible: boolean, validate: (errors: ValidationError[]) => void) => void,
};

function BaseModal(props: BaseModal) {
    const companyOptions = props.selectedCompanies.map(companyValue => {
        return {
            label: companyValue.name,
            value: companyValue.id
        } as SelectValue
    });
    const [name, setName] = useState<string>(props.name ?? '');
    const [url, setUrl] = useState<string>(props.url ?? '');
    const [nameError, setNameError] = useState<string | null>(null);
    const [visible, setVisible] = useState<boolean>(props.visible ?? true);
    const [urlError, setUrlError] = useState<string | null>(null);
    const [companies, setCompanies] = useState<SelectValue[]>(props.companies ?? companyOptions);
    const [companiesError, setCompaniesError] = useState<string | null>(null);

    function resetAll(): void {
        setName('');
        setUrl('');
        setVisible(true);
        setNameError(null);
        setUrlError(null);
        setCompanies(companyOptions);
        setCompaniesError(null);
    }

    useEffect(() => {
        if (props.modalTitle === 'Create Link') {
            resetAll()
        }
        if (props.companies.length === 0) {
            setCompanies(companyOptions);
        }
    }, [props.opened]);

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'link.name') {
                setNameError(error.message);
            } else if (error.property === 'link.url') {
                setUrlError(error.message);
            } else if (error.property === 'link.companies') {
                setCompaniesError(error.message);
            }
        });
    }

    function changeUrl(url: string) {
        const urlRegex = new RegExp(/https?:\/\//);
        if (urlRegex.test(url)){
            setUrl(url)
        } else {
            const urlProtocolRegex = new RegExp(/^ht|^htt|^http|^https|^https?:|^https?:\/\//i);
            if (urlProtocolRegex.test(url)) {
                setUrl('https://');
            } else {
                setUrl('https://' + url)
            }
        }
    }

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={ () => props.onOk(name, url, companies.map(company => company.value), visible, validate) }
        >
            <TextField
                label='Name'
                placeholder='Name...'
                required={ true }
                value={ name }
                type={ Type.Textarea }
                horizontal={ true }
                error={ nameError }
                onChange={ newName => {
                    setName(newName)
                    setNameError(null)
                    setCompaniesError(null)
                }}
                maxLength={50}
            />
            <Select
                label='Company'
                required={ true }
                isMulti={ true }
                value={ companies }
                options={ companyOptions }
                error={ companiesError }
                onChange={ (newCompanies: SelectValue[]) => {
                    setCompanies(newCompanies);
                    setCompaniesError(null);
                }}
                isSearchable={ false }
            />
            <TextField
                label='URL'
                placeholder='Url...'
                required={ true }
                value={ url }
                type={ Type.Textarea }
                horizontal={ true }
                error={ urlError }
                onChange={ newUrl => {
                    changeUrl(newUrl)
                    setUrlError(null)
                    setCompaniesError(null)
                }}
                maxLength={2083}
            />
            <Checkbox
                disabled={ false }
                label={ 'Visibility' }
                required={ null }
                checked={ visible }
                checkBoxLabel='Shown'
                onChange={ newVisible => setVisible(newVisible) }
                switch={ true }
            />
        </Modal>
    );
}

export default BaseModal;