import React from 'react';
import BaseModal from './BaseModal';
import { COMPANIES } from '../../../../../utils/constants/api';
import { CompanyFromApi } from '../../../../../shared/Company/data/CompanyFromApi';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type CreateCompany = {
    opened: boolean,
    companies: CompanyFromApi[],
    onOk: () => void,
    onClose: () => any
};

function CreateCompany(props: CreateCompany) {
    function create(
        name: string,
        parentId: string | null,
        logotype: File | null,
        domain: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        if (logotype) {
            formData.append('logotype', logotype)
        }

        formData.append('company', JSON.stringify({
            name: name,
            parentId: parentId,
            domain: domain
        }));

        fetch(COMPANIES, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Company'
            companies={ props.companies }
            name={ null }
            parentCompany={ null }
            logotype={ null }
            domain={ '' }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateCompany;