import React, { useState, useEffect } from 'react';
import './TimeOffPolicies.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import Button, { Size, Type } from '../../../Component/Button/Button';
import CreateTimeOffPolicy from './Modal/CreateTimeOffPolicy';
import DeleteTimeOffPolicy from './Modal/DeleteTimeOffPolicy';
import EditTimeOffPolicy from './Modal/EditTimeOffPolicy';
import {TimeOffPolicyFromApi} from '../../../../shared/TimeOffPolicy/data/TimeOffPolicyFromApi';
import {CompanyFromApi} from "../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../Locations/Locations";
import moment from "moment/moment";
import {MultiSelect} from "react-multi-select-component";
import {SelectValue} from "../../../../shared/Common/data/SelectValue";
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {
    COMPANIES,
    LOCATIONS,
    TIME_OFF_POLICIES,
} from '../../../../utils/constants/api';

type TimeOffPolicies = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function TimeOffPolicies(props: TimeOffPolicies) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createTimeOffPolicyModal, setCreateTimeOffPolicyModal] = useState<boolean>(false);
    const [editTimeOffPolicyModal, setEditTimeOffPolicyModal] = useState<boolean>(false);
    const [deleteTimeOffPolicyModal, setDeleteTimeOffPolicyModal] = useState<boolean>(false);

    const [timeOffPolicies, setTimeOffPolicies] = useState<TimeOffPolicyFromApi[]>([]);
    const [timeOffPolicyToChange, setTimeOffPolicyToChange] = useState<TimeOffPolicyFromApi | null>(null);
    const [timeOffPolicyToDelete, deleteTimeOffPolicy] = useState<TimeOffPolicyFromApi | null>(null);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);
    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectValue[]>([]);

    const [locations, setLocations] = useState<LocationFromApi[]>([]);
    const [locationOptions, setLocationOptions] = useState<SelectValue[]>([]);
    const [selectedLocationOptions, setSelectedLocationOptions] = useState<SelectValue[]>([]);

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        getLocations();
    }, []);

    useEffect(getTimeOffPolicies, [selectedCompanyOptions, selectedLocationOptions]);

    function getTimeOffPolicies() {
        fetch(TIME_OFF_POLICIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((timeOffPolicies: TimeOffPolicyFromApi[]) =>
                setTimeOffPolicies(
                    filterTimeOffPoliciesByCompany(
                        filterTimeOffPoliciesByLocation(timeOffPolicies, selectedLocationOptions),
                        selectedCompanyOptions
                    )
                )
            );
    }

    async function getCompanies() {
        const response = await fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });
        const companies = await response.json();
        setCompanies(companies);

        const companyOptions = companies.map((company: { id: string; }) => {
            let companyName = '';
            companies.map((companyValue: CompanyFromApi) => {
                if (companyValue.id === company.id) {
                    companyName = companyValue.name;
                }
            })
            return {
                label: companyName,
                value: company.id
            }
        });

        setCompanyOptions(companyOptions);
        setSelectedCompanyOptions(companyOptions);
    }

    async function getLocations() {
        const response = await fetch(LOCATIONS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });
        const locations = await response.json();
        setLocations(locations);

        const locationOptions = locations.map((location: { id: string; }) => {
            let locationName = '';
            locations.map((locationValue: LocationFromApi) => {
                if (locationValue.id === location.id) {
                    locationName = locationValue.name;
                }
            })
            return {
                label: locationName,
                value: location.id
            }
        });

        setLocationOptions(locationOptions);
        setSelectedLocationOptions(locationOptions);
    }

    function getValuesFromSelectValues(selectValues: SelectValue[]): string[] {
        return selectValues.map(selectValue => selectValue.value);
    }

    function filterTimeOffPoliciesByCompany(
        timeOffPolicies: TimeOffPolicyFromApi[], selectedCompanyOptions: SelectValue[]
    ): TimeOffPolicyFromApi[] {
        const companyArr = getValuesFromSelectValues(selectedCompanyOptions);
        return timeOffPolicies.filter(timeOffPolicy =>
            timeOffPolicy.value.generalInfo.companiesIds.some(company => companyArr.includes(company)));
    }

    function filterTimeOffPoliciesByLocation(
        timeOffPolicies: TimeOffPolicyFromApi[], selectedLocationOptions: SelectValue[]
    ): TimeOffPolicyFromApi[] {
        const locationArr = getValuesFromSelectValues(selectedLocationOptions);
        return timeOffPolicies.filter(timeOffPolicy =>
            timeOffPolicy.value.generalInfo.locationsIds.some(location => locationArr.includes(location)));
    }

    function editTimeOffPolicyModalVisibility(timeOffPolicy: TimeOffPolicyFromApi): void {
        setTimeOffPolicyToChange(timeOffPolicy);
        setEditTimeOffPolicyModal(true);
    }

    function confirmDeleteTimeOffPolicyModalVisibility(timeOffPolicy: TimeOffPolicyFromApi): void {
        deleteTimeOffPolicy(timeOffPolicy)
        setDeleteTimeOffPolicyModal(true);
    }

    function makeVisible (timeOffPolicy: TimeOffPolicyFromApi): void {
        fetch(TIME_OFF_POLICIES + '/' + timeOffPolicy.id + '/visible' , {
            method: 'POST',
        })
            .then(response => {
                if (response.ok) {
                    getTimeOffPolicies();
                } else {
                    response.json().then(jsonData => alert(jsonData.error))
                }
            });
    }

    function makeInvisible (timeOffPolicy: TimeOffPolicyFromApi): void {
        fetch(TIME_OFF_POLICIES + '/' + timeOffPolicy.id + '/visible' , {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    getTimeOffPolicies();
                } else {
                    response.json().then(jsonData => alert(jsonData.error))
                }
            });
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Times Off Policy</h3>
                                </div>
                                {!createTimeOffPolicyModal && !editTimeOffPolicyModal &&
                                    <div className='sub-header-filter-block align-items-center d-flex'>
                                        <div>
                                            <p>Companies:</p>
                                        </div>
                                        <div className='filter'>
                                            <MultiSelect
                                                options={ companyOptions }
                                                hasSelectAll={ true }
                                                shouldToggleOnHover={ false }
                                                disableSearch={ true }
                                                value={ selectedCompanyOptions }
                                                disabled={ false }
                                                onChange={ setSelectedCompanyOptions }
                                                labelledBy={ 'All companies' }
                                                className={ 'multi-select-with-checkboxes'}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "All companies",
                                                        "selectSomeItems": "Select companies",
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                                {!createTimeOffPolicyModal && !editTimeOffPolicyModal &&
                                    <div className='sub-header-filter-block align-items-center d-flex'>
                                        <div>
                                            <p>Locations:</p>
                                        </div>
                                        <div className='filter'>
                                            <MultiSelect
                                                options={ locationOptions }
                                                hasSelectAll={ true }
                                                shouldToggleOnHover={ false }
                                                disableSearch={ true }
                                                value={ selectedLocationOptions }
                                                disabled={ false }
                                                onChange={ setSelectedLocationOptions }
                                                labelledBy={ 'All locations' }
                                                className={ 'multi-select-with-checkboxes'}
                                                overrideStrings={
                                                    {
                                                        "allItemsAreSelected": "All locations",
                                                        "selectSomeItems": "Select locations",
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                                <div className='add-button-block'>
                                    <div>
                                        <Button
                                            text="<i className='material-icons add-item'>add</i> Add time off policy"
                                            size={Size.Medium}
                                            type={Type.Accent}
                                            onClick={() => setCreateTimeOffPolicyModal(true)}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='sub-content-main'>
                                {!createTimeOffPolicyModal && !editTimeOffPolicyModal && <table className='time-off-policies-table'>
                                    <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Working day
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {timeOffPolicies.map(timeOffPolicy => {
                                        return (
                                            <tr key={ timeOffPolicy.id }>
                                                <td title={ timeOffPolicy.value.generalInfo.name }>{ timeOffPolicy.value.generalInfo.name }</td>
                                                <td>{ moment(timeOffPolicy.createdAt).format("DD.MM.YYYY") }</td>
                                                <td> { timeOffPolicy.value.accrualSchedule.hoursPerWorkingDay } </td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editTimeOffPolicyModalVisibility(timeOffPolicy) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteTimeOffPolicyModalVisibility(timeOffPolicy) }
                                                    >
                                                        delete
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => timeOffPolicy.isVisible ? makeInvisible(timeOffPolicy) : makeVisible(timeOffPolicy) }
                                                    >
                                                        {timeOffPolicy.isVisible ? "visibility" : "visibility_off"}
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table> }
                                {
                                    <CreateTimeOffPolicy
                                        opened={ createTimeOffPolicyModal }
                                        onOk={ () => { getTimeOffPolicies(); setCreateTimeOffPolicyModal(false); } }
                                        onClose={ () => setCreateTimeOffPolicyModal(false) }
                                        companies={ companies }
                                        locations={ locations }
                                    />
                                }
                                {
                                    timeOffPolicyToChange
                                        ?
                                        <EditTimeOffPolicy
                                            opened={ editTimeOffPolicyModal }
                                            onOk={ () => { getTimeOffPolicies(); setEditTimeOffPolicyModal(false); setTimeOffPolicyToChange(null) } }
                                            onClose={ () => { setEditTimeOffPolicyModal(false); setTimeOffPolicyToChange(null) } }
                                            companies={ companies }
                                            locations={ locations }
                                            timeOffPolicy={ timeOffPolicyToChange }
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    timeOffPolicyToDelete ?
                        <DeleteTimeOffPolicy
                            opened={ deleteTimeOffPolicyModal }
                            onOk={ () => { getTimeOffPolicies(); setDeleteTimeOffPolicyModal(false); deleteTimeOffPolicy(null) } }
                            onClose={ () => { setDeleteTimeOffPolicyModal(false); deleteTimeOffPolicy(null) } }
                            timeOffPolicy={ timeOffPolicyToDelete }
                        /> : null
                }
            </div>
        </div>
    );
}

export default TimeOffPolicies;