import React, { useState, useEffect } from 'react';
import './Levels.scss';
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../../Page/Layer/SubMenu/SubMenu';
import CreateLevel from './Levels/Modal/CreateLevel';
import DeleteLevel from './Levels/Modal/DeleteLevel';
import EditLevel from './Levels/Modal/EditLevel';
import { LEVELS } from '../../../../../utils/constants/api';
import Button, {Size, Type} from '../../../../Component/Button/Button';
import { LevelFromApi } from '../../../../../shared/Employee/Level/data/LevelFromApi';
import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';

type Levels = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Levels(props: Levels) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createLevelModal, setCreateLevelModal] = useState<boolean>(false);
    const [editLevelModal, setEditLevelModal] = useState<boolean>(false);
    const [deleteLevelModal, setDeleteLevelModal] = useState<boolean>(false);

    const [levels, setLevels] = useState<LevelFromApi[]>([]);
    const [levelToChange, setLevelToChange] = useState<LevelFromApi | null>(null);
    const [levelToDelete, deleteLevel] = useState<LevelFromApi | null>(null);

    useEffect(() => {
        getLevels();
    }, [])

    function getLevels() {
        fetch(LEVELS, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((levels: LevelFromApi[]) => setLevels(levels));
    }

    function editLevelModalVisibility(level: LevelFromApi): void {
        setLevelToChange(level);
        setEditLevelModal(true);
    }

    function confirmDeleteLevelModalVisibility(level: LevelFromApi): void {
        deleteLevel(level)
        setDeleteLevelModal(true);
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Employee Levels</h3>
                                </div>

                                <div className='add-button-block'>
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add level"
                                        size={ Size.Medium }
                                        type={ Type.Accent }
                                        onClick={ () => setCreateLevelModal(true) }
                                    />
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <table className="levels-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Employee Level
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {levels.map(level => {
                                        return (
                                            <tr key={ level.id }>
                                                <td title={ level.name }>{ level.name }</td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editLevelModalVisibility(level) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteLevelModalVisibility(level) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateLevel
                    opened={ createLevelModal }
                    onOk={ () => { getLevels(); setCreateLevelModal(false); } }
                    onClose={ () => setCreateLevelModal(false) }
                />
                {
                    levelToDelete ?
                        <DeleteLevel
                            opened={ deleteLevelModal }
                            onOk={ () => { getLevels(); setDeleteLevelModal(false); deleteLevel(null) } }
                            onClose={ () => { setDeleteLevelModal(false); deleteLevel(null) } }
                            level={ levelToDelete }
                        /> : null
                }
                {
                    levelToChange
                        ?
                        <EditLevel
                            opened={ editLevelModal }
                            onOk={ () => { getLevels(); setEditLevelModal(false); setLevelToChange(null) } }
                            onClose={ () => { setEditLevelModal(false); setLevelToChange(null) } }
                            level={ levelToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Levels;