import React from 'react';
import BaseModal  from './BaseModal';
import { StatusFromApi } from '../../Statuses';
import { STATUSES } from '../../../../../../../utils/constants/api';
import {ValidationError} from "../../../../../../../shared/Common/Error/ValidationError";
import {VALIDATION_ERROR} from "../../../../../../../utils/constants/errors";

type EditStatus = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    status: StatusFromApi
};

function EditStatus(props: EditStatus) {
    function edit(name: string, validate: (errors: ValidationError[]) => void): void {
        fetch(STATUSES + '/' + props.status.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Employee Status'
            name={ props.status.name }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
        />
    )
}

export default EditStatus;