import React from 'react';
import BaseModal  from './BaseModal';
import {PUSH_NOTIFICATIONS} from '../../../../../utils/constants/api';
import { PushNotificationFromApi } from '../../../../../shared/PushNotification/data/PushNotificationFromApi';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../utils/constants/errors";
import moment from "moment";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../Locations/Locations";
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";

type EditPushNotification = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    pushNotification: PushNotificationFromApi
    companies: CompanyFromApi[],
    locations: LocationFromApi[]
};

function EditCategory(props: EditPushNotification) {
    function edit(
        subject: string,
        companies: string[],
        locations: string[],
        date: Date,
        content: string,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(PUSH_NOTIFICATIONS + '/' + props.pushNotification.id , {
            method: 'PUT',
            body: JSON.stringify({
                subject: subject,
                sendAt: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
                text: content,
                companiesIds: companies,
                locationsIds: locations
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'title.categoryId',
                        message: 'Category is required'
                    }]);
                }
            });
    }

    function isSent(): boolean {
        const currentDate = new Date();
        const sendAtDate = new Date(props.pushNotification.value.sendAt);
        return sendAtDate < currentDate;
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Push Notification'
            subject={props.pushNotification.value.subject}
            companyValues={ props.companies }
            companies={props.pushNotification.value.companiesIds.map(company => {
                let companyName = '';
                props.companies.map(companyValue => {
                    if (companyValue.id === company) {
                        companyName = companyValue.name;
                    }
                })
                return {
                    label: companyName,
                    value: company
                }
            }).filter(company => company.label !== '')}
            locationValues={ props.locations }
            locations={props.pushNotification.value.locationsIds.map(location => {
                let locationName = '';
                props.locations.map(locationValue => {
                    if (locationValue.id === location) {
                        locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                    }
                })
                return {
                    label: locationName,
                    value: location
                }
            }).filter(company => company.label !== '')}
            date={ new Date(moment(props.pushNotification.value.sendAt).format('YYYY-MM-DD HH:mm')) }
            text={props.pushNotification.value.text}
            okText='Edit'
            onCancel={props.onClose}
            onOk={edit}
            isSent={isSent()}
         />
    )
}

export default EditCategory;