import React, {useEffect, useState} from 'react';
import BaseModal from '../BaseModal/BaseModal';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {AccrualSchedule} from "../../../../../shared/TimeOffPolicy/data/AccrualSchedule";
import SelectInputSimple from "../../../../Component/Input/SelectInputSimple/SelectInputSimple";
import TextFieldSimple from "../../../../Component/Input/TextFieldSimple/TextFieldSimple";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";
import {
    TimeOptions,
    HoursWorkedTimeOptions,
    hoursPerWorkingDayOptions,
    accrualScheduleTypeOptions,
    daysOfWeekOptions,
    daysOfMonthOptions,
    monthsOptions,
    onceSelectOptions,
    hoursWorkedTypeOptions, daysOfMonthOptionsMonthly,
} from "../../../../../shared/TimeOffPolicy/constants/AccrualSchedule";
import DatePicker from "../../../../Component/Input/DatePicker/DatePicker";
import {TIME_OFF_POLICIES} from "../../../../../utils/constants/api";
import {castAccrualSchedule} from "./CreateTimeOffPolicy";

type AccrualScheduleModal = {
    opened: boolean,
    modalTitle: string,
    hoursPerWorkingDay: number | null,
    accrualSchedule: AccrualSchedule | null,
    okText: string,
    onOk: (
        hoursPerWorkingDay: number,
        accrualSchedule: object,
        validate: (errors: ValidationError[]) => void
    ) => void,
    style: string,
};

function AccrualScheduleModal(props: AccrualScheduleModal) {
    const [hoursPerWorkingDay, setHoursPerWorkingDay] = useState<number>(props.hoursPerWorkingDay || 8);

    const [accrualSchedule, setAccrualSchedule] = useState<AccrualSchedule | null>(props.accrualSchedule || null);
    const [allocateDays, setAllocateDays] = useState<string>(props.accrualSchedule?.params?.daysToAllocate?.toString() || '0');
    const [perDays, setPerDays] = useState<string>(props.accrualSchedule?.params?.days?.toString() || '');
    const [perHours, setPerHours] = useState<string>(props.accrualSchedule?.params?.perHours?.toString() || '');
    const [accrualScheduleType, setAccrualScheduleType] = useState<string>(props.accrualSchedule?.type || 'yearly');

    const dayValue = props.accrualSchedule?.params?.day ? Number(props.accrualSchedule?.params?.day) : 1;
    const [dayOfMonth, setDayOfMonth] = useState<string>(
        dayValue === 0
            ? '0'
            : (dayValue.toString().padStart(2, '0') || '01')
    );
    const [dayOfWeek, setDayOfWeek] = useState<string>(props.accrualSchedule?.params?.dayOfWeek || 'monday');
    const [month, setMonth] = useState<string>(props.accrualSchedule?.params?.month?.toString().padStart(2, '0') || '01');
    const [once, setOnce] = useState<string>('onHireDate');
    const [hoursWorkedType, setHoursWorkedType] = useState<string>('yearly');
    const [date, setDate] = useState<Date | null>(props.accrualSchedule?.params?.date ? new Date(props.accrualSchedule.params.date) : new Date());

    const [hoursPerWorkingDayOption, setHoursPerWorkingDayOption] = useState<SelectValue | null>(null);
    const [accrualScheduleTypeOption, setAccrualScheduleTypeOption] = useState<SelectValue | null>(null);
    const [dateTypeOption, setDateTypeOption] = useState<SelectValue | null>({ value: 'customDate', label: 'custom date' });
    const [dayOfMonthOption, setDayOfMonthOption] = useState<SelectValue | null>(null);
    const [dayOfWeekOption, setDayOfWeekOption] = useState<SelectValue | null>(null);
    const [monthOption, setMonthOption] = useState<SelectValue | null>(null);
    const [onceOption, setOnceOption] = useState<SelectValue | null>(null);
    const [hoursWorkedTypeOption, setHoursWorkedTypeOption] = useState<SelectValue | null>(null);

    const [hoursPerWorkingDayError, setHoursPerWorkingDayError] = useState<string | null>(null);
    const [accrualScheduleError, setAccrualScheduleError] = useState<string | null>(null);
    const [allocateDaysError, setAllocateDaysError] = useState<string | null>(null);
    const [accrualScheduleTypeError, setAccrualScheduleTypeError] = useState<string | null>(null);
    const [dateTypeError, setDateTypeError] = useState<string | null>(null);
    const [dayOfMonthError, setDayOfMonthError] = useState<string | null>(null);
    const [dayOfWeekError, setDayOfWeekError] = useState<string | null>(null);
    const [monthError, setMonthError] = useState<string | null>(null);
    const [perDaysError, setPerDaysError] = useState<string | null>(null);
    const [perHoursError, setPerHoursError] = useState<string | null>(null);
    const [onceError, setOnceError] = useState<string | null>(null);
    const [hoursWorkedTypeError, setHoursWorkedTypeError] = useState<string | null>(null);
    const [dateError, setDateError] = useState<string | null>(null);

    const [daysOfMonthBaseOptions, setDaysOfMonthBaseOptions] = useState<SelectValue[]>([]);

    const dateOptions = [
        { value: 'customDate', label: 'custom date' },
        { value: 'hireDate', label: 'hire date' },
        { value: 'employmentDate', label: 'employment date' },
        { value: 'birthdayDate', label: 'birthday date' },
    ];

    useEffect (() => {
        if(hoursPerWorkingDay) {
            const hoursPerWorkingDayOption = hoursPerWorkingDayOptions
                .find(option => option.value === hoursPerWorkingDay.toString());
            if(hoursPerWorkingDayOption) {
                setHoursPerWorkingDayOption(hoursPerWorkingDayOption);
            }
        }
    }, [hoursPerWorkingDay]);


    useEffect (() => {
        handleMonthChange({ value: props.accrualSchedule?.params?.month?.toString().padStart(2, '0') || '01', label: ''})
    }, []);

    useEffect (() => {
        if(accrualScheduleType) {
            if (accrualScheduleType === TimeOptions.Monthly) {
                setDaysOfMonthBaseOptions(daysOfMonthOptionsMonthly);
                setDayOfMonth(dayValue === 0
                    ? '0'
                    : (dayValue.toString().padStart(2, '0') || '01'))
            }
            const accrualScheduleTypeOption = accrualScheduleTypeOptions
                .find(option => option.value === accrualScheduleType);
            if(accrualScheduleTypeOption) {
                setAccrualScheduleTypeOption(accrualScheduleTypeOption);
            }
        }
    }, [accrualScheduleType]);

    useEffect(() => {
        if (dayOfMonth != null && accrualScheduleType) {
            let dayOfMonthOptions = accrualScheduleType === TimeOptions.Monthly ? daysOfMonthOptionsMonthly : daysOfMonthOptions;
            const dayOfMonthOption = dayOfMonthOptions
                .find(option => option.value === dayOfMonth);
            if (dayOfMonthOption) {
                setDayOfMonthOption(dayOfMonthOption);
            }
        }
    }, [dayOfMonth]);

    useEffect(() => {
        if (dayOfWeek) {
            const dayOfWeekOption = daysOfWeekOptions
                .find(option => option.value === dayOfWeek);
            if (dayOfWeekOption) {
                setDayOfWeekOption(dayOfWeekOption);
            }
        }
    }, [dayOfWeek]);

    useEffect(() => {
        if (month) {
            const monthOption = monthsOptions
                .find(option => option.value === month);
            if (monthOption) {
                setMonthOption(monthOption);
            }
        }
    }, [month]);

    useEffect(() => {
        if (once) {
            const onceOption = onceSelectOptions
                .find(option => option.value === once);
            if (onceOption) {
                setOnceOption(onceOption);
            }
        }
    }, [once]);

    useEffect(() => {
        if (hoursWorkedType) {
            const hoursWorkedTypeOption = hoursWorkedTypeOptions
                .find(option => option.value === hoursWorkedType);
            if (hoursWorkedTypeOption) {
                setHoursWorkedTypeOption(hoursWorkedTypeOption);
            }
        }
    }, [hoursWorkedType]);

    useEffect(() => {
        if (accrualSchedule?.type === TimeOptions.HoursWorkedYearly) {
            setAccrualScheduleType(TimeOptions.HoursWorked);
            setHoursWorkedType(HoursWorkedTimeOptions.Yearly);
        } else if (accrualSchedule?.type === TimeOptions.HoursWorkedMonthly) {
            setAccrualScheduleType(TimeOptions.HoursWorked);
            setHoursWorkedType(HoursWorkedTimeOptions.Monthly);
        } else if (accrualSchedule?.type === TimeOptions.HoursWorkedWeekly) {
            setAccrualScheduleType(TimeOptions.HoursWorked);
            setHoursWorkedType(HoursWorkedTimeOptions.Weekly);
        } else if (accrualSchedule?.type === TimeOptions.HoursWorkedDaily) {
            setAccrualScheduleType(TimeOptions.HoursWorked);
            setHoursWorkedType(HoursWorkedTimeOptions.Daily);
        } else if (accrualSchedule?.type === TimeOptions.HoursWorkedOnHireDate) {
            setAccrualScheduleType(TimeOptions.HoursWorked);
            setHoursWorkedType(HoursWorkedTimeOptions.OnHireDate);
        } else if (accrualSchedule?.type === TimeOptions.OnceOnHireDate) {
            setAccrualScheduleType(TimeOptions.Once);
            setOnce('onHireDate');
        } else if (accrualSchedule?.type === TimeOptions.OnceOnFixedDate) {
            setAccrualScheduleType(TimeOptions.Once);
            setOnce('onFixedDate');
        } else if (accrualSchedule?.type === TimeOptions.YearlyOnHireDate) {
            setAccrualScheduleType(TimeOptions.Yearly);
            setDateTypeOption({ value: 'hireDate', label: 'hire date' });
        } else if (accrualSchedule?.type === TimeOptions.YearlyOnBirthdayDate) {
            setAccrualScheduleType(TimeOptions.Yearly);
            setDateTypeOption({ value: 'birthdayDate', label: 'birthday date' });
        } else if (accrualSchedule?.type === TimeOptions.YearlyOnEmploymentDate) {
            setAccrualScheduleType(TimeOptions.Yearly);
            setDateTypeOption({ value: 'employmentDate', label: 'employment date' });
        }
    }, [props.accrualSchedule]);

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'hoursPerWorkingDay') {
                setHoursPerWorkingDayError(error.message);
            } else if (error.property === 'daysToAllocate') {
                setAllocateDaysError(error.message);
            } else if (error.property === 'dayOfMonth') {
                setDayOfMonthError(error.message);
            } else if (error.property === 'dayOfWeek') {
                setDayOfWeekError(error.message);
            } else if (error.property === 'month') {
                setMonthError(error.message);
            } else if (error.property === 'days') {
                setPerDaysError(error.message);
            } else if (error.property === 'perHours') {
                setPerHoursError(error.message);
            } else if (error.property === 'once') {
                setOnceError(error.message);
            } else if (error.property === 'hoursWorkedType') {
                setHoursWorkedTypeError(error.message);
            }
        });
    }

    function apiValidate(): void {
        const accrualScheduleData = {
            hoursPerWorkingDay: hoursPerWorkingDay,
            accrualSchedule: castAccrualSchedule(createAccrualScheduleObject())
        }
        fetch (TIME_OFF_POLICIES + '/validation/accrual-schedule', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(accrualScheduleData)
        }).then(response => {
            if (response.ok) {
                props.onOk(
                    hoursPerWorkingDayOption?.value ? parseInt(hoursPerWorkingDayOption.value) : 8,
                    createAccrualScheduleObject(),
                    validate
                );
            } else {
                response.json().then((errors: ValidationError[]) => {
                    validate(errors);
                });
            }
        });
    }

    function handleOk(): void {
        clearAllErrors();
        apiValidate();
    }

    function clearAllErrors(): void {
        setHoursPerWorkingDayError(null);
        setAllocateDaysError(null);
        setDayOfMonthError(null);
        setDayOfWeekError(null);
        setMonthError(null);
        setPerDaysError(null);
        setPerHoursError(null);
        setOnceError(null);
        setHoursWorkedTypeError(null);
    }

    function handleHoursPerWorkingDayChange(newHoursPerWorkingDay: SelectValue): void {
        setHoursPerWorkingDay(newHoursPerWorkingDay.value ? parseInt(newHoursPerWorkingDay.value) : 8);
        setHoursPerWorkingDayError(null);
    }

    function handleAccrualScheduleChange(newAccrualSchedule: AccrualSchedule): void {
        setAccrualSchedule(newAccrualSchedule);
        setAccrualScheduleError(null);
    }

    function handleAllocateDaysChange(newAllocateDays: string): void {
        if (/^\d*([.,]\d*)?$/.test(newAllocateDays) || newAllocateDays === '') {
            setAllocateDays(newAllocateDays);
        }
        setAllocateDaysError(null);
    }

    function handleAccrualScheduleTypeChange(newAccrualScheduleType: SelectValue): void {
        setAccrualScheduleType(newAccrualScheduleType.value || 'yearly');
        setAccrualScheduleTypeOption(newAccrualScheduleType);
        setAccrualScheduleTypeError(null);
        if (newAccrualScheduleType.value === TimeOptions.Monthly) {
            setDaysOfMonthBaseOptions(daysOfMonthOptionsMonthly);
        } else {
            setDaysOfMonthBaseOptions(daysOfMonthOptions);
        }
        setDayOfMonth('01');
    }

    function handleDateTypeChange(newDateType: SelectValue): void {
        setDateTypeOption(newDateType);
        setDateTypeError(null);
    }

    function handleDayOfMonthChange(newDayOfMonth: SelectValue): void {
        setDayOfMonth(newDayOfMonth.value || '01');
        setDayOfMonthOption(newDayOfMonth);
        setDayOfMonthError(null);
    }

    function handleDayOfWeekChange(newDayOfWeek: SelectValue): void {
        setDayOfWeek(newDayOfWeek.value || '1');
        setDayOfWeekOption(newDayOfWeek);
        setDayOfWeekError(null);
    }

    function handleMonthChange(newMonth: SelectValue): void {
        setMonth(newMonth.value || '01');
        setMonthOption(newMonth);
        setMonthError(null);

        let daysOfMonthOptionsForMonth: SelectValue[];

        switch (newMonth.value) {
            case '04':
            case '06':
            case '09':
            case '11':
                daysOfMonthOptionsForMonth = daysOfMonthOptions.slice(0, 30);
                break;
            case '02':
                daysOfMonthOptionsForMonth = daysOfMonthOptions.slice(0, 28);
                break;
            default:
                daysOfMonthOptionsForMonth = daysOfMonthOptions;
        }

        setDaysOfMonthBaseOptions(daysOfMonthOptionsForMonth);

        if (daysOfMonthOptionsForMonth.some(dayOption => dayOption.value === dayOfMonthOption?.value)) {
            setDayOfMonth(dayOfMonthOption?.value || '01');
        } else {
            const lastDayOption = daysOfMonthOptionsForMonth[daysOfMonthOptionsForMonth.length - 1];
            setDayOfMonth(lastDayOption.value || '01');
        }
    }

    function handlePerDaysChange(newPerDays: string): void {
        if (/^\d*([.,]\d*)?$/.test(newPerDays) || newPerDays === '') {
            setPerDays(newPerDays);
        }
        setPerDaysError(null);
    }

    function handlePerHoursChange(newPerHours: string): void {
        if (/^\d*([.,]\d*)?$/.test(newPerHours) || newPerHours === '') {
            setPerHours(newPerHours);
        }
        setPerHoursError(null);
    }

    function handleOnceSelectChange(newOnceSelect: SelectValue): void {
        setOnce(newOnceSelect.value || 'onHireDate');
        setOnceOption(newOnceSelect);
        setOnceError(null);
    }

    function handleHoursWorkedTypeChange(newHoursWorkedType: SelectValue): void {
        setHoursWorkedType(newHoursWorkedType.value || 'yearly');
        setHoursWorkedTypeOption(newHoursWorkedType);
        setHoursWorkedTypeError(null);
    }

    function handleDateChange(newDate: Date): void {
        setDate(newDate);
        setDateError(null);
    }

    function createAccrualScheduleObject(): AccrualSchedule {
        if (accrualScheduleType === TimeOptions.Yearly && dateTypeOption?.value === 'customDate') {
            return {
                type: accrualScheduleType,
                params: {
                    day: dayOfMonth,
                    month: month,
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Yearly && dateTypeOption?.value === 'hireDate') {
            return {
                type: TimeOptions.YearlyOnHireDate,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Yearly && dateTypeOption?.value === 'birthdayDate') {
            return {
                type: TimeOptions.YearlyOnBirthdayDate,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Yearly && dateTypeOption?.value === 'employmentDate') {
            return {
                type: TimeOptions.YearlyOnEmploymentDate,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Monthly) {
            return {
                type: accrualScheduleType,
                params: {
                    day: dayOfMonth,
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Weekly) {
            return {
                type: accrualScheduleType,
                params: {
                    dayOfTheWeek: dayOfWeek,
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Daily) {
            return {
                type: accrualScheduleType,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.NDays) {
            return {
                type: accrualScheduleType,
                params: {
                    daysToAllocate: allocateDays,
                    days: perDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Once && once === 'onHireDate') {
            return {
                type: TimeOptions.OnceOnHireDate,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        if (accrualScheduleType === TimeOptions.Once && once === 'onFixedDate') {
            return {
                type: TimeOptions.OnceOnFixedDate,
                params: {
                    daysToAllocate: allocateDays,
                    date: date ? date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() : ''
                }
            };
        }
        if (accrualScheduleType === TimeOptions.HoursWorked && hoursWorkedType === TimeOptions.Yearly) {
            return {
                type: TimeOptions.HoursWorkedYearly,
                params: {
                    day: dayOfMonth,
                    month: month,
                    daysToAllocate: allocateDays,
                    perHours: perHours
                }
            };
        }
        if (accrualScheduleType === TimeOptions.HoursWorked && hoursWorkedType === TimeOptions.Monthly) {
            return {
                type: TimeOptions.HoursWorkedMonthly,
                params: {
                    day: dayOfMonth,
                    daysToAllocate: allocateDays,
                    perHours: perHours
                }
            };
        }
        if (accrualScheduleType === TimeOptions.HoursWorked && hoursWorkedType === TimeOptions.Weekly) {
            return {
                type: TimeOptions.HoursWorkedWeekly,
                params: {
                    dayOfTheWeek: dayOfWeek,
                    daysToAllocate: allocateDays,
                    perHours: perHours
                }
            };
        }
        if (accrualScheduleType === TimeOptions.HoursWorked && hoursWorkedType === TimeOptions.Daily) {
            return {
                type: TimeOptions.HoursWorkedDaily,
                params: {
                    daysToAllocate: allocateDays,
                    perHours: perHours
                }
            };
        }
        if (accrualScheduleType === TimeOptions.HoursWorked && hoursWorkedType === TimeOptions.OnHireDate) {
            return {
                type: TimeOptions.HoursWorkedOnHireDate,
                params: {
                    daysToAllocate: allocateDays
                }
            };
        }
        throw new Error(`Unsupported accrual schedule type: ${accrualScheduleType}`);
    }

    const infoText = 'This will determine daily working hours. ' +
        'This value is used to convert days into hours for part day requests.';

    return (
        <div>
            <BaseModal
                opened={props.opened}
                modalTitle={props.modalTitle}
                okText={props.okText}
                onOk={handleOk}
                hasCancel={false}
                style={props.style}
            >
                <div className='wide-page-input-container'>
                    <div className='wide-page-input-group'>
                        <span>Working day is</span>
                        <SelectInputSimple
                            isSearchable={false}
                            required={false}
                            isMulti={false}
                            value={hoursPerWorkingDayOption}
                            options={hoursPerWorkingDayOptions}
                            error={hoursPerWorkingDayError}
                            onChange={newHoursPerWorkingDay => handleHoursPerWorkingDayChange(newHoursPerWorkingDay)}
                        />
                        <span>hours</span>
                    </div>
                    <div className='wide-page-modal-info'>
                        {infoText}
                    </div>
                </div>

                <div className='wide-page-input-container'>
                    <div className='wide-page-input-group'>
                        <span>Allocate</span>
                        <TextFieldSimple
                            placeholder={''}
                            required={false}
                            value={allocateDays}
                            error={allocateDaysError}
                            maxLength={6}
                            onChange={newAllocateDays => handleAllocateDaysChange(newAllocateDays)}
                        />
                        <span>days per</span>
                        <SelectInputSimple
                            isSearchable={false}
                            required={false}
                            isMulti={false}
                            value={accrualScheduleTypeOption}
                            options={accrualScheduleTypeOptions}
                            error={accrualScheduleTypeError}
                            onChange={newAccrualScheduleType => handleAccrualScheduleTypeChange(newAccrualScheduleType)}
                        />
                        {accrualScheduleType === TimeOptions.Yearly && (
                            <>
                                <span>on</span>
                                <SelectInputSimple
                                    isSearchable={false}
                                    required={false}
                                    isMulti={false}
                                    value={dateTypeOption}
                                    options={dateOptions}
                                    error={dateTypeError}
                                    onChange={newDateTypeValue => handleDateTypeChange(newDateTypeValue)}
                                />
                                {dateTypeOption?.value === 'customDate' && (
                                    <>
                                        <SelectInputSimple
                                            isSearchable={false}
                                            required={false}
                                            isMulti={false}
                                            value={dayOfMonthOption}
                                            options={daysOfMonthBaseOptions}
                                            error={dayOfMonthError}
                                            onChange={(newDayOfMonth) =>
                                                handleDayOfMonthChange(newDayOfMonth)
                                            }
                                        />
                                        <span>of</span>
                                        <SelectInputSimple
                                            isSearchable={false}
                                            required={false}
                                            isMulti={false}
                                            value={monthOption}
                                            options={monthsOptions}
                                            error={monthError}
                                            onChange={(newMonth) =>
                                                handleMonthChange(newMonth)
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {accrualScheduleType === TimeOptions.Monthly && (
                            <>
                                <span>on</span>
                                <SelectInputSimple
                                    isSearchable={false}
                                    required={false}
                                    isMulti={false}
                                    value={dayOfMonthOption}
                                    options={daysOfMonthBaseOptions}
                                    error={dayOfMonthError}
                                    onChange={(newDayOfMonth) =>
                                        handleDayOfMonthChange(newDayOfMonth)
                                    }
                                />
                                {dayOfMonthOption?.value &&
                                <span>day</span>
                                }
                            </>
                        )}
                        {accrualScheduleType === TimeOptions.Weekly && (
                            <>
                                <span>on</span>
                                <SelectInputSimple
                                    isSearchable={false}
                                    required={false}
                                    isMulti={false}
                                    value={dayOfWeekOption}
                                    options={daysOfWeekOptions}
                                    error={dayOfWeekError}
                                    onChange={(newDayOfWeek) =>
                                        handleDayOfWeekChange(newDayOfWeek)
                                    }
                                />
                            </>
                        )}
                        {accrualScheduleType === TimeOptions.Daily && (<><span></span></>)}
                        {accrualScheduleType === TimeOptions.NDays && (
                            <>
                                <span>How many days?</span>
                                <TextFieldSimple
                                    placeholder={''}
                                    required={false}
                                    value={perDays}
                                    error={perDaysError}
                                    maxLength={6}
                                    onChange={newPerDays => handlePerDaysChange(newPerDays)}
                                />
                            </>
                        )}
                        {accrualScheduleType === TimeOptions.Once && (
                            <>
                                <span>on</span>
                                <SelectInputSimple
                                    isSearchable={false}
                                    required={false}
                                    isMulti={false}
                                    value={onceOption}
                                    options={onceSelectOptions}
                                    error={onceError}
                                    onChange={(newOnceSelect) =>
                                        handleOnceSelectChange(newOnceSelect)
                                    }
                                />
                                {once === 'onFixedDate' && (
                                    <>
                                        <div className="only-input-container">
                                            <DatePicker
                                                disabled={false}
                                                label='Date'
                                                required={true}
                                                horizontal={true}
                                                date={date}
                                                error={dateError}
                                                onChange={(newDate) => handleDateChange(newDate)}
                                                maxDate={null}
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {accrualScheduleType === TimeOptions.HoursWorked && (
                            <>
                                <div className='new-line'></div>
                                <TextFieldSimple
                                    placeholder={''}
                                    required={false}
                                    value={perHours}
                                    error={perHoursError}
                                    maxLength={4}
                                    onChange={newPerHours => handlePerHoursChange(newPerHours)}
                                />
                                <span>hours on</span>
                                <SelectInputSimple
                                    isSearchable={false}
                                    required={false}
                                    isMulti={false}
                                    value={hoursWorkedTypeOption}
                                    options={hoursWorkedTypeOptions}
                                    error={hoursWorkedTypeError}
                                    onChange={(newHoursWorkedType) =>
                                        handleHoursWorkedTypeChange(newHoursWorkedType)
                                    }
                                />
                                {hoursWorkedType === HoursWorkedTimeOptions.Yearly && (
                                    <>
                                        <span>on</span>
                                        <SelectInputSimple
                                            isSearchable={false}
                                            required={false}
                                            isMulti={false}
                                            value={dayOfMonthOption}
                                            options={daysOfMonthBaseOptions}
                                            error={dayOfMonthError}
                                            onChange={(newDayOfMonth) =>
                                                handleDayOfMonthChange(newDayOfMonth)
                                            }
                                        />
                                        {dayOfMonthOption?.value !== 'hireDate' && (
                                            <>
                                                <span>of</span>
                                                <SelectInputSimple
                                                    isSearchable={false}
                                                    required={false}
                                                    isMulti={false}
                                                    value={monthOption}
                                                    options={monthsOptions}
                                                    error={monthError}
                                                    onChange={(newMonth) =>
                                                        handleMonthChange(newMonth)
                                                    }
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                                {hoursWorkedType === HoursWorkedTimeOptions.Monthly && (
                                    <>
                                        <span>on</span>
                                        <SelectInputSimple
                                            isSearchable={false}
                                            required={false}
                                            isMulti={false}
                                            value={dayOfMonthOption}
                                            options={daysOfMonthBaseOptions}
                                            error={dayOfMonthError}
                                            onChange={(newDayOfMonth) =>
                                                handleDayOfMonthChange(newDayOfMonth)
                                            }
                                        />
                                        <span>day</span>
                                    </>
                                )}
                                {hoursWorkedType === HoursWorkedTimeOptions.Weekly && (
                                    <>
                                        <span>on</span>
                                        <SelectInputSimple
                                            isSearchable={false}
                                            required={false}
                                            isMulti={false}
                                            value={dayOfWeekOption}
                                            options={daysOfWeekOptions}
                                            error={dayOfWeekError}
                                            onChange={(newDayOfWeek) =>
                                                handleDayOfWeekChange(newDayOfWeek)
                                            }
                                        />
                                    </>
                                )}
                                {hoursWorkedType === HoursWorkedTimeOptions.Daily && (<><span></span></>)}
                                {hoursWorkedType === HoursWorkedTimeOptions.OnHireDate && (<><span></span></>)}
                            </>
                        )}
                    </div>
                </div>
            </BaseModal>
        </div>
    );
}

export default AccrualScheduleModal;