import React, { useContext, useEffect, useRef, useState } from 'react';

import { MultiSelect } from "react-multi-select-component";
import { ModalContext } from "../../../../Common/Context/ModalContext";
import SearchResults from "./Component/SearchResults";
import { useKeyHandlers} from "./Hook/useKeyHandlers";

import { fetchCompanyOptions } from "../../../../../shared/FetchOptions/fetchCompanyOptions";
import { fetchJobTitleOptions } from "../../../../../shared/FetchOptions/fetchJobTitleOptions";
import { SelectValue } from "../../../../../shared/Common/data/SelectValue";
import { EmployeeDataFromApi} from "../../../../../shared/Search/data/EmployeeDataFromApi";

import { SEARCH } from "../../../../../utils/constants/api";

import './SearchInput.scss';

function SearchInput() {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [results, setResults] = useState<EmployeeDataFromApi[]>([]);

    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectValue[]>([]);

    const [jobTitleOptions, setJobTitleOptions] = useState<SelectValue[]>([]);
    const [selectedJobTitleOptions, setSelectedJobTitleOptions] = useState<SelectValue[]>([]);

    const searchWrapperRef = useRef<HTMLDivElement | null>(null);
    const highlightedIndexRef = useRef<number | null>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    const { isModalOpen } = useContext(ModalContext);

    useEffect(() => {
        if (searchPhrase && searchPhrase.length >= 2) {
            fetchSearchResults(searchPhrase.split(' ')[0]);
        }
    }, [selectedCompanyOptions, selectedJobTitleOptions]);

    useEffect(() => {
        fetchCompanyOptions()
            .then(options => {
                const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
                setCompanyOptions(sortedOptions);
            });
        fetchJobTitleOptions()
            .then(options => {
                const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
                setJobTitleOptions(sortedOptions);
            });

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useKeyHandlers({
        results,
        highlightedIndexRef,
        setHighlightedIndex,
        navigateTo: (url) => {
            window.location.href = url;
        },
        handleEscape: () => {
            handleEscape();
        },
        isModalOpen,
        searchWrapperRef
    });

    useEffect(() => {
        setHighlightedIndex(null);
    }, [searchPhrase]);

    function handleClickOutside(event: any) {
        if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
            clear();
        }
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchPhrase(e.target.value);
        if (e.target.value.length >= 2) {
            fetchSearchResults(e.target.value.split(' ')[0]);
        } else {
            setResults([]);
        }
    }

    function buildQueryString(
        baseURL: string,
        phrase: string,
        companies: SelectValue[],
        jobTitles: SelectValue[]
    ): string {
        const companyIDs = companies.map(c => c.value).join(',');
        const jobTitleIDs = jobTitles.map(j => j.value).join(',');

        return `${baseURL}/${phrase}?companies=${companyIDs}&jobTitles=${jobTitleIDs}`;
    }

    async function fetchSearchResults(searchWord: string) {
        const url = buildQueryString(SEARCH, searchWord, selectedCompanyOptions, selectedJobTitleOptions);
        try {
            const response = await fetch(url);
            if (!response.ok) {
                console.log('Network response was not ok' + response.statusText);
            }
            const data: EmployeeDataFromApi[] = await response.json();
            setResults(data);
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    function handleLinkClick() {
        setResults([]);
        setSearchPhrase('');
    }

    function handleFocus() {
        setIsFocused(true);
    }

    function handleClear() {
        setSearchPhrase('');
        setResults([]);
    }

    function handleEscape() {
        console.log('handleEscape');
        clear();
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    }

    function clear() {
        setSearchPhrase('');
        setResults([]);
        setIsFocused(false);
        setSelectedCompanyOptions([]);
        setSelectedJobTitleOptions([]);
    }

    function changeCompanyFilter(selected: SelectValue[]) {
        setSelectedCompanyOptions(selected);
    }

    function changeJobTitleFilter(selected: SelectValue[]) {
        setSelectedJobTitleOptions(selected);
    }

    function handleSearchInputClick() {
        const inputElement = document.getElementById('search');
        if (inputElement) {
            inputElement.focus();
        }
    }

    function clearFilters() {
        return () => {
            setSelectedCompanyOptions([]);
            setSelectedJobTitleOptions([]);
        }
    }

    return (
        <div ref={searchWrapperRef} className={`search-wrapper ${isFocused ? 'focused' : ''}`}>
            <div className='search-input' onClick={handleSearchInputClick}>
                <i className='material-icons search-icon'>search</i>
                <input
                    ref={searchInputRef}
                    id="search"
                    maxLength={50}
                    type='text'
                    autoComplete={'off'}
                    value={searchPhrase}
                    onChange={handleInputChange}
                    placeholder={'Search by nickname, email, etc..'}
                    onFocus={handleFocus}
                />
                {searchPhrase && searchPhrase.length >= 1 && (
                    <i className='material-icons close-icon' onClick={handleClear}>close</i>
                )}
            </div>
            <div className='search-settings'>
                <div className='select-chips'>
                    <MultiSelect
                        options={ companyOptions }
                        hasSelectAll={ false }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedCompanyOptions }
                        disabled={ false }
                        onChange={changeCompanyFilter}
                        className={'multi-select-with-checkboxes'}
                        labelledBy={'Company'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "Company",
                                "selectSomeItems": "Company",
                                "selectItems": "Company",
                                "selectAllFiltered": "Company"
                            }
                        }
                    />
                </div>
                <div className='select-chips'>
                    <MultiSelect
                        options={ jobTitleOptions }
                        hasSelectAll={ false }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedJobTitleOptions }
                        disabled={ false }
                        onChange={changeJobTitleFilter}
                        className={'multi-select-with-checkboxes'}
                        labelledBy={'Job Title'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "Job Title",
                                "selectSomeItems": "Job Title",
                                "selectItems": "Job Title",
                                "selectAllFiltered": "Job Title"
                            }
                        }
                    />
                </div>
                {(selectedJobTitleOptions.length > 0 || selectedCompanyOptions.length > 0) &&
                    <div className={'clear-filters'} onClick={clearFilters()}>
                        Clear filters
                    </div>
                }
            </div>
            {results.length > 0 ? (
                <SearchResults
                    results={results}
                    highlightedIndex={highlightedIndex}
                    onLinkClick={handleLinkClick}
                />
            ) : (
                searchPhrase && searchPhrase.length >= 2 && (
                    <div className='results'>
                        <p className='no-results'>No results found</p>
                    </div>
                )
            )}
        </div>
    );
}

export default SearchInput;
