export const MAIN: string = '/';
export const SIGN_IN: string = '/sign-in';
export const ADMIN: string = '/admin';
export const ADMIN_GREETINGS: string = '/admin/greetings';
export const ADMIN_LINKS: string = '/admin/links';
export const ADMIN_COMPANIES: string = '/admin/companies';
export const ADMIN_SEXES_AND_GENDERS: string = '/admin/genderes-sexes';
export const ADMIN_LEVELS: string = '/admin/levels';
export const ADMIN_JOB_TITLES: string = '/admin/job-titles';
export const ADMIN_TYPES: string = '/admin/types';
export const ADMIN_STATUSES: string = '/admin/statuses';
export const ADMIN_LOCATIONS: string = '/admin/locations';
export const ADMIN_TIMEOFF_TYPES: string = '/admin/timeoff-types';
export const ADMIN_TEAMS: string = '/admin/teams';
export const ADMIN_PUSH_NOTIFICATIONS: string = '/admin/push-notifications';
export const ADMIN_HOLIDAYS: string = '/admin/calendar';
export const ADMIN_EMPLOYEES: string = '/admin/employees';
export const EMPLOYEE_PROFILE: string = '/employee';
export const ADMIN_TIMEOFF_POLICIES: string = '/admin/timeoff-policies';
export const CALENDAR: string = '/calendar';
export const FORBIDDEN: string = '/forbidden';
export const NOT_FOUND: string = '/404';