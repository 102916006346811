import React, {useEffect, useState} from 'react';
import {LocationFromApi} from "../../Locations/Locations";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";
import GeneralInfoModal from "./GeneralInfoModal";
import AccrualScheduleModal from "./AccrualScheduleModal";
import AccrualOptionsModal from "./AccrualOptionsModal";
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {AccrualSchedule} from "../../../../../shared/TimeOffPolicy/data/AccrualSchedule";
import './TimeOffPolicyParentModal.scss';
import ApprovalOptionsModal from "./ApprovalOptionsModal";
import {TimeOptions} from "../../../../../shared/TimeOffPolicy/constants/AccrualSchedule";

type TimeOffPolicyParentModal = {
    source: string,
    opened: boolean,
    name: string | null,
    companies: SelectValue[],
    locations: SelectValue[],
    locationValues: LocationFromApi[],
    companyValues: CompanyFromApi[],
    description: string | null,
    iconId: string | null,
    hoursPerWorkingDay: number,
    accrualSchedule: AccrualSchedule | null,
    employeesReceiveAccruedTimeAt: string,
    firstAccrualShouldBe: string,
    recalculateTheRemainingDays: string,
    hasCarryOverAfterTheCarryOverDate: string,
    carryOverDays: number,
    carryOverDaysAreUnlimited: string,
    carryOverDateType: string,
    carryOverDay: number | null,
    carryOverMonth: number | null,
    hasWaitingPeriodBeforeAccruingTimeStarts: string,
    waitingPeriodDays: number,
    untilEmploymentDate: string,
    capOnTheBalanceAnEmployeeCanAccrue: string,
    maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
    automaticallyApproveRequests: string,
    approvalEmployeeType: string[]|null,
    approvalEmployeeIds: string[]|null,
    allowToAddReplacementPerson: string,
    allowToAddReplacementPersonIsMandatory: boolean,
    commentFieldIsMandatory: boolean,
    onOk: (
        name: string,
        companies: string[],
        locations: string[],
        iconId: string,
        description: string,
        hoursPerWorkingDay: number,
        accrualSchedule: object,
        employeesReceiveAccruedTimeAt: string,
        firstAccrualShouldBe: string,
        recalculateTheRemainingDays: boolean,
        hasCarryOverAfterTheCarryOverDate: boolean,
        carryOverDays: number,
        carryOverDaysAreUnlimited: boolean,
        carryOverDateType: string,
        carryOverDay: number | null,
        carryOverMonth: number | null,
        hasWaitingPeriodBeforeAccruingTimeStarts: boolean,
        waitingPeriodDays: number,
        untilEmploymentDate: boolean,
        capOnTheBalanceAnEmployeeCanAccrue: boolean,
        maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
        automaticallyApproveRequests: boolean,
        approvalEmployeeType: string[]|null,
        approvalEmployeeIds: string[]|null,
        allowToAddReplacementPerson: boolean,
        allowToAddReplacementPersonIsMandatory: boolean,
        commentFieldIsMandatory: boolean,
        validate: (errors: ValidationError[]) => void
    ) => void,
    onCancel: () => any
}

function TimeOffPolicyParentModal(props: TimeOffPolicyParentModal) {
    const [name, setName] = useState<string>(props.name || '');
    const [companies, setCompanies] = useState<SelectValue[]>(props.companies || []);
    const [locations, setLocations] = useState<SelectValue[]>(props.locations || []);
    const [iconId, setIconId] = useState<string | null>(props.iconId || null);
    const [description, setDescription] = useState<string>(props.description || '');
    const [hoursPerWorkingDay, setHoursPerWorkingDay] = useState<number>(props.hoursPerWorkingDay || 8);
    const [accrualSchedule, setAccrualSchedule] = useState<object>(props.accrualSchedule || {});
    const [employeesReceiveAccruedTimeAt, setEmployeesReceiveAccruedTimeAt] = useState<string>(props.employeesReceiveAccruedTimeAt || '');
    const [firstAccrualShouldBe, setFirstAccrualShouldBe] = useState<string>(props.firstAccrualShouldBe || '');
    const [recalculateTheRemainingDays, setRecalculateTheRemainingDays] = useState<string>(props.recalculateTheRemainingDays || 'false');
    const [hasCarryOverAfterTheCarryOverDate, setHasCarryOverAfterTheCarryOverDate] = useState<string>(props.hasCarryOverAfterTheCarryOverDate || 'false');
    const [carryOverDays, setCarryOverDays] = useState<number>(props.carryOverDays || 0);
    const [carryOverDaysAreUnlimited, setCarryOverDaysAreUnlimited] = useState<string>(props.carryOverDaysAreUnlimited || 'false');
    const [carryOverDateType, setCarryOverDateType] = useState<string>(props.carryOverDateType || '');
    const [carryOverDay, setCarryOverDay] = useState<number | null>(props.carryOverDay || null);
    const [carryOverMonth, setCarryOverMonth] = useState<number | null>(props.carryOverMonth || null);
    const [hasWaitingPeriodBeforeAccruingTimeStarts, setHasWaitingPeriodBeforeAccruingTimeStarts] = useState<string>(props.hasWaitingPeriodBeforeAccruingTimeStarts || 'false');
    const [waitingPeriodDays, setWaitingPeriodDays] = useState<number>(props.waitingPeriodDays || 0);
    const [untilEmploymentDate, setUntilEmploymentDate] = useState<string>(props.untilEmploymentDate || "false");
    const [capOnTheBalanceAnEmployeeCanAccrue, setCapOnTheBalanceAnEmployeeCanAccrue] = useState<string>(props.capOnTheBalanceAnEmployeeCanAccrue || 'false');
    const [maxNumberOfDaysForTheCapEmployeeCanAccrue, setMaxNumberOfDaysForTheCapEmployeeCanAccrue] = useState<number>(props.maxNumberOfDaysForTheCapEmployeeCanAccrue || 0);
    const [automaticallyApproveRequests, setAutomaticallyApproveRequests] = useState<string>(props.automaticallyApproveRequests || 'false');
    const [approvalEmployeeType, setApprovalEmployeeType] = useState<string[]|null>(props.approvalEmployeeType || null);
    const [approvalEmployeeIds, setApprovalEmployeeIds] = useState<string[]|null>(props.approvalEmployeeIds || null);
    const [allowToAddReplacementPerson, setAllowToAddReplacementPerson] = useState<string>(props.allowToAddReplacementPerson || 'false');
    const [allowToAddReplacementPersonIsMandatory, setAllowToAddReplacementPersonIsMandatory] = useState<boolean>(props.allowToAddReplacementPersonIsMandatory || false);
    const [commentFieldIsMandatory, setCommentFieldIsMandatory] = useState<boolean>(props.commentFieldIsMandatory || false);
    const [hiddenBlockByType, setHiddenBlockByType] = useState<boolean>(false);


    const [nameError, setNameError] = useState<string>('');
    const [companiesError, setCompaniesError] = useState<string>('');
    const [locationsError, setLocationsError] = useState<string>('');
    const [iconIdError, setIconIdError] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string>('');
    const [hoursPerWorkingDayError, setHoursPerWorkingDayError] = useState<string>('');
    const [accrualScheduleError, setAccrualScheduleError] = useState<string>('');
    const [employeesReceiveAccruedTimeAtError, setEmployeesReceiveAccruedTimeAtError] = useState<string>('');
    const [firstAccrualShouldBeError, setFirstAccrualShouldBeError] = useState<string>('');
    const [recalculateTheRemainingDaysError, setRecalculateTheRemainingDaysError] = useState<string>('');
    const [hasCarryOverAfterTheCarryOverDateError, setHasCarryOverAfterTheCarryOverDateError] = useState<string>('');
    const [carryOverDaysError, setCarryOverDaysError] = useState<string>('');
    const [carryOverDaysAreUnlimitedError, setCarryOverDaysAreUnlimitedError] = useState<string>('');
    const [carryOverDateTypeError, setCarryOverDateTypeError] = useState<string>('');
    const [hasWaitingPeriodBeforeAccruingTimeStartsError, setHasWaitingPeriodBeforeAccruingTimeStartsError] = useState<string>('');
    const [waitingPeriodDaysError, setWaitingPeriodDaysError] = useState<string>('');
    const [untilEmploymentDateError, setUntilEmploymentDateError] = useState<string>('');
    const [capOnTheBalanceAnEmployeeCanAccrueError, setCapOnTheBalanceAnEmployeeCanAccrueError] = useState<string>('');
    const [maxNumberOfDaysForTheCapEmployeeCanAccrueError, setMaxNumberOfDaysForTheCapEmployeeCanAccrueError] = useState<string>('');
    const [automaticallyApproveRequestsError, setAutomaticallyApproveRequestsError] = useState<string>('');
    const [allowToAddReplacementPersonError, setAllowToAddReplacementPersonError] = useState<string>('');
    const [allowToAddReplacementPersonIsMandatoryError, setAllowToAddReplacementPersonIsMandatoryError] = useState<string>('');
    const [commentFieldIsMandatoryError, setCommentFieldIsMandatoryError] = useState<string>('');

    const [generalInfoModalOpened, setGeneralInfoModalOpened] = useState<boolean>(true);
    const [accrualScheduleModalOpened, setAccrualScheduleModalOpened] = useState<boolean>(false);
    const [accrualOptionsModalOpened, setAccrualOptionsModalOpened] = useState<boolean>(false);
    const [approvalOptionsModalOpened, setApprovalOptionsModalOpened] = useState<boolean>(false);

    const infoText = 'Create and enforce time-off policies with custom parameters such as employee seniority, ' +
        'sabbaticals, and jury duty. Set rules and parameters to fit your company’s policies and comply with federal, ' +
        'state, and industry regulations.';

    useEffect(() => {
        setGeneralInfoModalOpened(props.opened);
    }, [props.opened]);

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'timeOffPolicy.name') {
                setNameError(error.message);
            } else if (error.property === 'timeOffPolicy.companiesIds') {
                setCompaniesError(error.message);
            } else if (error.property === 'timeOffPolicy.locationsIds') {
                setLocationsError(error.message);
            } else if (error.property === 'timeOffPolicy.iconId') {
                setIconIdError(error.message);
            } else if (error.property === 'timeOffPolicy.description') {
                setDescriptionError(error.message);
            } else if (error.property === 'timeOffPolicy.hoursPerWorkingDay') {
                setHoursPerWorkingDayError(error.message);
            } else if (error.property === 'timeOffPolicy.accrualSchedule') {
                setAccrualScheduleError(error.message);
            } else if (error.property === 'timeOffPolicy.employeesReceiveAccruedTimeAt') {
                setEmployeesReceiveAccruedTimeAtError(error.message);
            } else if (error.property === 'timeOffPolicy.firstAccrualShouldBe') {
                setFirstAccrualShouldBeError(error.message);
            } else if (error.property === 'timeOffPolicy.recalculateTheRemainingDays') {
                setRecalculateTheRemainingDaysError(error.message);
            } else if (error.property === 'timeOffPolicy.hasCarryOverAfterTheCarryOverDate') {
                setHasCarryOverAfterTheCarryOverDateError(error.message);
            } else if (error.property === 'timeOffPolicy.carryOverDays') {
                setCarryOverDaysError(error.message);
            } else if (error.property === 'timeOffPolicy.carryOverDaysAreUnlimited') {
                setCarryOverDaysAreUnlimitedError(error.message);
            } else if (error.property === 'timeOffPolicy.carryOverDateType') {
                setCarryOverDateTypeError(error.message);
            } else if (error.property === 'timeOffPolicy.hasWaitingPeriodBeforeAccruingTimeStarts') {
                setHasWaitingPeriodBeforeAccruingTimeStartsError(error.message);
            } else if (error.property === 'timeOffPolicy.waitingPeriodDays') {
                setWaitingPeriodDaysError(error.message);
            } else if (error.property === 'timeOffPolicy.untilEmploymentDate') {
                setUntilEmploymentDateError(error.message);
            } else if (error.property === 'timeOffPolicy.capOnTheBalanceAnEmployeeCanAccrue') {
                setCapOnTheBalanceAnEmployeeCanAccrueError(error.message);
            } else if (error.property === 'timeOffPolicy.maxNumberOfDaysForTheCapEmployeeCanAccrue') {
                setMaxNumberOfDaysForTheCapEmployeeCanAccrueError(error.message);
            } else if (error.property === 'timeOffPolicy.automaticallyApproveRequests') {
                setAutomaticallyApproveRequestsError(error.message);
            } else if (error.property === 'timeOffPolicy.allowToAddReplacementPerson') {
                setAllowToAddReplacementPersonError(error.message);
            } else if (error.property === 'timeOffPolicy.allowToAddReplacementPersonIsMandatory') {
                setAllowToAddReplacementPersonIsMandatoryError(error.message);
            } else if (error.property === 'timeOffPolicy.commentFieldIsMandatory') {
                setCommentFieldIsMandatoryError(error.message);
            }
        });
    }

    function clearErrors() {
        setNameError('');
        setCompaniesError('');
        setLocationsError('');
        setIconIdError('');
        setDescriptionError('');
        setHoursPerWorkingDayError('');
        setAccrualScheduleError('');
        setEmployeesReceiveAccruedTimeAtError('');
        setFirstAccrualShouldBeError('');
        setRecalculateTheRemainingDaysError('');
        setHasCarryOverAfterTheCarryOverDateError('');
        setCarryOverDaysError('');
        setCarryOverDaysAreUnlimitedError('');
        setCarryOverDateTypeError('');
        setHasWaitingPeriodBeforeAccruingTimeStartsError('');
        setWaitingPeriodDaysError('');
        setUntilEmploymentDateError('');
        setCapOnTheBalanceAnEmployeeCanAccrueError('');
        setMaxNumberOfDaysForTheCapEmployeeCanAccrueError('');
        setAutomaticallyApproveRequestsError('');
        setAllowToAddReplacementPersonError('');
        setAllowToAddReplacementPersonIsMandatoryError('');
        setCommentFieldIsMandatoryError('');
    }

    function resetAll() {
        setName('');
        setCompanies([]);
        setLocations([]);
        setIconId('');
        setDescription('');
        setHoursPerWorkingDay(0);
        setAccrualSchedule([]);
        setEmployeesReceiveAccruedTimeAt('');
        setFirstAccrualShouldBe('');
        setRecalculateTheRemainingDays('');
        setHasCarryOverAfterTheCarryOverDate('');
        setCarryOverDays(0);
        setCarryOverDaysAreUnlimited('');
        setCarryOverDateType('');
        setHasWaitingPeriodBeforeAccruingTimeStarts('');
        setWaitingPeriodDays(0);
        setUntilEmploymentDate('');
        setCapOnTheBalanceAnEmployeeCanAccrue('');
        setMaxNumberOfDaysForTheCapEmployeeCanAccrue(0);
        setAutomaticallyApproveRequests('');
        setAllowToAddReplacementPerson('');
        setAllowToAddReplacementPersonIsMandatory(false);
        setCommentFieldIsMandatory(false);
        setGeneralInfoModalOpened(true);
        setAccrualScheduleModalOpened(false);
        setAccrualOptionsModalOpened(false);
        setApprovalOptionsModalOpened(false);
        clearErrors();
    }

    return (
        <div className={ 'parent-modal parent-modal-' + (props.opened ? 'opened' : 'closed') }>
            <div className='parent-modal-info'>
                <i className='material-icons'>info</i> { infoText }
            </div>
            <div className='parent-modal-steps'>
                <span
                    className={accrualScheduleModalOpened || accrualOptionsModalOpened || approvalOptionsModalOpened ? 'clickable' : ''}
                    onClick={ generalInfoModalOpened ? undefined : () => {
                    setGeneralInfoModalOpened(true)
                    setAccrualScheduleModalOpened(false)
                    setAccrualOptionsModalOpened(false)
                    setApprovalOptionsModalOpened(false)
                }}>
                    <span className={generalInfoModalOpened ? "active-step-number" : "number"}>
                        {accrualScheduleModalOpened || accrualOptionsModalOpened || approvalOptionsModalOpened ? <i className='material-icons'>check</i> : '1'}
                    </span>
                    <span className={generalInfoModalOpened ? "active-step" : ""}>General info</span>
                </span>
                <span className="divider"></span>

                <span
                    className={accrualOptionsModalOpened || approvalOptionsModalOpened ? 'clickable' : ''}
                    onClick={ generalInfoModalOpened || accrualScheduleModalOpened ? undefined : () => {
                    setGeneralInfoModalOpened(false)
                    setAccrualScheduleModalOpened(true)
                    setAccrualOptionsModalOpened(false)
                    setApprovalOptionsModalOpened(false)
                }}>
                    <span className={accrualScheduleModalOpened ? "active-step-number" : "number"}>
                        {accrualOptionsModalOpened || approvalOptionsModalOpened ? <i className='material-icons'>check</i> : '2'}
                    </span>
                    <span className={accrualScheduleModalOpened ? "active-step" : ""}>Accrual Schedule</span>
                </span>
                <span className="divider"></span>

                <span
                    className={approvalOptionsModalOpened ? 'clickable' : ''}
                    onClick={ generalInfoModalOpened || accrualScheduleModalOpened || accrualOptionsModalOpened ? undefined : () => {
                    setGeneralInfoModalOpened(false)
                    setAccrualScheduleModalOpened(false)
                    setAccrualOptionsModalOpened(true)
                    setApprovalOptionsModalOpened(false)
                }}>
                    <span className={accrualOptionsModalOpened ? "active-step-number" : "number"}>
                        {approvalOptionsModalOpened ? <i className='material-icons'>check</i> : '3'}
                    </span>
                    <span className={accrualOptionsModalOpened ? "active-step" : ""}>Accrual Options</span>
                </span>
                <span className="divider"></span>

                <span>
                    <span className={approvalOptionsModalOpened ? "active-step-number" : "number"}>
                        4
                    </span>
                    <span className={approvalOptionsModalOpened ? "active-step" : ""}>Approval Options</span>
                </span>
            </div>

            <GeneralInfoModal
                source={props.source}
                opened={generalInfoModalOpened}
                modalTitle='General Info'
                name={props.name}
                companyValues={props.companyValues}
                companies={companies}
                locationValues={props.locationValues}
                locations={locations}
                iconId={props.iconId}
                description={props.description}
                okText='Continue'
                infoText={infoText}
                onOk={
                    (name, companies, locations, iconId, description) => {
                        setName(name);
                        setCompanies(companies);
                        setLocations(locations);
                        setIconId(iconId);
                        setDescription(description);
                        setGeneralInfoModalOpened(false);
                        setAccrualScheduleModalOpened(true);
                    }
                }
                style={'first-page'}
            />
            <AccrualScheduleModal
                opened={accrualScheduleModalOpened}
                modalTitle='Accrual Schedule'
                hoursPerWorkingDay={props.hoursPerWorkingDay}
                accrualSchedule={props.accrualSchedule}
                okText='Continue'
                onOk={
                    (hoursPerWorkingDay, accrualSchedule) => {
                        setHoursPerWorkingDay(hoursPerWorkingDay);
                        setAccrualSchedule(accrualSchedule);
                        // @ts-ignore
                        if (accrualSchedule.type === TimeOptions.YearlyOnHireDate || accrualSchedule.type === TimeOptions.YearlyOnBirthdayDate || accrualSchedule.type === TimeOptions.YearlyOnEmploymentDate || accrualSchedule.type === TimeOptions.NDays) {
                            setHiddenBlockByType(true);
                        }
                        setAccrualScheduleModalOpened(false);
                        setAccrualOptionsModalOpened(true);
                    }
                }
                style={'wide-page'}
            />
            <AccrualOptionsModal
                opened={accrualOptionsModalOpened}
                modalTitle='Accrual Options'
                okText='Continue'
                hiddenBlockByType={hiddenBlockByType}
                employeesReceiveAccruedTimeAt={props.employeesReceiveAccruedTimeAt}
                firstAccrualShouldBe={props.firstAccrualShouldBe}
                recalculateTheRemainingDays={props.recalculateTheRemainingDays}
                hasCarryOverAfterTheCarryOverDate={props.hasCarryOverAfterTheCarryOverDate}
                carryOverDays={props.carryOverDays}
                carryOverDaysAreUnlimited={props.carryOverDaysAreUnlimited}
                carryOverDateType={props.carryOverDateType}
                carryOverDay={props.carryOverDay}
                carryOverMonth={props.carryOverMonth}
                hasWaitingPeriodBeforeAccruingTimeStarts={props.hasWaitingPeriodBeforeAccruingTimeStarts}
                waitingPeriodDays={props.waitingPeriodDays}
                untilEmploymentDate={props.untilEmploymentDate}
                capOnTheBalanceAnEmployeeCanAccrue={props.capOnTheBalanceAnEmployeeCanAccrue}
                maxNumberOfDaysForTheCapEmployeeCanAccrue={props.maxNumberOfDaysForTheCapEmployeeCanAccrue}
                onOk={(
                    employeesReceiveAccruedTimeAt,
                    firstAccrualShouldBe,
                    recalculateTheRemainingDays,
                    hasCarryOverAfterTheCarryOverDate,
                    carryOverDays,
                    carryOverDaysAreUnlimited,
                    carryOverDateType,
                    carryOverDay,
                    carryOverMonth,
                    hasWaitingPeriodBeforeAccruingTimeStarts,
                    waitingPeriodDays,
                    untilEmploymentDate,
                    capOnTheBalanceAnEmployeeCanAccrue,
                    maxNumberOfDaysForTheCapEmployeeCanAccrue
                ) => {
                    setEmployeesReceiveAccruedTimeAt(employeesReceiveAccruedTimeAt);
                    setFirstAccrualShouldBe(firstAccrualShouldBe);
                    setRecalculateTheRemainingDays(recalculateTheRemainingDays);
                    setHasCarryOverAfterTheCarryOverDate(hasCarryOverAfterTheCarryOverDate);
                    setCarryOverDays(carryOverDays);
                    setCarryOverDaysAreUnlimited(carryOverDaysAreUnlimited);
                    setCarryOverDateType(carryOverDateType);
                    setCarryOverDay(carryOverDay);
                    setCarryOverMonth(carryOverMonth);
                    setHasWaitingPeriodBeforeAccruingTimeStarts(hasWaitingPeriodBeforeAccruingTimeStarts);
                    setWaitingPeriodDays(waitingPeriodDays);
                    setUntilEmploymentDate(untilEmploymentDate);
                    setCapOnTheBalanceAnEmployeeCanAccrue(capOnTheBalanceAnEmployeeCanAccrue);
                    setMaxNumberOfDaysForTheCapEmployeeCanAccrue(maxNumberOfDaysForTheCapEmployeeCanAccrue);
                    setAccrualOptionsModalOpened(false);
                    setApprovalOptionsModalOpened(true);
                }}
                style={'wide-page'}
            />
            <ApprovalOptionsModal
                source={props.source}
                opened={approvalOptionsModalOpened}
                modalTitle='Approval Options'
                okText='Save'
                automaticallyApproveRequests={props.automaticallyApproveRequests}
                approvalEmployeeType={props.approvalEmployeeType}
                approvalEmployeeIds={props.approvalEmployeeIds}
                allowToAddReplacementPerson={props.allowToAddReplacementPerson}
                allowToAddReplacementPersonIsMandatory={props.allowToAddReplacementPersonIsMandatory}
                commentFieldIsMandatory={props.commentFieldIsMandatory}
                companiesFromGeneralInfo={companies}
                onOk={(
                    automaticallyApproveRequests,
                    approvalEmployeeType,
                    approvalEmployeeIds,
                    allowToAddReplacementPerson,
                    allowToAddReplacementPersonIsMandatory,
                    commentFieldIsMandatory
                ) => {
                    setAutomaticallyApproveRequests(automaticallyApproveRequests);
                    setApprovalEmployeeType(approvalEmployeeType);
                    setApprovalEmployeeIds(approvalEmployeeIds);
                    setAllowToAddReplacementPerson(allowToAddReplacementPerson);
                    setAllowToAddReplacementPersonIsMandatory(allowToAddReplacementPersonIsMandatory);
                    setCommentFieldIsMandatory(commentFieldIsMandatory);
                    setApprovalOptionsModalOpened(false);

                    props.onOk(
                        name,
                        companies.map(company => company.value),
                        locations.map(location => location.value),
                        iconId ? iconId : '',
                        description,
                        hoursPerWorkingDay,
                        accrualSchedule,
                        employeesReceiveAccruedTimeAt,
                        firstAccrualShouldBe,
                        recalculateTheRemainingDays === 'true',
                        hasCarryOverAfterTheCarryOverDate === 'true',
                        carryOverDays,
                        carryOverDaysAreUnlimited === 'true',
                        carryOverDateType,
                        carryOverDay,
                        carryOverMonth,
                        hasWaitingPeriodBeforeAccruingTimeStarts === 'true',
                        waitingPeriodDays,
                        untilEmploymentDate === 'true',
                        capOnTheBalanceAnEmployeeCanAccrue === 'true',
                        maxNumberOfDaysForTheCapEmployeeCanAccrue,
                        automaticallyApproveRequests === 'true',
                        approvalEmployeeType,
                        approvalEmployeeIds,
                        allowToAddReplacementPerson === 'true',
                        allowToAddReplacementPersonIsMandatory,
                        commentFieldIsMandatory,
                        validate
                    );
                }}

                onCancel={() => {
                    props.onCancel();
                    resetAll();
                }}

                style={'wide-page'}
            />
        </div>
    )
}

export default TimeOffPolicyParentModal;