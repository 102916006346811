export function fieldAutoResizer(className: string, text: string, size: number = 25, needleSizeDefault = 228, maxSize = 300) {
    const fields = document.getElementsByClassName(className);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let textWidth = 0;
    if (context) {
        context.font = '16.2px sans-serif';
        textWidth = context.measureText(text).width;
    }
    canvas.remove();
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i] as HTMLTextAreaElement;
        field.style.width = '0';
        field.style.height = '36';
        let needlesize = size + textWidth < needleSizeDefault ? needleSizeDefault : size + textWidth;
        needlesize = maxSize < needlesize ? maxSize : needlesize;
        field.style.setProperty('width', `${needlesize}px`, 'important');
        field.style.setProperty('min-width', `${needlesize}px`, 'important');
        field.style.setProperty('height', `36px`, 'important');
        field.style.setProperty('max-height', `36px`, 'important');
    }
}