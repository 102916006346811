import React, {useEffect, useState} from 'react';

import {Tooltip} from "react-tooltip";

import {RequestFromApi} from "../../../../../shared/Calendar/Request/data/RequestFromApi";

import {CALENDAR} from "../../../../../utils/constants/api";
import {EMPLOYEE_PROFILE} from "../../../../../utils/constants/routes";
import {TimeOffTypeIconsCollection} from "../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import {TimeOffTypeCircleIconsCollection} from "../../../../../shared/TimeOffType/constants/TimeOffTypeCircleIconsCollection";

import './AbsentWidget.scss';
import Button, {Size, Type} from "../../../../Component/Button/Button";
import {dateFromStringWithDefaultTimezone} from "../../../../../utils/helpers/DateTime/dateFromStringWithDefaultTimezone";

type AbsentWidget = {
    setLoadingBarProgress: (progress: number) => void,
    requestModal: (isOpen: boolean) => void;
}

function AbsentWidget(props: AbsentWidget) {

    const [approvedRequests, setApprovedRequests] = useState<RequestFromApi[]>([]);
    const [areThereAnyAbsent, setAreThereAnyAbsent] = useState(false);

    useEffect(() => {
        props.setLoadingBarProgress(50);

        fetch(CALENDAR + '/requests?start-date=' + getTodayDateString() + '&end-date=' + getTodayDateString(), {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(data => {
                setApprovedRequests(data);
                setAreThereAnyAbsent(data.length > 0);
            })
            .finally(() => props.setLoadingBarProgress(100));
    }, []);

    function getTodayDateString() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        return `${year}-${month}-${day}`;
    }

    function formatDateToFriendly(date: string): string {
        const dateObject = dateFromStringWithDefaultTimezone(date) || (new Date(date));
        const monthName = dateObject.toLocaleString('en-US', { month: 'long' });
        const dayNumber = dateObject.getDate();

        return `${monthName} ${dayNumber}`;
    }

    return (
        <div className='absent-widget'>
            <div className={'widget-header'}>
                <div>
                    <div className='absent-block-description'>DAYS OFF</div>
                    <div className='absent-block-title'>Skipping work today</div>
                </div>
                <div className={'btns-block'}>
                    <Button
                        text="<i className='material-icons add-item'>add</i> Request Time Off"
                        size={ Size.Medium }
                        type={ Type.LightBlue }
                        onClick={ () => props.requestModal(true) }
                    />
                </div>
            </div>
            <div className='absent-body'>
                {!areThereAnyAbsent ? (
                    <div className={'no-absence-text'}>
                        <p> Everyone is present, accounted for, and diligently working. Must be some </p>
                        <p> sort of productivity potion going around! 😉 </p>
                    </div>
                ) : (
                    approvedRequests.map((request, index) => (
                        <div>
                            <div
                                className={'employee-card'}
                                data-tooltip-id={index + '-tooltip-key'}
                            >
                                <a
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                    href={EMPLOYEE_PROFILE + '/' + request.requesterNickname}
                                >
                                    { request.requesterAvatar ? (
                                        <div className='employee-image'>
                                            <img src={request.requesterAvatar} alt='Employee avatar' />
                                        </div>
                                    ) : (
                                        <div className='empty-image'>
                                            <p>{ request.requesterNickname.charAt(0).toUpperCase() }</p>
                                        </div>
                                    )}
                                </a>
                                <div className='request-icon'>
                                    <div
                                        className="svg-icon"
                                        dangerouslySetInnerHTML={{ __html: TimeOffTypeCircleIconsCollection[
                                            request.typeIconName as keyof typeof TimeOffTypeCircleIconsCollection
                                                ]}} />
                                </div>
                            </div>

                            <Tooltip
                                id={index + '-tooltip-key'}
                                place="right"
                                noArrow={false}
                            >
                                <div className={'employee-block'}>
                                    <span className={'popup-main-text'}>{request.requesterNickname}</span>
                                </div>

                                <span className={'company'}>{request.companyName}</span>

                                <div className={'type-block'} style={{
                                    background: request.typeColor
                                }}>
                                    <div className={'vacation-block'} dangerouslySetInnerHTML={{
                                        __html: TimeOffTypeIconsCollection[
                                            request.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                            ]

                                    }} />
                                    <span className={'event-name'}>{request.typeName}</span>
                                </div>

                                <div className={"tool-date"}>
                                    <i className={"material-symbols-outlined calendar-today"}>date_range</i>
                                    {formatDateToFriendly(request.startDate)} {request.endDate ? ' — ' + formatDateToFriendly(request.endDate) : ''}
                                </div>

                                {request.substituteNickname && (
                                    <span className="replacing">Replacing: {request.substituteNickname} </span>
                                )}
                            </Tooltip>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default AbsentWidget;