import React from 'react';
import BaseModal from './BaseModal';
import { STATUSES } from '../../../../../../../utils/constants/api';
import {ValidationError} from "../../../../../../../shared/Common/Error/ValidationError";
import {VALIDATION_ERROR} from "../../../../../../../utils/constants/errors";

type CreateStatus = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any
};

function CreateStatus(props: CreateStatus) {
    function create(name: string, validate: (errors: ValidationError[]) => void): void {
        let formData = new FormData();
        formData.append('employeeStatus', JSON.stringify({
            name: name
        }));

        fetch(STATUSES, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Employee Status'
            name={ null }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateStatus;