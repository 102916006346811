import React from 'react';
import { TEAMS } from '../../../../../utils/constants/api';
import { TeamFromApi } from '../../../../../shared/Team/data/TeamFromApi';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";

type DeleteTeam = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    team: TeamFromApi
};

function DeleteTeam(props: DeleteTeam) {
    function deleteTeam(): void {
        fetch(TEAMS + '/' + props.team.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then((jsonData: any) => {
                        if (jsonData.error) {
                            alert(jsonData.error);
                        } else if (Array.isArray(jsonData) && jsonData.length > 0) {
                            const errors: ValidationError[] = jsonData;
                            validate(errors);
                        }
                    });
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Team'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.team.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteTeam }
        />
    )
}

function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
        if (error.property === 'team') {
            alert(error.message);
        }
    });
}
export default DeleteTeam;