import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment/moment';
import DatePickerPlugin from "react-datepicker";
import {Link} from 'react-router-dom';
import {MultiSelect} from "react-multi-select-component";

import Button, {Size, Type} from '../../../Component/Button/Button';
import CreateRequest from './Modal/CreateRequest';
import PreviewRequest from "./Modal/PreviewRequest";
import ConfirmApproveRequest from "./Modal/ConfirmApproveRequest";
import {checkSubstitute} from "../../../../utils/helpers/EmployeeChecks";

import {RequestFromApi} from "../../../../shared/Calendar/Request/data/RequestFromApi";
import {ValidationError} from "../../../../shared/Common/Error/ValidationError";
import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {EmployeeFromApi} from "../../../../shared/Employee/data/EmployeeFromApi";
import {SelectValue} from "../../../../shared/Common/data/SelectValue";

import {CALENDAR, EMPLOYEES, EMPLOYEE} from "../../../../utils/constants/api";
import {VALIDATION_ERROR} from "../../../../utils/constants/errors";
import {EMPLOYEE_PROFILE} from "../../../../utils/constants/routes";
import {ROLE_ADMIN, ROLE_HR} from "../../../../shared/Security/constants/AccessLevels";

import './Requests.scss';
import {Popover} from "@headlessui/react";
import {TimeOffTypeIconsCollection} from "../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";

type Requests = {
    authEmployee: AuthEmployee
};

function Requests(props: Requests) {

    const [createRequestModal, setCreateRequestModal] = useState<boolean>(false);
    const [previewRequestModal, setPreviewRequestModal] = useState<boolean>(false);
    const [approveModalOpened, setApproveModalOpened] = useState<boolean>(false);
    const [requests, setRequests] = useState<RequestFromApi[]>([]);
    const [requestToPreview, setRequestToPreview] = useState<RequestFromApi | null>(null);
    const [requestToApprove, setRequestToApprove] = useState<RequestFromApi | null>(null);
    const [hierarchyChainIds, setHierarchyChainIds] = useState<string[]>([]);
    const [approverRelatedPolicies, setApproverRelatedPolicies] = useState<string[]>([]);
    const [myCompanyLabel, setMyCompanyLabel] = useState<string>('');

    const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);

    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectValue[]>([]);

    const [employeeOptions, setEmployeeOptions] = useState<SelectValue[]>([]);
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<SelectValue[]>([]);

    const [teamOptions, setTeamOptions] = useState<SelectValue[]>([]);
    const [selectedTeamOptions, setSelectedTeamOptions] = useState<SelectValue[]>([]);

    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;

    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(15);
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const [hasMore, setHasMore] = useState(true);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    const selectedEmployeeOptionsRef = useRef(selectedEmployeeOptions);
    const requestsRef = useRef(requests);
    const hasMoreRef = useRef(hasMore);
    const prevScrollTopRef = useRef(prevScrollTop);
    const loadingRef = useRef(loading);
    const dateRangeRef = useRef(dateRange);

    const statuses = ['New', 'Rejected', 'Approved', 'Cancelled'];
    const [statusesChecked, setStatusesChecked] = useState(statuses);
    const statusesCheckedRef = useRef(statusesChecked);
    const heightOverflow = document.querySelector('.requests');
    const handleStatusChange = (status: string) => {
        const updatedStatuses = statusesChecked.includes(status)
            ? statusesChecked.filter((s) => s !== status)
            : [...statusesChecked, status];

        setStatusesChecked(updatedStatuses);
    };

    useEffect(() => {
        setRequests([]);
        setPrevScrollTop(0);
        setHasMore(true);
        hasMoreRef.current = true;
        requestsRef.current = [];
        dateRangeRef.current = dateRange;
        statusesCheckedRef.current = statusesChecked;

        if ((dateRangeRef.current[0] && dateRangeRef.current[1]) || (!dateRangeRef.current[0] && !dateRangeRef.current[1])) {
            fetchRequests();
        }
    }, [employeeOptions, selectedEmployeeOptionsRef.current, dateRange, statusesChecked]);

    useEffect(() => {
        prevScrollTopRef.current = prevScrollTop;
    }, [prevScrollTop]);

    useEffect(() => {
        hasMoreRef.current = hasMore;
    }, [hasMore]);

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {
        dateRangeRef.current = dateRange;
    }, [dateRange]);

    useEffect(() => {
        getEmployeesWithCompanyAndTeam();
        getApproverRelatedPolicies();
        const container = document.querySelector('.sub-content-main');
        if (container) {
            container.addEventListener('wheel', handleScroll);
            container.addEventListener('touchmove', handleScroll);
            return () => {
                container.removeEventListener('wheel', handleScroll);
                container.removeEventListener('touchmove', handleScroll);
            };
        }
    }, []);

    function handleScroll(event:any)  {
        const deltaY = event.deltaY;
        if (hasMoreRef.current && !loadingRef.current && deltaY > 0 && !createRequestModal && !approveModalOpened && !previewRequestModal) {
            const container = containerRef.current;
            if (container) {
                if (containerRef.current.offsetHeight >= prevScrollTopRef.current) {
                    fetchRequests();
                }
                setPrevScrollTop(containerRef.current.offsetHeight);
            }
        }
    }

    function fetchRequests() {
        const employeesForCheck = JSON.stringify(selectedEmployeeOptionsRef.current);
        if (selectedEmployeeOptionsRef.current.length === 0) {
            selectedEmployeeOptionsRef.current = employeeOptions;
        }
        if (hasMoreRef.current) {
            setLoading(true);
            loadingRef.current = true;

            const startDate = dateRangeRef.current[0] ? dateRangeRef.current[0].getFullYear() + '-' + (dateRangeRef.current[0].getMonth()+1) + "-" + dateRangeRef.current[0].getDate() : '';
            const endDate = dateRangeRef.current[1] ? dateRangeRef.current[1].getFullYear() + '-' + (dateRangeRef.current[1].getMonth()+1) + "-" + dateRangeRef.current[1].getDate() : '';

            let formData = new FormData();
            formData.append('requestsList', JSON.stringify({
                employees: JSON.stringify(selectedEmployeeOptionsRef.current.map(option => option.value)),
                startDate: startDate,
                endDate: endDate,
                offset: requestsRef.current.length.toString(),
                limit: limit.toString(),
                statuses: statusesCheckedRef.current.toString(),
                authEmployeeId: props.authEmployee.id,
                roleEmployee: !hasRole,
                selfInRequests: selectedEmployeeOptionsRef.current.map(option => option.value).includes(props.authEmployee.id)
            }));

            fetch(CALENDAR + '/requests-list', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then((newRequests) => {
                    if (newRequests.length > 0) {
                        let combinedRequests = [...requestsRef.current, ...newRequests];
                        let uniqueRequests = Array.from(new Set(combinedRequests.map(req => req.id)))
                            .map(id => combinedRequests.find(req => req.id === id));

                        if (employeesForCheck !== JSON.stringify(selectedEmployeeOptionsRef.current)) {
                            setLoading(false);
                            loadingRef.current = false;
                            fetchRequests();
                            return;
                        }

                        setRequests(uniqueRequests);
                        requestsRef.current = uniqueRequests;

                        setHasMore(newRequests.length > 0);
                    } else {
                        setHasMore(false);
                    }
                    setLoading(false);
                    loadingRef.current = false;
                });
        }
    }

    function previewModalVisibility(request: RequestFromApi): void {
        setRequestToPreview(request);
        setPreviewRequestModal(true);
    }

    useEffect(() => {
        if (props.authEmployee && props.authEmployee.id) {
            getHierarchyChainIds(props.authEmployee.id)
                .then((ids: string[]) => setHierarchyChainIds(ids));
        }
    }, [props.authEmployee]);

    function setStatus(requestId: string, status: string) {
        fetch(CALENDAR + '/requests/' + requestId + '/status/' + status + '?status-change-by=' + props.authEmployee.id, {
            method: 'PUT',
        }).then(response => {
            if (response.ok) {
                const updatedRequests = requests.map(request => {
                    if (request.id === requestId) {
                        return {
                            ...request,
                            status: status,
                            statusChangeByNickname: props.authEmployee.nickname
                        };
                    }
                    return request;
                });
                setRequests(updatedRequests);
            } else if (response.status === VALIDATION_ERROR) {
                response.json().then((errors: ValidationError[]) => validate(errors));
            }
        });
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'requests.status') {
                alert(error.message);
            }
        });
    }

    function emptyEmployeesFilter(employeesValues: EmployeeFromApi[] | null = null) {
        if (!employeesValues) {
            employeesValues = employees;
        }
        let companyOptions = employeesValues
            .map(employee => {
                if (employee.companyId === props.authEmployee.companyId && employee.companyName) {
                    setMyCompanyLabel(employee.companyName);
                }
                return {
                    value: employee.companyId,
                    label: employee.companyName
                };
            })
            .filter((company, index, self) => {
                const foundIndex = self.findIndex(c => c.value === company.value);
                return foundIndex === index;
            })
            .filter(company => company.value !== null && company.label !== null);

        setCompanyOptions(sortASC(companyOptions as SelectValue[]));

        let teamOptions = employeesValues
            .map(employee => {
                return {
                    value: employee.teamId,
                    label: employee.teamName
                };
            })
            .filter((team, index, self) => {
                const foundIndex = self.findIndex(c => c.value === team.value);
                return foundIndex === index;
            })
            .filter(team => team.value !== null && team.label !== null);

        teamOptions.push({label: 'Without Team', value: 'null'});
        setTeamOptions(sortASC(teamOptions as SelectValue[]));

        let employeeOptions = employeesValues.map(employee => {
            return {
                value: employee.id,
                label: employee.nickname
            }
        });
        setEmployeeOptions(sortASC(employeeOptions))
    }

    function getEmployeesWithCompanyAndTeam() {
        fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(async (employees: EmployeeFromApi[]) => {
                if (!hasRole) {
                    let hierarchyChainIds: string[] = await getHierarchyChainIds(props.authEmployee.id);
                    let subordinateFromPolicyEmployeeIds: string[] = Object.values(await getSubordinatesFromPoliciesIds(props.authEmployee.id));
                    employees = employees.filter(
                        employee => employee.id === props.authEmployee.id ||
                            hierarchyChainIds.includes(employee.id) || subordinateFromPolicyEmployeeIds.includes(employee.id)
                    );
                }
                setEmployees(employees);
                emptyEmployeesFilter(employees);
            });
    }

    function getHierarchyChainIds(employeeId: string): Promise<string[]> {
        return fetch(EMPLOYEE + '/' + employeeId + '/all-hierarchy-chains', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json());
    }

    function getSubordinatesFromPoliciesIds(employeeId: string): Promise<string[]> {
        return fetch(EMPLOYEES + '/employee/' + employeeId + '/subordinates-from-policies', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json());
    }

    function isHierarchyChain(employeeId: string): boolean {
        return hierarchyChainIds.includes(employeeId);
    }

    function clearFilters() {
        setDateRange([]);
        setSelectedCompanyOptions([]);
        setSelectedTeamOptions([]);
        setSelectedEmployeeOptions([]);
        selectedEmployeeOptionsRef.current = [] as SelectValue[];
        emptyEmployeesFilter();
    }

    function getApproverRelatedPolicies() {
        return fetch(EMPLOYEES + '/' + props.authEmployee.id + '/approver-related-policies', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((policies: string[]) => setApproverRelatedPolicies(policies));
    }

    function confirmApproveRequest(request: RequestFromApi) {
        const employeeId = request.substituteId;
        if (!employeeId) {
            setStatus(request.id, 'Approved');
            setPreviewRequestModal(false);
            return;
        }
        const dateStart = moment(request.startDate).format('YYYY-MM-DD');
        const dateEnd = moment(request.endDate).format('YYYY-MM-DD');
        const requestId = request.id;

        checkSubstitute(employeeId, dateStart, dateEnd, requestId).then(isSubstitute => {
            if (isSubstitute) {
                setRequestToApprove(request);
                setApproveModalOpened(true);
            } else {
                setStatus(request.id, 'Approved');
                setPreviewRequestModal(false);
            }
        });
    }

    function renderRequests() {
        return requests.map(request => (
            <tr key={ request.id }>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                }>
                    {request.requesterAvatar ?
                        <div className='with-avatar'>
                            <img
                                src={request.requesterAvatar} alt="cropped"
                            />
                        </div> :
                        <div className='without-avatar'>
                            <p>{ request.requesterNickname.charAt(0).toUpperCase() }</p>
                        </div>
                    }
                    <Link to={ EMPLOYEE_PROFILE + '/' + request.requesterNickname } target={'_blank'}>
                        { request.requesterNickname }
                    </Link>
                </td>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                }>
                    <div className={'status-block'}>
                        <div className={request.status + (request.status === 'Cancelled' && request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? ' cancelled-border' : '')}>
                            {request.status}
                        </div>
                        {request.status === 'Rejected' && (
                            <p><span>by</span> <Link to={ EMPLOYEE_PROFILE + '/' + request.statusChangeByNickname } target={'_blank'}>{request.statusChangeByNickname}</Link></p>
                        )}
                        {(request.status === 'Approved') && (
                            <p><span>by</span> <Link to={ EMPLOYEE_PROFILE + '/' + request.statusChangeByNickname } target={'_blank'}>{request.statusChangeByNickname}</Link></p>
                        )}
                        {(request.status === 'Cancelled') && (
                            <p><span>by</span> <Link to={ EMPLOYEE_PROFILE + '/' + request.statusChangeByNickname } target={'_blank'}>{request.statusChangeByNickname}</Link></p>
                        )}
                    </div>
                </td>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                }>
                    {request.typeIconName ?
                        <div className={'request-icon'} dangerouslySetInnerHTML={{
                            __html: TimeOffTypeIconsCollection[
                                request.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                ]
                        }} /> : null}
                    { moment(request.startDate).format("DD.MM.YYYY") }
                    <i className={`material-icons trending-flat`}>keyboard_backspace</i>
                    { moment(request.endDate).format("DD.MM.YYYY") }
                </td>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (moment(request.lastDateSalary && request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                }>{ moment(request.createDate).format("DD.MM.YYYY") }</td>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                }>
                    <div className={'status-column'}>
                        {(request.lastDateSalary === null || moment(request.lastDateSalary).toDate() < moment(request.startDate).toDate()) &&
                            <>
                                {(request.status === 'New' && (hasRole || isHierarchyChain(request.requesterId) || approverRelatedPolicies.includes(request.policyId))) && (
                                    <div>
                                        <div onClick={() => confirmApproveRequest(request)} className="Approve request-btn">
                                            Approve <i className="material-icons">done</i>
                                        </div>
                                        <div onClick={() => setStatus(request.id, 'Rejected')} className="Reject request-btn">
                                            Reject
                                        </div>
                                    </div>
                                )}
                                {(request.status === 'Approved' && (hasRole || isHierarchyChain(request.requesterId) || approverRelatedPolicies.includes(request.policyId))) && (
                                    <div onClick={() => setStatus(request.id, 'Cancelled')} className="Cancel request-btn">
                                        Cancel
                                    </div>
                                )}
                                {(request.status === 'New' && props.authEmployee.id === request.requesterId && !hasRole && !approverRelatedPolicies.includes(request.policyId)) && (
                                    <div onClick={() => setStatus(request.id, 'Cancelled')} className="Cancel request-btn">
                                        Cancel
                                    </div>
                                )}
                            </>
                        }
                    </div>
                </td>
                <td className={
                    (request.status === 'New' ? 'new-request' : '')
                    + ' '
                    + (request.lastDateSalary && moment(request.lastDateSalary.substring(0, 10)).toDate() >= moment(request.startDate.substring(0, 10)).toDate() ? 'old-request' : '')
                } onClick={() => previewModalVisibility(request)}>
                    Review request
                </td>
                        </tr>
        ));
    }

    function changeCompanyFilter(companies: SelectValue[]) {
        setSelectedCompanyOptions(companies)
        if (companies.length === 0) {
            companies = companyOptions;
        }

        let teamOptions = employees
            .map(employee => {
                const isCompanyExist = companies.some(company => company.value === employee.companyId);

                if (isCompanyExist) {
                    return {
                        value: employee.teamId,
                        label: employee.teamName
                    };
                } else {
                    return {
                        value: null,
                        label: null
                    };
                }
            })
            .filter((team, index, self) => {
                const foundIndex = self.findIndex(c => c.value === team.value);
                return foundIndex === index;
            })
            .filter(team => team.value !== null && team.label !== null);

        teamOptions.push({label: 'Without Team', value: 'null'});
        setTeamOptions(sortASC(teamOptions as SelectValue[]));
        setSelectedTeamOptions([] as SelectValue[]);

        let employeeOptions = employees.map(employee => {
            const isCompanyExist = companies.some(company => company.value === employee.companyId);
            if (isCompanyExist) {
                return {
                    value: employee.id,
                    label: employee.nickname
                }
            } else {
                return {
                    value: null,
                    label: null
                };
            }
        })
        .filter(employee => employee.value !== null && employee.label !== null);
        setEmployeeOptions(sortASC(employeeOptions as SelectValue[]))
        setSelectedEmployeeOptions([] as SelectValue[])
        selectedEmployeeOptionsRef.current = [] as SelectValue[];
    }

    function changeTeamFilter(teams: SelectValue[]) {
        setSelectedTeamOptions(teams);
        if (teams.length === 0) {
            teams = teamOptions;
        }

        let employeeOptions = employees.map(employee => {
            const isTeamExist = teams.some(team => {
                return team.value === employee.teamId
                    || (team.value === 'null' && employee.teamId === null && selectedCompanyOptions.some(option => option.value === employee.companyId))
                    || (selectedCompanyOptions.length === 0 && team.value === 'null' && employee.teamId === null && companyOptions.some(option => option.value === employee.companyId));
            });
            if (isTeamExist) {
                return {
                    value: employee.id,
                    label: employee.nickname
                }
            } else {
                return {
                    value: null,
                    label: null
                };
            }
        })
        .filter(employee => employee.value !== null && employee.label !== null);

        setEmployeeOptions(sortASC(employeeOptions as SelectValue[]))
        setSelectedEmployeeOptions([] as SelectValue[])
        selectedEmployeeOptionsRef.current = [] as SelectValue[];
    }

    function changeEmployeeFilter(employeeValues: SelectValue[]) {
        setSelectedEmployeeOptions(employeeValues);
        selectedEmployeeOptionsRef.current = employeeValues;
    }

    function sortASC(employees: SelectValue[]) {
        employees.sort((a, b) => {
            const labelA = (a.label || '').toUpperCase();
            const labelB = (b.label || '').toUpperCase();

            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        });
        return employees;
    }

    return (
        <div className={'requests'} ref={containerRef}>
            <div className={'calendar-filter sub-header-filter-block-request sub-header-filter-block align-items-center d-flex'}>
                <div className='filter'>
                    <span onClick={clearFilters} className={'clear-filters'}>Reset</span>
                </div>
                <div className='filter date-range'>
                    <DatePickerPlugin
                        placeholderText={'Filter by period of absence'}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                            dateRangeRef.current = update;
                        }}
                        dateFormat='dd.MM.yyyy'
                        calendarStartDay={1}
                    />
                    {startDate ?
                        <svg width="24" height="24" fill="none" stroke="currentColor" stroke-width="2"
                             onClick={() => setDateRange([])} className="dropdown-search-clear-icon gray date-range-delete">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg> : null}
                    <i className="material-icons calendar-today">calendar_today</i>
                </div>
                <div className={`filter ${!hasRole && companyOptions.length <= 1 ? 'disabled-filter' : ''}`}>
                    <MultiSelect
                        options={ companyOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedCompanyOptions }
                        disabled={ !hasRole && companyOptions.length <= 1 }
                        onChange={changeCompanyFilter}
                        labelledBy={ !hasRole ? myCompanyLabel : 'All companies' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": !hasRole ? myCompanyLabel : 'All companies',
                                "selectSomeItems": "Select companies",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
                <div className='filter'>
                    <MultiSelect
                        options={ teamOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedTeamOptions }
                        disabled={ false }
                        onChange={changeTeamFilter}
                        labelledBy={ 'All teams' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "All teams",
                                "selectSomeItems": "Select teams",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
                <div className='filter'>
                    <MultiSelect
                        options={ employeeOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedEmployeeOptions }
                        disabled={ false }
                        onChange={changeEmployeeFilter}
                        labelledBy={ 'All employees' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "All employees",
                                "selectSomeItems": "Select employees",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
            </div>
            <div className='add-button-block'>
                <Button
                    text="<i className='material-icons add-item'>add</i> Request Time Off"
                    size={ Size.Medium }
                    type={ Type.LightBlue }
                    onClick={ () => setCreateRequestModal(true) }
                />
            </div>
            <table className="requests-table">
                <thead className={heightOverflow && heightOverflow.scrollTop > 0 ? 'thead-shadow-scrolling' : ''}>
                <tr>
                    <th>
                        Requester
                    </th>
                    <th>
                        <Popover className="relative">
                            Status
                            <Popover.Button id='popover-btn' className={'vert-menu-btn'}><i className='material-icons'>arrow_drop_down</i></Popover.Button>
                            <Popover.Panel className="absolute z-10">
                                {statuses.map((status, index) => {
                                    const checkboxId = `checkbox-${status}`;

                                    return (
                                        <div key={status}>
                                            <label htmlFor={checkboxId}>
                                                <input
                                                    type="checkbox"
                                                    id={checkboxId}
                                                    checked={statusesChecked.includes(status)}
                                                    onChange={() => handleStatusChange(status)}
                                                />
                                                {status}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Popover.Panel>
                        </Popover>
                    </th>
                    <th>
                        Period of request
                    </th>
                    <th>
                        Created
                    </th>
                    <th>
                        Actions
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    {renderRequests()}
                </tbody>
            </table>
            {loading &&
                <section className={'loading'}>
                  <span className="svg-spinner">
                      <svg width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="spiner-gradient" x1="50%" y1="70%" x2="80%" y2="60%" spreadMethod="pad">
                      <stop offset="0%" stop-color="#085CFF" stop-opacity="1"/>
                      <stop offset="100%" stop-color="#085CFF" stop-opacity="0"/>
                    </linearGradient>
                    <circle className="path" fill="none" stroke-width="1.3" stroke-linecap="round" stroke="url(#spiner-gradient)" cx="8"
                            cy="8" r="7"></circle>
                  </svg>
                  </span>
                </section>
            }
            <CreateRequest
                opened={ createRequestModal }
                authEmployee={ props.authEmployee }
                onOk={ () => { setCreateRequestModal(false) }}
                onNewRequestCreated={ (newRequest) => {
                    setRequests(prevRequests => [newRequest, ...prevRequests]);
                }}
                onClose={ () => setCreateRequestModal(false) }
                origin={ hasRole ? '' : 'requests'}
            />
            {
                previewRequestModal
                    ?
                    <PreviewRequest
                        opened={ previewRequestModal }
                        onOk={ () => { setPreviewRequestModal(false)}}
                        onClose={ () => { setPreviewRequestModal(false)}}
                        request={ requestToPreview }
                        requests={ requests }
                        onChangeStatus={(newRequests: RequestFromApi[]) => {
                            setRequests(newRequests);
                        }}
                        authEmployee={ props.authEmployee }
                        checkConfirmRequest={ (request: RequestFromApi) => {confirmApproveRequest(request)}}
                    />
                    : null }
            <ConfirmApproveRequest
                opened={approveModalOpened}
                modalTitle='Approve Request'
                confirmModalText={'The substitutor is already filling in for another employee during the chosen period.'}
                okText='Approve'
                onOk={() => {
                    if (requestToApprove) {
                        setStatus(requestToApprove.id, 'Approved');
                    }
                    setRequestToApprove(null);
                    setApproveModalOpened(false);
                    setPreviewRequestModal(false);
                }}
                onClose={() => {
                    setRequestToApprove(null);
                    setApproveModalOpened(false);
                }}
                request={requestToApprove}
            />
        </div>
    );
}

export default Requests;