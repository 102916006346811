import React from 'react';
import BaseModal from './BaseModal';
import { JOB_TITLES_CATEGORIES } from '../../../../../../utils/constants/api';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {VALIDATION_ERROR} from "../../../../../../utils/constants/errors";
import {JobTitlesCategoryFromApi} from "../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi";

type CreateCategory = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    categories: JobTitlesCategoryFromApi[],
};

function CreateLink(props: CreateCategory) {
    function create(name: string, validate: (errors: ValidationError[]) => void): void {
        let formData = new FormData();
        formData.append('category', JSON.stringify({
            name: name
        }));

        fetch(JOB_TITLES_CATEGORIES, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Category'
            name={ null }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
            categories = {props.categories}
        />
    )
}

export default CreateLink;