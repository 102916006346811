import React from 'react';
import BaseModal  from './BaseModal';
import {TimeOffTypeFromApi} from '../../../../../shared/TimeOffType/data/TimeOffTypeFromApi';
import {TIME_OFF_TYPES} from '../../../../../utils/constants/api';
import {ValidationError} from '../../../../../shared/Common/Error/ValidationError';
import {VALIDATION_ERROR} from '../../../../../utils/constants/errors';

type EditTimeOffType = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    timeOffTypeToChange: TimeOffTypeFromApi,
    iconLabel: any,
};

function EditTimeOffType(props: EditTimeOffType) {
    function edit(name: string, iconId: string, validate: (errors: ValidationError[]) => void): void {
        fetch(TIME_OFF_TYPES + '/' + props.timeOffTypeToChange.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                timeOffTypeIconId: +iconId
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    type SelectValue = {
        value: string,
        label: string
    }

    const icon = {
        label: props.iconLabel,
        value: props.timeOffTypeToChange.value.timeOffTypeIconId.toString()
    } as SelectValue;

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit time off type'
            name={ props.timeOffTypeToChange.value.name }
            icon={ icon }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditTimeOffType;