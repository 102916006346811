import React from 'react';

import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';

import {LocationFromApi} from '../Locations';
import {CountryFromApi} from '../../../../../shared/Location/data/CountryFromApi';

import { LOCATIONS } from '../../../../../utils/constants/api';


type DeleteLocation = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    location: LocationFromApi,
    countries: CountryFromApi[]
};

function DeleteLocation(props: DeleteLocation) {
    function deleteLocation(): void {
        fetch(LOCATIONS + '/' + props.location.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then(jsonData => alert(jsonData.error));
                }
            });
    }

    function getCountryNameByCountryCode(countryCode: string): string {
        const countryName = props.countries.find(
            country => country.code === countryCode
        )?.name;

        return countryName ? countryName : '';
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Location'
            cancelText='Cancel'
            deleteText={props.location.value.city ?
                '<span>&laquo;' + props.location.value.city + ', '
                + getCountryNameByCountryCode(props.location.value.countryCode) + '&raquo;</span>' :
                '<span>&laquo;' + getCountryNameByCountryCode(props.location.value.countryCode)
                + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteLocation }
        />
    )
}

export default DeleteLocation;