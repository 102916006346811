import React, { useState } from 'react';
import './NotFound.scss';
import { AuthEmployee } from '../../../shared/Security/data/AuthEmployee';
import Header from "../Layer/Header/Header";
import {Link} from "react-router-dom";
import {MAIN} from "../../../utils/constants/routes";
import Footer from "../Layer/Footer/Footer";

type Employees = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function NotFound(props: Employees) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className={'not-found'}>
                <p><span>404</span></p>
                <h1>Sorry, we can’t find that page.</h1>
                <Link to={ MAIN }>Back to Homepage</Link>
            </div>
            <Footer/>
        </div>
    );
}

export default NotFound;