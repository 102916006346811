import React, {useEffect, useState} from 'react';
import Modal from '../../../Layer/Modal/Modal';
import Select from '../../../../Component/Input/SelectInput/SelectInput';
import {TIME_OFF_TYPE_ICONS} from '../../../../../utils/constants/api';
import {ValidationError} from '../../../../../shared/Common/Error/ValidationError';
import {IconFromApi} from "../../../../../shared/TimeOffType/data/IconFromApi";
import TextField, {Type} from "../../../../Component/Input/TextField/TextField";
import {TimeOffTypeIconsCollection} from "../../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    name: string,
    icon: SelectValue,
    okText: string,
    onCancel: () => any,
    onOk: (name: string, iconId: string, validate: (errors: ValidationError[]) => void) => void
};

type SelectValue = {
    value: string,
    label: any
};

function BaseModal(props: BaseModal) {
    const emptySelectValue = { label: '', value: '' } as SelectValue;

    const [name, setName] = useState<string>(props.name ?? '');
    const [icon, setIcon] = useState<SelectValue>(props.icon ?? emptySelectValue);

    const [textError, setTextError] = useState<string | null>(null);
    const [iconError, setIconError] = useState<string | null>(null);

    const [unusedIconsFromApi, setUnusedIconsFromApi] = useState<IconFromApi[]>([]);

    let [iconOptions, setIconOptions] = useState <SelectValue[]>([]);

    useEffect(() => { getUnusedIconsFromApi(); }, [props.opened]);

    useEffect(() => {
            if (unusedIconsFromApi.length > 0) {
                setIconOptions(unusedIconsFromApi.map(
                    icon => {
                        return {
                            label: <div dangerouslySetInnerHTML={{ __html: TimeOffTypeIconsCollection[
                                icon.correspondingIconName as keyof typeof TimeOffTypeIconsCollection
                                    ] }} />,
                            value: icon.id.toString()} as SelectValue;
                    }
                ));
            }
        }, [unusedIconsFromApi]
    );

    function getUnusedIconsFromApi(): void {
        fetch(TIME_OFF_TYPE_ICONS + '?unused=1', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((icons: IconFromApi[]) => setUnusedIconsFromApi(icons))
    }

    function handleTextChange(newText: string): void {
        setTextError(null);
        setName(newText);
    }

    function handleIconChange(newIcon: SelectValue): void {
        setIconError(null);
        addCurrentIconToOptions();
        setIcon(newIcon);
    }

    function addCurrentIconToOptions(): void {
        if (props.icon && !iconOptions.some(option => option.value === props.icon.value) && props.icon.value !== '') {
            setIconOptions([...iconOptions, props.icon]);
        }
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'timeOffType.name') {
                setTextError(error.message);
            } else if (error.property === 'timeOffType.iconId') {
                setIconError(error.message);
            }
        });
    }

    function resetAll(): void {
        setName('');
        setIcon(emptySelectValue);
        setTextError(null);
        setIconError(null);
    }

    useEffect(() => {
        if (props.modalTitle === 'Create time off type') {
            resetAll()
        }
    }, [props.opened]);

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                    resetAll();
                    props.onCancel();
            }}
            onOk={ () => props.onOk( name, icon.value, validate) }
        >
            <TextField
                label='Name'
                placeholder='Time off type name'
                required={ true }
                value={ name }
                type={ Type.Textarea }
                horizontal={ true }
                error={ textError }
                onChange={ newName => handleTextChange(newName) }
                maxLength={50}
            />
            <Select
                label='Icon'
                isSearchable={false}
                required={ true }
                isMulti={ false }
                value={ icon }
                options={ iconOptions }
                error={ iconError }
                onChange={ newIcon => handleIconChange(newIcon)}
            />
        </Modal>
    );
}

export default BaseModal;
