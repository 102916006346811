import React, { useEffect, useState } from 'react';
import {EmployeeFromApi} from "../../../../../../shared/Employee/data/EmployeeFromApi";
import {EMPLOYEES} from "../../../../../../utils/constants/api";
import {Link} from "react-router-dom";
import {EMPLOYEE_PROFILE} from "../../../../../../utils/constants/routes";


type ProfileHierarchyChain = {
    employee: EmployeeFromApi,
}

function ProfileHierarchyChain(props: ProfileHierarchyChain) {
    const [managerHierarchy, setManagerHierarchy] = useState<JSX.Element[]>([]);

    useEffect(() => {
        getManagerHierarchy();
    }, [props.employee]);

    function getManagerHierarchy() {
        if (!props.employee) return;
        fetch(EMPLOYEES + '/' + props.employee.id + '/managers', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then((data) => {
                if(Array.isArray(data)) {
                    const managerHierarchy = data.map((nickname, index) => (
                        <React.Fragment key={`manager-${index}`}>
                            <div className={'hierarchy-chain-employee'}>
                            {index > 0 && <i className='material-icons default-cursor'>arrow_forward</i>}
                                <Link to={EMPLOYEE_PROFILE + '/' + nickname} target={'_blank'}>
                                    <span className="manager-nickname">
                                        {nickname}
                                    </span>
                                </Link>
                            </div>
                        </React.Fragment>
                    ));
                    managerHierarchy.push(
                        <div className={'hierarchy-chain-employee'}>
                            <i className='material-icons default-cursor'>arrow_forward</i>
                            <span className="manager-nickname default-cursor">{props.employee.nickname}</span>
                        </div>
                    );
                    setManagerHierarchy(managerHierarchy);
                }
            });
    }

    return (
        <>
            {managerHierarchy.length > 1 && (
                <div className={'hierarchy-chain'}>
                    <h4>Hierarchy Chain:</h4>
                    <div className={'manager-hierarchy'}>{managerHierarchy}</div>
                </div>
            )}
        </>
    );
}

export default ProfileHierarchyChain;