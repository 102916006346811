import React, { useState, useEffect } from 'react';
import Header from '../Layer/Header/Header';
import Navbar from '../Layer/Navbar/Navbar';
import GreetingsWidget from './Widgets/Greetings/GreetingsWidget';
import LinksWidget from './Widgets/Links/LinksWidget';
import CelebrationsWidget from "./Widgets/Celebrations/CelebrationsWidget";
import CalendarWidget from './Widgets/Calendar/CalendarWidget';
import AbsentWidget from "./Widgets/Absent/AbsentWidget";
import ClockWidget from "./Widgets/Clock/ClockWidget";
import Head from "../../Component/Head/Head";

import { AuthEmployee } from '../../../shared/Security/data/AuthEmployee';
import Footer from "../Layer/Footer/Footer";
import EmployeesStatusWidget from './Widgets/EmployeeStatus/EmployeesStatusWidget';
import CreateRequest from "../Calendar/Requests/Modal/CreateRequest";
import {EMPLOYEES} from "../../../utils/constants/api";
import {EmployeeFromApi} from "../../../shared/Employee/data/EmployeeFromApi";
import MemeGenWidget from "./Widgets/MemeGen/MemeGenWidget";

type Main = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Main(props: Main) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);
    const [createRequestModal, setCreateRequestModal] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmployeeFromApi | null>(null);

    async function getEmployee() {
        await fetch(EMPLOYEES + '/' + props.authEmployee.nickname, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((employee: EmployeeFromApi) => setEmployee(employee));
                }
            });
    }

    useEffect(() => {
        const rootElement = document.getElementById('root')
        getEmployee();
        if (rootElement) {
            rootElement.scrollTo(0, 0);
        }
    }, []);

    const handleRequestModal = (isOpen: boolean) => {
        setCreateRequestModal(isOpen)
    };

    return (
        <>
            <Head
                title="Netpeak One"
                metaNameTitle = "Netpeak One — Home Page"
                description="Netpeak One — HRM system for Netpeak Group"
            />
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex main-page'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content block-max-size'>
                    <div className='d-flex flex-block-main'>
                        <div className='main-left-block'>
                            <GreetingsWidget />
                            <CelebrationsWidget />
                            <AbsentWidget setLoadingBarProgress={setLoadingBarProgress} requestModal={handleRequestModal} />
                            <EmployeesStatusWidget />
                        </div>
                        <div className='main-right-block'>
                            <ClockWidget authEmployee={props.authEmployee} />
                            <CalendarWidget authEmployeeId={props.authEmployee.id} />
                            <LinksWidget />
                            <MemeGenWidget />
                        </div>
                    </div>
                        <CreateRequest
                            opened={ createRequestModal }
                            authEmployee={ props.authEmployee }
                            onOk={() => {
                                setCreateRequestModal(false);
                            }}
                            onClose={ () => setCreateRequestModal(false) }
                        />
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Main;