import React, { useState, useEffect } from 'react';
import Modal from '../../../Layer/Modal/Modal';
import TextField, { Type } from '../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../Component/Input/SelectInput/SelectInput';
import Radio from '../../../../Component/Input/Radio/Radio';
import Checkbox from '../../../../Component/Input/Checkbox/Checkbox';
import DatePicker from '../../../../Component/Input/DatePicker/DatePicker';
import moment from "moment";
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { LocationFromApi } from '../../Locations/Locations';
import { SelectValue } from '../../../../../shared/Common/data/SelectValue';
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";

type BaseModal = {
    opened: boolean,
    modalTitle: string,
    text: string | null,
    locations: SelectValue[],
    selectedLocations: LocationFromApi[],
    date: Date | null,
    repeatAnnually: boolean,
    visible: boolean,
    type: string,
    okText: string,
    onCancel: () => any,

    onOk: (
        text: string,
        locations: string[],
        date: Date | null,
        type: string,
        repeatAnnually: boolean,
        visible: boolean,
        validate: (errors: ValidationError[]) => void) => void
};

function BaseModal(props: BaseModal) {
    const locationOptions = props.selectedLocations.map(locationValue => {
        return {
            label: locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone),
            value: locationValue.id
        } as SelectValue
    });

    const [type, setType] = useState<string>(props.type ?? 'daily');
    const [specialTypeChose, setHolidayTypeChosen] = useState<boolean>(type === 'special');

    const [text, setText] = useState<string>(props.text ?? '');
    const [textError, setTextError] = useState<string | null>(null);

    const [date, setDate] = useState<Date | null>(props.date ? moment(props.date).toDate() : null);
    const [dateError, setDateError] = useState<string | null>(null);

    const [locations, setLocations] = useState<SelectValue[]>(props.locations ?? locationOptions);
    const [locationsError, setLocationsError] = useState<string | null>(null);

    const [repeatAnnually, setRepeatAnnually] = useState<boolean>(props.repeatAnnually ?? false);

    const [visible, setVisible] = useState<boolean>(props.visible ?? true);

    function resetAll(): void {
        setText('');
        setType('daily');
        setHolidayTypeChosen(false);
        setDate(null);
        setDateError(null);
        setLocations(locationOptions);
        setLocationsError(null);
        setRepeatAnnually(false);
        setVisible(true);
        setTextError(null);
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'greeting.text') {
                setTextError(error.message);
            } else if (error.property === 'greeting.locations' || error.property === 'greeting.dateAndLocation') {
                setLocationsError(error.message);
            } else if (error.property === 'greeting.date') {
                setDateError(error.message);
            }
        });
    }

    function changeType(newType: string) {
        setHolidayTypeChosen(newType === 'special');
        setType(newType);
    }

    useEffect(() => {
        if (props.modalTitle === 'Create Greeting') {
            resetAll()
        }
        if (props.locations.length === 0) {
            setLocations(locationOptions);
        }
    }, [props.opened]);

    return (
        <Modal
            opened={ props.opened }
            modalTitle={ props.modalTitle }
            cancelText='Cancel'
            okText={ props.okText }
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={ () => props.onOk(text, locations.map(location => location.value), date, type, repeatAnnually, visible, validate) }
        >
            <Radio
                label='Type of greeting'
                required={ true }
                name={ 'type' }
                value={ type }
                onChange={ newType => {
                    changeType(newType);
                    setDate(null);
                } }
                radios={[
                    { label: 'Daily greeting', value: 'daily' },
                    { label: 'Special greeting', value: 'special' }
                ]}
            />
            <TextField
                label='Text of greeting'
                placeholder='Text...'
                required={ true }
                value={ text }
                type={ Type.Textarea }
                horizontal={ true }
                error={ textError }
                onChange={ newText => {
                    setText(newText);
                    setTextError(null);
                    setLocationsError(null);
                }}
                maxLength={100}
            />
            <SelectInput
                label='Location'
                required={ true }
                isMulti={ true }
                value={ locations }
                options={ locationOptions }
                error={ locationsError }
                onChange={ (newLocations: SelectValue[]) => {
                    setLocations(newLocations);
                    setLocationsError(null);
                }}
                isSearchable={ false }
            />
            <div style={{ display: specialTypeChose ? 'block' : 'none' }}>
                <DatePicker
                    disabled={ false }
                    label='Date'
                    required={ true }
                    horizontal={ true }
                    date={ date }
                    error={ dateError }
                    onChange={ newDate => {
                        setDate(newDate);
                        setDateError(null);
                        setLocationsError(null);
                    }}
                    maxDate={ null }
                    minDate={ null }
                />
                <Checkbox
                    disabled={ false }
                    label={ null }
                    required={ null }
                    checked={ repeatAnnually }
                    checkBoxLabel='Repeat annually'
                    onChange={ newRepeatAnnually => setRepeatAnnually(newRepeatAnnually) }
                    switch={ false }
                />
            </div>
            <Checkbox
                disabled={ false }
                label={ 'Visibility' }
                required={ null }
                checked={ visible }
                onChange={ newVisible => setVisible(newVisible) }
                checkBoxLabel='Shown'
                switch={ true }
            />
        </Modal>
    );
}

export default BaseModal;