import React from 'react';
import {TIME_OFF_TYPES} from '../../../../../utils/constants/api';
import {TimeOffTypeFromApi} from '../../../../../shared/TimeOffType/data/TimeOffTypeFromApi';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";

type DeleteTimeOffType = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    timeOffType: TimeOffTypeFromApi
};

function DeleteTimeOffType(props: DeleteTimeOffType) {
    function deleteTimeOffType(): void {
        fetch(TIME_OFF_TYPES + '/' + props.timeOffType.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then((jsonData: any) => {
                        if (jsonData.error) {
                            alert(jsonData.error);
                        } else if (Array.isArray(jsonData) && jsonData.length > 0) {
                            const errors: ValidationError[] = jsonData;
                            validate(errors);
                        }
                    });
                }
            });
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            alert(error.message)
        });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Time Off Type'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.timeOffType.value.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteTimeOffType }
        />
    )
}

export default DeleteTimeOffType;