import React from 'react';
import {Link} from "react-router-dom";

import {MAIN} from "../../../utils/constants/routes";

import './Breadcrumbs.scss';


type BreadcrumbProps = {
    home: string;
    firstElement: string;
}

function Breadcrumbs(props: BreadcrumbProps) {
    return (
        <div className={'breadcrumbs-block'}>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={ MAIN }>
                <div className={'home'}>
                    <div className={'material-symbols-outlined'}>home</div>
                    <p>{props.home}</p>
                </div>
            </Link>
            <div className={'material-symbols-outlined'}>
                keyboard_arrow_right
            </div>
            <div className={'breadcrumb-item'}>
                <p>{props.firstElement}</p>
            </div>
        </div>
    );
}

export default Breadcrumbs;
