import React, {useEffect, useMemo, useRef, useState} from 'react';
import './Calendar.scss';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";

import {renderToString} from "react-dom/server";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {eachDayOfInterval, addDays} from 'date-fns';

import Button, {Size, Type} from '../../../Component/Button/Button';
import CreateRequest from "../Requests/Modal/CreateRequest";

import {EMPLOYEE_PROFILE} from "../../../../utils/constants/routes";
import {ROLE_ADMIN, ROLE_HR} from "../../../../shared/Security/constants/AccessLevels";
import {CALENDAR, EMPLOYEES} from '../../../../utils/constants/api';
import {TimeOffTypeIconsCollection} from "../../../../shared/TimeOffType/constants/TimeOffTypeIconsCollection";
import {AuthEmployee} from "../../../../shared/Security/data/AuthEmployee";
import {EmployeeFromApi} from "../../../../shared/Employee/data/EmployeeFromApi";
import {SelectValue} from "../../../../shared/Common/data/SelectValue";
import './Calendar.scss';
import {MultiSelect} from "react-multi-select-component";

type Calendar = {
    authEmployee: AuthEmployee,
};

function Calendar(props: Calendar) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const [createRequestModal, setCreateRequestModal] = useState<boolean>(false);
    const [selectedEmployee, setSelectedEmployee] = useState<string | null>(params.get("selected-employee"));
    const [startDate, setStartDate] = useState<string | null>(params.get("start-date"));
    const [calendarData, setCalendarData] = useState<any[]>([]);
    const dateForCalendar = startDate ? new Date(startDate) : new Date();
    if (!startDate) {
        dateForCalendar.setDate(1);
    }
    const [selectedDate, setSelectedDate] = useState<Date>(dateForCalendar);
    const monthDates = eachDayOfInterval({ start: selectedDate, end: addDays(selectedDate, 31) });

    const [companyOptions, setCompanyOptions] = useState<SelectValue[]>([]);
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<SelectValue[]>([]);

    const [employeeOptions, setEmployeeOptions] = useState<SelectValue[]>([]);
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<SelectValue[]>([]);

    const [teamOptions, setTeamOptions] = useState<SelectValue[]>([]);
    const [selectedTeamOptions, setSelectedTeamOptions] = useState<SelectValue[]>([]);

    const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);

    const [loading, setLoading] = useState(false);
    const standartLimit = 15;
    const [limit, setLimit] = useState(standartLimit);
    const [offset, setOffset] = useState(0);
    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));
    const [hasMore, setHasMore] = useState(true);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    const selectedEmployeeOptionsRef = useRef(selectedEmployeeOptions);
    const calendarDataRef = useRef(calendarData);
    const hasMoreRef = useRef(hasMore);
    const prevScrollTopRef = useRef(prevScrollTop);
    const loadingRef = useRef(loading);
    const offsetRef = useRef(offset);
    const limitRef = useRef(limit);
    const selectedDateRef = useRef(selectedDate);
    let isFirstLoad = true;

    const heightOverflow = document.querySelector('.calendar');

    useEffect(() => {
        offsetRef.current = 0;
        setOffset(0);
        setCalendarData([]);
        setPrevScrollTop(0);
        setHasMore(true);
        calendarDataRef.current = [];
        selectedDateRef.current = selectedDate;
        fetchCalendar();
    }, [employeeOptions, selectedEmployeeOptionsRef.current, selectedDate]);

    useEffect(() => {
        emptyEmployeesFilter();
    }, [employees]);

    useEffect(() => {
        prevScrollTopRef.current = prevScrollTop;
    }, [prevScrollTop]);

    useEffect(() => {
        selectedDateRef.current = selectedDate;
    }, [selectedDate]);

    useEffect(() => {
        hasMoreRef.current = hasMore;
    }, [hasMore]);

    useEffect(() => {
        offsetRef.current = offset;
    }, [offset]);

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {
        limitRef.current = limit;
    }, [limit]);

    useEffect(() => {
        getEmployeesWithCompanyAndTeam();
        const container = document.querySelector('.sub-content-main');
        if (container) {
            container.addEventListener('wheel', handleScroll);
            container.addEventListener('touchmove', handleScroll);
            return () => {
                container.removeEventListener('wheel', handleScroll);
                container.removeEventListener('touchmove', handleScroll);
            };
        }
    }, []);

    function handleScroll(event:any)  {
        const deltaY = event.deltaY;
        if (hasMoreRef.current && !loadingRef.current && deltaY > 0 && !createRequestModal) {
            const container = containerRef.current;
            if (container) {
                if (containerRef.current.offsetHeight >= prevScrollTopRef.current) {
                    fetchCalendar();
                }
                setPrevScrollTop(containerRef.current.offsetHeight);
            }
        }
    }

    const handleDateChange = (date: Date) => {
        setSelectedDate(moment(date).toDate());
    };

    const previousMonth = () => {
        const newDate = moment(selectedDate).subtract(1, 'month');
        offsetRef.current = 0;
        setSelectedDate(newDate.toDate());
    };

    const nextMonth = () => {
        const newDate = moment(selectedDate).add(1, 'month');
        offsetRef.current = 0;
        setSelectedDate(newDate.toDate());
    };

    const CustomInput: React.FC<any> = ({ value, onClick }) => (
        <input
            value={value}
            onClick={onClick}
            onChange={() => {}}
            onKeyDown={(e) => e.preventDefault()}
            style={{ cursor: 'pointer' }}
            className={'date-input'}
            readOnly
        />
    );

    function fetchCalendar() {
        if (selectedEmployeeOptionsRef.current.length === 0) {
            selectedEmployeeOptionsRef.current = employeeOptions;
        }
        const employeesForCheck = JSON.stringify(selectedEmployeeOptionsRef.current);
        const startDateString = selectedDateRef.current ? selectedDateRef.current.getFullYear() + '-' + (selectedDateRef.current.getMonth() + 1).toString().padStart(2, '0') + "-" + selectedDateRef.current.getDate().toString().padStart(2, '0') : '';
        const endDate =  moment(selectedDateRef.current).add(31, 'day').toDate();
        const endDateString = endDate ? endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + "-" + endDate.getDate().toString().padStart(2, '0') : '';
        selectedEmployeeOptionsRef.current = sortASC(selectedEmployeeOptionsRef.current as SelectValue[]);
        let needEmployees = selectedEmployeeOptionsRef.current.map(option => option.value).slice(offsetRef.current, offsetRef.current + limitRef.current);
        if (startDateString && endDateString && selectedEmployeeOptionsRef.current.length > 0) {
            setLoading(true);
            loadingRef.current = true;

            return fetch(CALENDAR + '?employees=' + JSON.stringify(needEmployees) +
                '&start-date=' + startDateString + '&end-date=' + endDateString, {
                method: 'GET',
                headers: {'Accept': 'application/json', 'Content-type': 'application/json'},
            })
                .then(response => response.json())
                .then((responseCalendarData) => {
                        if (responseCalendarData) {
                            const updatedCalendarData = {
                                ...calendarDataRef.current,
                                ...responseCalendarData
                            };
                            if (employeesForCheck !== JSON.stringify(selectedEmployeeOptionsRef.current)) {
                                setLoading(false);
                                loadingRef.current = false;
                                fetchCalendar();
                                return;
                            }
                            const sortedKeys = Object.keys(updatedCalendarData).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
                            const sortedObject = sortedKeys.reduce((acc:any, key) => {
                                acc[key] = updatedCalendarData[key];
                                return acc;
                            }, {});
                            setCalendarData(sortedObject);
                            calendarDataRef.current = sortedObject;
                            if (limitRef.current !== standartLimit) {
                                limitRef.current = standartLimit;
                                setLimit(standartLimit);
                            }
                            setOffset(offsetRef.current + ((offsetRef.current === Object.keys(sortedObject).length) ? 0 : limitRef.current));
                        } else {
                            setHasMore(false);
                        }
                        setLoading(false);
                        loadingRef.current = false;
                    }
                );
        }
    }


    function emptyEmployeesFilter() {
        let companyOptions = employees
            .map(employee => {
                return {
                    value: employee.companyId,
                    label: employee.companyName
                };
            })
            .filter((company, index, self) => {
                const foundIndex = self.findIndex(c => c.value === company.value);
                return foundIndex === index;
            })
            .filter(company => company.value !== null && company.label !== null);

        setCompanyOptions(sortASC(companyOptions as SelectValue[]));
        if (isFirstLoad) {
            const selectedCompany = companyOptions.find(option => option.value === props.authEmployee.companyId);
            if (selectedCompany) {
                changeCompanyFilter([selectedCompany] as SelectValue[]);
            }
            isFirstLoad = false;
            return
        }


        let teamOptions = employees
            .map(employee => {
                return {
                    value: employee.teamId,
                    label: employee.teamName
                };
            })
            .filter((team, index, self) => {
                const foundIndex = self.findIndex(c => c.value === team.value);
                return foundIndex === index;
            })
            .filter(team => team.value !== null && team.label !== null);

        teamOptions.push({label: 'Without Team', value: 'null'});
        setTeamOptions(sortASC(teamOptions as SelectValue[]));

        let employeeOptions = employees.map(employee => {
            return {
                value: employee.id,
                label: employee.nickname
            }
        });

        setEmployeeOptions(sortASC(employeeOptions))
    }

    function sortASC(employees: SelectValue[]) {
        employees.sort((a, b) => {
            const labelA = (a.label || '').toUpperCase();
            const labelB = (b.label || '').toUpperCase();

            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        });
        return employees;
    }
    function getEmployeesWithCompanyAndTeam() {
        fetch(EMPLOYEES + '/type/active', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        })
            .then(response => response.json())
            .then(async (employees: EmployeeFromApi[]) => {
                setEmployees(employees);
            });
    }

    function changeCompanyFilter(companies: SelectValue[]) {
        setSelectedCompanyOptions(companies)
        if (companies.length === 0) {
            companies = companyOptions;
        }

        let teamOptions = employees
            .map(employee => {
                const isCompanyExist = companies.some(company => company.value === employee.companyId);

                if (isCompanyExist) {
                    return {
                        value: employee.teamId,
                        label: employee.teamName
                    };
                } else {
                    return {
                        value: null,
                        label: null
                    };
                }
            })
            .filter((team, index, self) => {
                const foundIndex = self.findIndex(c => c.value === team.value);
                return foundIndex === index;
            })
            .filter(team => team.value !== null && team.label !== null);

        teamOptions.push({label: 'Without Team', value: 'null'});
        setTeamOptions(sortASC(teamOptions as SelectValue[]));
        setSelectedTeamOptions([] as SelectValue[]);

        let employeeOptions = employees.map(employee => {
            const isCompanyExist = companies.some(company => company.value === employee.companyId);
            if (isCompanyExist) {
                return {
                    value: employee.id,
                    label: employee.nickname
                }
            } else {
                return {
                    value: null,
                    label: null
                };
            }
        })
        .filter(employee => employee.value !== null && employee.label !== null);
        let selectedEmployeesForFilter = employeeOptions;
        if (selectedEmployee) {
            params.delete("start-date");
            params.delete("selected-employee");
            navigate(location.pathname, {replace: true});
            // @ts-ignore
            selectedEmployeesForFilter = [selectedEmployeesForFilter.find(employee => employee.value === selectedEmployee)] as SelectValue;
            setSelectedEmployee(null)
            setStartDate(null)
        }
        setEmployeeOptions(sortASC(employeeOptions as SelectValue[]))
        setSelectedEmployeeOptions([] as SelectValue[])
        selectedEmployeeOptionsRef.current = selectedEmployeesForFilter as SelectValue[];
    }

    function changeTeamFilter(teams: SelectValue[]) {
        setSelectedTeamOptions(teams);
        if (teams.length === 0) {
            teams = teamOptions;
        }

        let employeeOptions = employees.map(employee => {
            const isTeamExist = teams.some(team => {
                return team.value === employee.teamId
                    || (team.value === 'null' && employee.teamId === null && selectedCompanyOptions.some(option => option.value === employee.companyId))
                    || (selectedCompanyOptions.length === 0 && team.value === 'null' && employee.teamId === null && companyOptions.some(option => option.value === employee.companyId));
            });
            if (isTeamExist) {
                return {
                    value: employee.id,
                    label: employee.nickname
                }
            } else {
                return {
                    value: null,
                    label: null
                };
            }
        })
        .filter(employee => employee.value !== null && employee.label !== null);

        setEmployeeOptions(sortASC(employeeOptions as SelectValue[]));
        setSelectedEmployeeOptions([] as SelectValue[]);
        selectedEmployeeOptionsRef.current = [] as SelectValue[];
    }

    function changeEmployeeFilter(employeeValues: SelectValue[]) {
        setSelectedEmployeeOptions(employeeValues);
        selectedEmployeeOptionsRef.current = employeeValues;
    }

    function clearFilters () {
        setSelectedDate(dateForCalendar);
        isFirstLoad = true;
        emptyEmployeesFilter();

    }

    function isDateAWeekend(dateString: string) {
        const date = new Date(dateString);
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function renderCalendar() {
        if (calendarData) {
            return Object.entries(calendarData).map(([employeeKey, employeeData], mainIndex) => {
                const employeeDataArray = Object.entries(employeeData as object);
                return (
                <tr key={employeeKey}>
                    {employeeDataArray.map(([key, value], index) => {
                        let tooltipClass = Math.random().toString(36).substr(2, 5);
                        if (index === 0) {
                            return (
                                <td key={index}>
                                    {value.url ? (
                                        <div className='with-avatar'>
                                            <img src={value.url} alt="cropped"/>
                                        </div>
                                    ) : (
                                        <div className='without-avatar'>
                                            <p>{value.nickname.charAt(0).toUpperCase()}</p>
                                        </div>
                                    )}
                                    <Link to={ EMPLOYEE_PROFILE + '/' + value.nickname } target={'_blank'}>
                                        { value.nickname }
                                    </Link>
                                </td>
                            );
                        } else {
                            return (
                                <td
                                    id={key + '-' + tooltipClass}
                                    key={index}
                                    className={`
                                    ${value.holiday === 'weekend' ? 'weekend ' : ''}
                                    ${value.length === 0 ? 'simple-day ' : ''}
                                    ${new Date(key).toDateString() === new Date().toDateString() ? 'today-border ' : ''}
                                    ${Object.keys(calendarData).length - 1 === mainIndex && new Date(key).toDateString() === new Date().toDateString() ? 'last-today-border ' : ''}
                                    ${0 === mainIndex && new Date(key).toDateString() === new Date().toDateString() ? 'first-today-border ' : ''}
                                  `}
                                >
                                    <div className={'event-style'} style={{
                                        background: value.status === 'New' ? '#f9f9f9' : value.typeColor,
                                        margin: value.firstColumn && !value.lastColumn ? '0 0 0 4px' :
                                            value.firstColumn && value.lastColumn ? '0 4px' :
                                                !value.firstColumn && value.lastColumn ? '0 4px 0 0' : '',
                                        borderRadius: value.firstColumn && !value.lastColumn ? '4px 0 0 4px' :
                                            value.firstColumn && value.lastColumn ? '4px' :
                                                !value.firstColumn && value.lastColumn ? '0 4px 4px 0' : ''
                                    }}>
                                    {
                                        value.firstColumn && value.typeIconName ?
                                            <div className={'event-icon'} dangerouslySetInnerHTML={{
                                                __html: TimeOffTypeIconsCollection[
                                                    value.status === 'New'
                                                        ? (value.typeIconName + 'NoConfirm') as keyof typeof TimeOffTypeIconsCollection
                                                        : value.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                                    ]
                                            }} /> : null
                                    }
                                    { value.holiday !== 'weekend' && (value.holiday || value.workingHours) ?
                                        <div>
                                            <ReactTooltip
                                                anchorId={key + '-' + tooltipClass}
                                                place="bottom"
                                                noArrow={false}
                                                html={`<div>
                                                                ${value.typeIconName === 'holiday'
                                                    ?  renderToString(<span className={'popup-main-holiday-text'}>{value.name}</span>)
                                                    : '' }
                                                                ${value.url && value.typeIconName !== 'holiday' ?
                                                    renderToString(
                                                        <div className={'employee-block'}>
                                                            <div className='with-avatar'>
                                                                <img src={value.url} alt="cropped"/>
                                                            </div>
                                                            <span className={'popup-main-text'}>{value.nickname}</span>
                                                        </div>
                                                    ) :
                                                    value.typeIconName !== 'holiday' && value.nickname ?
                                                        renderToString(
                                                            <div className={'employee-block'}>
                                                                <div className='without-avatar'>
                                                                    <p>{value.nickname.charAt(0).toUpperCase()}</p>
                                                                </div>
                                                                <span className={'popup-main-text'}>{value.nickname}</span>
                                                            </div>
                                                        ) : ''
                                                }
                                                        ${value.typeIconName && value.typeIconName !== 'holiday' ? renderToString(<span className={'company'}>{value.company}</span>) : ''}
                                                        ${value.holiday === 'stateHoliday' ? renderToString(<span className={'holiday-text'}>State Holiday</span>) : ''}
                                                        ${value.holiday === 'stateHoliday' && value.paidTimeOff && !isDateAWeekend(key) && !value.additionalPaidTimeOff ? renderToString(<span className={'additional-paid-time-off'}>Paid Time Off</span>) : ''}
                                                        ${value.holiday === 'corporateEvent' && value.paidTimeOff && !isDateAWeekend(key) ? renderToString(<span className={'additional-paid-time-off'}>Paid Time Off</span>) : ''}
                                                   ${value.typeIconName === 'holiday' && value.additionalPaidTimeOff
                                                    ? renderToString(<span className={'additional-paid-time-off'}>Additional Paid Time Off</span>)
                                                    : ''}
                                                    ${value.typeIconName && value.typeIconName !== 'holiday'?
                                                    renderToString(
                                                        <div className={'type-block'} style={{
                                                            background: value.status === 'New' ? '#f9f9f9' : value.typeColor
                                                        }}>
                                                            <div dangerouslySetInnerHTML={{
                                                                __html: TimeOffTypeIconsCollection[
                                                                    value.status === 'New'
                                                                        ? (value.typeIconName + 'NoConfirm') as keyof typeof TimeOffTypeIconsCollection
                                                                        : value.typeIconName as keyof typeof TimeOffTypeIconsCollection
                                                                    ]

                                                            }} />
                                                            <span className={'event-name'}>{value.name}</span>
                                                        </div>
                                                    ) : ''
                                                }

                                                                  ${!value.workingHours ?
                                                    renderToString(<div className={"tool-date"}>
                                                        <i className={"material-icons calendar-today"}>calendar_today</i>
                                                        <div className={"date-inner-container"}>{value.startDate} {value.endDate ? ' — ' +  value.endDate : ''}</div>
                                                    </div>) : ''}
                                                        ${value.status === 'New' ? '<span class="not-confirmed">Request is not confirmed</span>' : ''}
                                                        ${(value.status === 'Approved' || value.status === 'New') && value.substitute ? '<span class="replacing">Replacing: ' + value.substitute + '</span>' : ''}
                                                        ${value.workingHours ? renderToString(<div className={"schedule-block"}><i className={"material-icons"}>schedule</i>Working Hours:<span>{value.workingHours}</span></div>) : ''}                                                                
                                                   </div>`}
                                            />
                                        </div> : null }
                                    </div>
                                </td>

                            );
                        }
                    })}
                </tr>
                );
            });
        }
        return null;
    }

    return (
        <div className={'calendar'} ref={containerRef}>
            <div className={'sub-header-filter-block sub-header-filter-block-calendar align-items-center d-flex'}>
                <div className='filter'>
                    <span onClick={clearFilters} className={'clear-filters'}>Reset</span>
                </div>
                <div className='filter'>
                    <MultiSelect
                        options={ companyOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedCompanyOptions }
                        disabled={ false }
                        onChange={changeCompanyFilter}
                        labelledBy={ 'All companies' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "All companies",
                                "selectSomeItems": "Select companies",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
                <div className='filter'>
                    <MultiSelect
                        options={ teamOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedTeamOptions }
                        disabled={ false }
                        onChange={changeTeamFilter}
                        labelledBy={ 'All teams' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "All teams",
                                "selectSomeItems": "Select teams",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
                <div className='filter'>
                    <MultiSelect
                        options={ employeeOptions }
                        hasSelectAll={ true }
                        shouldToggleOnHover={ false }
                        disableSearch={ false }
                        value={ selectedEmployeeOptions }
                        disabled={ false }
                        onChange={changeEmployeeFilter}
                        labelledBy={ 'All employees' }
                        className={ 'multi-select-with-checkboxes'}
                        overrideStrings={
                            {
                                "allItemsAreSelected": "All employees",
                                "selectSomeItems": "Select employees",
                                "selectAll": "All",
                                "selectAllFiltered": "All filtered"
                            }
                        }
                    />
                </div>
            </div>
            <div className={'calendar-date-filter'}>
                <i className='material-icons' onClick={previousMonth}>keyboard_arrow_left</i>
                <i className='material-icons' onClick={nextMonth}>keyboard_arrow_right</i>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MMMM yyyy"
                    customInput={<CustomInput />}
                />
            </div>
            <div className='add-button-block'>
                <Button
                    text="<i className='material-icons add-item'>add</i> Request Time Off"
                    size={ Size.Medium }
                    type={ Type.LightBlue }
                    onClick={ () => setCreateRequestModal(true) }
                />
            </div>
            <table className="calendar-data-table">
                <thead className={heightOverflow && heightOverflow.scrollTop > 0 ? 'thead-shadow-scrolling' : ''}>
                <tr>
                    <th key={'nick'}><div className={'first-column'}>Nick</div></th>
                    {monthDates.map((elem, index) => (
                        <th key={index}>
                            <div className={new Date(elem).toDateString() === new Date().toDateString() ? 'today' : ''}>
                                <span className={'day'}>{new Date(elem).getDate()}</span>
                                <br />
                                <span className={'weekday'}>{new Date(elem).toLocaleString('en-US', { weekday: 'short' }).slice(0, 2)}</span>
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    {renderCalendar()}
                </tbody>
            </table>
            {loading &&
                <section className={'loading'}>
                  <span className="svg-spinner">
                      <svg width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="spiner-gradient" x1="50%" y1="70%" x2="80%" y2="60%" spreadMethod="pad">
                      <stop offset="0%" stop-color="#085CFF" stop-opacity="1"/>
                      <stop offset="100%" stop-color="#085CFF" stop-opacity="0"/>
                    </linearGradient>
                    <circle className="path" fill="none" stroke-width="1.3" stroke-linecap="round" stroke="url(#spiner-gradient)" cx="8"
                            cy="8" r="7"></circle>
                  </svg>
                  </span>
                </section>
            }
            <CreateRequest
                opened={ createRequestModal }
                onOk={ () => {
                    setCreateRequestModal(false)
                    limitRef.current = offsetRef.current;
                    setLimit(offsetRef.current);
                    offsetRef.current = 0;
                    setOffset(0);
                    calendarDataRef.current = []
                    setCalendarData([]);
                    fetchCalendar();
                }}
                onClose={ () => setCreateRequestModal(false) }
                authEmployee={ props.authEmployee }
                origin={hasRole ? '' : 'calendar'}
            />
        </div>
    );
}

export default Calendar;