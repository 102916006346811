import BaseModal from './BaseModal';
import {TIME_OFF_TYPES} from '../../../../../utils/constants/api';
import {ValidationError} from '../../../../../shared/Common/Error/ValidationError';
import {VALIDATION_ERROR} from '../../../../../utils/constants/errors';

type CreateTimeOffType = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any
};

type SelectValue = {
    value: string,
    label: string
}
const emptySelectValue = { label: '', value: '' } as SelectValue;

const icon = emptySelectValue;

function CreateTimeOffType(props: CreateTimeOffType) {
    function create(name: string, iconId: string, validate: (errors: ValidationError[]) => void): void {
        if(!iconId) {
            validate([{message: 'Icon is required', property: 'timeOffType.iconId'}]);
        }
        let formData = new FormData();
        formData.append('timeOffType', JSON.stringify({
            name: name,
            timeOffTypeIconId: +iconId
        }));

        fetch(TIME_OFF_TYPES, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create time off type'
            name={ '' }
            icon={ icon }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateTimeOffType;