import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './application/App';
import reportWebVitals from './reportWebVitals';
import './../node_modules/material-design-icons/iconfont/material-icons.css';
import './../node_modules/material-symbols/index.css';
import MomentTimezone from 'moment-timezone';
import { TIMEZONE } from './utils/constants/projectSettingConstants';
import { ModalProvider } from "./application/Common/Context/ModalContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
MomentTimezone.tz.setDefault(TIMEZONE);

root.render(
    <React.StrictMode>
        <ModalProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ModalProvider>
    </React.StrictMode>
);

reportWebVitals();