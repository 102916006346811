import React from 'react';
import { STATE_HOLIDAYS } from '../../../../../../utils/constants/api';
import { StateHolidayFromApi } from '../../../../../../shared/Holiday/StateHoliday/data/StateHolidayFromApi';
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {VALIDATION_ERROR} from "../../../../../../utils/constants/errors";

type DeleteStateHoliday = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    stateHoliday: StateHolidayFromApi
};

function DeleteStateHoliday(props: DeleteStateHoliday) {
    function deleteStateHoliday(): void {
        fetch(STATE_HOLIDAYS + '/' + props.stateHoliday.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'stateHoliday') {
                alert(error.message);
            }
        });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete State Holiday'
            cancelText='Cancel'
            deleteText={'<span class="what-to-delete">&laquo;' + props.stateHoliday.value.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteStateHoliday }
        />
    )
}

export default DeleteStateHoliday;