import React from 'react';
import BaseModal from './BaseModal';
import { STATE_HOLIDAYS } from '../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';
import { CompanyFromApi } from '../../../../../../shared/Company/data/CompanyFromApi';
import { SelectorData } from 'application/Component/Input/DateSelector/data/SelectorData';
import { LocationFromApi } from '../../../Locations/Locations';
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import {addDays} from "date-fns";

type CreateStateHoliday = {
    companies: CompanyFromApi[],
    locations: LocationFromApi[],
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
};

function CreateStateHoliday(props: CreateStateHoliday) {
    function create(
        name: string,
        locations: string[],
        companies: string[],
        type: string,
        movingType: SelectorData,
        date: Date | null,
        repeatAnnually: boolean,
        dayOff: boolean,
        paidTimeOff: boolean,
        additionalPaidTimeOff: SelectValue,
        additionalPaidTimeDate: Date | null,
        text: string | null,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('stateHoliday', JSON.stringify({
            name: name,
            companies: companies,
            locations: locations,
            type:  type,
            movingType: type === 'moving' ? movingType : null,
            date: date ? date.getFullYear() + '-' + (date.getMonth() + 1) + "-" + date.getDate() : null,
            repeatAnnually: repeatAnnually,
            dayOff: dayOff,
            paidTimeOff: paidTimeOff,
            additionalPaidTimeOff: additionalPaidTimeOff ? additionalPaidTimeOff.value : null,
            additionalPaidTimeDate: additionalPaidTimeDate
                ? additionalPaidTimeDate.getFullYear() + '-' + (additionalPaidTimeDate.getMonth() + 1) + "-" + additionalPaidTimeDate.getDate()
                : null,
            text: text,
            visible: visible,
        }));

        fetch(STATE_HOLIDAYS, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create State Holiday'
            name={ '' }
            companyValues={ props.companies }
            companies={ [] }
            locationValues={ props.locations }
            locations={ [] }
            date={ null }
            repeatAnnually={ true }
            dayOff={ 'false' }
            paidTimeOff={ 'true' }
            additionalPaidTimeOff={ { label: 'Yes, next working day', value: '1' } }
            additionalPaidTimeDate={ null }
            type={ 'fixed' }
            movingType={ {'month': '1', 'weekNumber': '1', 'dayOfWeek': 'Monday'} as SelectorData }
            text={ null }
            visible={ true }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateStateHoliday;