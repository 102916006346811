import React, {useEffect, useState} from 'react';
import Modal from '../../../Layer/Modal/Modal';
import TextField, {Type} from '../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../Component/Input/SelectInput/SelectInput';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {SelectValue} from "../../../../../shared/Common/data/SelectValue";
import TextEditor from "../../../../Component/Input/TextEditor/TextEditor";
import DateTimePicker from "../../../../Component/Input/DateTimePicker/DateTimePicker";
import Radio from "../../../../Component/Input/Radio/Radio";
import moment from "moment/moment";
import {LocationFromApi} from "../../Locations/Locations";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {getGMTOffsetByTimezoneName} from "../../../../Common/Timezones/Services";


type BaseModal = {
    opened: boolean,
    modalTitle: string,
    subject: string | null,
    locations: SelectValue[],
    companies: SelectValue[],
    locationValues: LocationFromApi[],
    companyValues: CompanyFromApi[],
    date: Date,
    text: string | null,
    okText: string,
    onCancel: () => any,
    onOk: (
        subject: string,
        companies: string[],
        locations: string[],
        date: Date,
        content: string,
        validate: (errors: ValidationError[]) => void
    ) => void,
    isSent?: boolean
};

function BaseModal(props: BaseModal) {
    const companyOptions = props.companyValues.map(companyValue => {
        return {
            label: companyValue.name,
            value: companyValue.id
        } as SelectValue
    });
    const locationOptions = props.locationValues.map(locationValue => {
        return {
            label: locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone),
            value: locationValue.id
        } as SelectValue
    });

    const [notificationType, setNotificationType] = useState<string>(props.okText === 'Create' ? 'sendNow' : 'sendLater');
    const [sendTypeChoice, setSendTypeChoice] = useState<boolean>(notificationType === 'sendLater');

    const [companies, setCompanies] = useState<SelectValue[]>(props.companies || []);
    const [locations, setLocations] = useState<SelectValue[]>(props.locations || []);
    const [subject, setSubject] = useState<string>(props.subject || '');
    const [date, setDate] = useState<Date>(moment(props.date).toDate());
    const [text, setText] = useState<string>(props.text || '');

    const [subjectError, setSubjectError] = useState<string | null>(null);
    const [locationError, setLocationError] = useState<string | null>(null);
    const [companyError, setCompanyError] = useState<string | null>(null);
    const [dateError, setDateError] = useState<string | null>(null);
    const [textError, setTextError] = useState<string | null>(null);

    function resetAll(): void {
        setSubject('');
        setCompanies([]);
        setLocations([]);
        setText('');
        setSubjectError(null);
        setLocationError(null);
        setCompanyError(null);
        setTextError(null);
        setDateError(null);
    }

    useEffect(() => {
        if (props.modalTitle === 'Create Push Notification') {
            resetAll()
        }
        if (props.companies.length === 0 && props.okText != 'Edit') {
            setCompanies(companyOptions);
        }
        if (props.locations.length === 0 && props.okText != 'Edit') {
            setLocations(locationOptions);
        }
    }, [props.opened]);

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'pushNotification.companiesIds') {
                setCompanyError(error.message);
            } else if (error.property === 'pushNotification.locationsIds') {
                setLocationError(error.message);
            } else if (error.property === 'pushNotification.text') {
                setTextError(error.message);
            } else if (error.property === 'pushNotification.subject') {
                setSubjectError(error.message);
            } else if (error.property === 'pushNotification.sendAt') {
                setDateError(error.message)
            }
        });
    }

    function handleCompaniesChange(newCompanies: SelectValue[]): void {
        setCompanies(newCompanies);
        setCompanyError(null);
    }

    function handleLocationsChange(newLocations: SelectValue[]): void {
        setLocations(newLocations);
        setLocationError(null);
    }

    function handleSubjectChange(newSubject: string): void {
        setSubject(newSubject);
        setSubjectError(null);
    }

    function handleDateChange(newDate: Date): void {
        setDate(newDate);
        setDateError(null);
    }

    function handleEditorChange(newText: string): void {
        setText(newText);
        setTextError(null);
    }

    function handleChangeType(newType: string): void {
        if (newType === 'sendNow') {
            setSendTypeChoice(false);
        }
        if (newType === 'sendLater') {
            setSendTypeChoice(true);
            const now = new Date();
            const nowPlus5Min = new Date(now.getTime() + 5 * 60000);
            setDate(nowPlus5Min);
        }
        setNotificationType(newType);
    }

    function generateDateForSendNowOption() {
        const now = new Date();
        return  new Date(now.getTime() + 60000);
    }

    return (
        <Modal
            isDisabled={props.isSent}
            opened={props.opened}
            modalTitle={props.modalTitle}
            cancelText='Cancel'
            okText={props.okText}
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={() => props.onOk(
                subject,
                companies.map(company => company.value),
                locations.map(location => location.value),
                notificationType === 'sendNow' ? generateDateForSendNowOption() : date,
                text,
                validate
            )}
        >
            <SelectInput
                isSearchable={true}
                label={'Companies'}
                required={true}
                isMulti={true}
                value={companies}
                options={companyOptions}
                error={companyError}
                onChange={newCompanies => handleCompaniesChange(newCompanies)}
            />
            <SelectInput
                isSearchable={true}
                label={'Locations'}
                required={true}
                isMulti={true}
                value={locations}
                options={locationOptions}
                error={locationError}
                onChange={newLocations => handleLocationsChange(newLocations)}
            />
            <TextField
                label='Subject'
                placeholder='Subject...'
                required={true}
                value={subject}
                type={Type.Textarea}
                horizontal={true}
                error={subjectError}
                onChange={newSubject => handleSubjectChange(newSubject)}
                maxLength={100}
            />
            <div style={{display: props.okText==='Create' ? 'block' : 'none'}}>
                <Radio
                    label='Sending'
                    required={false}
                    name={'sending'}
                    value={notificationType}
                    onChange={newType => handleChangeType(newType)}
                    radios={[
                        {label: 'Send Now', value: 'sendNow'},
                        {label: 'Send Later', value: 'sendLater'}
                    ]}
                />
            </div>
            <div style={{display: sendTypeChoice ? 'block' : 'none'}}>
                <DateTimePicker
                    date={date}
                    label={''}
                    onChange={newDate => handleDateChange(newDate)}
                    horizontal={true}
                    required={false}
                    error={dateError}
                />
            </div>
            <TextEditor
                label={'Text'}
                required={true}
                content={text}
                onChange={newText => handleEditorChange(newText)}
                error={textError}
            />
        </Modal>
    );
}

export default BaseModal;