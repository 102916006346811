import React, { useState, useEffect } from 'react';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import './Teams.scss';
import {COMPANIES} from '../../../../utils/constants/api';
import InnerTab from './InnerTab';
import {CompanyFromApi} from '../../../../shared/Company/data/CompanyFromApi';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {ROLE_ADMIN, ROLE_HR} from "../../../../shared/Security/constants/AccessLevels";

type Teams = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Teams(props: Teams) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [activeTab, setActiveTab] = useState(-1);
    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);

    function getCompanies() {
        fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((companies: CompanyFromApi[]) => setCompanies(companies));
    }

    useEffect(() => {
        getCompanies();
    }, [])

    const handleTabClick = (index: React.SetStateAction<number>) => {
        setActiveTab(index);
    };

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex z-index-fix'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Teams</h3>
                                </div>
                            </div>
                            <div className="sub-content-main teams-z-index">
                                <div className="teams">
                                    <div className="vertical-tabs">
                                        <div className="vertical-tabs-menu">
                                            <h3>COMPANIES</h3>
                                            {companies.map((company, index) => (
                                                <div
                                                    key={company.id}
                                                    className={`vertical-tab ${activeTab === index ? "active-company-tab" : ""}`}
                                                    onClick={() => {
                                                        handleTabClick(index);
                                                    }}
                                                >
                                                    <p>{company.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <InnerTab id={companies[activeTab] ? companies[activeTab].id : null} authEmployee={props.authEmployee}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Teams;