import React, {useEffect, useState} from 'react';
import Modal from '../../../../Layer/Modal/Modal';
import TextField, {Type} from '../../../../../Component/Input/TextField/TextField';
import SelectInput from '../../../../../Component/Input/SelectInput/SelectInput';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import DatePicker from "../../../../../Component/Input/DatePicker/DatePicker";
import Radio from "../../../../../Component/Input/Radio/Radio";
import Checkbox from "../../../../../Component/Input/Checkbox/Checkbox";
import moment from "moment";
import {CompanyFromApi} from "../../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../../Locations/Locations";
import {getGMTOffsetByTimezoneName} from "../../../../../Common/Timezones/Services";


type BaseModal = {
    opened: boolean,
    modalTitle: string,
    name: string | null,
    locations: SelectValue[],
    companies: SelectValue[],
    locationValues: LocationFromApi[],
    companyValues: CompanyFromApi[],
    date: Date | null,
    dayOff: string,
    paidTimeOff: string,
    text: string | null,
    visible: boolean,
    archiveAfterFinish: boolean,
    okText: string,
    onCancel: () => any,
    onOk: (
        name: string,
        companies: string[],
        locations: string[],
        date: Date,
        dayOff: boolean,
        paidTimeOff: boolean,
        text: string,
        visible: boolean,
        archiveAfterFinish: boolean,
        validate: (errors: ValidationError[]) => void
    ) => void,
    isSent?: boolean
};

function BaseModal(props: BaseModal) {
    const companyOptions = props.companyValues.map(companyValue => {
        return {
            label: companyValue.name,
            value: companyValue.id
        } as SelectValue
    });
    const locationOptions = props.locationValues.map(locationValue => {
        return {
            label: locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone),
            value: locationValue.id
        } as SelectValue
    });
    const [name, setName] = useState<string>(props.name || '');
    const [companies, setCompanies] = useState<SelectValue[]>(props.okText == 'Edit' ? props.companies : companyOptions);
    const [locations, setLocations] = useState<SelectValue[]>(props.okText == 'Edit' ? props.locations : locationOptions);
    const [date, setDate] = useState<Date | null>(props.date ? moment(props.date).toDate() : null);
    const [dayOff, setDayOff] = useState<string>(props.dayOff);
    const [paidTimeOff, setPaidTimeOff] = useState<string>(props.paidTimeOff);
    const [text, setText] = useState<string>(props.text || '');
    const [visible, setVisible] = useState<boolean>(props.visible);
    const [archiveAfterFinish, setArchiveAfterFinish] = useState<boolean>(props.archiveAfterFinish);

    const [nameError, setNameError] = useState<string | null>(null);
    const [companyError, setCompanyError] = useState<string | null>(null);
    const [locationError, setLocationError] = useState<string | null>(null);
    const [dateError, setDateError] = useState<string | null>(null);
    const [textError, setTextError] = useState<string | null>(null);

    useEffect(() => {
        if (props.modalTitle === 'Create Corporate Event') {
            resetAll()
        }
        if (props.companies.length === 0 && props.okText != 'Edit') {
            setCompanies(companyOptions);
        }
        if (props.locations.length === 0 && props.okText != 'Edit') {
            setLocations(locationOptions);
        }
    }, [props.opened]);

    function resetAll(): void {
        setName('');
        setCompanies([]);
        setLocations([]);
        setDate(null);
        setDayOff('false');
        setPaidTimeOff('false');
        setText('');
        setVisible(true);
        setArchiveAfterFinish(false)
        setNameError(null);
        setLocationError(null);
        setCompanyError(null);
        setDateError(null);
        setTextError(null);
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (!date) {
                console.log('Hey, date is absent!');
            }
            if (error.property === 'corporateEvent.name') {
                setNameError(error.message);
            } else if (error.property === 'corporateEvent.location') {
                setLocationError(error.message);
            } else if (error.property === 'corporateEvent.company') {
                setCompanyError(error.message);
            } else if (error.property === 'corporateEvent.text') {
                setTextError(error.message);
            } else if (error.property === 'corporateEvent.date') {
                setDateError(error.message)
            }
        });
    }

    function clearErrors() {
        setNameError(null);
        setLocationError(null)
        setCompanyError(null)
        setDateError(null)
        setTextError(null)
    }

    function handleNameChange(newName: string): void {
        setName(newName);
        setNameError(null);
    }

    function handleCompaniesChange(newCompanies: SelectValue[]): void {
        setCompanies(newCompanies);
        setCompanyError(null);
    }

    function handleLocationsChange(newLocations: SelectValue[]): void {
        setLocations(newLocations);
        setLocationError(null);
    }

    function handleDateChange(newDate: Date): void {
        setDate(newDate);
        setDateError(null);
    }

    function handleDayOffChange(newDayOff: string): void {
        setDayOff(newDayOff);
    }

    function handlePaidTimeOffChange(newPaidTimeOff: string): void {
        setPaidTimeOff(newPaidTimeOff);
    }

    function handleTextChange(newText: string): void {
        setText(newText);
        setTextError(null);
    }

    function handleVisibleChange(newVisible: boolean): void {
        setVisible(newVisible);
    }

    function handleArchiveAfterFinish(newValue: boolean): void {
        setArchiveAfterFinish(newValue);
    }

    function addOneDay(): Date {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }

    function checkDate (date: Date | null): Date {
        if (!date) {
            setDateError('Date is required');
        }
        return date as Date;
    }

    return (
        <Modal
            isDisabled={ date ? moment(date).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') : false}
            opened={props.opened}
            modalTitle={props.modalTitle}
            cancelText='Cancel'
            okText={props.okText}
            onCancel={() => {
                props.onCancel();
                resetAll();
            }}
            onOk={() => props.onOk(
                name,
                companies.map(company => company.value),
                locations.map(location => location.value),
                checkDate(date),
                dayOff === 'true',
                paidTimeOff === 'true',
                text,
                visible,
                archiveAfterFinish,
                validate
            )}
        >
            <TextField
                label='Name'
                placeholder="Please enter event's name"
                required={true}
                value={name}
                type={Type.Textarea}
                horizontal={true}
                error={nameError}
                onChange={newName => {
                    handleNameChange(newName);
                }}
                onClick={() => clearErrors()}
                maxLength={100}
            />
            <SelectInput
                label={'Companies'}
                isSearchable={true}
                required={true}
                isMulti={true}
                value={companies}
                options={companyOptions}
                error={companyError}
                onChange={newCompanies => {
                    handleCompaniesChange(newCompanies);
                }}
                onFocus={() => clearErrors()}
            />
            <SelectInput
                label={'Locations'}
                isSearchable={true}
                required={true}
                isMulti={true}
                value={locations}
                options={locationOptions}
                error={locationError}
                onChange={newLocations => {
                    handleLocationsChange(newLocations);
                }}
                onFocus={() => clearErrors()}
            />
            <div>
                <DatePicker
                    date={date}
                    minDate={addOneDay()}
                    maxDate={null}
                    label={'Date'}
                    onChange={newDate => {
                        setLocationError(null);
                        setCompanyError(null);
                        handleDateChange(newDate);
                    }}
                    onClick={() => clearErrors()}
                    horizontal={true}
                    required={true}
                    error={dateError}
                    disabled={ props.date ? moment(props.date).toDate() < new Date() : false }
                />
            </div>
            <Radio
                label='Day Off'
                required={false}
                name={'dayOff' + props.okText}
                value={dayOff}
                onChange={newDayOff => handleDayOffChange(newDayOff)}
                radios={[
                    {label: 'Yes', value: 'true'},
                    {label: 'No', value: 'false'}
                ]}
            />
            <div style={{display: dayOff == 'true' ? 'block' : 'none'}}>
                <Radio
                    label='Paid Time Off'
                    required={false}
                    name={'paidTimeOff' + props.okText}
                    value={paidTimeOff}
                    onChange={newPaidTimeOff => handlePaidTimeOffChange(newPaidTimeOff)}
                    radios={[
                        {label: 'Yes', value: 'true'},
                        {label: 'No', value: 'false'}
                    ]}
                />
            </div>
            <TextField
                label='Text'
                placeholder='Please enter text'
                required={false}
                value={text}
                type={Type.Textarea}
                horizontal={true}
                error={textError}
                onChange={newText => {
                    handleTextChange(newText);
                }}
                onClick={() => clearErrors()}
                maxLength={150}
            />
            <Checkbox
                disabled={ false }
                label={ 'Visibility' }
                required={ false }
                checked={ visible }
                checkBoxLabel='Shown'
                onChange={ newShow => handleVisibleChange(newShow) }
                switch={ true }
            />
            <Checkbox
                disabled={ false }
                label={ '' }
                required={ false }
                checked={ archiveAfterFinish }
                checkBoxLabel='Move to archive after finished'
                onChange={ newValue => handleArchiveAfterFinish(newValue) }
                switch={ false }
            />
        </Modal>
    );
}

export default BaseModal;