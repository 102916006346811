import React, {useState} from 'react';
import { FileUploader } from'react-drag-drop-files';
import parse from 'html-react-parser';

import {MAX_IMAGE_SIZE} from "../../../../utils/constants/imageProcess";

import './DragAndDrop.scss';
import '../index.scss';


type DragAndDrop = {
    label: string,
    required: boolean,
    image: File | null,
    onChange: (value: any) => void,
    horizontal: boolean,
    preview: boolean,
    error: string | null,
};

function DragAndDrop(props: DragAndDrop) {
    const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "WEBP"];
    const [previewUrl, setPreviewUrl] = useState('');
    const [image, setImage] = useState<File | null>(props.image);
    const [clicked, setClicked] = useState<boolean>(false);
    const handleChange = (newImage: File | null) => {
        if (newImage instanceof File) {
            resizeImage(newImage, MAX_IMAGE_SIZE, (resizedBlob: Blob) => {
                const resizedFile = new File([resizedBlob], newImage.name, { type: resizedBlob.type });
                const objectUrl = URL.createObjectURL(resizedBlob);
                setImage(resizedFile);
                setPreviewUrl(objectUrl);
                props.onChange(resizedFile);
            });
        } else {
            props.onChange(null);
            setImage(null);
            setPreviewUrl('');
        }
    };

    function resizeImage(file: File, maxSize: number, callback: (resizedBlob: Blob) => void) {
        const img = new Image();
        img.onload = function() {
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    if (blob) callback(blob);
                }, file.type);
            }
        };

        img.src = URL.createObjectURL(file);
    }

    const childrenBlock = '<div class="dnd-title">' +
        '<i class="material-icons drop-icon">file_upload_off</i><br>' +
        'Drag and drop or <span>browse</span></div>';

    return (
        <div className={ 'input' + (props.horizontal ? ' horizontal-input' : '') }>
            <label>{ props.label }{ props.required ? <span>*</span> : '' }</label>
            <div className={'drag-and-drop'}>
                <FileUploader
                    multiple={false}
                    handleChange={(e: any) => {
                        handleChange(e);
                        props.onChange(e);
                        setClicked(true);
                    }}
                    value={ image }
                    name="image"
                    types={fileTypes}
                    children={parse(childrenBlock)}
                    hoverTitle={' '}
                    maxSize={5}
                    onBlur={ () => {
                        setClicked(false);
                        props.error = null }
                    }
                    onDraggingStateChange={(dragging: boolean) => {
                        let icon = document.getElementsByClassName('drop-icon') as HTMLCollectionOf<HTMLElement>;
                        if (dragging && icon) {
                            icon[0].style.color = '#0052CC';
                        } else if (!dragging && icon) {
                            icon[0].style.color = '#6a7885';
                        }
                    }}
                    onTypeError={(error: any) => alert(error)}
                    onSizeError={(error: any) => alert(error)}
                />
                {image && props.preview ?
                    <div className='preview'>
                        <i onClick={() => handleChange(null) } className='material-icons delete-img'>close</i>
                        <img src={
                            previewUrl ?
                                previewUrl :
                                // @ts-ignore
                                image.file
                        }/>
                    </div>
                    : ''
                }
                {(() => {
                    if (props.error && !clicked) {
                        return (
                            <div className='error'>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                                <span>{ props.error }</span>
                            </div>
                        );
                    }
                })()}
            </div>
        </div>
    );
}

export default DragAndDrop;