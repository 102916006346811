import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

import SearchInput from "./Search/SearchInput";
import Button, {Size, Type} from "../../../Component/Button/Button";

import {AUTH, STATUSES} from '../../../../utils/constants/api';
import {EMPLOYEE_PROFILE, MAIN} from '../../../../utils/constants/routes';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';

import ReactLogo from '../../../../assets/images/logo/icon.svg';
import pause from '../../../../assets/images/mainPageIcons/pause.svg';
import close from '../../../../assets/images/mainPageIcons/close.svg';
import play from '../../../../assets/images/mainPageIcons/play.svg';

import './Header.scss';


type Header = {
    loadingBarProgress: number,
    setLoadingBarProgress: (newLoadingBarProgress: number) => void
    authEmployee: AuthEmployee,
    signOut: () => void,
    updateAuthEmployee?: (updatedAuthEmployee: AuthEmployee) => void;
    origin?: string;
};

function Header(props: Header) {
    const [imageUrl, setImageUrl] = useState<string>(props.authEmployee.imageUrl);
    const [attendanceUrls, setAttendanceUrls] = useState({
        start: '',
        end: '',
        pause: '',
        unpause: '',
    });

    useEffect(() => {
        setImageUrl(props.authEmployee.imageUrl);
    }, [props.authEmployee.imageUrl]);

    useEffect(() => {
        fetchAttendanceUrls(props.authEmployee.nickname, props.authEmployee.email);
    }, [props.authEmployee]);

    const handleButtonClick = (url: string) => {
        window.location.href = url;
    };

    function signOut() {
        props.setLoadingBarProgress(50);

        fetch(AUTH, { method: 'DELETE' })
            .then(response => {
                if (response.ok) {
                    response.text()
                        .then(signOutUrl => {
                            props.signOut();
                            window.location.href = signOutUrl;
                            props.setLoadingBarProgress(100);
                        })
                }
            });
    }

    async function fetchAttendanceUrls(nickname: string, email: string): Promise<void> {
        try {
            const queryParams = new URLSearchParams({
                user_nickname: nickname,
                user_email: email,
            });

            const response = await fetch(STATUSES + '/attendance-buttons-statuses?' + queryParams, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            });

            if (!response.ok) {
                console.error('Network response was not ok');
                return;
            }

            const data = await response.json();

            if (data.error) {
                console.error('Invalid data format or failed request:', data.error);
                return;
            }

            const mappedUrls = {
                start: data.attendanceStartURL,
                end: data.attendanceEndURL,
                pause: data.attendancePauseURL,
                unpause: data.attendanceUnpauseURL
            };

            setAttendanceUrls(mappedUrls);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const location = useLocation();

    const handleLogoClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (location.pathname === MAIN) {
            event.preventDefault();
            window.location.reload();
        }
    };

    return (
        <header>
            <LoadingBar
                color='#0052CC'
                transitionTime={0}
                progress={ props.loadingBarProgress }
                onLoaderFinished={() => props.setLoadingBarProgress(0)}
            />
            <div className='text-center d-flex align-items-center logo-block'>
                <Link
                    className='logo'
                    title={"Netpeak One Homepage"}
                    to={ MAIN }
                    onClick={handleLogoClick}
                >
                    <img src={ ReactLogo } alt='Netpeak One Logo'/>
                </Link>
            </div>

            <div className='header-container'>
                <div className='header-row'>
                    <div className={'search-input-container'}>
                        <SearchInput />
                    </div>

                    <div className='d-flex text-center align-items-center user-block'>
                        <div className={'checkin-button-group'}>
                            {attendanceUrls.start &&
                                <Button
                                    text={'Start'}
                                    size={Size.Medium}
                                    type={Type.Accent}
                                    onClick={() => handleButtonClick(attendanceUrls.start)}
                                    icon={play}
                                />
                            }

                            {attendanceUrls.pause &&
                                <Button
                                    text={'Pause'}
                                    size={Size.Medium}
                                    type={Type.Accent}
                                    onClick={() => handleButtonClick(attendanceUrls.pause)}
                                    icon={pause}
                                />
                            }

                            {attendanceUrls.unpause &&
                                <Button
                                    text={'Unpause'}
                                    size={Size.Medium}
                                    type={Type.Accent}
                                    onClick={() => handleButtonClick(attendanceUrls.unpause)}
                                    icon={play}
                                />
                            }

                            {attendanceUrls.end &&
                                <Button
                                    text={'End'}
                                    size={Size.Medium}
                                    type={Type.Accent}
                                    onClick={() => handleButtonClick(attendanceUrls.end)}
                                    icon={close}
                                />
                            }
                        </div>
                        <div className='personal-info-block'>
                            <Link
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                to={EMPLOYEE_PROFILE + '/' + props.authEmployee.nickname}
                            >
                                { imageUrl ? (
                                    <div className='avatar'>
                                        <img src={imageUrl} alt='Employee avatar' />
                                    </div>
                                ) : (
                                    <div className='without-avatar'>
                                        <p>{ props.authEmployee.nickname.charAt(0).toUpperCase() }</p>
                                    </div>
                                )}
                            </Link>
                        </div>
                        <div className='link' title={'Logout'} onClick={ signOut }>
                            <div className='logout-icon-box'>
                                <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M13.1665 3.83333L11.9915 5.00833L14.1415 7.16667H5.6665V8.83333H14.1415L11.9915 10.9833L13.1665 12.1667L17.3332 8L13.1665 3.83333ZM2.33317 2.16667H8.99984V0.5H2.33317C1.4165 0.5 0.666504 1.25 0.666504 2.16667V13.8333C0.666504 14.75 1.4165 15.5 2.33317 15.5H8.99984V13.8333H2.33317V2.16667Z' fill='#6A7885'/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;