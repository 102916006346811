import React from 'react';
import TimeOffPolicyParentModal from './TimeOffPolicyParentModal';
import {TIME_OFF_POLICIES} from '../../../../../utils/constants/api';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../utils/constants/errors";
import {CompanyFromApi} from "../../../../../shared/Company/data/CompanyFromApi";
import {LocationFromApi} from "../../Locations/Locations";
import {AccrualScheduleCast} from "../../../../../shared/TimeOffPolicy/data/AccrualSchedule";

type CreateTimeOffPolicy = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    companies: CompanyFromApi[],
    locations: LocationFromApi[],
};

function CreateTimeOffPolicy(props: CreateTimeOffPolicy) {
    function create(
        name: string,
        companies: string[],
        locations: string[],
        iconId: string,
        description: string,
        hoursPerWorkingDay: number,
        accrualSchedule: object,
        employeesReceiveAccruedTimeAt: string,
        firstAccrualShouldBe: string,
        recalculateTheRemainingDays: boolean,
        hasCarryOverAfterTheCarryOverDate: boolean,
        carryOverDays: number,
        carryOverDaysAreUnlimited: boolean,
        carryOverDateType: string,
        carryOverDay: number | null,
        carryOverMonth: number | null,
        hasWaitingPeriodBeforeAccruingTimeStarts: boolean,
        waitingPeriodDays: number,
        untilEmploymentDate: boolean,
        capOnTheBalanceAnEmployeeCanAccrue: boolean,
        maxNumberOfDaysForTheCapEmployeeCanAccrue: number,
        automaticallyApproveRequests: boolean,
        approvalEmployeeType: string[]|null,
        approvalEmployeeIds: string[]|null,
        allowToAddReplacementPerson: boolean,
        allowToAddReplacementPersonIsMandatory: boolean,
        commentFieldIsMandatory: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('timeOffPolicy', JSON.stringify({
            generalInfo:{
                name: name,
                companiesIds: companies,
                locationsIds: locations,
                iconId: iconId,
                description: description,
            },
            accrualSchedule: {
                hoursPerWorkingDay: hoursPerWorkingDay,
                accrualSchedule: castAccrualSchedule(accrualSchedule as AccrualScheduleCast),
            },
            accrualOptions: {
                employeesReceiveAccruedTimeAt: employeesReceiveAccruedTimeAt,
                firstAccrualShouldBe: firstAccrualShouldBe,
                recalculateTheRemainingDays: recalculateTheRemainingDays,
                hasCarryOverAfterTheCarryOverDate: hasCarryOverAfterTheCarryOverDate,
                carryOverDays: carryOverDays,
                carryOverDaysAreUnlimited: carryOverDaysAreUnlimited,
                carryOverDateType: carryOverDateType,
                carryOverDay: carryOverDay,
                carryOverMonth: carryOverMonth,
                hasWaitingPeriodBeforeAccruingTimeStarts: hasWaitingPeriodBeforeAccruingTimeStarts,
                waitingPeriodDays: waitingPeriodDays,
                untilEmploymentDate: untilEmploymentDate,
                capOnTheBalanceAnEmployeeCanAccrue: capOnTheBalanceAnEmployeeCanAccrue,
                maxNumberOfDaysForTheCapEmployeeCanAccrue: maxNumberOfDaysForTheCapEmployeeCanAccrue,
            },
            approvalOptions: {
                automaticallyApproveRequests: automaticallyApproveRequests,
                approvalEmployeeType: approvalEmployeeType,
                approvalEmployeeIds: approvalEmployeeIds,
                allowToAddReplacementPerson: allowToAddReplacementPerson,
                allowToAddReplacementPersonIsMandatory: allowToAddReplacementPersonIsMandatory,
                commentFieldIsMandatory: commentFieldIsMandatory
            }
        }));

        fetch(TIME_OFF_POLICIES, {method: 'POST', body: formData})
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'timeOffPolicy.name',
                        message: 'Not found'
                    }]);
                }
            });
    }
    return (
        <TimeOffPolicyParentModal
            source = { 'create' }
            opened={ props.opened }
            name={ null }
            companyValues={ props.companies }
            companies={ [] }
            locationValues={ props.locations }
            locations={ [] }
            iconId = { null }
            description={ null }
            hoursPerWorkingDay={ 8 }
            accrualSchedule={ null }
            employeesReceiveAccruedTimeAt={ 'atTheStart' }
            firstAccrualShouldBe={ 'prorated' }
            recalculateTheRemainingDays={ 'false' }
            hasCarryOverAfterTheCarryOverDate={ 'false' }
            carryOverDays={ 0 }
            carryOverDaysAreUnlimited={ 'false' }
            carryOverDateType={ 'hireDate' }
            carryOverDay={ null }
            carryOverMonth={ null }
            hasWaitingPeriodBeforeAccruingTimeStarts={ 'false' }
            waitingPeriodDays={ 0 }
            untilEmploymentDate={ 'false' }
            capOnTheBalanceAnEmployeeCanAccrue={ 'false' }
            maxNumberOfDaysForTheCapEmployeeCanAccrue={ 0 }
            automaticallyApproveRequests={ 'true' }
            approvalEmployeeType={ null }
            approvalEmployeeIds={ null }
            allowToAddReplacementPerson={ 'false' }
            allowToAddReplacementPersonIsMandatory={ false }
            commentFieldIsMandatory={ false }
            onOk={ create }
            onCancel={ props.onClose }
            />
    )
}

export function castAccrualSchedule(accrualSchedule: AccrualScheduleCast) {
    let newObject = {...accrualSchedule} as AccrualScheduleCast
    if (newObject.params.daysToAllocate === '') {
        newObject.params.daysToAllocate = 0;
    }
    if (newObject.params.days === '') {
        newObject.params.days = 0;
    }
    if (newObject.params.perHours === '') {
        newObject.params.perHours = 0;
    }
    if (newObject.params.daysToAllocate) {
        newObject.params.daysToAllocate = parseFloat(
            (newObject.params.daysToAllocate as string).replace(',', '.')
        );
    }
    if (newObject.params.days) {
        newObject.params.days = parseFloat((newObject.params.days as string).replace(',', '.'));
    }
    if (newObject.params.perHours) {
        newObject.params.perHours = parseFloat(
            (newObject.params.perHours as string).replace(',', '.')
        );
    }
    if (newObject.params.daysPerDays) {
        newObject.params.daysPerDays = parseFloat(
            (newObject.params.daysPerDays as string).replace(',', '.')
        );
    }
    if (newObject.params.day) {
        newObject.params.day = parseInt(newObject.params.day as string);
    }
    if (newObject.params.month) {
        newObject.params.month = parseInt(newObject.params.month as string);
    }
    return newObject;
}

export default CreateTimeOffPolicy;