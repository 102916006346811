import React, { useState, useEffect } from 'react';
import './Types.scss';
import Header from './../../../../Page/Layer/Header/Header';
import Navbar from '../../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../../Page/Layer/SubMenu/SubMenu';
import CreateType from './Modal/CreateType';
import DeleteType from './Modal/DeleteType';
import EditType from './Modal/EditType';
import { TYPES } from '../../../../../utils/constants/api';
import Button, {Size, Type} from '../../../../Component/Button/Button';
import { AuthEmployee } from '../../../../../shared/Security/data/AuthEmployee';


type Types = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

export type TypeFromApi = {
    id: string,
    name: string,
}

function Types(props: Types) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createTypeModal, setCreateTypeModal] = useState<boolean>(false);
    const [editTypeModal, setEditTypeModal] = useState<boolean>(false);
    const [deleteTypeModal, setDeleteTypeModal] = useState<boolean>(false);

    const [types, setTypes] = useState<TypeFromApi[]>([]);
    const [typeToChange, setTypeToChange] = useState<TypeFromApi | null>(null);
    const [typeToDelete, deleteType] = useState<TypeFromApi | null>(null);

    useEffect(() => {
        getTypes();
    }, [])

    function getTypes() {
        fetch(TYPES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((types: TypeFromApi[]) => setTypes(types));
    }

    function editTypeModalVisibility(type: TypeFromApi): void {
        setTypeToChange(type);
        setEditTypeModal(true);
    }

    function confirmDeleteTypeModalVisibility(type: TypeFromApi): void {
        deleteType(type)
        setDeleteTypeModal(true);
    }

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Employee Types</h3>
                                </div>

                                <div className='add-button-block'>
                                    <Button
                                        text="<i className='material-icons add-item'>add</i> Add Type"
                                        size={ Size.Medium }
                                        type={ Type.Accent }
                                        onClick={ () => setCreateTypeModal(true) }
                                    />
                                </div>
                            </div>
                            <div className='sub-content-main'>
                                <table className="types-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Type
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {types.map(type => {
                                        return (
                                            <tr key={ type.id }>
                                                <td title={ type.name }>{ type.name }</td>
                                                <td>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => editTypeModalVisibility(type) }
                                                    >
                                                        edit
                                                    </i>
                                                    <i
                                                        className='material-icons'
                                                        onClick={ () => confirmDeleteTypeModalVisibility(type) }
                                                    >
                                                        delete
                                                    </i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateType
                    opened={ createTypeModal }
                    onOk={ () => { getTypes(); setCreateTypeModal(false); } }
                    onClose={ () => setCreateTypeModal(false) }
                />
                {
                    typeToDelete ?
                        <DeleteType
                            opened={ deleteTypeModal }
                            onOk={ () => { getTypes(); setDeleteTypeModal(false); deleteType(null) } }
                            onClose={ () => { setDeleteTypeModal(false); deleteType(null) } }
                            type={ typeToDelete }
                        /> : null
                }
                {
                    typeToChange
                        ?
                        <EditType
                            opened={ editTypeModal }
                            onOk={ () => { getTypes(); setEditTypeModal(false); setTypeToChange(null) } }
                            onClose={ () => { setEditTypeModal(false); setTypeToChange(null) } }
                            type={ typeToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Types;