import React from 'react';
import './FirstPageBaseModal.scss';
import './WidePageBaseModal.scss';
import Button, { Size, Type } from '../../../../Component/Button/Button';

type BaseModal = {
    children: React.ReactNode,
    opened: boolean,
    modalTitle: string,
    okText: string,
    onOk: (...value: any) => any,
    hasCancel: boolean,
    cancelText?: string,
    onCancel?: (...value: any) => any,
    style: string,
};

function BaseModal(props: BaseModal) {
    const modalClass = props.style === 'first-page' ? 'first-page-modal' : 'wide-page-modal';
    return (
        <div className={`${modalClass} ${modalClass}-${props.opened ? 'opened' : 'closed'}`}>
            <div className={`${modalClass}-content`}>
                <div className={`${modalClass}-title`}>
                    { props.modalTitle }
                </div>
                <div className={`${modalClass}-line`}></div>
                <div className={`${modalClass}-body`}>
                    { props.children }
                </div>
                <div className={`${modalClass}-footer`}>
                    {props.hasCancel && props.onCancel && (
                        <Button
                            text={props.cancelText || "Cancel"}
                            size={Size.Medium}
                            type={Type.White}
                            onClick={props.onCancel}
                        />
                    )}
                    <Button
                        text={props.okText}
                        size={Size.Medium}
                        type={Type.Accent}
                        onClick={props.onOk}
                    />
                </div>
            </div>
        </div>
    );
}

export default BaseModal;