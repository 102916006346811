import React from 'react';
import { SEXES } from '../../../../../../../utils/constants/api';
import { SexFromApi } from '../../../../../../../shared/Employee/Sex/data/SexFromApi';
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal';
import {ValidationError} from "../../../../../../../shared/Common/Error/ValidationError";
import {VALIDATION_ERROR} from "../../../../../../../utils/constants/errors";

type DeleteSex = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    sex: SexFromApi
};

function DeleteSex(props: DeleteSex) {
    function deleteSex(): void {
        fetch(SEXES + '/' + props.sex.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    function validate(errors: ValidationError[]): void {
        errors.forEach((error: ValidationError) => {
            if (error.property === 'sex') {
                alert(error.message);
            }
        });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Sex'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.sex.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteSex }
        />
    )
}

export default DeleteSex;