import React from 'react';
import BaseModal  from './BaseModal';
import { JOB_TITLES_CATEGORIES } from '../../../../../../utils/constants/api';
import { JobTitlesCategoryFromApi} from '../../../../../../shared/JobTitle/data/JobTitlesCategoryFromApi';
import { JobTitleFromApi } from '../../../../../../shared/JobTitle/data/JobTitleFromApi';
import {ValidationError} from "../../../../../../shared/Common/Error/ValidationError";
import {NOT_FOUND_ERROR, VALIDATION_ERROR} from "../../../../../../utils/constants/errors";

type EditJobTitle = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    categories: JobTitlesCategoryFromApi[],
    jobTitle: JobTitleFromApi
};

type CategorySelectValue = {
    label: string,
    value: string
}

function EditCategory(props: EditJobTitle) {

    const category = props.categories
        .find(category => category.id === props.jobTitle.categoryId) as JobTitlesCategoryFromApi;

    const categorySelectValue = { label: category.name, value: category.id } as CategorySelectValue;
    function edit(name: string, categoryId: string|null, validate: (errors: ValidationError[]) => void): void {
        fetch(JOB_TITLES_CATEGORIES + '/' + categoryId + '/job-titles/' + props.jobTitle.id , {
            method: 'PUT',
            body: JSON.stringify({
                name: name
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                } else if (response.status === NOT_FOUND_ERROR) {
                    validate([{
                        property: 'title.categoryId',
                        message: 'Category is required'
                    }]);
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Job Title'
            name={ props.jobTitle.name }
            category={ categorySelectValue }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
            categories={ props.categories }
         />
    )
}

export default EditCategory;