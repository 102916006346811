import React, {useEffect, useState} from 'react';
import './ClockWidget.scss';
import {AuthEmployee} from "../../../../../shared/Security/data/AuthEmployee";
import {LOCATIONS} from "../../../../../utils/constants/api";

type ClockWidget = {
    authEmployee: AuthEmployee,
};

type Timezone = {
    country: string,
    city: string,
    timezone: string,
}

function ClockWidget(props: ClockWidget) {
    const [timezones, setTimezones] = useState<Timezone[]>([]);

    useEffect(() => {
        async function fetchTimezones() {
            const res = await fetch(LOCATIONS + '/employee/' + props.authEmployee.id + '/related-timezones');
            const data = await res.json();
            setTimezones(data.slice(0, 5));
        }

        fetchTimezones();
    }, [props.authEmployee.id]);

    useEffect(() => {
        function updateClock() {
            if(timezones.length > 0) {
                const currentTime = document.querySelector('.clock-widget .time');
                const today = new Date(new Date().toLocaleString('en-US', {timeZone: timezones[0].timezone}));
                let h = today.getHours().toString().padStart(2, '0');
                let m = today.getMinutes().toString().padStart(2, '0');

                if (currentTime) {
                    currentTime.innerHTML =  h + ':' + m;
                }

                timezones.slice(1).forEach(({ city, timezone }) => {
                    const additionalTime = document.querySelector(`.clock-widget .${city.split(' ').join('_')}`);
                    const currentTimeZone = new Date(new Date().toLocaleString('en-US', {timeZone: timezone}));
                    let ht = currentTimeZone.getHours().toString().padStart(2, '0');
                    let mt = currentTimeZone.getMinutes().toString().padStart(2, '0');

                    if (additionalTime) {
                        additionalTime.innerHTML = ht + ':' + mt;
                    }
                });
            }
        }

        updateClock();
        const currentTime = new Date();
        const delayUntilNextMinute = 60 - currentTime.getSeconds();
        setTimeout(() => {
            updateClock();
            setInterval(updateClock, 60000);
        }, delayUntilNextMinute * 1000);
    }, [timezones]);

    return (
        <div className='clock-widget'>
            {timezones.length > 0 && (
                <>
                    <div className='time-zone-container'>
                        <span className='time'>00:00</span>
                        <div className='timezone-info'>
                            <span className='current-timezone'>CURRENT TIMEZONE</span>
                            <span className='timezone'>{`${timezones[0].city}, ${timezones[0].country}`}</span>
                        </div>
                    </div>
                    <div className='divider' />
                    <div className='additional-timezones'>
                        {timezones.slice(1).map(({ city}) => (
                            <div key={city} className='timezone-container' title={city}>
                                <span className='city'>{city}</span>
                                <span className={`additional-time ${city.split(' ').join('_')}`}>00:00</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default ClockWidget;