import React, { useState, useEffect } from 'react';
import './Companies.scss';
import Header from './../../../Page/Layer/Header/Header';
import Navbar from '../../../Page/Layer/Navbar/Navbar';
import SubMenu from '../../../Page/Layer/SubMenu/SubMenu';
import CreateCompany from './Modal/CreateCompany';
import DeleteCompany from './Modal/DeleteCompany';
import EditCompany from './Modal/EditCompany';
import { COMPANIES } from '../../../../utils/constants/api';
import Button, { Size, Type } from '../../../Component/Button/Button';
import { CompanyFromApi } from '../../../../shared/Company/data/CompanyFromApi';
import { AuthEmployee } from '../../../../shared/Security/data/AuthEmployee';
import {ROLE_ADMIN, ROLE_HR, ROLE_OPERATIONAL_MANAGER} from "../../../../shared/Security/constants/AccessLevels";

type Companies = {
    authEmployee: AuthEmployee,
    signOut: () => void
};

function Companies(props: Companies) {
    const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);

    const [createCompanyModal, setCreateCompanyModal] = useState<boolean>(false);
    const [editCompanyModal, setEditCompanyModal] = useState<boolean>(false);
    const [deleteCompanyModal, setDeleteCompanyModal] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanyFromApi[]>([]);
    const [companyToChange, setCompanyToChange] = useState<CompanyFromApi | null>(null);
    const [companyToDelete, deleteCompany] = useState<CompanyFromApi | null>(null);


    useEffect(getCompanies, []);

    function getCompanies() {
        fetch(COMPANIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((companies: CompanyFromApi[]) => setCompanies(companies));
    }

    function editCompanyModalVisibility(company: CompanyFromApi): void {
        setCompanyToChange(company);
        setEditCompanyModal(true);
    }

    function confirmDeleteCompanyModalVisibility(company: CompanyFromApi): void {
        deleteCompany(company)
        setDeleteCompanyModal(true);
    }

    const roles = [ROLE_ADMIN, ROLE_HR];
    const hasRole = roles.some(role => props.authEmployee.roles.includes(role));

    return (
        <div>
            <Header
                loadingBarProgress={ loadingBarProgress }
                setLoadingBarProgress={ newLoadingBarProgress => setLoadingBarProgress(newLoadingBarProgress) }
                authEmployee={ props.authEmployee }
                signOut={ props.signOut }
            />
            <div className='main-flex'>
                <Navbar authEmployee={props.authEmployee} />
                <div className='main-content'>
                    <div className='main-title-block'>
                        <div className='d-flex align-items-center'>
                            <i className='material-icons'>settings</i>
                        </div>
                        <h2>Settings</h2>
                    </div>
                    <div className='d-flex'>
                        <SubMenu authEmployee={props.authEmployee} />
                        <div className='sub-content-block d-flex'>
                            <div className='sub-content-header d-flex'>
                                <div className='sub-title-block d-flex'>
                                    <h3>Companies</h3>
                                </div>

                                {hasRole && (
                                    <div className='add-button-block'>
                                        <Button
                                            text="<i className='material-icons add-item'>add</i> Add company"
                                            size={ Size.Medium }
                                            type={ Type.Accent }
                                            onClick={ () => setCreateCompanyModal(true) }
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='sub-content-main'>
                                <table className="companies-table">
                                    <thead>
                                    <tr>
                                        <th>
                                            Logotype
                                        </th>
                                        <th>
                                            Company Name
                                        </th>
                                        <th>
                                            Parent’s Company name
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {companies.map(company => {
                                        return (
                                            <tr key={ company.id }>
                                                <td> {company.logotype ?
                                                    <img src={
                                                        // @ts-ignore
                                                        company.logotype.file }
                                                    /> : '' }
                                                </td>
                                                <td title={ company.name }>{ company.name }</td>
                                                <td title={ company.parentName ?? '' }>{ company.parentName ?? '-' }</td>
                                                <td>
                                                    {hasRole && (
                                                        <>
                                                            <i
                                                                className='material-icons'
                                                                onClick={ () => editCompanyModalVisibility(company) }
                                                            >
                                                                edit
                                                            </i>
                                                            <i
                                                                className='material-icons'
                                                                onClick={ () => confirmDeleteCompanyModalVisibility(company) }
                                                            >
                                                                delete
                                                            </i>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateCompany
                    companies={ companies }
                    opened={ createCompanyModal }
                    onOk={ () => { getCompanies(); setCreateCompanyModal(false); } }
                    onClose={ () => setCreateCompanyModal(false) }
                />
                {
                    companyToDelete ?
                        <DeleteCompany
                            opened={ deleteCompanyModal }
                            onOk={ () => { getCompanies(); setDeleteCompanyModal(false); deleteCompany(null) } }
                            onClose={ () => { setDeleteCompanyModal(false); deleteCompany(null) } }
                            company={ companyToDelete }
                        /> : null
                }
                {
                    companyToChange
                        ?
                        <EditCompany
                            companies={ companies.filter(company => company.id !== companyToChange.id) }
                            opened={ editCompanyModal }
                            onOk={ () => { getCompanies(); setEditCompanyModal(false); setCompanyToChange(null) } }
                            onClose={ () => { setEditCompanyModal(false); setCompanyToChange(null) } }
                            company={ companyToChange }
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default Companies;