import React from 'react';
import BaseModal  from './BaseModal';
import { TeamFromApi } from '../../../../../shared/Team/data/TeamFromApi';
import { TEAMS } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type EditTeam = {
    opened: boolean,
    teams: TeamFromApi[],
    onOk: () => void,
    onClose: () => any,
    team: TeamFromApi
};

function EditTeam(props: EditTeam) {
    const parentTeam = props.team.parentId && props.team.parentName ?
        { label: props.team.parentName, value: props.team.parentId } : null;

    const leaderTeam = props.team.leaderId && props.team.leaderName ?
        { label: props.team.leaderName, value: props.team.leaderId } : null;

    function edit(
        name: string,
        parentId: string | null,
        companyId: string,
        leaderId: string | null,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(TEAMS + '/' + props.team.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                parentId: parentId,
                companyId: companyId,
                leaderId: leaderId,
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Team'
            teams={ props.teams }
            teamValue={ { id: props.team.id, name: props.team.name} }
            parentTeam={ parentTeam }
            leaderTeam={ leaderTeam }
            companyId={ props.team.companyId }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditTeam;