export function getPreviousSalaryPeriodDates(): { startDate: string, endDate: string } {
    const today = new Date();
    const year = today.getFullYear();
    let startMonth, endMonth, startYear, endYear;

    if (today.getDate() >= 5) {
        startMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
        endMonth = today.getMonth();
        startYear = today.getMonth() === 0 ? year - 1 : year;
        endYear = year;
    } else {
        startMonth = today.getMonth() === 0 ? 10 : today.getMonth() - 2;
        endMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
        startYear = (today.getMonth() === 0 || today.getMonth() === 1) ? year - 1 : year;
        endYear = today.getMonth() === 0 ? year - 1 : year;
    }

    const startDate = new Date(startYear, startMonth, 5);
    const endDate = new Date(endYear, endMonth, 4);

    const formatDate = (date: Date): string => {
        const d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        return [year, month.length < 2 ? '0' + month : month, day.length < 2 ? '0' + day : day].join('-');
    };

    return {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
    };
}