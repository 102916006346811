import React, {useEffect, useState} from 'react';
import './MemeGenWidget.scss';
import {MEMEGEN} from '../../../../../utils/constants/api';
import {MemeGenForWidget} from "../../../../../shared/MemeGen/data/MemeGenForWidget";

function MemeGenWidget() {
    const [lastMeme, setLastMeme] = useState<MemeGenForWidget|null>(null);
    const [topMeme, setTopMeme] = useState<MemeGenForWidget|null>(null);

    useEffect(() => {
        fetch(MEMEGEN + '/last', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((meme: MemeGenForWidget) => {
                setLastMeme(meme);
            });
        fetch(MEMEGEN + '/top', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((meme: MemeGenForWidget) => {
                setTopMeme(meme);
            });
    }, []);

    if (lastMeme || topMeme) {
        return (
            <div className='meme-gen-widget'>
                <div className='meme-gen-header'>
                    <a target="_blank" rel="noopener noreferrer" href="https://mem.netpeak.ua/">
                        <h2>Memegen</h2>
                    </a>
                </div>
                <div className='widget-body'>
                    {lastMeme &&
                        <>
                            <a target="_blank" rel="noopener noreferrer" href={lastMeme.url}>
                                <p>Last meme:</p>
                            </a>
                            <a className={'meme-link-block'} target="_blank" rel="noopener noreferrer" href={lastMeme.url}>
                                <img src={lastMeme.image} alt='Last Meme'/>
                                <div className="meme-bg-block"
                                     style={{backgroundImage: `url(${lastMeme.image})`}}></div>
                            </a>
                        </>
                    }
                    {topMeme &&
                        <>
                            <a target="_blank" rel="noopener noreferrer" href={topMeme.url}>
                                <p className={'top-title'}>Previous month best meme:</p>
                            </a>
                            <a className={'meme-link-block'} target="_blank" rel="noopener noreferrer" href={topMeme.url}>
                                <img src={topMeme.image} alt='Previous Month Best Meme'/>
                                <div className="meme-bg-block" style={{backgroundImage: `url(${topMeme.image})`}}></div>
                            </a>
                        </>
                    }
                </div>
            </div>
        )
    } else {
        return <div></div>;
    }
}

export default MemeGenWidget;