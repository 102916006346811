import React from 'react';
import { STATUSES } from '../../../../../../../utils/constants/api';
import  {StatusFromApi } from '../../Statuses';
import ConfirmDeleteModal from '../../../../../Layer/Modal/ConfirmDeleteModal';

type DeleteStatus = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    status: StatusFromApi
};

function DeleteStatus(props: DeleteStatus) {
    function deleteStatus(): void {
        fetch(STATUSES + '/' + props.status.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then();
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Employee Status'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.status.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteStatus }
        />
    )
}

export default DeleteStatus;